import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/FourthTeenagers360.jpg"

const FourthTeenagers360 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            29 и 30 ноября в Москве состоялся IV Федеральный форум «Подростки 360»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>На форуме были впервые представлены результаты всероссийского исследования «Подростки 360», охватившего
                300 тысяч респондентов, и лучшие практики по работе с подростками. Темы выездных площадок и круглых
                столов выбраны именно на основании результатов проведенных исследований.</p>
            <p>В ходе IV Федерального форума «Подростки 360» были подведены итоги девятимесячной работы стратегической
                программы «Подростки России». На пленарном заседания форума Уполномоченный при Президенте Российской
                Федерации по правам ребенка Мария Львова- Белова отметила такие достижения программы как:</p>
            <p>• сформированное сообщество экспертов, выявленные и распространяемые ведущие технологии работы с
                подростками;</p>
            <p>• четыре открытых подростковых центра и завершающиеся приготовления в новых;</p>
            <p>• создание социальных уличных служб и запуск формата лагерных смен для подростков, переживших боевые
                действия, под названием «Послезавтра»;</p>
            <p>• более 623 тысяч уникальных пользователей медиаресурса «Росподрос»;</p>
            <p>• федеральная поддержка на оснащение центров, в размере 15 млн рублей, которую получили региональные
                команды, предложившие лучшие программы социализации;</p>
            <p>• Федеральный подростковый центр – отдельная структура Минпросвещения, созданная по поручению Президента
                и отвечающая за методическое и ресурсное сопровождение работы с подростками по всей стране.</p>
            <p>Модерировал дискуссию круглого стола Майоров Валерий Сергеевич, руководитель Федерального Центра развития
                программ социализации подростков.</p>
            <p><strong>«Очень важно говорить про “помогающие” проекты, работающие на подростков, во многом опираясь на
                научные исследования и доказательный подход, чтобы они были полезны, и чтобы их польза была измерима» –
                заявил Валерий Майоров. В рамках круглого стола эксперты знакомились с лучшими практиками, обсуждали
                виды и формы проектов, их жизнеспособность, критерии и показатели эффективности программ
                для подростков, культуру доказательности. В обсуждении принимали участие и сами подростки, что
                положительно повлияет на результативность принимаемых решений.<br/>
                Форум состоялся по инициативе Уполномоченного при Президенте Российской Федерации по правам ребенка
                Марии Львовой-Беловой.<br/>
                Организатор — Центр развития социальных проектов в рамках федерального проекта.</strong></p>
        </div>
        <ToNews/>
    </div>
}


export default FourthTeenagers360;