import React from "react";
import ico from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {Hrefs} from "../PageInfo/PageInfoPriceLife"
import {FileLeftLineKDN} from "./FilesKDNNews";

const hrefsArr = [
    {text: "Нормативно-правовые материалы", to: "/spec-value-of-life/normativno-pravovye-materialy", input: []},
    {
        text: "Информационно-методические материалы для специалистов",
        to: "/spec-value-of-life/metodicheskie-materialy-dlya-spetsialistov"
    },
    {
        text: "Информационные материалы для родителей и детей",
        to: "/spec-value-of-life/informatsionnye-materialy-dlya-roditelej"
    },
    {text: "Видеоматериалы", to: "/spec-value-of-life/video-price"},
    {text: "Полезные ссылки", to: "/spec-value-of-life/poleznye-ssylki"},
]
const ValueRef = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>


        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2 style={{
                    fontWeight: "normal",
                    color: "#3a4663",
                    fontFamily: "Proxima Nova Lt",
                    fontSize: "30px",
                    textAlign: "start"
                }}>
                    Полезные ссылки
                </h2>
                <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                    Ссылки на информационные ресурсы
                </h1>
                <FileLeftLineKDN text="Опрос о безопасном поведении в условиях современной жизни"
                                 refers="http://опрос-о-безопасном-поведении.рф/" ico={ico}/>
                <FileLeftLineKDN
                    text="Тематический выпуск по проблеме суицидального поведения: журнал «Консультативная психология и психотерапия». 2016. Том 24. № 3."
                    refers="http://psyjournals.ru/mpj/2016/n3/syrokvashina.shtml" ico={ico}/>
                <FileLeftLineKDN text="Журнал «Суицидология»" refers="https://cyberleninka.ru/journal/n/suicidology"
                                 ico={ico}/>
                <FileLeftLineKDN text="Психологическая газета" refers="https://psy.su/" ico={ico}/>
                <FileLeftLineKDN text="Видеоматериалы" refers="https://psy.su/feed/cat/videomaterialy/" ico={ico}/>
                <FileLeftLineKDN text="http://www.ya-roditel.ru/" refers="Сайт Я — родитель" ico={ico}/>
            </div>
            <div style={{marginTop: "57px"}}>
                <Hrefs hrefsArr={hrefsArr}/>
            </div>
        </div>
    </>
}
export default ValueRef