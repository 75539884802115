import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/ps-on-the-child.jpg"

const PCOnTheSideOfTheChild = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Пресс-конференция, посвященная проведению Всероссийского конкурса «На стороне ребенка»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>8 ноября в 13:00 в <a href="http://pressmia.ru/pressclub/20221108/953885107.html">Международном
                мультимедийном пресс-центре «Россия сегодня»</a> состоится
                пресс-конференция, посвященная проведению Всероссийского конкурса в отношении несовершеннолетних граждан
                «На стороне ребенка»</p>
        </div>
        <ToNews/>
    </div>
}


export default PCOnTheSideOfTheChild;