import React from "react";
import ico from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1} from "../PageInfo/PageInfo"

const hrefsArr = [
    {
        text: "Нормативно-правовые документы",
        input: [{text: "Федеральные законы", to: "/spec-materials-category/federalnye-zakony"}, {
            text: "Стратегии",
            to: "/spec-materials-category/strategii"
        }, {text: "Концепции", to: "/spec-materials-category/kontseptsii"}, {
            text: "Иные регламентирующие документы",
            to: "files-addect-behavior"
        }],
        to: "/spec-materials-category/normativno-pravovye-dokumenty"
    },
    {
        text: "Материалы для специалистов",
        input: [{
            text: "Материалы антинаркотического содержания, разработанные в субъектах Российской Федерации",
            to: ""
        },
            {
                text: "Методические материалы по профилактике аддиктивного поведения",
                to: "/"
            }, {text: "Статьи и публикации", to: ""}],
        to: ""
    },
    {text: "Материалы для родителей", to: "/spec-materials-category/informatsionnye-materialy-dlya-roditelej"},
    {
        text: "Материалы для подростков и молодёжи", to: "",
        input: [{
            text: "Информационные материалы для обучающихся",
            to: ""
        }, {
            text: "Видеоролики, направленные на профилактику зависимого поведения обучающихся и формирование здорового образа жизни",
            to: ""
        }]
    },
    {
        text: "Эффективная региональная политика",
        input: [{
            text: "Материалы антинаркотического содержания, разработанные в субъектах Российской Федерации",
            to: ""
        },
            {
                text: "Школьное телевидение как форма отражения и трансляции социальных инициатив",
                to: "/school-behavior"
            }],
        to: ""
    },
    {text: "Мероприятия", to: ""},
    {text: "Полезная информация", to: "/spec-materials-category/poleznye-ssylki-goryachie-linii"},
]
const FilesInfMatireal = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>

        <div className={s.filesLeftPage}>
            <h2>
                Информационные материалы для обучающихся
            </h2>
            <div className={s.newHeader}>
                <FileLeftLine text={respData[44]?.description} refers={respData[44]?.file} ico={ico}/>
                <FileLeftLine text={respData[45]?.description} refers={respData[45]?.file} ico={ico}/>
                <FileLeftLine text={respData[46]?.description} refers={respData[46]?.file} ico={ico}/>
                <FileLeftLine text={respData[47]?.description} refers={respData[47]?.file} ico={ico}/>

            </div>
            <div>
                <HrefsBeh1 hrefsArr={hrefsArr}/>
            </div>
        </div>
    </>
}
export default FilesInfMatireal