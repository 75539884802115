import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News2112232.png"

const News2112232 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Всероссийская научно-практическая конференция по актуальным вопросам профилактики девиантного поведения
            несовершеннолетних «Безопасная образовательная среда: стратегии, практики, ресурсы»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            7 декабря 2023 году Минпросвещения России проводит Всероссийскую научно-практическую конференцию по
            актуальным вопросам профилактики девиантного поведения несовершеннолетних «Безопасная образовательная среда:
            стратегии, практики, ресурсы», цель которой – обсуждение эффективных механизмов предупреждения и
            нивелирования факторов формирования девиантного поведения несовершеннолетних в образовательной среде, с
            учетом актуальных рисков и негативных агентов социализации, на основе применения передового
            научно-практического опыта.
            <p></p>
            Оператор Конференции – ФГБУ «Центр защиты прав и интересов детей».
            <p></p>
            В Конференции запланировано участие представителей аппарата Уполномоченного при Президенте Российской
            Федерации по правам ребенка; заинтересованных федеральных органов власти; органов и учреждений системы
            профилактики безнадзорности и правонарушений несовершеннолетних субъектов Российской Федерации; научных,
            общественных организаций; советников по воспитанию, педагогов, педагогов-психологов, социальных педагогов,
            специалистов служб медиации и примирения образовательных организаций.
            <p></p>
            Проведение Конференции запланировано в комбинированном формате: очном/дистанционном (пленарное заседание),
            дистанционном (секционная работа), дистанционном/заочном (марафон мастер-классов) и заочном (публикация в
            электронном сборнике, доступ к материалам Конференции).
            <p></p>
            Ссылки на доступ к мероприятиям Конференции доступны в программе Конференции.
            <p></p>
            <strong><a href="https://fcprc.ru/media/media/behavior/Программа_конференции_Профилактика.pdf">Программа конференции</a></strong>
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News2112232;