import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import ToNews from "../News/ToNews";
import img from "../../assets/News/news150824.jpg"


const News160824 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
           Вебинар 
«Успешная адаптация обучающихся к новому учебному году»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
        23 августа 2024 г. в 10.00 (мск) Минпросвещения России совместно с ФГБУ «Центр защиты прав и интересов детей» в рамках реализации Десятилетия детства проводит вебинар «Успешная адаптация обучающихся к новому учебному году». 
            <p></p>
            На данном вебинаре будут рассмотрены такие актуальные вопросы, как психологическое сопровождение детей в начале учебного года и профилактика социально-психологической дезадаптации школьников.
            <p></p>
            Мероприятие пройдет в онлайн-формате.
            <div></div>
            Необходима предварительная регистрация.
            <p></p>
            
            
        </div>
        <a target="_blank" href="https://my.mts-link.ru/j/19466565/1358501762" style={{fontWeight:600}}> Зарегистрироваться</a>
        <p></p>
        <ToNews/>
    </div>
}


export default News160824;