import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News20062022.jpg"

const News20062022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Открыт доступ для заочного участия во Всероссийской научно-практической конференции «На защите детства:
            стратегии, практики, ресурсы»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>31 мая – 1 июня 2022 года Министерством просвещения Российской Федерации проведена Всероссийская
                научно-практическая конференция по актуальным вопросам профилактики девиантного поведения
                несовершеннолетних «На защите детства: стратегии, практики, ресурсы».</p>
            <p>В мероприятиях Конференции приняли участие более 3000 специалистов из 85 субъектов Российской Федерации.
                Среди участников конференции: представители Администрации Президента Российской Федерации; аппарата
                Уполномоченного при Президенте Российской Федерации по правам ребенка; заинтересованных федеральных
                органов государственной власти; органов и учреждений системы профилактики безнадзорности и
                правонарушений несовершеннолетних субъектов Российской Федерации; образовательных, научных и
                общественных организаций.</p>
            <p>Учитывая большой интерес к обсуждаемым на площадках Конференции вопросам, принято решение о
                предоставлении заинтересованным лицам возможности заочного участия в период с 20 июня 2022 года по 20
                декабря 2022 года.</p>
            <p>Площадкой проведения заочного этапа Конференции выступает официальный сайт ФГБУ «Центр защиты прав и
                интересов детей»</p>
            <p>Для заочного участия в Конференции необходимо заполнить регистрационную форму участника.</p>
            <p>После заполнения регистрационной формы вам откроется электронный доступ ко всем материалам Конференции,
                включая сертификат участника.</p>
            <br/>
            <div className={s.blueButtonMediaciaBase} style={{maxWidth: "300px"}}>
                <a href="https://forms.gle/va5HvHDsUJzdkj8u9">Регистрационная кнопка участника</a>
            </div>
            <br/>
            <div style={{textAlign: "center"}}>
                Желаем всем участникам заочного этапа Конференции получить максимальную пользу от представленных
                материалов и успешно использовать их в своей дальнейшей работе!
            </div>
        </div>
        <ToNews/>
    </div>
}


export default News20062022;