import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/SupportEducationalInstitutions2022_1.jpg"
import img2 from "../../assets/News/SupportEducationalInstitutions2022_2.jpg"
import img3 from "../../assets/News/SupportEducationalInstitutions2022_3.png"
import img4 from "../../assets/News/SupportEducationalInstitutions2022_4.jpg"

const SupportEducationalInstitutions2022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сопровождение служб медиации в специальных учебно- воспитательных учреждениях в ноябре 2022 года
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Сотрудники <strong>отдела медиации</strong> ФГБУ «Центр защиты прав и интересов детей» провели
                мероприятия по
                сопровождению служб медиации в специальных учебно-воспитательных учреждениях, подведомственных
                Минпросвещения России (СУВУ) в <strong>ноябре 2022 года</strong>. Мероприятия состояли из <strong>семинара
                    «Мы и они»</strong> (далее –
                семинар) групповых <strong>консультаций</strong> для специалистов служб и <strong>интерактивных
                    занятий</strong> с обучающимися.</p>
            <p>Мероприятия прошли в дистанционном формате: семинар 10 ноября (все СУВУ), консультации и занятия:
                17 – 18 ноября (Калтанское СУВУ, Санкт-Петербургское СУВУ, Ишимбайское СУВУ), 24 – 25 ноября (Омское
                СУВУ, Майкопское СУВУ, Неманское СУВУ, Куртамышское СУВУ).</p>
            <p>Семинар проходил в формате <strong>деловой игры</strong>, моделирующей конфликтную ситуацию связанную
                с обучающимися.</p>
            <p>Рассматривались способы работы с интересами обучающихся, пути установления доверительных отношений для
                дальнейшей воспитательной работы и методы профилактики конфликтов.</p>
            <p><strong>Целью</strong> семинара было развитие у специалистов служб медиации СУВУ навыков профилактики и разрешения спорных
                ситуаций с помощью медиативного подхода.</p>
            <p>В рамках <strong>консультаций</strong> совместно с сотрудниками служб медиации СУВУ был рассмотрен и проанализирован
                конфликт между обучающимися. Разбирались варианты урегулирования и профилактики подобных случаев, ведь
                разрешение ситуации с использование медиативно-восстановительных технологий дает возможность исчерпать
                конфликт полностью и помочь обучающимся наладить взаимоотношения.</p>
            <p>Также с сотрудниками служб медиации СУВУ рассматривалось актуальное состояние службы и планы на 2023
                год.</p>
            <p>Интерактивные занятия с обучающимися из разных СУВУ включали в себя знакомство с техниками медиации в
                условиях деловой игры, события которой происходят в космическом пространстве.</p>
            <p>Участники занятий с интересом приняли новые формы работы и отметили, что в формате игры материал
                усваивается проще и быстрее.</p>
        </div>
        <div style={{
            margin: "0 auto"
        }}>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <div>
            <img src={img4}/>
        </div>
        <ToNews/>
    </div>
}


export default SupportEducationalInstitutions2022;