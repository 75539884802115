import React from "react";
import ico from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {hrefsArrKDN} from "./FilesKDNGos";


const FilesKDNHis = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/kdn").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <div className={s.backgroundPage}>
        <h2 style={{
            fontWeight: "normal",
            paddingTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "0px auto",
            fontFamily: "Proxima Nova Lt",
            fontSize: "30px",
            marginRight: "23vw"
        }}>
            О КДНиЗП
        </h2>
        <h3 style={{
            fontWeight: "550",
            marginTop: "30px",
            color: "#005090",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "20px auto",
            fontFamily: "Proxima Nova Lt",
            fontSize:"22px",
            marginRight: "23vw"
        }}>
            Историческая справка
        </h3>

        <div className={s.filesLeftPage}>
            <div>
                <FileLeftLine text={respData[0]?.description} refers={respData[0]?.file} ico={ico}/>
            </div>
            <div>
                <Hrefs hrefsArr={hrefsArrKDN}/>
            </div>
        </div>
    </div>
}
export default FilesKDNHis