import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import logo from "../../../assets/News/Plen2020.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {NavLink} from "react-router-dom";
import img1 from "../../../assets/News/Ploshad2.jpg"
import img2 from "../../../assets/News/Ploshad2_2.jpg"
import img3 from "../../../assets/News/Ploshad2_3.jpg"
import img4 from "../../../assets/News/Ploshad2_4.jpg"
import img5 from "../../../assets/News/Ploshad2_5.jpg"
import img6 from "../../../assets/News/Ploshad2_6.jpg"
import file1 from "../../../assets/Files/Gabdrahimova-O.YU..pdf"
import file2 from "../../../assets/Files/Zulkarnaev-T.R..pdf"
import file3 from "../../../assets/Files/step6.pdf"
import file4 from "../../../assets/Files/Fedorovskij-M.YU..pptx"
import file5 from "../../../assets/Files/Konovalov-A.YU..pptx"
import file6 from "../../../assets/Files/Popova-I.N..pptx"
import file7 from "../../../assets/Files/Trunov-D.V..pptx"
import YouTube from "react-youtube";
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";


const Ploshad3 = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2>
                    Площадка № 3 VII Всероссийского совещание КДН и ЗП
                </h2>
            </div>


            <p style={{fontSize: "17px", color: "#212529"}} className={s.concept}>
                <p><strong>
                    Дискуссионная площадка № 3
                    «Предупреждение деструктивного поведения несовершеннолетних. Декриминализация подростковой среды.
                    Практики и технологии превенции»
                </strong></p>
                <p><strong>Модератор:</strong></p>
                <p>
                    Кольцова Мария Моисеевна, заместитель начальника Управления организации охраны общественного порядка
                    в жилом секторе и деятельности по исполнению административного законодательства Главного управления
                    по обеспечению охраны общественного порядка и координации взаимодействия с органами исполнительной
                    власти субъектов Российской Федерации МВД
                </p>
                <p><strong>Сомодератор:</strong></p>
                <p>
                    Артамонова Елена Геннадьевна, заместитель директора федерального государственного бюджетного
                    учреждения «Центр защиты прав и интересов детей»
                </p>
                <p><strong>Участники:</strong></p>
                <p>
                    Заварзин Денис Сергеевич, генеральный директор автономной некоммерческой организации «Центр изучения
                    и сетевого мониторинга молодежной среды»
                </p>
                <p>
                    «Противодействие криминализации подростковой среды в сетевом пространстве»
                </p>
                <p>
                    Кулагина Юлия Валерьевна, начальник Управления по организации деятельности участковых
                    уполномоченных полиции и подразделений по делам несовершеннолетних главного управления Министерства
                    внутренних дел Российской Федерации по городу Санкт-Петербургу и Ленинградской области
                </p>
                <p>
                    «Предупреждение деструктивного поведения несовершеннолетних. Отдельные положительные практики
                    превентивной деятельности на примере города Санкт-Петербурга и Ленинградской области»
                </p>
                <p>
                    Зулкарнаев Тимур Рустамович, консультант службы по обеспечению деятельности комиссий по делам
                    несовершеннолетних и защите их прав Аппарата Губернатора Курганской области, ответственный секретарь
                    комиссии по делам несовершеннолетних и защите их прав Курганской области
                </p>
                <p>
                    «Формы дистанционной профилактической работы с несовершеннолетними и их законными представителями в
                    актуальных условиях»
                </p>
                <p>
                    Давлетшин Алексей Фаритович, Министр молодежной политики Тульской области, член комиссии по делам
                    несовершеннолетних и защите их прав Тульской области
                </p>
                <p>
                    «О работе Регионального центра информационной безопасности молодежи и психологической помощи»
                </p>
                <p>
                    Ковалева Юлия Анатольевна, заместитель министра социальной защиты Алтайского края, начальник
                    управления по социальной политике Министерства социальной защиты Алтайского края
                </p>
                <p>«Об опыте Алтайского края по реализации восстановительных программ в целях профилактики детского и
                    семейного неблагополучия»</p>
                <p>Галстян Маргарита Сергеевна, директор государственного казенного учреждения Иркутской области «Центр
                    профилактики, реабилитации</p>
                <p>«Использование социально-педагогических технологий в области профилактики аддиктивного поведения
                    несовершеннолетних в системе образования Иркутской области (из опыта работы)»</p>
                <p>Быкова Марина Викторовна, аналитик отдела по развитию медиации и восстановительных технологий
                    федерального государственного бюджетного учреждения «Центр защиты прав и интересов детей»</p>
                <p>«Применение медиативных и восстановительных технологий в сфере предупреждения деструктивных
                    проявлений среди несовершеннолетних»</p>
                <p>Драганова Оксана Александровна, директор государственного областного бюджетного учреждения Липецкой
                    области «Центр «СемьЯ», главный внештатный педагог-психолог в системе образования Липецкой
                    области</p>
                <p>«Медиативные технологии при межведомственном взаимодействии в работе с несовершеннолетними и их
                    семьями»</p>
                <p>Самсонюк Татьяна Анатольевна, начальник управления образования города Волгодонска Ростовской
                    области</p>
                <p>«Профилактика асоциального поведения несовершеннолетних через реализацию воспитательных проектов,
                    образовательных активностей, событийных мероприятий»</p>
                <p>Федоровский Михаил Юрьевич, начальник Управления по работе с комиссиями по делам несовершеннолетних и
                    защите их прав Департамента общественной безопасности города Севастополя, заместитель председателя
                    комиссии по делам несовершеннолетних и защите их прав города Севастополя</p>
                <p>«Укрепление семейных ценностей, как фактор предупреждения асоциального поведения подростков»</p>
                <p>Габдрахимова Оксана Юрьевна, заместитель главы администрации городского округа город Выкса
                    Нижегородской области, председатель комиссии по делам несовершеннолетних и защите их прав при
                    администрации городского округа город Выкса</p>
                <p>«О положительном опыте городского округа город Выкса Нижегородской области по использованию
                    социальной рекламы как способа профилактики негативных явлений в подростковой среде»</p>
                <p><a href={file1}>Габдрахимова О.Ю.</a></p>
                <p><a href={file2}>Зулкарнаев Т.Р.</a></p>
                <p><a href={file3}>Самсонюк Т.А.</a></p>
                <p><a href={file4}>Федоровский М.Ю.</a></p>
                <YouTube id="7DWOE1oRDyU"/>
            </p>
        </div>
        <div style={{marginTop: "57px"}}>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право"/>

        </div>


    </div>


}

export const HrefsBeh1 = ({
                              hrefsArr, stylef = {}, to = "/"
                          }) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({
                        text, ul, input = null, to
                    }) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({
                              text, to
                          }) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({
                        text, stylesheet
                    }) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({
                         text, stylesheet
                     }) => {
    return <div className={s.whitebutton}>
        {text}
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default Ploshad3