import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/EducationForDisabled.jpg"

const EducationForDisabled = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар «Формы обучения и получения образования для лиц с ограниченными возможностями здоровья и
            инвалидностью»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Уважаемые коллеги!</p>
            <p>Приглашаем Вас принять участие во Всероссийском вебинаре 22 декабря 2022 года в 09:30 по московскому
                времени по теме: «Формы обучения и получения образования для лиц с ограниченными возможностями здоровья
                и инвалидностью» для руководителей и специалистов психолого-медико- педагогических комиссий, центров
                психолого-педагогической, медицинской социальной помощи, образовательных Участие в вебинаре бесплатное.
                Регистрация на мероприятие по <strong><a href="https://events.webinar.ru/19466565/1760917012">ссылке</a></strong>
            </p>
        </div>
        <ToNews/>
    </div>
}


export default EducationForDisabled;