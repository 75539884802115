import React from "react";
import ico from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {Hrefs} from "../PageInfo/PageInfoPriceLife"
import {FileLeftLineKDN} from "./FilesKDNNews";

const hrefsArr = [
    {text: "Нормативно-правовые материалы", to: "/spec-value-of-life/normativno-pravovye-materialy", input: []},
    {
        text: "Информационно-методические материалы для специалистов",
        to: "/spec-value-of-life/metodicheskie-materialy-dlya-spetsialistov"
    },
    {
        text: "Информационные материалы для родителей и детей",
        to: "/spec-value-of-life/informatsionnye-materialy-dlya-roditelej"
    },
    {text: "Видеоматериалы", to: "/spec-value-of-life/video-price"},
    {text: "Полезные ссылки", to: "/spec-value-of-life/poleznye-ssylki"},
]
const FilesValueNorm = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Нормативная правовая база
                </h2>
                <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                    Федеральные законы
                </h1>
                <FileLeftLineKDN
                    text="Федеральный закон «Об образовании в Российской Федерации» № 273-ФЗ от 29 декабря 2012 года"
                    refers="https://fcprc.ru/media/media/behavior/Zakon-ob-obrazovanii-1.pdf" ico={ico}/>
                <FileLeftLineKDN text="Федеральный закон «Об основных гарантиях прав ребенка в Российской Федерации»"
                                 refers="https://fcprc.ru/media/media/behavior/Zakon-ob-osnovnyh-garantiyah-prav-detei-v-rossii-skoi-federatsii-1_b8a4kpq.pdf"
                                 ico={ico}/>
                <FileLeftLineKDN
                    text="Федеральный закон «О защите детей от информации причиняющей вред их здоровью и развитию» № 436-ФЗ от 29 декабря 2010 года"
                    refers="https://fcprc.ru/media/media/behavior/Zakon-o-zashhite-detej-ot-informatsii-prichinyayushhej-vred-ih-zdorovyu-i_0TYRY1x.pdf"
                    ico={ico}/>
                <FileLeftLineKDN
                    text="Федеральный закон от 07.06.2017 N 120-ФЗ «О внесении изменений в УК РФ и статью 151 УПК»"
                    refers="https://fcprc.ru/media/media/behavior/Federalnyj-zakon-ot-07.06.2017-N-120-FZ-O-vnesenii-izmenenij-v-UK-RF-i-st_L3shKGT.pdf"
                    ico={ico}/>
                <div>
                    <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                        Стратегии
                    </h1>
                    <FileLeftLineKDN text="Национальная стратегия действий в интересах детей на 2012−2017 годы"
                                     refers="https://fcprc.ru/media/media/behavior/Natsionalnaya-strategiya-dejstvij-v-interesah-detej-na-2012-2017-gody.pdf"
                                     ico={ico}/>
                    <FileLeftLineKDN
                        text="Стратегия развития воспитания на период до 2025 года (Распоряжение от 29 мая 2015 года № 996-р.)"
                        refers="https://fcprc.ru/media/media/behavior/Strategiya-razvitiya-vospitaniya.pdf" ico={ico}/>
                </div>
                <div>
                    <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                        Кодексы Российской Федерации
                    </h1>
                    <FileLeftLineKDN text="Семейный кодекс Российской Федерации. Права несовершеннолетних детей"
                                     refers="https://fcprc.ru/media/media/behavior/Семеиныи_кодекс_Россиискои_Федерации..pdf"
                                     ico={ico}/>
                    <FileLeftLineKDN text="Трудовой кодекс РФ"
                                     refers="https://fcprc.ru/media/media/behavior/Трудовои_кодекс_Россиискои_Федерации.pdf"
                                     ico={ico}/>
                    <FileLeftLineKDN text="Уголовный кодекс Российской Федерации"
                                     refers="https://fcprc.ru/media/media/behavior/Уголовныи_кодекс_Россиискои_Федерации.pdf"
                                     ico={ico}/>
                </div>
                <div>
                    <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                        Распорядительные документы
                    </h1>
                    <FileLeftLineKDN text="Комплекс мер до 2025 года по профилактике суицида несовершеннолетних"
                                     refers="https://fcprc.ru/media/media/behavior/Kompleks-mer-do-2025-goda-po-profilaktike-suitsida-nesovershennoletnih.pdf"
                                     ico={ico}/>
                    <FileLeftLineKDN
                        text="Протокол заседания Правительственной комиссии по делам несовершеннолетних и защите их прав от 25 сентября 2019 г. №23"
                        refers="https://fcprc.ru/media/media/behavior/Protokol-zasedaniya-Pravitelstvennoj-komissii-po-delam-nesovershennoletnih.pdf"
                        ico={ico}/>
                    <FileLeftLineKDN
                        text="Письмо Минобрнауки России от 05.09.2011 г. № МД-1197/06 «О Концепции профилактики употребления психоактивных веществ в образовательной среде»"
                        refers="https://fcprc.ru/media/media/behavior/Зарегистрировано_в_Минюсте_РФ_2_февраля_2011_г.pdf"
                        ico={ico}/>
                    <FileLeftLineKDN
                        text={"Постановление Правительства Р Ф от 11 марта 2011 г. N 164 «Об осуществлении государственного контроля (надзора) в сфере образования» "}
                        refers="https://base.garant.ru/55170752/#block_5" ico={ico}/>
                </div>
                <div>
                    <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                        Другие документы
                    </h1>
                    <FileLeftLineKDN
                        text="Конвенция о правах ребенка. Принята Генеральной Ассамблеей ООН 20.11.1989 года"
                        refers="https://fcprc.ru/media/media/behavior/Konventsiya-o-pravah-rebenka-1.pdf"
                        ico={ico}/>
                    <FileLeftLineKDN
                        text="Указ президента об утверждении концепции демографической политики российской федерации на период до 2025 года"
                        refers="https://fcprc.ru/media/media/behavior/Ukaz-prezidenta-ob-utverzhdenii-kontseptsii-demograficheskoj-politiki-ros_5BHI17v.pdf"
                        ico={ico}/>
                </div>

            </div>
            <div style={{marginTop: "57px"}}>
                <Hrefs hrefsArr={hrefsArr}/>
            </div>
        </div>
    </>
}
export default FilesValueNorm