import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/bestIncSchool.png"

const News170221 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сопровождение служб медиации СУВУ
        </h2>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Медиаторами Федерального государственного бюджетного учреждения «Центр защиты прав и интересов детей» на
            2021 год запланирована серия мероприятий по сопровождению работы служб медиации в специальных
            учебно-воспитательных учреждениях, подведомственных Минпросвещения России (проведение интерактивных занятий
            с обучающимися и консультаций для специалистов служб медиации).
            <p></p>
            18 и 19 февраля 2021 г. состоятся первые групповые занятия с сотрудниками и воспитанниками Астраханского,
            Абаканского и Известковского СУВУ.
        </div>
        <ToNews/>
    </div>
}


export default News170221;