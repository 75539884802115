import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News150424.png"

const News150424 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Федеральный центр развития программ социализации подростков начинает прием заявок для участия во Всероссийском конкурсе программ социализации подростков.
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Конкурс, который пройдет с 15 апреля по 1 ноября 2024 года, направлен на выявление лучших практик по
            социализации подростков в Российской Федерации и формирование сообщества специалистов по подростковой
            работе.
            <p></p>
            Организатор конкурса — Федеральное государственное бюджетное учреждение «Центр защиты прав и интересов
            детей».
            <p></p>
            В конкурсе принимают участие организации, осуществляющие свою деятельность не менее года и планирующие
            открыть подростковый центр до конца 2024 г.
            <p></p>

            Основные задачи конкурса:
            <ul>
                <li>
                    выявление и поддержка лучших программ (практик) по социализации подростков в организациях
                    (юридических
                    лицах), обеспечивающих социализацию и поддержку подростков на базе «низкопороговых» пространств
                    (далее –
                    Подростковые центры) в регионах;
                </li>
                <li>
                    выявление и трансляция технологий и эффективных форм работы с подростками в подростковых центрах;
                </li>
                <li>
                    обобщение опыта, формирование базы знаний и практик, тиражирование успешных подходов и технологий
                    поддержки подростков;
                </li>
                <li>
                    содействие в создании сообщества профессионалов, реализующих программы (практики), направленные на
                    социализацию подростков.
                </li>
            </ul>
            <p></p>

            Конкурс проводится по следующим номинациям:
            <ul>
                <li>
                    «Социализация через искусство, литературу и кино»;
                </li>
                <li>
                    «Социализация через организацию свободного досуга с элементами игровой деятельности»;
                </li>
                <li>
                    «Социализация через спортивную деятельность»;
                </li>
                <li>
                    «Социализация через театральную деятельность»;
                </li>
                <li>
                    «Социализация через трудовую занятость»;
                </li>
                <li>
                    «Социализация через вовлечение в социально-значимую и волонтерскую деятельность»;
                </li>
                <li>
                    «Социализация через конструктивное использование интернет-сервисов»;
                </li>
                <li>
                    «Социализация подростков, состоящих на различных видах учета»
                </li>
            </ul>
            <p></p>
            Прием заявок и конкурсных материалов осуществляется в следующие сроки:
            <ul>
                <li>
                    первый этап Конкурса (1 - 4 номинации) – с 15 апреля по 31 мая 2024 г.
                </li>
                <li>
                    второй этап Конкурса (5 - 8 номинации) – с 1 августа по 13 сентября 2024 г.
                </li>
            </ul>
            Участие в конкурсе является добровольным и бесплатным. Одна заявка может участвовать в нескольких
            номинациях конкурса.
            <p></p>
            Для подачи заявки необходимо заполнить форму на сайте <u><a
            href="https://подростковыецентры.рф/contest">https://подростковыецентры.рф/contest</a></u>
            <p></p>
            Ознакомиться с Положением о конкурсе можно здесь <u><a
            href="https://disk.yandex.ru/i/B45x4KV6obSCwQ">https://disk.yandex.ru/i/B45x4KV6obSCwQ</a></u>
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News150424;