import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/StartASP2023.png"

const StartASP2023 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Начинается прием заявок для участия во Всероссийском конкурсе программ социализации подростков 15 марта 2023
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>С 15 марта стартует прием заявок для участия во Всероссийском конкурсе программ социализации
                подростков.</p>
            <p>Конкурс, который проходит с 15 марта по 8 сентября 2023 года, направлен на выявление лучших практик по
                социализации подростков в Российской Федерации и формирование сообщества специалистов по подростковой
                работе.</p>
            <p>Организатор конкурса — федеральное государственное бюджетное учреждение «Центр защиты прав и интересов
                детей».</p>
            <p>В конкурсе принимают участие организации, осуществляющие свою деятельность не менее года и планирующие
                открыть подростковый центр до конца 2023 г.</p>
            <p>Основные задачи конкурса:</p>
            <p>— выявление и поддержка региональных инициатив по реализации программ в организациях, обеспечивающих
                социализацию и поддержку подростков (подростковых центрах);</p>
            <p>— выявление и трансляция технологий и эффективных форм работы с подростками в подростковых центрах;</p>
            <p>— обобщение опыта, формирование базы знаний и практик, тиражирование успешных подходов и технологий
                поддержки подростков;</p>
            <p>— содействие в создании сообщества профессионалов, реализующих программы, направленные на социализацию
                подростков.</p>
            <p>Конкурс проводится по следующим номинациям:</p>
            <p>— «Социализация через патриотизм, семейные ценности и духовно-нравственное воспитание»;</p>
            <p>— «Социализация через трудовую занятость, искусство и спорт»;</p>
            <p>— «Социализация через общение, творчество и поддержку».</p>
            <p>Прием заявок и конкурсных материалов, их проверка осуществляется в следующие сроки:</p>
            <p>— номинация «Социализация через патриотизм, семейные ценности и духовно-нравственное воспитание» — с 15
                марта по 17 апреля 2023 года;</p>
            <p>— номинация «Социализация через трудовую занятость, искусство и спорт» — с 15 мая по 1 июня 2023
                года;</p>
            <p>— номинация «Социализация через общение, творчество и поддержку»</p>
            <p>— с 17 июля по 14 августа 2023 года.</p>
            <p>Участие в конкурсе является добровольным и бесплатным. Одна заявка может участвовать в нескольких
                номинациях
                конкурса. Заявка на участие в конкурсе и конкурсные материалы направляются по электронному адресу:
                <a href="mailto:fpc_info@fcprc.ru">fpc_info@fcprc.ru</a>.</p>
            <p> <a style={{fontStyle: "Italic"}}
                href="https://fcprc.ru/media/media/behavior/Положение_о_конкурсе_ФЦ_РПСП_2023.pdf">Ознакомиться с Положением о конкурсе можно здесь</a>.
            </p>
        </div>
        <ToNews/>
    </div>
}


export default StartASP2023;