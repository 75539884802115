import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/SecondSiberiaEuropeAsia.jpg"
import img2 from "../../assets/News/SecondSiberiaEuropeAsia_2.jpg"

const SecondSiberiaEuropeAsia = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            II Международный форум «Сибирь: Европа и Азия – диалог о медиации»
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>
                25-26 февраля 2021 года в г. Иркутске прошёл II Международный форум «Сибирь: Европа и Азия – диалог о
                медиации», посвященный обсуждению вопросов развития медиации, выработке предложений, способствующих
                дальнейшему ее интегрированию в практику деятельности правозащитных организаций, государственных и
                муниципальных органов, бизнеса.
            </p>
            <p>
                Форум объединил медиаторов, юристов, представителей бизнеса, образовательных организаций, медицинских
                учреждений, общественных и правозащитных организаций, представителей органов государственной власти и
                местного самоуправления, психологов, всех интересующихся проблематикой конфликта и медиации.
            </p>
            <p>
                В течение двух дней спикеры из России, Казахстана, Киргизии, Беларуси, Узбекистана делились своим опытом и знаниями.
            </p>
            <p>
                Со стороны ФГБУ «Центр защиты прав и интересов детей» в пленарном заседании с докладами выступили медиаторы А.В.Зацаринный и М.А.Сулева.
            </p>
        </div>
        <div>
            <img src={img2}/>
        </div>
    </div>
}


export default SecondSiberiaEuropeAsia;