import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/news15.05.png"


const IINomination = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Стартовал прием заявок для участия во второй номинации Всероссийского конкурса программ социализации подростков
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>С 15 мая начинается прием заявок и материалов для участия по второй номинации Всероссийского конкурса программ социализации подростков «Социализация через трудовую занятость, искусство и спорт».</p>
           
            В направление входит:
<div>
— развитие профессиональных навыков,
</div>
<div>

— повышение интереса к проектной, исследовательской и культурной деятельности,
</div>
<div>

— развитие креативного мышления,
</div>
<div>

— формирование у подростков здорового образа жизни.
</div>
<p></p>
Напомним, Всероссийский конкурс программ социализации подростков проводится с целью выявления лучших практик по социализации подростков в РФ и формирования сообщества специалистов по подростковой работе.
           <p></p>
           В конкурсе могут участвовать организации, осуществляющие свою деятельность не менее года; планирующие открыть подростковый центр до конца 2023 года. Участие в конкурсе является добровольным и бесплатным. Одна команда может быть представлена в нескольких направлениях.
           <p></p>
           Заявки принимаются до 1 июня 2023 года включительно по электронной почте fpc_info@fcprc.ru. 
           <div>

            <a href="https://fcprc.ru/media/media/behavior/Положение_о_конкурсе_ФЦ_РПСП_2023.pdf"> Ознакомиться с положением конкурса можно здесь (ссылка на положение https://u.to/XxKlHw)</a>
           </div>
        </div>
        
        <ToNews/>
    </div>
}


export default IINomination;