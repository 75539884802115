import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/SuvuApril2021.jpg"

const SuvuApril2021 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            16 апреля 2021 года состоялось Всероссийское совещание по вопросам организации деятельности специальных
            учебно-воспитательных учреждений (СУВУ) на тему «Ресурсы профессионального образования в ресоциализации
            детей с девиантным поведением»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>В Российской академии образования прошло Всероссийское совещание по вопросам организации деятельности
                специальных учебно-воспитательных учреждений (СУВУ) на тему «Ресурсы профессионального образования в
                ресоциализации детей с девиантным поведением».</p>
            <p>В совещании приняли участие представители представителей Администрации Президента Российской Федерации;
                Уполномоченного при Президенте Российской Федерации по правам ребенка; Фонда поддержки детей,
                находящихся в трудной жизненной ситуации; Минпросвещения России, МВД России; Генеральной прокуратуры
                Российской Федерации; Следственного комитета Российской Федерации; ФСИН России, органов исполнительной
                власти субъектов Российской Федерации, осуществляющих государственное управление в сфере образования,
                руководители специальных учебно-воспитательных учреждений, представители науки и общественности.</p>
            <p>Директор Департамента государственной политики в сфере защиты прав детей Минпросвещения России
                Фальковская Лариса Павловна обозначила актуальные векторы совершенствования деятельности специальных
                учебно-воспитательных учреждений в условиях обновления законодательства в сфере защиты прав
                несовершеннолетних и профилактики их антиобщественного и противоправного поведения.</p>
            <p>О перспективах развития системы среднего профессионального образованиям и актуальных вопросах
                профессионального образования в специальных учебно-воспитательных учреждениях рассказал Скрябин Евгений
                Вячеславович, начальник отдела Департамента государственной политики в сфере среднего профессионального
                образования и профессионального обучения Минпросвещения России.</p>
            <p>В программу совещания были включены вопросы реализации основных программ профессионального обучения,
                сетевой формы реализации образовательных программ, внедрения рабочих программ воспитания, проведения
                конкурса профессионального мастерства для обучающихся специальных учебно-воспитательных учреждений.</p>
            <p>Спикеры в своих выступлениях представили практики формирования позитивной жизненной перспективы у
                обучающихся с девиантным поведением, реализации программ проектной деятельности, организации старт-ап
                площадок профессиональных проб.</p>
        </div>
        <div className={s.blueButtonMediaciaBase} style={{maxWidth: "270px"}}>
            <a href="https://fcprc.ru/media/media/behavior/Programma-Vserossijskogo-soveshhaniya.pdf">Программа всероссийского совещания</a>
        </div>
        <div className={s.blueButtonMediaciaBase} style={{maxWidth: "270px"}}>
            <a href="https://fcprc.ru/media/media/behavior/Falkovskaya-L.P..pdf">Презентация Фальковской Л.П.</a>
        </div>
        <div className={s.blueButtonMediaciaBase} style={{maxWidth: "270px"}}>
            <a href="https://fcprc.ru/media/media/behavior/Abakumova-L.V..pdf">Презентация Абакумова Л.В.</a>
        </div>
        <div className={s.blueButtonMediaciaBase} style={{maxWidth: "270px"}}>
            <a href="https://fcprc.ru/media/media/behavior/Kruglova-O.V..pdf">Презентация Круглова О.В.</a>
        </div>
        <div className={s.blueButtonMediaciaBase} style={{maxWidth: "270px"}}>
            <a href="https://fcprc.ru/media/media/behavior/Paatova-M.E..pdf">Презентация Паатова М.Э.</a>
        </div>
        <div className={s.blueButtonMediaciaBase} style={{maxWidth: "270px"}}>
            <a href="https://fcprc.ru/media/media/behavior/Romashkin-A.A..pdf">Презентация Ромашкин А.А.</a>
        </div>
        <div className={s.blueButtonMediaciaBase} style={{maxWidth: "270px"}}>
            <a href="https://fcprc.ru/media/media/behavior/Serdyukova-O.A..pdf">Презентация Сердюкова О.А.</a>
        </div>
        <div className={s.blueButtonMediaciaBase} style={{maxWidth: "270px"}}>
            <a href="https://fcprc.ru/media/media/behavior/Skryabin-E.V..pdf">Презентация Скрябин Е.В.</a>
        </div>
        <div className={s.blueButtonMediaciaBase} style={{maxWidth: "270px"}}>
            <a href="https://fcprc.ru/media/media/behavior/Smolko-P.N.-.pdf">Презентация Смолко П.Н.</a>
        </div>
        <div className={s.blueButtonMediaciaBase} style={{maxWidth: "270px"}}>
            <a href="https://fcprc.ru/media/media/behavior/SHishkina-M.V..pdf">Презентация Шишкина М.В.</a>
        </div>
    </div>
}


export default SuvuApril2021;