import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img1 from "../../assets/News/WorkOrganizationVICH.jpeg";
import YouTube from "react-youtube";

const Osobennosti = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: «Особенности подросткового возраста с точки зрения психологии, социологии и этологии:
            подростковая девиантность»
        </h2>
        <div>
            <p>
                10 апреля 2020 года состоялся вебинар, посвященный проблемам зависимого поведения. Ведущий вебинара,
                Дубровский Роман Геннадьевич, аналитик ФГБУ «Центр защиты прав и интересов детей», психолог, кандидат
                социологических наук, рассказал о природе «конфликта поколений»,
                ее этологическом и социологическом объяснении; об инстинктивных программах в поведении подростков; о
                позитивной девиантности, как ресурсе профилактики негативных явлений среди подростков.
            </p>
            <p>
                Ссылка на вебинар: <a
                href="https://www.youtube.com/watch?v=HhucqBtPJeg">https://www.youtube.com/watch?v=HhucqBtPJeg</a>
            </p>
            <p></p>
        </div>
    </div>
}


export default Osobennosti