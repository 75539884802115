import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import logo from "../../../assets/Behavior/conceptions.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {NavLink} from "react-router-dom";
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";
import adobe from "../../../assets/Behavior/adobe.png"
import presen from "../../../assets/Behavior/presen.jpg"


const SolovievaVICH = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.header}>
                <h2 style={{
                    fontWeight: "normal",
                    color: "#3a4663",
                    fontFamily: "Proxima Nova Lt",
                    fontSize: "30px",
                    textAlign: "start"
                }}>
                    Соловьева Н.В. Социально-психологическое сопровождение семей, воспитывающих ВИЧ-инфицированных детей
                </h2>
            </div>

            <div style={{fontSize: "17px", color: "#3a4663"}} className={s.concept}>
                <p><strong>// Электронный журнал «Общество. Культура. Наука. Образование». 2016. Вып. 1</strong></p>
            </div>
            <div style={{fontSize: "17px", color: "#212529"}} className={s.concept}>
                <p>
                    <div style={{textAlign: "center"}}><strong>СОЦИАЛЬНО_ПСИХОЛОГИЧЕСКОЕ СОПРОВОЖДЕНИЕ СЕМЕЙ,
                        ВОСПИТЫВАЮЩИХ ВИЧ-ИНФИЦИРОВАННЫХ ДЕТЕЙ</strong></div>
                    <br/>
                    <strong>Н.В. Соловьева,</strong> социальный педагог<br/>
                    ГБУ Центр содействия семейному воспитанию<br/>
                    «Солнечный круг», г. Москва<br/>
                    В рамках Национальной стратегии действий в интересах детей на 2012−2017гг., утвержденной Указом
                    Президента Р Ф№ 761 от 01.06.2012 г., государственная политика направлена на стимулирование граждан
                    к семейному устройству детей-сирот и детей, оставшихся без попечения родителей. Актуальность статьи
                    обусловлена отсутствием координации деятельности между государственными ведомствами и, как следствие
                    этого, отсутствие нормативной документации по оказанию специализированной медицинской и
                    психосоциальной помощи семьям, воспитывающим детей с ВИЧ-инфекцией.<br/>
                </p>
            </div>
            <div style={{fontSize: "17px", color: "#3a4663"}} className={s.concept}>
                <p><strong>Ключевые слова:</strong> дети-сироты, дети, оставшиеся без попечения родителей,
                    ВИЧ-инфицированных детей,
                    социально-психологической адаптации.</p>
                <p>Сопровождение замещающих семей включает в себя комплекс мер направленных на успешное взаимодействие
                    членов семьи, нормализации психологического климата и успешной адаптации ребенка, оставшегося без
                    попечения родителей к жизни в социуме. Сопровождение замещающих семей регламентируется
                    законодательными актами субъектов Российской Федерации. В городе Москве сопровождение семей
                    осуществляется на основе Постановления Правительства города Москвы № 443 от 20.09.2011 г. «О мерах
                    по обеспечению реализации Закона города Москвы от 14 апреля 2010 г. № 12 «Об организации опеки,
                    попечительства и патронажа в городе Москве».</p>
                <p>Сопровождение семей, принявших на воспитание ребенка, оставшегося без попечения родителей, включает в
                    себя следующие направления работы:<br/>
                    • Образовательная поддержка замещающих семей (формирование психолого-педагогической компетентности
                    родителей через организацию и проведение консультаций и обучающих семинаров, тренингов).<br/>
                    • Психолого-педагогическая поддержка семьи (содействие созданию благоприятного микроклимата в
                    замещающей семье в периоды адаптации и нормативных кризисов и коррекция межличностных отношений;
                    помощь в адаптации ребенка в школьном коллективе).<br/>
                    • Социальная поддержка семьи (помощь в организации семейного досуга; содействие в решении проблем и
                    взаимодействии с различными организациями, информирование по вопросам получения социальной и
                    правовой поддержки).</p>
                <p>Уполномоченная служба по сопровождению замещающих семей осуществляет свою работу в рамках
                    заключенного договора. Направление работы в области сопровождения семьи подразумевают следующие виды
                    деятельности: разработка индивидуального плана сопровождения ребенка в семье; составление плана
                    текущего сопровождения семьи; проведение мониторинга развития ребенка в семье; проведение
                    психолого-медико-социальных консилиумов; оказание содействия в устройстве детей в дошкольные и
                    школьные образовательные учреждения; посещение детей в семьях, детских садах, общеобразовательных
                    учреждениях; проведение занятий, коррекционно-развивающих игр, индивидуальных бесед, консультаций
                    специалистов с детьми и замещающими родителями; наблюдение за ходом адаптации и проживания ребенка в
                    замещающей семье; методическое сопровождение замещающих семей (организация семинаров, круглых
                    столов, консультаций).</p>
                <p><strong>Цель сопровождения</strong> — поддержка процесса функциональной и структурной перестройки
                    принимающей семьи,
                    связанной с включением в нее ребенка, и его интеграции в замещающую семью.</p>
                <p><strong>Задачи сопровождения:</strong><br/>
                    1. Создание оптимальных условий для развития и социализации ребенка, переданного на воспитание в
                    семью.<br/>
                    2. Организация и осуществление эффективной замещающей семейной заботы.<br/>
                    3. Предотвращение отказа от ребенка, переданного на воспитание в семью.<br/>
                    4. Профилактика кризисных ситуаций в семье и содействие укреплению семьи.<br/>
                    5. Помощь семье в прохождении кризисов при приеме ребенка.<br/>
                    6. Коррекционная работа с детьми по запросу семей.</p>
                <p>Специалисты выделяют следующие уровни сопровождения семей: базовый уровень (поддерживающий,
                    адаптационный); кризисный уровень (семья не в состоянии самостоятельно справиться со сложившейся
                    ситуацией, требуется вмешательство специалистов); экстренный уровень (угроза возврата ребенка).</p>
                <p>Семьям, принявших на воспитание ВИЧ инфицированного ребенка приходится сталкиваться со сложной
                    фрагментированной и незнакомой системой оказания медицинских, социальных услуг. В Российской
                    Федерации медицинские услуги, обеспечение бесплатными медикаментами для ВИЧ-инфицированных
                    осуществляется через Центр СПИД. Основными задачами Центра являются:<br/>
                    • Эпидемиологический надзор за распространением ВИЧ инфекции<br/>
                    • Осуществление клинико-лабораторной диагностики и исследований<br/>
                    • Осуществление профилактических мероприятий</p>
                <p><strong>Образовательная поддержка замещающих семей</strong> включает в себя организацию семинаров по
                    темам:<br/>
                    1. Понятие нормы в физиологии и медицине.<br/>
                    2. Вопросы анамнеза, перинатальный, анте-, интра- и постнатальный анамнез.<br/>
                    3. Физиологические особенности детей с хроническими заболеваниями, часто болеющих детей.<br/>
                    Иммунокомплексная патология.
                    4. Наследственные нервно-психические синдромы и заболевания.<br/>
                    5. Вегето-сосудистые дистонии у детей.<br/>
                    6. Последствия перенесенных органических и инфекционных заболеваний центральной нервной системы у
                    детей.<br/>
                    7. Физиологические и патофизиологические основы стресса.<br/>
                    8. Гигиенические основы режима дня.</p>
                <p><strong>Нормативно-правовое регулирование РФ в области защиты прав ВИЧ инфицированных детей.</strong><br/>
                    Социальная поддержка ВИЧ-инфицированных — несовершеннолетних достигается путем назначения социальной
                    пенсии, а также предусматриваются все льготы, установленные для детей-инвалидов законодательством
                    Российской Федерации. Лицам, осуществляющим уход за ВИЧ-инфицированными — несовершеннолетними,
                    выплачивается пособие по уходу за ребенком-инвалидом в порядке, установленном законодательством
                    Российской Федерации.</p>
                <p>Важнейшим документом, посвященным охране здоровья школьников, является совместный приказ Министерства
                    здравоохранения России и Министерства образования России N 186/272 от 30.06.1992 г. «О
                    совершенствовании медицинского обеспечения детей в образовательных учреждениях». Он предусматривает
                    обеспечение комплекса мер по охране здоровья детей, которые должны осуществляться совместно органами
                    управления образованием и здравоохранением. Эти меры включают:<br/>
                    • совершенствование нормативно-правовой базы, обеспечивающей охрану здоровья детей и подростков в
                    образовательных учреждениях;<br/>
                    • разработку и внедрение практику методических рекомендаций по организации медицинского контроля
                    развития здоровья школьников и их оздоровления в условиях школы, создание школьных реабилитационных
                    центров, «школ здоровья»;<br/>
                    • совершенствование содержания подготовки и переподготовки педагогических кадров по проблемам охраны
                    здоровья.</p>
                <p>Родители ВИЧ инфицированного ребенка имеют право на сохранение тайны диагноза в общеобразовательных
                    учреждениях. Но насколько пользоваться данным правом отвечает безопасности других детей и взрослых
                    школьной и дошкольной среды?</p>
                <p><strong>Раскрытие ВИЧ статуса ребенку</strong><br/>
                    Кроме вопросов предоставления медицинских услуг по поддержке ВИЧ-инфицированных детей на
                    качественном уровне, актуальным остается вопрос раскрытия ВИЧ-статуса ребенка. На сегодняшний день
                    не существует единой методики процесса раскрытия диагноза.
                    Раскрытие ВИЧ-статуса ребенка является значимым событием в семье. Членам семьи, принявшим решение о
                    раскрытии ВИЧ-статуса своему ребенку, необходима помощь специалистов, чтобы рассказать ребенку о его
                    заболевании в доступной форме с наименьшим травматическим воздействием на психическое состояние
                    ребенка. Вопрос о раскрытии диагноза ВИЧ-инфицированному ребенку требует учета следующих параметров:
                    возраст ребенка, способность ребенка осознавать существующие границы в социуме, отношения в семье,
                    состояние здоровья ребенка и др.</p>
                <p>Реакция ребенка на информацию о его положительном ВИЧ-статусе может повлечь за собой такие реакции
                    как:<br/>
                    1. Депрессия.<br/>
                    2. Восприятие болезни как наказание за плохое поведение.<br/>
                    3. Отрицание болезни<br/>
                    4. Использование своего статуса как механизма привлечения дополнительного внимания к себе.</p>
                <p>Родители в свою очередь боятся, что при раскрытии ребенку сведений о его болезни, возникнут проблемы
                    о разглашении сведений о ВИЧ-инфекции среди друзей и педагогов, окружающих ребенка, опасаясь
                    депрессий и ухудшения психического состояния ребенка. Давая лекарства ребенку, родители предпочитают
                    скрывать информацию о назначении этих лекарств.</p>
                <p>Важно помнить, что болезнь — это нарушение нормальной жизнедеятельности организма, что, к сожалению,
                    случается в течение жизни с любым человеком — и взрослым, и ребенком. Все люди болеют — одни чаще,
                    другие реже. Хроническая болезнь не может пройти бесследно, человеку нужно научиться жить с ней.
                    Именно так и происходит в случае, когда человек сталкивается с ВИЧ-инфекцией. Люди, имеющие
                    какое-либо серьезное хроническое заболевание, воспринимают болезнь как одно из наиболее
                    травматических событий в своей жизни. Переживания сказываются на психофизическом состоянии больного.
                    Прежде всего возникают отрицательные эмоции: страх, тревога, боль, страдания, гнев, чувство вины,
                    которые по-разному проявляются на разных стадиях течения заболевания и его лечения. Взрослый человек
                    не всегда в состоянии преодолеть такие переживания, иная ситуация когда речь идет о детях.
                    ВИЧ-инфицированный ребенок неизбежно испытывает страх и тревогу, связанные с ограничением свободы,
                    одиночеством и отсутствием поддержки со стороны ближайшего окружения. Такие ощущения могут
                    углубляться в связи с необходимостью госпитализации ребенка и вынужденной разлукой с близкими,
                    отсутствием у ребенка информации о болезни и ее последствиях, назначением медицинских манипуляций.
                    Чаще всего причинами откладывания обсуждения является то, что сами родители или попечители ребенка
                    не готовы говорить с ним о его ВИЧ-статусе и поэтому стараются отложить этот разговор как можно
                    дольше. Родители не уверены как лучше это сделать: каким образом и когда начать разговор с ребенком,
                    что при этом следует сказать, о чем рассказать и стоит ли предоставлять всю информацию во время
                    одного разговора. Возможно, кто-то может это сделать лучше их?</p>
                <p>Жизнь ВИЧ-инфицированного человека часто связана с явлениями стигмы и дискриминации со стороны
                    социального окружения. Стигма и дискриминация являются одними из главных причин, по которым родители
                    отказываются рассказать о ВИЧ-положительном статусе ребенка. То есть, пытаясь защитить ребенка от
                    стигмы или дискриминации, семья скрывает от него правду. Кроме того, дети 4−7 лет открыты в общении
                    с друзьями, другими родственниками, соседями, воспитателями и т. д. Учитывая это, родители
                    переживают, что их ребенок может случайно рассказать о своем статусе кому-то из социального
                    окружения и, таким образом, повредит себе и семье. По их мнению, свой ВИЧ-статус следует держать в
                    тайне, по крайней мере, некоторое время. Все эти переживания могут повлечь увеличение внутреннего
                    напряжения каждого из родителей, что приводит к ухудшению отношений между ними, а также между
                    родителями и ребенком. Из-за желания не навредить ребенку родители часто отказываются отвечать на
                    вопросы ребенка относительно его состояния здоровья, не дают объяснения по приему лекарств либо
                    отвечают слишком обобщенно, в частности говорят ребенку, что у него другая болезнь. При отсутствии
                    ответов родителей дети пытаются самостоятельно ответить на собственные вопросы. Зачастую эти ответы
                    являются ошибочными. Дети могут подумать, что болеют из-за того, что плохо себя вели, что скоро
                    умрут, или же лекарство можно не пить, поскольку они не чувствуют себя больными. Если рассказать
                    ребенку о его ВИЧ-положительном статусе, исчезнут барьеры в общении между родителями и ребенком.
                    Родители освободятся от тягостных мыслей и переживаний, а ребенок избавится от ложных представлений
                    о своем диагнозе, что также может положительно повлиять на эмоциональное состояние ребенка: он будет
                    чувствовать себя важным для семьи и заслуживающим доверия взрослых. Общая ситуация высвобождения
                    эмоций, которые все члены семьи очень долго держали в себе, может способствовать созданию в семье
                    атмосферы открытости, взаимопонимания и поддержки.</p>
                <p>Раскрытие ВИЧ-статуса поможет ребенку понять, что происходит с ним или с его родителями. Когда
                    исчезнут секреты, ребенок сможет свободно задавать вопросы и получать на них ответы от родителей. В
                    свою очередь, родители освободятся от необходимости постоянно контролировать собственные слова,
                    разговоры и тоже почувствуют облегчение. Искренность, доверие и открытость во всех аспектах,
                    связанных с ВИЧ, благоприятно скажутся на отношениях между членами семьи.</p>
                <p>Раскрытие ВИЧ-положительного статуса ребенку — это процесс сотрудничества специалистов и членов семьи
                    в проведении оценки физического и психического состояния ребенка, его потребностей, а также
                    планирования дальнейшей работы с целью успешной адаптации к новым условиям жизнедеятельности.</p>
                <p>Раскрытие статуса не означает просто сообщить ребенку название его болезни. ВИЧ-положительный статус
                    должен быть раскрыт ребенку лицом, с которым он имеет близкие и доверительные отношения, в
                    подходящее для этого время и момент с точки зрения возраста, развития ребенка, жизненной ситуации,
                    состояния здоровья и т. д. Раскрытие ВИЧ-положительного статуса должно быть осуществлено лишь тогда,
                    когда ребенок получил предварительно соответствующую информацию и успешно ее усвоил, что можно
                    проверить с помощью специальных игр, задач и бесед с ребенком. Процесс раскрытия ВИЧ-положительного
                    статуса ребенку имеет определенные последовательные этапы, которые помогают ему понять особенности
                    своего состояния здоровья наименее травматическим способом. Процесс раскрытия ребенку его диагноза
                    должен начинаться с этапа оценки конкретного случая, то есть определения состояния семьи и ее
                    жизнедеятельности, учитывая потенциальное раскрытие ВИЧ-статуса.</p>
                <p>1. Специалисты, работающие с семьей, должны составить индивидуальный план с целью раскрытия
                    ВИЧ-положительного статуса, по которому будет происходить последовательная работа с родителями и их
                    ребенком. План должен основываться на полученной ранее от ребенка и родителей информации и включать
                    все необходимые меры, методы и формы работы с семьей, чтобы раскрытие ВИЧ-положительного статуса
                    произошло в наиболее благоприятной для ребенка форме.</p>
                <p>2. В план работы с семьей следует включить конкретные действия, направленные на подготовку родителей
                    и ребенка к раскрытию его ВИЧ- положительного статуса. Кроме того, план должен обязательно учитывать
                    индивидуальные особенности и потребности ребенка. Тщательно разработанный план предоставления услуг
                    семье в процессе раскрытия ВИЧ-положительного статуса ребенку позволяет увидеть наличие позитивной
                    динамики или же оценивать происходящие изменения и, в случае необходимости, изменять план в процессе
                    работы, если это необходимо для достижения поставленной цели, то есть раскрытие родителями
                    ВИЧ-положительного статуса ребенку.</p>
                <p>3. Консультирование замещающих родителей с целью подготовки их к ключевому разговору с ребенком о его
                    ВИЧ-статусе. И хотя мы отмечаем тот факт, что в центре процесса раскрытия ВИЧ-статуса находится
                    ребенок, на практике основная работа специалистов направлена на родителей. Родители как лица,
                    ответственные за развитие и воспитание ребенка, должны решить, каким образом лучше раскрыть ему
                    статус и определить тот образ жизни, который следует вести, чтобы оставаться активным и не иметь
                    проблем с состоянием здоровья.
                    Важно, чтобы родители приняли это решение сознательно, без давления со стороны специалистов. Как
                    отмечалось ранее, родители испытывают много страхов и сомнений по поводу раскрытия
                    ВИЧ-положительного статуса ребенку, поскольку желают защитить его от сложных личностных переживаний
                    и проявлений стигмы и дискриминации со стороны социального окружения. Именно поэтому
                    консультирование родителей и является основным в процессе оказания профессиональной
                    социально-психологической поддержки семьям, которые решили начать процесс раскрытия
                    ВИЧ-положительного статуса их ребенку.</p>
                <p>4. Подготовка ребенка к сообщению о его ВИЧ-статусе. Это последовательный и одновременно творческий
                    процесс, поскольку дети в возрасте от 7 до 11 лет воспринимают новую информацию о себе спокойно и
                    естественно. Задачей этого этапа является проведение ряда встреч с ребенком и последовательное
                    предоставление ему общей информации о понятии здоровья и его особенностях, об основах гигиены и
                    основах
                    сохранения и укрепления здоровья и т. д.В зависимости от уровня знаний ребенка, как в целом о
                    понятии
                    здоровья, так и о своем собственном, специалисты составляют индивидуальный план подготовки ребенка к
                    получению информации о ВИЧ-инфекции и его положительном статусе.
                </p>
                <p>5. Для формирования в семье доброжелательной атмосферы проводятся семейные консультации. Они также
                    направлены на повышение эмоциональных связей родителей с ребенком через совместную деятельность,
                    специальные упражнения на развитие и поддержку доверия, тактильный контакт и т. д. Такие встречи
                    проводит психолог или социальный работник отдельно для конкретной семьи. На встречи приходят те
                    члены семьи, которые участвуют в подготовке к раскрытию ВИЧ-позитивного статуса ребенку, и
                    непосредственно сам ребенок. Семейные консультации должны проводиться столько раз, сколько этого
                    требует семья, и пока они сами или психолог не увидят, что взаимоотношения родителей с ребенком
                    улучшились.</p>
                <p>6. Одним из эффективных видов подготовки для родителей может быть посещение клубов взаимопомощи для
                    родителей. Такие клубы могут посещать как родители, только готовящиеся к раскрытию, так и родители,
                    которые сомневаются и еще не приняли решение вообще.
                    В целом этот этап — самый длинный среди всех остальных в процессе раскрытия ВИЧ-положительного
                    статуса ребенку. Его продолжительность является сугубо индивидуальной и зависит, прежде всего, от
                    готовности родителей и ребенка к началу диалога.</p>
                <p>Сообщение ребенку о его ВИЧ-статусе может произойти в любой момент привычного ритма жизни семьи. Чаще
                    всего дети сами начинают задавать вопросы родителям относительно лекарств, медицинских манипуляций
                    либо просто о своем здоровье. Родителям, которые готовились к этому вместе со специалистами и
                    моделировали ситуации раскрытия ВИЧ-статуса, уже гораздо легче начать отвечать на вопросы ребенка.
                    Если раньше родители избегали вопросов ребенка, то теперь они могут давать понятные для детей
                    ответы. Большинство подготовленных родителей используют момент, когда ребенок снова начинает
                    задавать вопросы о врачах, лекарствах и т. п., для разговора с ребенком о его ВИЧ-статусе.
                    Специалистам следует поощрять родителей к самостоятельному разговору с ребенком о его ВИЧ-статусе.
                    Раскрытие должно быть осуществлено родителями, как ближайшими для ребенка людьми. Ребенок должен
                    почувствовать, что в дальнейшем он не останется наедине со своими переживаниями, страхами и
                    вопросами, и что после процесса раскрытия ничего особенного с ним не произойдет: родители не
                    перестанут его любить, родные и друзья будут по-прежнему с ним общаться, а все остальные знакомые не
                    изменят своего привычного отношения.</p>
                <p>Главная цель специалистов на этапе раскрытия ВИЧ-положительного статуса — наблюдение за эмоциональным
                    состоянием и взаимоотношениями в семье, оказание психологической поддержки.</p>
                <p><strong>Список литературы:</strong><br/>
                    1. Дети со знаком «плюс»: Информационное пособие для сотрудников дошкольных и общеобразовательных
                    учреждений / Воронин Е. Е., Глазырина Л. А., Загайнова А. И., Епоян Т. А., Фомина Е. В.; Под ред. А.
                    И. Загайновой. М., 2008.<br/>
                    2. Информационные данные семинара-тренинга «Интеграция детей, затронутых эпидемией ВИЧ инфекции в
                    образовательную среду», М.2013г.<br/>
                    3. Социально-психологическая работа с ВИЧ-позитивными детьми и их семьями. Методическое пособие /
                    Под общ ред. канд. пед. наук Н. Ф. Романовой., М.2010г.<br/>
                    4. Медведева Е. А. Формирование личности ребенка с проблемами психического развития средствами
                    искусства в артпедагогическом и арттерапевтическом пространстве. М. 2012.<br/>
                    5. Ослон В. Н. Жизнеустройство детей-сирот: профессиональная замещающая семья. Москва, 2006.<br/>
                    6. Социально-психологические проблемы современной семьи // Материалы III Всероссийской конференции
                    (с международным участием) студентов, аспирантов и молодых ученых. АГПА, Армавир, 2011.<br/>
                    7. Ковалев С. А. Психологические проблемы воспитания приемных детей // Сиротство как социальная
                    проблема: Пособие для педагогов / Л. И. Смагина, А. К. Воднева, Ю. А. Сойка и др.; Под ред. Л. И.
                    Смагиной. 1999.<br/>
                    8. ВИЧ/СПИД сегодня и рядом. Пособия для людей, принимающих решения / Под ред. H.A. Белякова, А. Г.
                    Рахмановой. СПб: Балт. мед. образ, центр, 2012.<br/>
                    9. Конституция Р Ф, 12 декабря 1993 г.<br/>
                    10. ФЗ от 30 марта 1995 г. № 38-ФЗ «О предупреждении распространения в Российской Федерации
                    заболевания, вызываемого вирусом иммунодефицита человека (ВИЧ-инфекции)»</p>
            </div>
            <div style={{fontSize: "17px", color: "#212529", fontWeight: "550"}} className={s.concept}>
                <p><strong>Соловьева Н.В.</strong> Социально-психологическое сопровождение семей,
                    воспитывающих ВИЧ-инфицированных детей // Электронный журнал «Общество. Культура. Наука.
                    Образование», 2016. Вып. 1</p>
            </div>
        </div>
        <div style={{marginTop: "57px"}}>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право" to="/tvoyo-pravo"/>
        </div>


    </div>


}

export const HrefsBeh1 = ({hrefsArr, stylef = {}, to = "/"}) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({text, stylesheet}) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({text, stylesheet, to}) => {
    return <div className={s.whitebutton}>
        <NavLink to={to}>
            {text}
        </NavLink>
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default SolovievaVICH