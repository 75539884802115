import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/WebinarWorkStudents.png"

const WebinarWorkStudents = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            21 декабря 2022 года состоялся обучающий вебинар по вопросам организации профилактической работы с
            обучающимися
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>21 декабря 2022 года на вебинарной платформе ФГБУ «Центр защиты прав и интересов детей» состоялся
                обучающий вебинар на тему: «Организация профилактической работы с обучающимися образовательных
                организаций общего и среднего профессионального образования, в том числе о практической реализации
                положений Концепции ПАВ в образовательной среде».</p>
            <p>В вебинаре приняли участие специалисты органов исполнительной власти субъектов Российской Федерации,
                осуществляющих государственное управление в сфере образования, охраны здоровья, иные органы
                исполнительной власти субъектов Российской Федерации, из 64 субъектов Российской Федерации. Всего в
                совещании приняло участие 1069 человек.</p>
            <p>С приветствием к участникам вебинара обратилась Костыряченко Юлия Александровна, заместитель директора
                Департамента государственной политики в сфере защиты прав детей Министерства просвещения Российской
                Федерации. Юлия Александровна отметила, что формирование в обществе осознанного негативного отношения к
                незаконному потреблению наркотиков и участию в их незаконном обороте является стратегической целью
                государственной антинаркотической политики, и что эта цель реализуема при формировании первичной
                профилактики. Как сообщила Юлия Александровна, необходимо создавать условия для формирования в базовой
                системе ценностей обучающихся неприятия употребления наркотиков.</p>
            <p>Вебинар продолжила Ольга Вячеславовна Заева, начальник отдела профилактики девиантного поведения
                несовершеннолетних ФГБУ «Центр защиты прав и интересов детей». Ольга Вячеславовна описала особенности
                практического применения положений Концепции профилактики употребления психоактивных веществ в
                образовательной среде на период до 2025 года, утвержденной 15 июня 2021 года статс-секретарем —
                заместителем Министра просвещения Российской Федерации А. А. Корнеевым. Также Ольга Вячеславовна
                напомнила, что социально-психологическое тестирование обладает большим потенциалом в организации
                адресной профилактической работы с обучающимися.</p>
            <p>Начальник отдела межведомственного взаимодействия в сфере профилактики Управления межведомственного
                взаимодействия Главного управления по контролю за оборотом наркотиков Министерства внутренних дел
                Российской Федерации полковник полиции Ольга Владимировна Гребешкова в своем выступлении сообщила, что в
                настоящее время ведется профилактическая работа по разным направлениям. Так, ведется работа по
                прекращению доступа к ресурсам, пропагандирующим потребление наркотических средств, реализуются
                практические профилактические мероприятия и акции в образовательных организациях (например,
                общероссийской антинаркотической акции «Сообщи, где торгуют смертью»). На сайте МВД России создан раздел<strong>
                    <a href="https://мвд.рф/библиотека-антинаркотической-пропаганды">«Библиотека антинаркотической
                        пропаганды»</a></strong>, где аккумулируются ресурсы, материалы, направленные на
                предотвращение развития аддиктивного поведения у детей и подростков. Как отметила Ольга Владимировна, в
                настоящее время сеть Интернет используется в качестве рекламной и пропагандистской площадки, в том числе
                для вербовки несовершеннолетних в преступную деятельность по распространению наркотиков, и это требует
                особенного внимания всех органов и учреждений системы профилактики безнадзорности и правонарушений
                несовершеннолетних.</p>
            <p>Денис Владимирович Астафуров, заместитель начальника отдела методического и аналитического обеспечения
                молодежной политики Управления молодежных проектов и программ Федерального агентства по делам молодежи,
                описал особенности профилактики потребления психоактивных веществ в рамках реализации государственной
                молодежной политики. Денис Владимирович отметил, что Росмолодежью организуется ряд грантовых конкурсов,
                поддерживающих проекты по профилактике социально-негативных явлений в молодежной среде. Также Денис
                Владимирович напомнил о прошедшем на днях I Съезде Российского движения детей и молодежи «Движение
                первых»: на съезде была принята программа, заложены цели и задачи движения, ориентирующие движение на
                формирование безопасной детской среды. По словам Дениса Владимировича, уже сейчас виден огромный
                объединяющий потенциал этого движения, через которое можно продвигать значимые идеи и ценности.</p>
            <p>Вебинар завершало выступление заведующей отделением организации профилактической помощи в наркологии
                Национального научного центра наркологии — филиала ФГБУ «Национальный медицинский исследовательский
                центр психиатрии и наркологии имени В. П. Сербского» Министерства здравоохранения Российской Федерации,
                Евгении Владимировны Фадеевой. Как сообщила Евгения Владимировна, употребление психоактивных веществ
                среди подростков и молодежи является серьезным фактором, влияющим на рост заболеваемости и смертности от
                причин, связанных с их потреблением, а также на сокращение продолжительности жизни и ухудшение ее
                качества. Это обосновывает необходимость ранней диагностики факторов риска, влияющих на начало
                употребления психоактивных веществ; создания комфортного пространства для учебы, которое бы
                способствовало успешному усвоению знаний, умений и навыков учащимися; а также развития социальных и
                коммуникативных навыков взаимодействия между учащимися своего и противоположного пола, с учащимися
                старшего возраста и с учителями.</p>
            <p>Спикеры и слушатели отметили высокую практическую значимость представленной информации. Ольга
                Вячеславовна Заева напомнила о функционировании на сайте «Центра защиты прав и интересов детей»
                специализированной страницы антинаркотического содержания <strong><a
                    href="https://fcprc.ru/responsible-behavior/">«Ответственное поведение — ресурс здоровья!»</a></strong>,
                на которой можно найти материалы по профилактике потребления ПАВ для специалистов, родителей и
                подростков.</p>
            <p><strong>Материалы обучающего вебинара</strong></p>
            <p style={{fontStyle: "Italic"}}><a
                href="https://fcprc.ru/wp-content/uploads/2022/12/Prezentatsiya-Zaevoi-O.-V.-1.pdf">Презентация Заевой
                О.В.</a></p>
            <p style={{fontStyle: "Italic"}}><a
                href="https://fcprc.ru/wp-content/uploads/2022/12/Prezentatsiya-Astafurova-D.-V..pdf">Презентация
                Астафурова Д.В.</a></p>
            <p style={{fontStyle: "Italic"}}><a
                href="https://fcprc.ru/wp-content/uploads/2022/12/Prezentatsiya-Fadeevoi-E.-V..pdf">Презентация
                Фадеевой Е.В.</a></p>
            <p style={{fontStyle: "Italic"}}><a href="https://www.youtube.com/watch?v=UWlY2ArxuiE">Видеозапись
                вебинара</a></p>
        </div>
        <ToNews/>
    </div>
}


export default WebinarWorkStudents;