import s from "./Mediacia.module.css"
import React, {useEffect} from "react";
import {NavLink} from "react-router-dom";
import {DownloadOutlined, FilePdfOutlined} from "@ant-design/icons";
import logo from "../../assets/img/library_logo.png"
import c1 from "../../assets/img/crs1.png"
import c2 from "../../assets/img/crs2.png"
import c3 from "../../assets/img/crs3.png"
import {usePageTracking} from "../../hooks/usePageTracking";

const VideoMediacia = () => {
    usePageTracking();
    return (
        <div style={{fontFamily: "Montserrat"}}>
            <div className={s.main}>
                <p></p>
                <div className={s.logo}>
                    <img src={logo} alt=""/>
                </div>
                <p>
                    <strong>
                        Представляем Библиотеку Практик Медиатора от Центра защиты прав и интересов детей!
                    </strong>
                </p>
                <p>
                    Это ценный ресурс для всех, кто интересуется медиативными и
                    восстановительными технологиями в разрешении конфликтов и выстраивании конструктивных
                    взаимоотношений.
                </p>
                <p>
                    На данной странице собрана важная информация и полезные материалы на темы медиативных и
                    восстановительных технологий, разрешения конфликтов и ненасильственного общения в виде записей
                    вебинаров, тренингов, мастер-классов и семинаров от экспертов и практиков со всей страны.
                </p>
                <p>
                    Предлагаем Вам познакомиться с опытом других специалистов и различными подходами к урегулированию
                    споров. Осваивайте эффективные коммуникационные стратегии построения доверительного общения и
                    развивайте навыки восстановления отношений между участниками конфликта.
                </p>
                <p>
                    Представленные материалы не только познакомят Вас с основами медиативного и восстановительного
                    подхода, но и будут способствовать развитию и углублению практических знаний и навыков в данной
                    тематике.
                </p>
                Информация будет полезна для:
                <ul className={s.list}>
                    <li>
                        родителей и законных представителей несовершеннолетних
                    </li>
                    <li>
                        специалистов по работе с детьми и подростками (учителя, сотрудники образовательных организаций,
                        педагоги-психологи, советники директоров по воспитательной работе, сотрудники комиссии по делам
                        несовершеннолетних и защите их прав, специалисты органов опеки и попечительства, специалисты
                        органов
                        молодёжной политики, члены детских и молодёжных организаций и объединений)
                    </li>
                    <li>
                        специалистов и координаторов служб медиации и примирения
                    </li>
                </ul>
                Вы можете выбрать интересующую Вас категорию:
                <p></p>
                <Filter filterArray={filterArray}/>

                {/*Видео*/}

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Тренинг</span><br/>Медиативная беседа: разрешение споров
                            между взрослым и подростком
                        </div>
                        <p></p>
                        <p>
                            Знакомство с особенностями медиативного подхода в общении с подростками.

                            Анализ структуры медиативной беседы, которую можно применять в работе и личном общении.

                            Разбор практического кейса по предложенной структуре.
                        </p>
                        <p>
                            <strong>Спикер: </strong>Козлов Анатолий Андреевич – начальник отдела медиации ФГБУ «Центр
                            защиты прав и интересов детей», медиатор, педагог-психолог, член Ассоциации профессиональных
                            медиаторов «Единство»
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Тренинг проведен в рамках форума «РосПодрос» по инициативе
                                Уполномоченного при Президенте
                                Российской Федерации по правам ребенка. Марии Львовой-Беловой. Организатор – АНО «Центр
                                развития
                                социальных проектов» в рамках Национального проекта «Образование» при поддержке
                                Министерства
                                просвещения Российской Федерации</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=107772182&id=456239442&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Тренинг_Медиативная_беседа_разрешение_споров_между_взрослым_и_подростком__HTuXj5O.pdf"/>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>

                            Семейный совет по ситуации правонарушений или деструктивного поведения несовершеннолетнего:
                            цели, этапы, подготовка участников
                        </div>
                        <p></p>
                        <p>
                            Знакомство восстановительной программой «Семейный совет», которая помогает работать с
                            трудной ситуацией несовершеннолетнего, когда родительский контроль не дает результата.


                            Управление ситуацией с недирективной и неэкспертной позицией специалиста, участие значимых
                            людей и роль социальных связей в восстановительном процессе, подготовку участников и этапы
                            семейного совета.
                        </p>
                        <p>
                            <strong>Спикер: </strong>Коновалов Антон Юрьевич – руководитель Московской городской службы
                            примирения при ГБУ ГППЦ ДОНМ <br/>Член Экспертного совета по развитию служб медиации и
                            примирения
                            в образовательных организациях<br/>Старший преподаватель кафедры юридической психологии
                            ФГБОУ ВО
                            МГППУ Медиатор и тренер по медиации в системе образования
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведён в рамках проекта «Федеральный лекторий»
                                Уполномоченного при Президенте Российской Федерации по правам ребёнка</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239042&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Коновалов_А.Ю._Семейный_совет_по_ситуации_правонарушений_или_деструктивно_oHh2BG2.pdf"/>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>
                            12 барьеров в общении с ребенком
                        </div>
                        <p></p>
                        <p>
                            Рассмотрены отношения дети-родители, разобраны двенадцать барьеров общения. Поиск
                            конструктивного пути реагирования и выстраивания собственного поведения с целью сохранения
                            доверительных и добрых отношений с ребенком.
                        </p>
                        <p>
                            <strong>Спикеры: </strong>Быкова Марина Викторовна — профессиональный медиатор,
                            тренер-преподаватель медиации, аналитик ФГБУ «Центр защиты прав и интересов детей»,
                            сертифицированный медиатор Gollege Of Mediators UK (Коллегии медиаторов Великобритании),
                            старший преподаватель МГППУ
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведён в рамках проекта «Федеральный лекторий»
                                Уполномоченного при Президенте Российской Федерации по правам ребёнка
                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239050&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Быкова_М.В._12_барьеров_в_общении_с_ребенком.pdf"/>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>Как реагировать на детскую грубость:
                            инструменты медиатора
                        </div>
                        <p></p>
                        <p>
                            Участники вебинара узнают о причинах подобного поведения и целях негативных высказываний.

                            Проанализируют конструктивные и ненасильственные способы реагирования на детскую грубость на
                            основе медиативных техник. Услышат о способах профилактики грубого и агрессивного поведения
                            у детей и подростков.
                        </p>
                        <p>
                            <strong>Спикер: </strong>Козлов Анатолий Андреевич – начальник отдела медиации ФГБУ «Центр
                            защиты прав и интересов детей», медиатор, педагог-психолог, член Ассоциации профессиональных
                            медиаторов «Единство»
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведён в рамках проекта «Федеральный лекторий»
                                Уполномоченного при Президенте Российской Федерации по правам ребёнка</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=107772182&id=456239463&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Вебинар_Как_реагировать_на_детскую_грубость_Козлов_А.А..pdf"/>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>

                            Восстановительный подход к профилактике школьной травли
                        </div>
                        <p></p>
                        <p>
                            Знакомство с основными подходами к понятию школьной травли: причины и действующие лица.
                            Способы профилактики школьной травли в рамках восстановительного подхода в службах
                            примирения.
                            Примерами из практики, в том числе городской службы примирения Москвы.
                        </p>
                        <p>
                            <strong>Спикер: </strong>Коновалов Антон Юрьевич – руководитель Московской городской службы
                            примирения при ГБУ ГППЦ ДОНМ <br/>Член Экспертного совета по развитию служб медиации и
                            примирения
                            в образовательных организациях<br/>Старший преподаватель кафедры юридической психологии
                            ФГБОУ ВО
                            МГППУ Медиатор и тренер по медиации в системе образования
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведён в рамках проекта «Федеральный лекторий»
                                Уполномоченного при Президенте Российской Федерации по правам ребёнка</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239043&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Восстановительный_подход_к_профилактике_школьной_травли.pptx"/>
                    </div>
                </div>
                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>
                            Медиативные технологии для родителей
                        </div>
                        <p></p>
                        <p>
                            Рассмотрены условия, принципы и технологии медиации. Конструктивная коммуникация. Опытом из
                            личной практики Марины Быковой.
                        </p>
                        <p>
                            <strong>Спикеры: </strong>Быкова Марина Викторовна — профессиональный медиатор,
                            тренер-преподаватель медиации, аналитик ФГБУ «Центр защиты прав и интересов детей»,
                            сертифицированный медиатор Gollege Of Mediators UK (Коллегии медиаторов Великобритании),
                            старший преподаватель МГППУ
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведён в рамках проекта «Федеральный лекторий»
                                Уполномоченного при Президенте Российской Федерации по правам ребёнка
                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239052&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>


                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>
                            Использование ролевых игр в развитии конфликтной компетентности обучающихся
                        </div>
                        <p></p>
                        <p>
                            Рассмотрим особенности создания и применения ролевых игр для развития конфликтной
                            компетентности обучающихся.

                            Познакомимся с конкретной ролевой игрой, апробированной в рамках работы с обучающимися
                            специальных учебно-воспитательных учреждений подведомственных Минпросвещения России.

                            Проанализируем специфику групповой динамики обучающихся и работы с ней в рамках формирования
                            конфликтной компетентности.
                        </p>
                        <p>
                            <strong>Спикер: </strong>Козлов Анатолий Андреевич – начальник отдела медиации ФГБУ «Центр
                            защиты прав и интересов детей», медиатор, педагог-психолог, член Ассоциации профессиональных
                            медиаторов «Единство»
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведён в рамках проекта «Федеральный лекторий»
                                Уполномоченного при Президенте Российской Федерации по правам ребёнка</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=107772182&id=456239421&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Вебинар_Использование_ролевых_игр__для_развития_конфликтной_компетнтности_J6lLbwC.pdf"/>
                    </div>
                </div>
                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>

                            Круг сообщества: восстановительный подход в работе с групповыми конфликтами детей в классе
                        </div>
                        <p></p>
                        <p>
                            Знакомство с основными принципами восстановительного подхода в работе с групповыми
                            конфликтами в классе через программу «Круг сообщества».


                            Особенности проведения круга сообщества со сложными конфликтами.


                            Информация о подготовке и проведении круга сообщества школьной службой примирения или
                            службой примирения из внешней организации. Материал будет рассматриваться на примере работы
                            Городской службы примирения Москвы и других регионов.
                        </p>
                        <p>
                            <strong>Спикер: </strong>Коновалов Антон Юрьевич – руководитель Московской городской службы
                            примирения при ГБУ ГППЦ ДОНМ <br/>Член Экспертного совета по развитию служб медиации и
                            примирения
                            в образовательных организациях<br/>Старший преподаватель кафедры юридической психологии
                            ФГБОУ ВО
                            МГППУ Медиатор и тренер по медиации в системе образования
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведён в рамках проекта «Федеральный лекторий»
                                Уполномоченного при Президенте Российской Федерации по правам ребёнка</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239041&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Круг_сообщества_восст_подход_в_работе_с_групп_конфликт_детей_в_классе.pptx"/>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>
                            Возможности и риски семейной медиации по спорам о детях
                        </div>
                        <p></p>
                        <p>
                            Освещены тонкости процедуры семейной медиации и работы медиатора. Принципы и преимущества
                            медиации. Что нужно знать, чтобы правильно выбрать медиатора.
                        </p>
                        <p>
                            <strong>Спикеры: </strong>Быкова Марина Викторовна — профессиональный медиатор,
                            тренер-преподаватель медиации, аналитик ФГБУ «Центр защиты прав и интересов детей»,
                            сертифицированный медиатор Gollege Of Mediators UK (Коллегии медиаторов Великобритании),
                            старший преподаватель МГППУ
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведён в рамках проекта «Федеральный лекторий»
                                Уполномоченного при Президенте Российской Федерации по правам ребёнка
                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239058&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Быкова_М.В._Возможности_и_риски_семейной_медиации_по_спорам_о_детях_1.pdf"/>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>

                            Как правильно задавать вопросы ребёнку
                        </div>
                        <p></p>
                        <p>
                            Рассмотрена структура открытого вопроса, в том числе с позиции медиативного подхода.

                            Особенности задавания вопросов ребенку.

                            Разобраны ситуации, когда ребенок отказывается отвечать или отвечает грубо.
                        </p>
                        <p>
                            <strong>Спикер: </strong>Козлов Анатолий Андреевич – начальник отдела медиации ФГБУ «Центр
                            защиты прав и интересов детей», медиатор, педагог-психолог, член Ассоциации профессиональных
                            медиаторов «Единство»
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведён в рамках проекта «Федеральный лекторий»
                                Уполномоченного при Президенте Российской Федерации по правам ребёнка</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=107772182&id=456239443&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Вебинар_Как_правильно_задавать_вопросы_ребенку_Козлов_А.А..pdf"/>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>

                            Подготовка педагогической команды к встрече с родителями школьника, написавшими жалобу
                        </div>
                        <p></p>
                        <p>
                            Как подготовить педагогическую команду, чтобы она выступила в переговорах с родителями как
                            профессиональная группа, имеющая ресурсы и компетенции для нахождения грамотного
                            педагогического решения.


                            Как выделить позитивный опыт педагогической команды, чтобы она нашла ресурсы в своем опыте.
                            Какую позицию занимает служба примирения.


                            Как организовать такую работу в дистанционном формате.
                        </p>
                        <p>
                            <strong>Спикер: </strong>Коновалов Антон Юрьевич – руководитель Московской городской службы
                            примирения при ГБУ ГППЦ ДОНМ <br/>Член Экспертного совета по развитию служб медиации и
                            примирения
                            в образовательных организациях<br/>Старший преподаватель кафедры юридической психологии
                            ФГБОУ ВО
                            МГППУ Медиатор и тренер по медиации в системе образования
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведён в рамках проекта «Федеральный лекторий»
                                Уполномоченного при Президенте Российской Федерации по правам ребёнка</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239056&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>
                            Медиация как технология регулирования межпоколенных конфликтов
                        </div>
                        <p></p>
                        <p>
                            Рассмотрено понятие межпоколенного конфликта, особенности его возникновения. Специфика
                            применения процедуры медиации как с точки зрения организации процесса переговоров, так и с
                            точки зрения проведения.
                        </p>
                        <p>
                            <strong>Спикер: </strong>Пегашова Станислава Олеговна – начальник Центра медиации СПб ГБУ
                            «Городской центр социальных программ и профилактики асоциальных явлений среди молодежи
                            «Контакт» (г. Санкт-Петербург)
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар организован АНО «Центр развития социальных проектов» в
                                рамках лектория «Подростковая среда» проекта «РосПодрос» в рамках Национального проекта
                                «Образование» при поддержке Министерства
                                просвещения Российской Федерации и Уполномоченного при Президенте Российской Федерации
                                по правам ребенка</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239062&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Медиация_как_технология_регулирования_межпоколенных_конфликтов.pdf"/>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>
                            Медиативная беседа с ребёнком
                        </div>
                        <p></p>
                        <p>
                            Знакомство с особенностями медиативного подхода в общении с детьми.

                            Разбор структуры медиативной беседы, которую можно применять в работе и личном общении.

                            Возможность узнать тонкости выстраивания диалога с ребенком в ситуации конфликта.
                        </p>
                        <p>
                            <strong>Спикер: </strong>Козлов Анатолий Андреевич – начальник отдела медиации ФГБУ «Центр
                            защиты прав и интересов детей», медиатор, педагог-психолог, член Ассоциации профессиональных
                            медиаторов «Единство»
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведён в рамках проекта «Федеральный лекторий»
                                Уполномоченного при Президенте Российской Федерации по правам ребёнка</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=107772182&id=456239464&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Вебинар_Медиативная_беседа_Козлов_А.А..pdf"/>
                    </div>
                </div>
                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>Работа с родителями несовершеннолетних,
                            находящихся в конфликтных отношениях с образовательной организацией
                        </div>
                        <p></p>
                        <p>
                            Опыт работы Городской службы примирения Москвы с родителями, находящимися в конфликте с
                            образовательной организацией.


                            Информация о ценностях, на которые опирается служба примирения, когда начинает
                            взаимодействовать с родителями учащихся.


                            Примеры, в которых реализуются эти принципы в формате различных техник.
                        </p>
                        <p>
                            <strong>Спикер: </strong>Коновалов Антон Юрьевич – руководитель Московской городской службы
                            примирения при ГБУ ГППЦ ДОНМ <br/>Член Экспертного совета по развитию служб медиации и
                            примирения
                            в образовательных организациях<br/>Старший преподаватель кафедры юридической психологии
                            ФГБОУ ВО
                            МГППУ Медиатор и тренер по медиации в системе образования
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведён в рамках проекта «Федеральный лекторий»
                                Уполномоченного при Президенте Российской Федерации по правам ребёнка</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239060&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Работа_с_родителями_несовершеннолетниховательной_организацией.pptx"/>
                    </div>
                </div>
                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>
                            Возможность медиации для построения правил совместной жизни, или как договариваться, чтобы
                            все были довольны
                        </div>
                        <p></p>
                        <p>
                            Что означает «договариваться» и как понять, что «все довольны»? Рассмотрена процедура
                            медиации как подход к достижению договоренностей. Какие инструменты технологии можно
                            применять при разрешении спора, не будучи медиатором.
                        </p>
                        <p>
                            <strong>Спикер: </strong>Пегашова Станислава Олеговна – начальник Центра медиации СПб ГБУ
                            «Городской центр социальных программ и профилактики асоциальных явлений среди молодежи
                            «Контакт» (г. Санкт-Петербург)
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар организован АНО «Центр развития социальных проектов» в
                                рамках лектория «Подростковая среда» проекта «РосПодрос» в рамках Национального проекта
                                «Образование» при поддержке Министерства
                                просвещения Российской Федерации и Уполномоченного при Президенте Российской Федерации
                                по правам ребенка</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239063&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>

                            Дискуссионные игры как инструмент развития коммуникативной компетентности
                        </div>
                        <p></p>
                        <p>
                            Рассмотрены особенности дискуссионных игр.<br/>
                            Их возможности в сфере развития коммуникативной компетентности подростков.<br/>
                            Разбор практической модели создания своей дискуссионной игры и особенности ее проведения.
                        </p>
                        <p>
                            <strong>Спикер: </strong>Козлов Анатолий Андреевич – начальник отдела медиации ФГБУ «Центр
                            защиты прав и интересов детей», медиатор, педагог-психолог, член Ассоциации профессиональных
                            медиаторов «Единство»
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведён в рамках проекта «Федеральный лекторий»
                                Уполномоченного при Президенте Российской Федерации по правам ребёнка</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=107772182&id=456239465&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Вебинар_Дискуссионные_игры_как_инструмент_развития_коммуникативной_компет_mVKSvW4.pdf"/>
                    </div>
                </div>
                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>

                            Работа с учащимися в службах примирения как часть воспитательной работы
                        </div>
                        <p></p>
                        <p>
                            Воспитательный ресурс службы примирения и восстановительных практик.


                            Роль юных волонтеров служб примирения, как их подготовить, в чем их действенность.


                            Какие воспитательные эффекты возникают в восстановительных программах и за счет чего.
                        </p>
                        <p>
                            <strong>Спикер: </strong>Коновалов Антон Юрьевич – руководитель Московской городской службы
                            примирения при ГБУ ГППЦ ДОНМ <br/>Член Экспертного совета по развитию служб медиации и
                            примирения
                            в образовательных организациях<br/>Старший преподаватель кафедры юридической психологии
                            ФГБОУ ВО
                            МГППУ Медиатор и тренер по медиации в системе образования
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведён в рамках проекта «Федеральный лекторий»
                                Уполномоченного при Президенте Российской Федерации по правам ребёнка</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239055&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Работа_с_учащимися_в__службах_примирения_как_часть_воспитания_Уполномоченным.pptx"/>
                    </div>
                </div>
                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>
                            Конфликты в учебных заведениях
                        </div>
                        <p></p>
                        <p>
                            Какими бывают конфликты в учебных заведениях и способы их разрешения.

                        </p>
                        <p>
                            <strong>Спикеры: </strong>Бриль Михаил Сергеевич – начальник Службы Медиации ГЦСП "КОНТАКТ",
                            практикующий медиатор<p></p>
                            Пегашова
                            Станислава Олеговна – начальник Центра медиации СПб ГБУ «Городской центр социальных программ
                            и профилактики асоциальных явлений среди молодежи «Контакт» (г. Санкт-Петербург)
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведен СПб ГБУ «Городской центр социальных программ
                                и профилактики асоциальных явлений среди молодежи «КОНТАКТ»
                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239068&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>

                            Конструктивное общение с техникой Я-сообщение
                        </div>
                        <p></p>
                        <p>
                            Участники познакомятся со структурой техники ненасильственного общения «Я-сообщение».

                            Возможности применения данной техники в общении со взрослыми и детьми.

                            Образовательные программы Центра защиты прав и интересов детей и их особенности.
                        </p>
                        <p>
                            <strong>Спикер: </strong>Козлов Анатолий Андреевич – начальник отдела медиации ФГБУ «Центр
                            защиты прав и интересов детей», медиатор, педагог-психолог, член Ассоциации профессиональных
                            медиаторов «Единство»
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведен отделом медиации ФГБУ «Центр защиты прав и
                                интересов детей»</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=107772182&id=456239461&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameBorder="0"
                                allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Вебинар_Конструктивное_общение_с_техникой_Я-сообщение_Козлов_А.А..pdf"/>
                    </div>
                </div>
                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Мастер-класс</span><br/>
                            Общение с ребёнком без конфликтов: медиативный подход
                        </div>
                        <p></p>
                        <p>
                            Способы создания безопасной и доверительной среды для общения.

                            Как медиативным путем узнать об интересах ребенка и какие есть инструменты определения
                            потребностей родителей.

                            Как поможет техника общения «Я-сообщение»
                        </p>
                        <p>
                            <strong>Спикер: </strong>Козлов Анатолий Андреевич – начальник отдела медиации ФГБУ «Центр
                            защиты прав и интересов детей», медиатор, педагог-психолог, член Ассоциации профессиональных
                            медиаторов «Единство»
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Мастер-класс проводился в рамках Всероссийского форума
                                приёмных семей на площадке Усыновите.ру.

                                Проект реализуется при использовании гранта Президента Российской Федерации,
                                предоставленного Фондом президентских грантов</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=107772182&id=456239466&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Мастер-класс_общение_с_ребенком_без_конфликтов_Козлов_А.А..pdf"/>
                    </div>
                </div>


                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>

                            Формат супервизии педагогической команды при работе с трудным случаем, включая жалобы
                            родителей
                        </div>
                        <p></p>
                        <p>
                            Знакомство с опытом работы городской службы примирения совместно со школами по трудным
                            случаям - из позиции сотрудничества.


                            Формат проведения супервизии/совета профилактики/малого педсовета в форме Круга сообщества
                            (в том числе онлайн).


                            Задачи, приемы и медиативные техники организации конструктивной коммуникации на таком Круге.
                        </p>
                        <p>
                            <strong>Спикер: </strong>Коновалов Антон Юрьевич – руководитель Московской городской службы
                            примирения при ГБУ ГППЦ ДОНМ <br/>Член Экспертного совета по развитию служб медиации и
                            примирения
                            в образовательных организациях<br/>Старший преподаватель кафедры юридической психологии
                            ФГБОУ ВО
                            МГППУ Медиатор и тренер по медиации в системе образования
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведён в рамках проекта «Федеральный лекторий»
                                Уполномоченного при Президенте Российской Федерации по правам ребёнка</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239059&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Коновалов_А.Ю._Формат_супервизии_педагогической_команды_при_работе_с_труд_dwqidCN.pdf"/>
                    </div>
                </div>
                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>
                            Возможности медиации для построения правил совместной жизни, или как договариваться, чтобы
                            все были довольны
                        </div>
                        <p></p>
                        <p>
                            Рассмотрена формы разрешения конфликтов и стратегии поведения в конфликтных ситуациях.
                            Процесс медиативной процедуры.

                        </p>
                        <p>
                            <strong>Спикер: </strong>Сизова Наталья Сергеевна – начальник отдела разрешения
                            межличностных конфликтных ситуаций Центра медиации СПб ГБУ «Городской центр социальных
                            программ и профилактики асоциальных явлений среди молодежи «Контакт» (г. Санкт-Петербург)

                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар организован АНО «Центр развития социальных проектов» в
                                рамках лектория «Подростковая среда» проекта «РосПодрос» в рамках Национального проекта
                                «Образование» при поддержке Министерства просвещения Российской Федерации и
                                Уполномоченного при Президенте Российской Федерации по правам ребенка
                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239061&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="/media/media/behavior/Возможности_медиации_для_построения_правил_совместной_жизни_или.pdf"/>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Доклад</span><br/>
                            Опыт внедрения восстановительной медиации с участием несовершеннолетних в Архангельской
                            области

                        </div>
                        <p></p>
                        <p>
                            Правовая база, на основе которой проводятся региональные восстановительные программы.
                            Рассмотрена система урегулирования конфликтных и криминальных ситуаций с участием детей.
                            Программы обучения медиаторов.

                        </p>
                        <p>
                            <strong>Спикер: </strong>Дунаева Елена Алексеевна – заведующая отделением медиации ГБУ
                            Архангельской области «Центр «Надежда»
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Доклад на секции "Медиация" на III Всероссийской
                                научно-практической конференции «Молодежь и общество: среда, коммуникация, конфликт»
                                20-21 июня 2019 года
                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239067&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>

                            Этапы развития детского коллектива и современное детское сообщество
                        </div>
                        <p></p>
                        <p>
                            Рассмотрены основные понятия развития группы в советской педагогике (в коммунарском
                            движении). Формирование коллективистических ценностно личностных ориентациях.


                            Основные этапы развития детского коллектива и задачи на каждом этапе.


                            Какие понятия могут быть положены в основу современных представлений о развитии детской
                            группы в общеобразовательной организации.
                        </p>
                        <p>
                            <strong>Спикер: </strong>Коновалов Антон Юрьевич – руководитель Московской городской службы
                            примирения при ГБУ ГППЦ ДОНМ <br/>Член Экспертного совета по развитию служб медиации и
                            примирения
                            в образовательных организациях<br/>Старший преподаватель кафедры юридической психологии
                            ФГБОУ ВО
                            МГППУ Медиатор и тренер по медиации в системе образования
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведён в рамках проекта «Федеральный лекторий»
                                Уполномоченного при Президенте Российской Федерации по правам ребёнка</i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239053&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>
                            Роль медиации и переговоров в современном образовательном процессе

                        </div>
                        <p></p>
                        <p>
                            Рассмотрены принципы современного образования и медиации. Функции школьной медиации и
                            результаты её работы для учебного заведения и семьи.

                        </p>
                        <p>
                            <strong>Спикер: </strong>Бриль Михаил Сергеевич – начальник Службы Медиации ГЦСП "КОНТАКТ",
                            практикующий медиатор

                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Вебинар проведен СПб ГБУ «Городской центр социальных программ
                                и профилактики асоциальных явлений среди молодежи «КОНТАКТ»

                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239069&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Круглый стол</span><br/>
                            Медиативная культура и восстановительные практики в системе образования

                        </div>
                        <p></p>
                        <p>
                            <strong>Спикеры: </strong>Андронникова О.О., Шевелёва Н.Н., Гараев С.Н., Носова Л.В.,
                            Королёва Ю.В., Быкова Л.В., Аракелов С.А., Курдин Ю.В., Градусова Ю.А., Лидовская Н.Н.,
                            Новикова Т.А., Гончарова Е.Л.

                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Круглый стол проведён в рамках II Всероссийского совещания
                                школьных служб примирения и медиации
                                <p></p>
                                Оператор Всероссийского совещания – ФГБУ «Центр защиты прав и интересов детей»

                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239070&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Sbornik
                            url="/media/media/behavior/Sbornik-materialov-II-Vserossijskogo-soveshhaniya-sluzhb-primireniya-i-me_ZZgDEt2.pdf"/>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Круглый стол</span><br/>


                            Подготовка специалиста в области восстановительного подхода и медиации в системе
                            образования: современные вызовы и решения

                        </div>
                        <p></p>
                        <p>
                            <strong>Спикеры: </strong>Архипкина А.С., Иванова Е.Н., Горшенина Е.В., Манина И.И.,
                            Колотильщикова Е.В., Дорошенко И.С., Максимова Л.А., Валиев Р.А., Каримова В.Г., Попова
                            Е.П., Серякина А.В., Филёва Н.Н., Таллибулина М.Т.

                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Круглый стол проведён в рамках II Всероссийского совещания
                                школьных служб примирения и медиации

                                <p></p>
                                Оператор Всероссийского совещания – ФГБУ «Центр защиты прав и интересов детей»

                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239071&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Sbornik
                            url="/media/media/behavior/Sbornik-materialov-II-Vserossijskogo-soveshhaniya-sluzhb-primireniya-i-me_ZZgDEt2.pdf"/>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Круглый стол</span><br/>
                            Реализация восстановительных практик и медиации как элемента профилактики правонарушений в
                            подростковой среде

                        </div>
                        <p></p>
                        <p>
                            <strong>Спикеры: </strong>Заковряшина С.Н., Гиенко Л.Н., Ильиных Е.А., Хоменко Л.Н.,
                            Шалагинова М.В., Коробкова В.В., Хмелева Т.Н., Хованская Т.В., Рамазанова М.М.

                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Круглый стол проведён в рамках II Всероссийского совещания
                                школьных служб примирения и медиации

                                <p></p>
                                Оператор Всероссийского совещания – ФГБУ «Центр защиты прав и интересов детей»

                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239072&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Sbornik
                            url="/media/media/behavior/Sbornik-materialov-II-Vserossijskogo-soveshhaniya-sluzhb-primireniya-i-me_ZZgDEt2.pdf"/>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Круглый стол</span><br/>
                            Восстановительные практики и медиация в социальной сфере ребенок-семья-школа

                        </div>
                        <p></p>
                        <p>
                            <strong>Спикеры: </strong>Ковалёва Ю.А., Графский В.П., Заманова С.М., Кузнецова Ю.Е.,
                            Измайлова Е.В., Дрянных О.В., Морозова Е.В., Бушмелева И.В., Романова Н.Г., Беляев М.А.

                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Круглый стол проведён в рамках II Всероссийского совещания
                                школьных служб примирения и медиации

                                <p></p>
                                Оператор Всероссийского совещания – ФГБУ «Центр защиты прав и интересов детей»

                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239073&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Sbornik
                            url="/media/media/behavior/Sbornik-materialov-II-Vserossijskogo-soveshhaniya-sluzhb-primireniya-i-me_ZZgDEt2.pdf"/>
                    </div>
                </div>


                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Круглый стол</span><br/>
                            Управленческие механизмы поддержки сети служб медиации и примирения

                        </div>
                        <p></p>
                        <p>
                            <strong>Спикеры: </strong>Чуприков Р.А., Коновалов А.Ю., Прянишников Т.В., Пономарева И.Н.,
                            Ильина С.В., Белоногова Е.В., Хавкина А.Л., Зыкова Н.А., Крупин М.Л., Неверова Л.В.,
                            Захарова Н.И.
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Круглый стол проведён в рамках II Всероссийского совещания
                                школьных служб примирения и медиации

                                <p></p>
                                Оператор Всероссийского совещания – ФГБУ «Центр защиты прав и интересов детей»

                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239074&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Sbornik
                            url="/media/media/behavior/Sbornik-materialov-II-Vserossijskogo-soveshhaniya-sluzhb-primireniya-i-me_ZZgDEt2.pdf"/>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Секция</span><br/>
                            Марафон практик
                        </div>
                        <p></p>
                        <p>
                            <strong>Спикеры: </strong>Хут С.Е., Надегина Н.Н., Кустова А.Н., Протащук М.А., Мусатова
                            И.Б., Мичурина Г.Р., Пискунова Ю.В., Судьина Л.А., Козлов А.А.
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Круглый стол проведён в рамках II Всероссийского совещания
                                школьных служб примирения и медиации

                                <p></p>
                                Оператор Всероссийского совещания – ФГБУ «Центр защиты прав и интересов детей»

                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239075&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Sbornik
                            url="/media/media/behavior/Sbornik-materialov-II-Vserossijskogo-soveshhaniya-sluzhb-primireniya-i-me_ZZgDEt2.pdf"/>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Доклад</span><br/>
                            Служба примирения
                        </div>
                        <p>
                            Оригинал видео по ссылке:<br/>
                            <a
                                href="https://www.youtube.com/watch?v=wLUu1OMTSWw">https://www.youtube.com/watch?v=wLUu1OMTSWw</a>
                        </p>
                        <p></p>
                        <p>
                            <strong>Спикер: </strong>Коновалов Антон Юрьевич – руководитель Московской городской службы
                            примирения при ГБУ ГППЦ ДОНМ <br/>Член Экспертного совета по развитию служб медиации и
                            примирения
                            в образовательных организациях<br/>Старший преподаватель кафедры юридической психологии
                            ФГБОУ ВО
                            МГППУ Медиатор и тренер по медиации в системе образования
                        </p>
                        <div className={s.categories}>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239076&hd=1" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>


                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Онлайн-семинар</span><br/>
                            Внедрение медиативных технологий в работу муниципальных структур по координации деятельности
                            органов и учреждений системы профилактики безнадзорности и правонарушений несовершеннолетних
                            и учреждений, оказывающих социальные услуги
                        </div>
                        <p>
                            В онлайн-семинаре выступили эксперты по темам «Ценности восстановительного правосудия и их
                            реализация в городской службе примирения», «Психологические особенности применения
                            медиативных технологий в работе с детьми и родителями», «Актуальная практика применения
                            медиативных техник в работе с несовершеннолетними», «Социально-психологическая профилактика
                            разводов в условиях социозащитного учреждения. Медиация как инструментарий разрешения
                            конфликтов в семье», «Внедрение социальной услуги примирения как способ решения конфликтных
                            ситуаций, социальной реабилитации и сохранности кровной семьи на основе восстановительной
                            медиации», а также освещен региональный опыт по реализации восстановительных технологий в
                            учреждениях социального обслуживания населения и работы служб примирения по случаям
                            административных правонарушений несовершеннолетних
                        </p>
                        <p></p>
                        <p>
                            <i style={{fontSize: "12px"}}>Проведено Фондом поддержки детей, находящихся в трудной
                                жизненной ситуации

                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239077&hd=1" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Вебинар</span><br/>
                            Медиативные технологии в деятельности СУВУ: конфликт и ресурсы работы с ним
                        </div>
                        <p></p>
                        <p>
                            <strong>Спикеры: </strong>Фальковская Л.П., Зверева Н.Е., Леденцова В.А., Быкова М.В.,
                            Коновалов А.Ю., Денисова С.А., Круглова О.В., Козлов А.А., Беляев М.А., Хозова И.А., Искрук
                            И.В., Пестовская И.В.
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Проведено Фондом поддержки детей, находящихся в трудной
                                жизненной ситуации

                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239078&hd=1" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="https://fond-detyam.ru/biblioteka/materialy-i-seminary/17110/"/>
                    </div>
                </div>


                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Онлайн-курс</span><br/>
                            Технология «круги сообщества» в образовательном процессе
                        </div>
                        <p>
                            На платформе открытых онлайн-курсов ГАОУ ВО МГПУ <a
                            href="https://ok.mgpu.ru">https://ok.mgpu.ru</a> размещен бесплатный онлайн-курс, содержащий
                            темы:
                            <ul>
                                <li>Введение в технологию «круги сообщества» в образовательном процессе</li>
                                <li>Родительское собрание в формате круга сообщества</li>
                                <li>Классный час в формате круга сообщества</li>
                                <li>Цикл занятий с учащимися «Учусь общаться»</li>
                                <li>Применение кругов сообщества по запросу «трудный класс»</li>
                            </ul>
                        </p>
                        <p></p>
                        <p>
                            <strong>Преподаватель: </strong>Путинцева Наталья Владимировна – медиатор, тренер по
                            медиации, ведущая восстановительных программ в образовательной и социальной среде,
                            преподаватель кафедры юридической психологии и права факультета «Юридическая психология»
                            Московского государственного психолого-педагогического университета, член Всероссийской
                            ассоциации восстановительной медиации
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Онлайн-курс подготовлен Московским городским педагогическим
                                университетом совместно с Городским психолого-педагогическим центром при поддержке
                                Департамента образования и науки города Москвы

                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <a href="https://ok.mgpu.ru/courses/tehnologiya-krugi-soobshhestva-v-obrazovatelnom-protsesse/?tab=tab-instructor">
                            <img src={c1} style={{width: "100%", height: "400px"}}/>
                        </a>
                    </div>
                </div>


                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Онлайн-курс</span><br/>
                            Техники разрешения конфликтных ситуаций
                        </div>
                        <p>
                            На платформе открытых онлайн-курсов ГАОУ ВО МГПУ <a
                            href="https://ok.mgpu.ru">https://ok.mgpu.ru</a> размещен бесплатный
                            онлайн-курс, освещающий вопросы профилактики травли, применения коммуникативных техник,
                            проведения кругов сообщества и др. Курс будет полезен классным руководителям, педагогам
                            образовательных организаций и другим заинтересованным лицам.
                        </p>
                        <p></p>
                        <p>
                            <strong>Преподаватель: </strong>Коновалов Антон Юрьевич – руководитель Московской городской
                            службы примирения при ГБУ ГППЦ ДОНМ Член Экспертного совета по развитию служб медиации и
                            примирения в образовательных организациях Старший преподаватель кафедры юридической
                            психологии ФГБОУ ВО МГППУ Медиатор и тренер по медиации в системе образования
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Онлайн-курс подготовлен Московским городским педагогическим
                                университетом совместно с Городским психолого-педагогическим центром при поддержке
                                Департамента образования и науки города Москвы

                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <a href="https://ok.mgpu.ru/courses/krugi-soobshhestva-po-konfliktnym-situatsiyam/?tab=tab-instructor">
                            <img src={c2} style={{width: "100%", height: "400px"}}/>
                        </a>
                    </div>
                </div>

                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Онлайн-курс</span><br/>
                            Формы и практики управления поведением обучающихся в классе
                        </div>
                        <p>
                            На платформе открытых онлайн-курсов ГАОУ ВО МГПУ <a
                            href="https://ok.mgpu.ru">https://ok.mgpu.ru</a> размещен бесплатный
                            онлайн-курс, содержащий темы:
                            <ul>
                                <li>Подходы к реагированию на деструктивное поведение учеников</li>
                                <li>Ответственное поведение</li>
                                <li>Понимающая (рефлексивная) позиция</li>
                                <li>Теория воссоединяющего стыжения</li>
                                <li>Теория обретения внутренней силы</li>
                                <li>Традиции мирного проживания</li>
                                <li>Лестница к сотрудничеству</li>
                            </ul>
                        </p>
                        <p></p>
                        <p>
                            <strong>Преподаватель: </strong>Коновалов Антон Юрьевич – руководитель Московской городской
                            службы примирения при ГБУ ГППЦ ДОНМ Член Экспертного совета по развитию служб медиации и
                            примирения в образовательных организациях Старший преподаватель кафедры юридической
                            психологии ФГБОУ ВО МГППУ Медиатор и тренер по медиации в системе образования
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Онлайн-курс подготовлен Московским городским педагогическим
                                университетом совместно с Городским психолого-педагогическим центром при поддержке
                                Департамента образования и науки города Москвы

                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <a href="https://ok.mgpu.ru/courses/formy-i-praktiki-upravleniya-povedeniem-obuchayushhihsya-v-klasse/?tab=tab-curriculum">
                            <img src={c3} style={{width: "100%", height: "400px"}}/>
                        </a>
                    </div>
                </div>
                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Выпуск программы «Час с психологом»</span><br/>
                            Службы примирения
                        </div>
                        <p></p>
                        <p>
                            В передаче приняли участие руководитель PR-службы Департамента образования и науки города
                            Москвы Наталья Александровна Цымбаленко, руководитель городской службы примирения ГППЦ
                            Департамента образования и науки города Москвы Антон Юрьевич Коновалов и директор школы №
                            1285 Ярослава Дмитриевна Алпатова
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy" src="https://vk.com/video_ext.php?oid=-182917553&id=456239081&hd=2" width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
                                frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
                <div className={s.videoBlock}>
                    <div className={s.left}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            <span style={{fontSize: "15px"}}>Онлайн-семинар</span><br/>
                            Использование медиативных технологий в деятельности специальных учебно-воспитательных
                            учреждений для восстановления детско-родительских отношений
                        </div>
                        <p></p>
                        <p>
                            <strong>Спикеры: </strong>Козлов А.А., Махнева О.П., Спесивов Н.В., Василевская В.В., Салькова Н.В., Заманова С.М.
                        </p>
                        <p>
                            <i style={{fontSize: "12px"}}>Проведено Фондом поддержки детей, находящихся в трудной жизненной ситуации
                            </i>
                        </p>
                        <div className={s.categories}>
                            <Category title="Родителям" color="#e07b9b"/>
                            <Category title="Специалистам по работе с детьми и подростками" color="#f6a54a"/>
                            <Category title="Специалистам служб медиации и примирения" color="#a6cdec"/>
                        </div>
                    </div>
                    <div className={s.right}>
                        <iframe loading="lazy"  src="https://vk.com/video_ext.php?oid=-182917553&id=456239079&hd=2&autoplay=1"
                                width="100%"
                                height="400px" style={{borderRadius: "20px"}}
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
                                frameBorder="0" allowFullScreen></iframe>
                        <Presentation
                            url="https://fond-detyam.ru/biblioteka/materialy-i-seminary/17836/"/>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default VideoMediacia

export const Category = ({color, title}) => {
    return (
        <div className={s.category} style={{backgroundColor: color}}>
            {title}
        </div>
    )
}

export const Filter = ({filterArray}) => {
    return (
        <div className={s.filter}>
            <div className={s.filterRefs}>
                {filterArray.map(el =>
                    <FilterRef title={el.title} url={el.url} background={el.background}/>
                )}
            </div>
        </div>
    )
}

const FilterRef = ({url, title, background}) => {
    return <>
        <div className={s.href} style={{background: background}}>
            <NavLink to={url}>
                <span style={{boxShadow: "3px 3px 3px black;"}}>{title}</span>
            </NavLink>
        </div>
    </>
}

const filterArray = [
    {
        title: "Родителям",
        url: "/video_mediacia/parents",
        background: "rgba(224, 123, 155, 0.7)"
    },
    {
        title: "Специалистам по работе с детьми и подростками",
        url: "/video_mediacia/children",
        background: "rgba(246, 165, 74, 0.7)"
    },
    {
        title: "Специалистам служб медиации и примирения",
        url: "/video_mediacia/piece",
        background: "rgba(166, 205, 236, 0.7)"
    }
]

export const Presentation = ({url}) => {
    return (
        <div className={s.presentation}>
            <a target="_blank" href={url}><DownloadOutlined style={{paddingRight: "6px", fontSize: "22px"}}/>Скачать
                презентацию</a>
        </div>
    )
}
export const Sbornik = ({url}) => {
    return (
        <div className={s.presentation}>
            <a target="_blank" href={url}><DownloadOutlined style={{paddingRight: "6px", fontSize: "22px"}}/>Сборник
                материалов</a>
        </div>
    )
}