import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import logo from "../../../assets/News/OOMDK.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {NavLink} from "react-router-dom";
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";



const OOMDK = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2>
                    Вебинар по актуальным вопросам деятельности комиссий по делам несовершеннолетних и защите их прав для
                    ответственных секретарей комиссий субъектов Российской Федерации и специалистов, обеспечивающих
                    деятельность указанных комиссий, по теме: «Об организации мониторинга деятельности комиссий по делам
                    несовершеннолетних и защите их прав», 4 февраля 2021 г.
                </h2>
            </div>

            <div>
                <img src={logo}/>
            </div>

            <p style={{fontSize: "17px", color: "#212529"}} className={s.concept}>
                <p>
                    4 февраля 2021 года в дистанционном формате на Webinar-платформе ФГБУ «Центр защиты прав и интересов
                    детей» состоялся вебинар по актуальным вопросам деятельности комиссий по делам несовершеннолетних и
                    защите их прав для ответственных секретарей комиссий по делам несовершеннолетних и защите их прав
                    субъектов Российской Федерации и специалистов, обеспечивающих деятельность указанных комиссий, по
                    теме: «Об организации мониторинга деятельности комиссий по делам несовершеннолетних и защите их
                    прав» (далее – вебинар).
                </p>
                <p>Данное мероприятие, организованное Минпросвещения России совместно с ФГБУ «Центр защиты прав и
                    интересов детей», проводилось в целях разъяснения актуальных вопросов принятия мер по обеспечению
                    мониторинга деятельности комиссий по делам несовершеннолетних и защите их прав.</p>
                <p>С обращением к участникам вебинара о необходимости обеспечения особого внимания анализу состояния
                    подростковой преступности, прогнозированию причин и условий, способствующих совершению
                    правонарушений, принятию дополнительных мер по вопросам: предупреждения вовлечения
                    несовершеннолетних в участие в несанкционированных мероприятиях, организации своевременной
                    индивидуальной профилактической работы в отношении несовершеннолетних, доставленных в органы
                    внутренних дел, допустивших нарушение законодательства при проведении публичных и массовых
                    мероприятий, выступила директор Департамента государственной политики в сфере защиты прав детей
                    Минпросвещения России Л.П. Фальковская.</p>
                <p>
                    В рамках мероприятия заместителем директора Департамента государственной политики в сфере защиты
                    прав детей Минпросвещения России Ю.А. Костыряченко даны разъяснения по вопросам заполнения формы
                    федерального статистического наблюдения № 1-КДН «Сведения о деятельности комиссии по делам
                    несовершеннолетних и защите их прав по профилактике безнадзорности и правонарушений
                    несовершеннолетних»; в докладе аналитика ФГБУ «Центр защиты прав и интересов детей» Н.Т. Григорьевой
                    отражены сведения о структуре и содержательном наполнении отчета комиссий о работе по профилактике
                    безнадзорности и правонарушений несовершеннолетних в соответствии с подпунктом 6 пункта 2 статьи 11
                    Федерального закона от 24 июня 1999 г. № 120-ФЗ «Об основах системы профилактики безнадзорности и
                    правонарушений несовершеннолетних»; опыт субъектов Российской Федерации по формированию отчетов
                    комиссий о работе по профилактике безнадзорности и правонарушений несовершеннолетних представили:
                    ответственный секретарь комиссии по делам несовершеннолетних и защите их прав аппарата комиссии по
                    делам несовершеннолетних и защите их прав аппарата Губернатора Ямало-Ненецкого автономного округа
                    Д.А. Попов и ведущий консультант отдела администрации Губернатора Ульяновской области по обеспечению
                    деятельности комиссии по делам несовершеннолетних, член комиссии по делам несовершеннолетних и
                    защите их прав при Правительстве Ульяновской области Ю.В. Ковтун.
                </p>
                <p><a href="https://fcprc.ru/media/media/behavior/2021-02-14-programma.doc">Программа вебинара</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/2021-02-14-otchet.docx">Отчет о проведении
                    вебинара</a></p>
            </p>
        </div>
        <div style={{marginTop: "57px"}}>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право"/>

        </div>


    </div>


}

export const HrefsBeh1 = ({
                              hrefsArr, stylef = {}, to = "/"
                          }) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({
                        text, ul, input = null, to
                    }) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({
                              text, to
                          }) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({
                        text, stylesheet
                    }) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({
                         text, stylesheet
                     }) => {
    return <div className={s.whitebutton}>
        {text}
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default OOMDK