import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/ChildDefence2021.jpg"
import logo from "../../../assets/Behavior/conceptions.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {NavLink} from "react-router-dom";
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";


const ChildDefence2021 = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2>
                    Всероссийская научно-практическая конференция «Актуальные проблемы профилактики девиантного
                    поведения
                    несовершеннолетних», 2021 год

                </h2>
                <div>
                    <img src={PageImg}/>
                </div>
                <p style={{fontSize: "17px", color: "#212529"}} className={s.concept}>
                    <p>15-17 декабря 2021 года Министерством просвещения Российской Федерации проведена Всероссийская
                        научно-практическая конференция «Актуальные проблемы профилактики девиантного поведения
                        несовершеннолетних», цель которой – обсуждение ключевых проблем и задач развития системы
                        профилактики безнадзорности и правонарушений несовершеннолетних, включающей комплекс социальных,
                        правовых, психолого-педагогических, медико-социальных, воспитательных, в том числе
                        правовоспитательных, и иных мер, направленных на выявление и устранение причин и условий,
                        способствующих правонарушениям и антиобщественным действиям несовершеннолетних, предупреждение и
                        коррекцию девиантного поведения детей и подростков с учетом современных научных методов,
                        ресурсов и
                        возможностей, повышение компетентности специалистов органов и учреждений системы
                        профилактики.</p>
                    <p></p>
                    <p>Оператор конференции – федеральное государственное бюджетное учреждение «Центр защиты прав и
                        интересов детей».</p>
                    <p>
                        В этом году конференция проходила в очном и дистанционном форматах. Всего в мероприятиях
                        конференции
                        приняли участие более 2 500 специалистов из 85 субъектов Российской Федерации. Среди участников
                        Конференции: представители Администрации Президента Российской Федерации; Уполномоченного при
                        Президенте Российской Федерации по правам ребенка; федеральных органов исполнительной власти;
                        Генеральной прокуратуры Российской Федерации; Следственного комитета Российской Федерации; Фонда
                        поддержки детей, находящихся в трудной жизненной ситуации; автономной некоммерческой организации
                        «Агентство стратегических инициатив по продвижению новых проектов»; органов и учреждений системы
                        профилактики субъектов Российской Федерации; науки, общественности, экспертного сообщества.
                    </p>

                    <p>
                        На пленарных докладах обсуждались пути реализации приоритетных направлений государственной
                        политики
                        в сфере профилактики девиантного поведения несовершеннолетних, проблемные вопросы реализации
                        федеральными органами исполнительной власти мероприятий по реализации Концепции развития системы
                        профилактики безнадзорности и правонарушений несовершеннолетних до 2025 года, современные тренды
                        проектирования в области профилактики асоциального поведения детей и подростков.
                    </p>

                    <p>
                        В ходе работы секций «Безопасность детства. Социальные практики помощи ребенку и его семье»,
                        «Личность и социум. Профилактика асоциального поведения детей и подростков», «Ценность здоровья.
                        Позитивная профилактика зависимого поведения», «Медиативные практики. Пространство
                        конструктивного
                        взаимодействия» был представлен опыт профилактической деятельности Республики Адыгея, Республики
                        Башкортостан, Карачаево-Черкесской Республики, Республики Карелия, Республики Коми, Республики
                        Мордовия, Республики Саха (Якутия), Республики Татарстан, Удмуртской Республики, Республики
                        Хакасия,
                        Чувашской Республики, Забайкальского края, Краснодарского края, Красноярского края, Пермского
                        края,
                        Астраханской, Вологодской, Иркутской, Калужской, Кемеровской, Курской, Курганской,
                        Ленинградской,
                        Липецкой, Московской, Мурманской, Омской, Самарской, Саратовской, Свердловской, Тамбовской,
                        Тверской, Томской, Ульяновской, Челябинской областей,
                        г. Москвы, г. Санкт-Петербург, Ханты-Мансийского автономного округа.
                    </p>
                    <p>
                        В рамках работы конференции прошли Всероссийское совещание директоров специальных
                        учебно-воспитательных учреждений (СУВУ), панельная дискуссия «Психологическое благополучие как
                        основа безопасности детей и профилактики подростковой девиации» и проведенный в Общественной
                        палате
                        Российской Федерации круглый стол «О повышении эффективности деятельности сети организаций,
                        обеспечивающих специальную профилактическую работу с несовершеннолетними, и внедрение
                        современных
                        технологий реабилитации и ресоциализации несовершеннолетних. Ресурсы СУВУ», ключевой вопрос
                        которого
                        — внедрение современных технологий реабилитации и ресоциализации несовершеннолетних, ресурсов
                        СУВУ в
                        профилактической работе.
                    </p>
                    <p>
                        Большой интерес у участников конференции вызвал открытый марафон мастер-классов: «Школа
                        осознанных
                        родителей как ранняя профилактика семейного неблагополучия», «Ресурсные медиативные практики в
                        тренингах как способ профилактики отклоняющегося поведения подростков», «Давай договоримся!
                        Развиваем навыки конструктивного диалога», «Просто дети, не трудные». Развитие отношения
                        повышения
                        внимания и заботы к детям с отклонениями в поведении».
                    </p>
                    <p>
                        По завершении мероприятия участники получили возможность внести предложения в резолюцию
                        Всероссийской научно-практической конференции.
                    </p>
                </p>
            </div>
        </div>
        <div style={{marginTop: "57px"}}>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право"/>

        </div>

    </div>


}

export const HrefsBeh1 = ({hrefsArr, stylef = {}, to = "/"}) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
    {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({text, stylesheet}) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({text, stylesheet}) => {
    return <div className={s.whitebutton}>
        {text}
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default ChildDefence2021