import React from "react";
import ico from "../../../assets/arrow.svg"
import s from "./PageFiles.module.css"
import {useState, useEffect} from "react";
import axios from "axios";
import {hrefsArrKDN} from "./FilesKDNGos";
import {NavLink} from "react-router-dom";
import {DownOutlined} from "@ant-design/icons";


const FilesKDNNews = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Новости и события
                </h2>
                <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                    2024
                </h1>
                <FileLeftLineKDN
                    text={"Правительственную комиссию по делам несовершеннолетних и защите их прав возглавил Заместитель Председателя Правительства Российской Федерации Д.Н. Чернышенко"}
                    refers={"/news/280624"} ico={ico}/>
                <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                    2023
                </h1>
                <FileLeftLineKDN
                    text={"В Саратове состоялась межрегиональная научно-практическая конференция, посвященная 105-летию комиссий по делам несовершеннолетних и защите их прав"}
                    refers={"/saratov-105"} ico={ico}/>
                <FileLeftLineKDN
                    text={"Внесены изменения в состав Правительственной комиссии по делам несовершеннолетних и защите их прав"}
                    refers={"/news0305"} ico={ico}/>
                <FileLeftLineKDN
                    text={"30 марта 2023 года состоялось заочное заседание Правительственной комиссии по делам несовершеннолетних и защите их прав"}
                    refers={"/zasedanie30032023"} ico={ico}/>
            </div>
            <div style={{marginTop: "-3px"}}>
                <HrefsKDN hrefsArr={hrefsArrKDN}/>
            </div>
        </div>
    </>
}
export const FileLeftLineKDN = ({text, refers, ico, styles = {}}) => {
    return <div className={s.KDNfileleftlineRed} style={styles}>
        <img src={ico}/>
        <div style={{
            margin: "20px"
        }}>
            <a href={refers} target="_blank">
                {text}
            </a>
        </div>
    </div>
}

export const FileLeftLineKDNPlus = ({text, ico, refers, styles = {}, answer}) => {
    const [activeQ, setActiveQ] = useState(false);

    return <div onClick={() => {
        activeQ ? setActiveQ(false) : setActiveQ(true)}
    } style={styles}>
        <div className={activeQ ? s.KDNfileleftlineRedNew : s.KDNfileleftlineRed}>
            <img src={ico}/>
            <div style={{
                margin: "20px"
            }}>
                <a href={refers} target="_blank">
                    {text}
                </a>
            </div>
        </div>
        {activeQ ? <Answer text={answer}/> : <></>}
    </div>
}

export const Answer = ({text}) => {
    return (
        <div className={s.answer}>
            {text}
        </div>
    )
}

const OneLiHrefs = ({text, ul, input = null, href, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
export const HrefsKDN = ({hrefs}) => {
    return <div className={s.hrefs}>
        <ul>
            {hrefsArrKDN.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} href={el.href} to={el.to}/>
            </>)}
        </ul>

    </div>
}
export default FilesKDNNews