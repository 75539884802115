import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {NavLink} from "react-router-dom";
import {hrefsArr} from "../FilePage/FilesNPD"

const PageInfo = ({url, header, children, style = {}}) => {
    return <>
        <div className={s.page}>
            <div className={s.content}>
                <div className={s.newHeader}>
                    <h2 style={{
                        fontWeight: "normal",
                        color: "#3a4663",
                        fontFamily: "Proxima Nova Lt",
                        fontSize: "30px",
                        textAlign: "start"
                    }}>
                        Ответственное поведение — ресурс здоровья
                    </h2>

                <div>
                    <img src={PageImg}/>
                </div>
                <div>
                    <p><strong>Специализированная страница «Ответственное поведение — ресурс здоровья»</strong> создана в целях оказания
                        информационно-методической помощи педагогам и родителям (законным представителям) в организации
                        работы по <strong>профилактике аддиктивного поведения несовершеннолетних</strong>, а также в области <strong>профилактики
                            ВИЧ/СПИДа</strong> в образовательной среде, формирования у обучающихся образовательных организаций <strong>ответственного отношения к своему здоровью</strong> и психологического иммунитета к употреблению
                        психоактивных веществ.
                    </p>
                    <p align="JUSTIFY">На данной странице педагогические работники, родители (законные представители)
                        несовершеннолетних и обучающиеся образовательных организаций могут ознакомиться с
                        информационными и справочными материалами, предназначенными для профилактики зависимого
                        поведения и социально значимых заболеваний, а также направленными на формирование здорового
                        образа жизни. На странице представлены: нормативно-правовые документы; методические и
                        информационно-аналитические материалы для педагогических работников; информационно-аналитические
                        материалы для родителей (законных представителей) и обучающихся образовательных организаций;
                        информация о наиболее эффективных практиках субъектов Российской Федерации в области построения
                        системной профилактической работы в образовательных организациях по профилактике незаконного
                        оборота и немедицинского потребления наркотических средств; сведения об актуальных
                        профилактических мероприятиях; информация о постоянно действующих телефонах доверия, горячих
                        линиях, ссылки на электронные страницы федеральных и региональных органов исполнительной власти,
                        реализующих мероприятия, направленные на профилактику незаконного потребления наркотических
                        средств и иных психоактивных веществ среди несовершеннолетних.</p>
                    <p></p>
                    <p align="JUSTIFY">Материалы специализированной страницы «Ответственное поведение — ресурс здоровья»
                        могут быть использованы руководителями, педагогами, психологами и специалистами образовательных
                        организаций при организации профилактической работы с обучающимися и их родителями (законными
                        представителями) и разработке профилактических программ.</p>
                    <p></p>
                    <p align="JUSTIFY">Информационные материалы для родителей (законных представителей) помогут повысить
                        уровень родительской компетенции в области профилактики аддиктивного поведения и социально
                        значимых заболеваний, дать необходимые знания о развитии, здоровье, воспитании детей с учетом их
                        возрастных особенностей, а также о юридических и медицинских последствиях зависимого поведения
                        несовершеннолетних.</p>
                    <p></p>
                    <p align="JUSTIFY">Материалы для подростков и молодежи предназначены для формирования у обучающихся
                        образовательных организаций навыков и умений, обеспечивающих принятие ими большей
                        ответственности за своё поведение и стимулирующих их к соблюдению принципов здорового и
                        безопасного образа жизни.</p>
                    <p></p>
                    <p align="JUSTIFY">Также материалы данной страницы могут быть полезны преподавателям и слушателям
                        системы подготовки и переподготовки педагогов, психологов и других специалистов, обеспечивающих
                        профилактику аддиктивного поведения и социально значимых заболеваний среди обучающихся и их
                        родителей (законных представителей).</p>
                </div>
                </div>
            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArr}/>
                <PinkButton text="Материалы по профилактике ВИЧ/СПИДa" stylesheet={"pinkbutton"} refs="/spec-materials-category/metodicheskie-materialy-po-profilaktike-vich-spida"/>
                <PinkButton text="Инструкция по подготовке материалов по профилактике"
                            refs="https://fcprc.ru//media/media/behavior/07-6523-s-Pr.pdf"/>
                <WhiteButton text="Перейти к опросу родителей по информированности о профилактике ПАВ"
                             refs="http://опрос-родителей-о-пав.рф/"/>
                <WhiteButton text="Перейти к опросу молодёжи о ВИЧ" refs="http://опрос-молодежи-о-вич.рф/"/>
                <WhiteButton text="Перейти к опросу педагогов о профилактике ВИЧ-инфекции среди обучающихся"
                             refs="http://опрос-педагогов-о-вич.рф/"/>
            </div>
        </div>
    </>


}

export const HrefsBeh1 = ({hrefsArr, stylef = {}, to = "/"}) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
export const PinkButton = ({text, stylesheet, refs}) => {
    return <div className={s.pinkbutton}>
        <a href={refs}>

            {text}
        </a>

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
export const WhiteButton = ({text, stylesheet, refs = "/"}) => {
    return <div className={s.whitebutton}>
        <a href={refs}>

            {text}
        </a>
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}


export default PageInfo