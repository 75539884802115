import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News030624.jpg"

const News030624 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Семинар «Безопасность обучающихся в период летних каникул» 29 мая 2024 года в Калининграде
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Мероприятие проводилось Министерством образования Калининградской области при участии Центра диагностики и
            консультировании детей и подростков в рамках исполнения программы профилактики деструктивного поведения
            несовершеннолетних в Калининградской области.
            <p></p>
            На семинаре были освещены вопросы психологической безопасности образовательной среды и оказания практической
            помощи в организации профилактической работы с учащимися и их родителями в период летних каникул.
            <p></p>
            С докладом по теме «Профилактика конфликтов и агрессивного поведения обучающихся в период летних каникул»
            выступил в онлайн-формате начальник отдела медиации Центра защиты прав и интересов детей Козлов Анатолий
            Андреевич.
            <p></p>
            Анатолий Андреевич рассказал о проведении дополнительных развивающих занятий для обучающихся по
            использованию медиативных технологий на основе рекомендованных Минпросвещения России программ, а также о
            важности просветительской работы среди педагогического и родительского сообществ.
            <p></p>
            В мероприятии приняли участие более 120 человек из образовательных учреждений Калининградской области:
            руководители образовательных учреждений, советники по воспитанию, социальные педагоги, педагоги-психологи,
            педагоги, специалисты служб медиации и примирения образовательных учреждений.
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News030624;