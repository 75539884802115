import React from "react";
import Falldown, {Falldown2, HrefLi, FileLi} from "../common/FalldownList/Falldown";
import img21 from "../../assets/img/1.png"
import s from "./Mediacia.module.css"

import {useEffect, useState} from "react";
import axios from "axios";


const NewMonitorings = () => {
    const [respData, setData] = useState()

    useEffect(() => {
        axios.get("https://fcprc.ru/api/monitorings").then(
            res => {
                setData(res.data)

            })

    }, [])

    return <div className={s.newHeader}>
        <h2>
            Мониторинги
        </h2>

        {/*Мониторинг исполнения Перечня дополнительных мер, направленных на координацию деятельности органов и учреждений*/}
        {/*системы профилактики безнадзорности и правонарушений несовершеннолетних по предупреждению преступных деяний*/}
        {/*против половой неприкосновенности несовершеннолетних на период 2023-2025 годов*/}

        {(respData ? respData[4] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[4].img} header={respData[4].theme}>
                {respData[4].files ? respData[4].files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                {respData[4].refs ? respData[4].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[4].children ? respData[4].children.map(child =>
                    <Falldown2 href={child.ref} header={child.theme}>

                        {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                        {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    </Falldown2>) : <></>}
            </Falldown> : <div></div>}


        {/*Мониторинг организации работы по профилактике вовлечения обучающихся в незаконное потребление наркотических*/}
        {/*средств и психотропных веществ, в том числе организации проведения социально-психологического тестирования*/}
        {/*обучающихся образовательных организаций*/}

        {(respData ? respData[0] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[0].img} header={respData[0].theme}>
                {console.log(respData)}
                {respData[0].files ? respData[0].files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                {respData[0].refs ? respData[0].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[0].children[4] ?
                    <Falldown2 href={respData[1].children[4].ref} header={respData[0].children[4].theme}>
                        {respData[0].children[4].files ? respData[0].children[4].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[0].children[4].refs ? respData[0].children[4].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}
                {respData[1].children[3] ?
                    <Falldown2 href={respData[0].children[3].ref} header={respData[0].children[3].theme}>
                        {respData[0].children[3].files ? respData[0].children[3].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[0].children[3].refs ? respData[0].children[3].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}
                {respData[0].children[2] ?
                    <Falldown2 href={respData[0].children[2].ref} header={respData[0].children[2].theme}>
                        {respData[0].children[2].files ? respData[0].children[2].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[0].children[2].refs ? respData[0].children[2].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}
                {respData[0].children[1] ?
                    <Falldown2 href={respData[0].children[1].ref} header={respData[0].children[1].theme}>
                        {respData[0].children[1].files ? respData[0].children[1].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[1].children[1].refs ? respData[0].children[1].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}
                {respData[0].children[0] ?
                    <Falldown2 href={respData[0].children[0].ref} header={respData[0].children[0].theme}>
                        {respData[0].children[0].files ? respData[0].children[0].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[0].children[0].refs ? respData[0].children[0].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}
            </Falldown> : <div></div>}

        {/*Мониторинг реализации Концепции развития системы профилактики безнадзорности и правонарушений несовершеннолетних*/}
        {/*на период до 2025 года*/}

        {(respData ? respData[1] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[1].img} header={respData[1].theme}>
                {respData[1].files ? respData[1].files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                {respData[1].refs ? respData[1].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}

                {/*2023 год*/}

                {respData[1].children[4] ?
                    <Falldown2 href={respData[1].children[4].ref} header={respData[1].children[4].theme}>
                        {respData[1].children[4].files ?
                            <>
                                <FileLi
                                    href={respData[1].children[4].files[11].file}
                                    text={respData[1].children[4].files[11].name}/>
                                <FileLi
                                    href={respData[1].children[4].files[0].file}
                                    text={respData[1].children[4].files[0].name}/>

                                <FileLi
                                    href={respData[1].children[4].files[2].file}
                                    text={respData[1].children[4].files[2].name}/>
                                <FileLi
                                    href={respData[1].children[4].files[4].file}
                                    text={respData[1].children[4].files[4].name}/>
                                <FileLi
                                    href={respData[1].children[4].files[1].file}
                                    text={respData[1].children[4].files[1].name}/>
                                <FileLi
                                    href={respData[1].children[4].files[5].file}
                                    text={respData[1].children[4].files[5].name}/>
                                <FileLi
                                    href={respData[1].children[4].files[6].file}
                                    text={respData[1].children[4].files[6].name}/>
                                <FileLi
                                    href={respData[1].children[4].files[7].file}
                                    text={respData[1].children[4].files[7].name}/>
                                <FileLi
                                    href={respData[1].children[4].files[3].file}
                                    text={respData[1].children[4].files[3].name}/>
                                <FileLi
                                    href={respData[1].children[4].files[8].file}
                                    text={respData[1].children[4].files[8].name}/>
                                <FileLi
                                    href={respData[1].children[4].files[9].file}
                                    text={respData[1].children[4].files[9].name}/>
                                <FileLi
                                    href={respData[1].children[4].files[10].file}
                                    text={respData[1].children[4].files[10].name}/>
                            </>


                            : <></>}
                        {respData[1].children[4].refs ? respData[1].children[4].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}

                {/*2021 год*/}
                {respData[1].children[0] ?
                    <Falldown2 href={respData[1].children[0].ref} header={respData[1].children[0].theme}>
                        {respData[1].children[0].files ? respData[1].children[0].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[1].children[0].refs ? respData[1].children[0].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}

                {/*2020 год*/}
                {respData[1].children[1] ?
                    <Falldown2 href={respData[1].children[1].ref} header={respData[1].children[1].theme}>
                        {respData[1].children[1].files ? respData[1].children[1].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[1].children[1].refs ? respData[1].children[1].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}

                {/*2018/19 учебный год*/}
                {respData[1].children[2] ?
                    <Falldown2 href={respData[1].children[2].ref} header={respData[1].children[2].theme}>
                        {respData[1].children[2].files ? respData[1].children[2].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[1].children[2].refs ? respData[1].children[2].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}

                {/*2017/18 учебный год*/}
                {respData[1].children[3] ?
                    <Falldown2 href={respData[1].children[3].ref} header={respData[1].children[3].theme}>
                        {respData[1].children[3].files ? respData[1].children[3].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[1].children[3].refs ? respData[1].children[3].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}
            </Falldown> : <div></div>}




        {/*Мониторинг деятельности служб медиации (примирения) в субъектах Российской Федерации*/}

        {(respData ? respData[2] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[2].img} header={respData[2].theme}>
                {respData[2].files ? respData[2].files.map(file => <FileLi href={file.file}
                                                                           text={file.name}/>) : <></>}
                {respData[2].refs ? respData[2].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[2].children ? respData[2].children.map(child =>
                    <Falldown2 href={child.ref} header={child.theme}>

                        {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                        {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    </Falldown2>) : <></>}
            </Falldown> : <div></div>}

        {/*Мониторинг служб медиации*/}

        {(respData ? respData[3] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[3].img} header={respData[3].theme}>
                {respData[3].files ? respData[3].files.map(file => <FileLi href={file.file}
                                                                           text={file.name}/>) : <></>}
                {respData[3].refs ? respData[3].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[3].children ? respData[3].children.map(child =>
                    <Falldown2 href={child.ref} header={child.theme}>

                        {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                        {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    </Falldown2>) : <></>}
            </Falldown> : <div></div>}

        {/*Архив*/}

        {(respData ? respData[5] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[5].img} header={respData[5].theme}>
                {respData[5].files ? respData[5].files.map(file => <FileLi href={file.file}
                                                                           text={file.name}/>) : <></>}
                {respData[5].refs ? respData[5].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[5].children ? respData[5].children.map(child =>
                    <Falldown2 href={child.ref} header={child.theme}>

                        {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                        {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    </Falldown2>) : <></>}
            </Falldown> : <div></div>}
        <p></p>
    </div>
}


export default NewMonitorings