import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News270422.jpg"

const News270422 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Российское общество «Знание» открывает сбор заявок на участие в новом просветительском проекте –
            конкурсе-фестивале театральных произведений «Знание. Театр» для молодежной аудитории.
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Сообщаем, что Российское общество «Знание» открывает сбор заявок на
                участие в новом просветительском проекте – конкурсе-фестивале театральных
                произведений «Знание. Театр» для молодежной аудитории. Идея лучшей пьесы
                будет реализована профессиональной труппой на сцене московского театра, а
                также рекомендована к постановке в школьных и студенческих театральных
                студиях по всей стране. Участниками конкурса может стать любой желающий:
                от профессиональных драматургов до авторов-любителей.
                Для участия в конкурсе Общества «Знание» необходимо прислать
                театральную пьесу, ее краткий синопсис или сценарий через форму заявки на
                сайте theatre.znanierussia.ru. К рассмотрению принимаются оригинальные,
                актуальные для молодежной аудитории и ранее не поставленные на сцене пьесы
                на русском языке по тематическим трекам: история, социология и психология,
                наука, экология, культура, искусство и другие.</p>
            <p>До 15 июня 2022 г. присланные работы будет оценивать экспертный совет,
                в который войдут известные театральные режиссёры, писатели, художественные
                руководители, драматурги, театральные и общественные деятели, среди них:
                художественный руководитель театра «Практика», профессор, заведующая
                кафедрой сценической речи и вокала института имени
                Вл.И. Немировича-Данченко Марина Брусникина; драматург, лауреат премии
                «Золотая маска» (2018) и премии Андрея Белого (2019) Дмитрий Данилов;
                кандидат искусствоведения, театровед, доцент Школы-студии МХАТ
                им. Вл.И. Немировича-Данченко Павел Руднев и другие. Главные критерии,
                по которым жюри будет оценивать работы: актуальность заявленной темы –
                ориентированность на молодежную аудиторию и просветительская
                направленность.</p>
            <p>По итогам конкурса эксперты выберут победителя, чья пьеса будет
                поставлена на сцене московского театра с участием известных российских
                актеров.</p>
            <p>Режиссером постановки лучшего спектакля проекта «Знание. Театр»
                выступит лауреат премии «Золотая маска», режиссер театра «Практика»,
                преподаватель школы-студии МХАТ, режиссер Юрий Квятковский.
                В сентябре состоится премьера спектакля-победителя, которая даст старт
                театральному фестивалю Российского общества «Знание». В его программу
                войдут читки лучших пьес проекта, а также тематические лекции, мастер-классы
                и экспертные круглые столы от ведущих театральных деятелей России.
                Фестиваль пройдет на театральных площадках Москвы, Санкт-Петербурга,
                Нижнего Новгорода, Екатеринбурга, Новосибирска и Владивостока.
                Контактное лицо для предоставления дополнительной информации
                и обсуждения организационных вопросов: руководитель проекта «Знание.
                Театр» Мария Немчина, + 7 926 853 57 82, m.nemchina@znanierussia.ru.</p>
        </div>
        <ToNews/>
    </div>
}


export default News270422;