import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News121124.png"

const News121124 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            6 ноября 2024 г. состоялся вебинар«Вовлечение детей и молодежи
            в научную, исследовательскую деятельность как перспективное направление
            государственной политики в сфере детства», организованный Минпросвещения
            России совместно с ФГБУ «Центр защиты прав и интересов детей».
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Модератором вебинара выступил директор Центра Гусев Борис Борисович,
            а с приветственным словом к участникам обратилась директор Департамента
            государственной политики в сфере защиты прав детей Минпросвещения России
            Фальковская Лариса Павловна.
            <p></p>
            В ходе вебинара были представлены актуальные методы привлечения детей
            и молодежи в сферу исследований и разработок, распространения информации
            о достижениях и перспективах российской науки, а такжелучшие практики
            по развитию современной инфраструктуры дополнительного образования детей
            и молодежи, созданию условий для ранней профориентации детей и повышению
            их интереса к науке и технологиям. Также была отмечена немаловажная роль
            модернизации подготовки педагогических кадров для обеспечения дальнейшего
            научно-технологического развития страны.
            <p></p>
            Спикерами вебинара стали представители Минобрнауки России, федеральных
            образовательных организаций дополнительного образования детей, организаций
            высшего образования, автономной некоммерческой организации.
            Запись мероприятия и презентации спикеров доступны на портале
            Десятилетия детства в разделе «Вебинары»: <a style={{fontWeight: "bold"}}
                                                         href="https://10let.edu.gov.ru/vebinary ">https://10let.edu.gov.ru/vebinary</a>.
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News121124;