import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News251024_1.jpg"
import img2 from "../../assets/News/News251024_2.png"
import img3 from "../../assets/News/News251024_3.png"
import img4 from "../../assets/News/News251024_4.png"

const News251024 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            ХI Всероссийское совещание по вопросам организации
            и обеспечения деятельности комиссий по делам несовершеннолетних
            и защите их прав, 2024 год
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            В Москве 22 октября 2024 года состоялось ХI Всероссийское совещание по вопросам организации и обеспечения
            деятельности комиссий по делам несовершеннолетних и защите их прав.
            <p></p>
            Темой мероприятия, которое проходило на площадке Центра культур Национального исследовательского
            университета «Высшая школа экономики», стало <b>«Повышение эффективности системы профилактики безнадзорности
            и
            правонарушений несовершеннолетних и создание условий для безопасного детства»</b>. Совещание ежегодно
            проводится
            Минпросвещения России совместно с Центром защиты прав и интересов детей.
            <p></p>
            <div style={{width: "max-content", margin: "0  auto", maxWidth: "100%"}}>
                <img src={img2}/>
            </div>
            Заместитель Председателя Правительства России <b>Дмитрий Чернышенко</b> и Министр просвещения РФ <b>Сергей
            Кравцов</b> направили участникам приветственные слова. «Сегодня нам как никогда важно сохранять традиционные
            духовно-нравственные и семейные ценности – такую задачу ставит Президент Владимир Путин. Один из действенных
            инструментов для ее решения – совершенствование системы профилактики по противодействию новым вызовам и
            угрозам, в том числе в цифровой среде. Подрастающее поколение регулярно сталкивается с фейками и
            представляющей опасность информацией в сети Интернет. В России около 3 тысяч комиссий и более 5 тысяч
            специалистов, используя современные технологии и правовые механизмы, борются с этими явлениями и помогают
            предотвращать совершение правонарушений и преступлений в отношении детей», – отметил вице-премьер,
            председатель Правительственной комиссии по делам несовершеннолетних и защите их прав. Одним из главных
            залогов результативности такой профилактической работы Дмитрий Чернышенко назвал эффективную деятельность и
            координирующую роль региональных комиссий. Он также пожелал участникам Всероссийского совещания
            конструктивного открытого диалога и новых плодотворных идей.
            <p></p>
            Министр просвещения РФ Сергей Кравцов напомнил о необходимости обеспечения безопасности детства в стране. «В
            России насчитывается порядка 30 миллионов детей, и нам необходимо делать все для того, чтобы защищать их
            права и интересы, создавать все условия для развития и предупреждать правонарушения. Уважаемые коллеги, в
            сегодняшнем совещании участвуют представители министерств и ведомств, научного сообщества, общественных
            организаций и комиссий по делам несовершеннолетних и защите их прав. В этом году к работе впервые
            подключились представители региональных антитеррористических организаций. Важность вашей совместной работы
            трудно переоценить», – подчеркнул Сергей Кравцов.
            <p></p>
            Участники совещания, члены Правительственной комиссии по делам несовершеннолетних, заместители
            председателей, ответственные секретари комиссий по делам несовершеннолетних и защите их прав (КДНиЗП)
            субъектов РФ, представители антитеррористических комиссий регионов, федеральных органов исполнительной
            власти, Государственной Думы, общественных и иных организаций, обсудили вопросы повышения эффективности
            деятельности КДНиЗП с учетом современных тенденций, а также приоритетных направлений государственной
            политики в сфере защиты детства. Комиссии координируют межведомственное взаимодействие органов и учреждений
            системы профилактики безнадзорности и правонарушений несовершеннолетних.
            <p></p>
            <div style={{width: "max-content", margin: "0  auto", maxWidth: "100%"}}>
                <img src={img3}/>
            </div>
            В рамках Всероссийского совещания состоялась фокус-сессия на тему <b>«Комплексное решение вопросов
            безопасности
            в фокусе взаимодействия комиссий по делам несовершеннолетних и защите их прав и антитеррористических
            комиссий»</b> с участием представителей комиссии по делам несовершеннолетних и защите их прав субъектов
            Российской Федерации, представителей антитеррористических комиссий субъектов Российской Федерации.
            <p></p>
            <div style={{width: "max-content", margin: "0  auto", maxWidth: "100%"}}>
                <img src={img4}/>
            </div>
            На совещании выразили благодарность и были награждены специалисты за успешное решение актуальных задач в
            сфере защиты детей от информации, причиняющей вред их здоровью и развитию, за личный вклад в развитие
            системы профилактики безнадзорности и правонарушений несовершеннолетних и активное участие в обеспечении
            защиты прав детей, за активное участие в реализации государственной политики в области профилактики
            безнадзорности и правонарушений несовершеннолетних, защиты прав детей, за многолетний добросовестный труд и
            активную деятельность в сфере профилактики безнадзорности и правонарушений несовершеннолетних, защите их
            прав.
            <p></p>
            Озвученные участниками совещания лучшие предложения по профилактике безнадзорности и правонарушений
            несовершеннолетних и созданию условий для безопасного детства вошли в итоговую резолюцию. Документ станет
            практическим ориентиром для специалистов сферы защиты прав несовершеннолетних, а также федеральных и
            региональных органов исполнительной власти, общественных организаций и экспертного сообщества
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News251024;