import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img1 from "../../assets/News/FeaturesConditions.jpg"
import img2 from "../../assets/News/FeaturesConditions2.jpg"
import img3 from "../../assets/News/FeaturesConditions3.jpg"
import img4 from "../../assets/News/FeaturesConditions4.jpg"
import YouTube from "react-youtube";

const FeaturesConditions = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: «Особенности определения специальных условий при прохождении государственной итоговой
            аттестации обучающимися с ограниченными возможностями здоровья и инвалидностью»
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <p>
                14 мая 2021 г. состоялся вебинар для руководителей и специалистов ПМПК, руководящих работников
                образовательных организаций.
            </p>
            <p>
                Участие в вебинаре приняли 1434 специалиста из 83 регионов Российской Федерации.
            </p>
            <p>
                Дацюк Таис Александровна, начальник отдела образования детей с особыми образовательными потребностями
                Департамента государственной политики в сфере защиты прав детей Минпросвещения России рассказала о
                нормативных правовых актах, регламентирующие организацию специальных условий прохождения государственной
                итоговой аттестации.
            </p>
            <div>
                <img src={img2}/>
            </div>
            <p>
                Дониченко Ольга Георгиевна, начальник отдела центральной психолого-медико-педагогической комиссии
                Государственного автономного образовательного учреждения дополнительного профессионального образования
                города Москвы «Московский центр качества образования», руководитель центральной
                психолого-медико-педагогической комиссии г. Москвы представила опыт ЦПМПК г. Москвы.
            </p>
            <div>
                <img src={img3}/>
            </div>
            <p>
                Валгепеа Артем Алексеевич, аналитик I категории ФГБУ «Центр защиты прав и интересов детей» рассказал об
                организации обследования обучающихся с ОВЗ и инвалидностью и принципах формирования заключения ПМПК,
                содержащего рекомендации о создании условий при проведении ГИА
            </p>
            <div>
                <img src={img4}/>
            </div>
            <p>
                Завершился вебинар ответами на вопросы. От слушателей поступило более 96 вопросов, касающихся
                формирования заключений о создании специальных условий при проведении ГИА и обучении на различных
                уровнях образования, также были разобраны конкретные кейсы.
            </p>
            <YouTube style={{textAlign: "center", height: "100%"}} videoId="Ky90D46P2mY"/>
            <p></p>
            <br/>
        </div>
    </div>
}


export default FeaturesConditions