import React from "react";
import ico from "../../../assets/img/link.png"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, PinkButton, WhiteButton} from "../PageInfo/PageInfo"
import {hrefsArr} from "./FilesNPD";
import {FileLeftLineKDN} from "./FilesKDNNews";

const BehHelphul = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Полезная информация
                </h2>
                <FileLeftLineKDN text={"Телефон доверия для детей, подростков и их родителей"}
                                 refers="https://telefon-doveria.ru/" ico={ico}/>
                <FileLeftLineKDN text={"Министерство просвещения Российской Федерации"} refers={"https://edu.gov.ru/"}
                                 ico={ico}/>
                <FileLeftLineKDN text={"Министерство здравоохранения Российской Федерации"}
                                 refers={"https://minzdrav.gov.ru/"} ico={ico}/>
                <FileLeftLineKDN text={"Министерство труда и социальной защиты Российской Федерации"}
                                 refers={"https://mintrud.gov.ru/"} ico={ico}/>
                <FileLeftLineKDN text={"Уполномоченный при Президенте Российской Федерации по правам ребенка"}
                                 refers={"http://deti.gov.ru/"} ico={ico}/>
                <FileLeftLineKDN
                    text={"Федеральная служба по надзору в сфере защиты прав потребителей и благополучия человека"}
                    refers={"https://rospotrebnadzor.ru/"} ico={ico}/>
                <FileLeftLineKDN text={"Министерство внутренних дел Российской Федерации"} refers={"https://мвд.рф/"}
                                 ico={ico}/>
                <FileLeftLineKDN text={"Фонд поддержки детей, находящихся в трудной жизненной ситуации"} refers={""}
                                 ico={ico}/>
                <FileLeftLineKDN text={"Портал «Я — родитель»"} refers={"https://fond-detyam.ru/"} ico={ico}/>
                <FileLeftLineKDN text={"Электронный учебно-методический журнал «ПРОФИЛАКТИКА ЗАВИСИМОСТЕЙ»"}
                                 refers={"http://профилактика-зависимостей.рф/"} ico={ico}/>
                <FileLeftLineKDN text={"Федеральный научно-методический Центр по профилактике и борьбе со СПИДом"}
                                 refers={"http://www.hivrussia.ru/"} ico={ico}/>
                <FileLeftLineKDN text={"Объединенная Группа ООН по ВИЧ/СПИДу"}
                                 refers={"http://www.unrussia.ru/ru/agencies/obedinennaya-programma-organizatsii-obedinennykh-natsii-po-vichspidu-yuneids"}
                                 ico={ico}/>

            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArr}/>
                <PinkButton text="Материалы по профилактике ВИЧ/СПИДa" stylesheet={"pinkbutton"}
                            refs="/spec-materials-category/metodicheskie-materialy-po-profilaktike-vich-spida/"/>
                <PinkButton text="Инструкция по подготовке материалов по профилактике"
                            refs="https://fcprc.ru/media/media/behavior/07-6523-s-Pr.pdf"/>
                <WhiteButton text="Перейти к опросу родителей по информированности о профилактике ПАВ"
                             refs="http://опрос-родителей-о-пав.рф/"/>
                <WhiteButton text="Перейти к опросу молодёжи о ВИЧ" refs="http://опрос-молодежи-о-вич.рф/"/>
                <WhiteButton text="Перейти к опросу педагогов о профилактике ВИЧ-инфекции среди обучающихся"
                             refs="http://опрос-педагогов-о-вич.рф/"/>
            </div>
        </div>
    </>
}

export default BehHelphul