import React from "react";
import ico10 from "../../../assets/AD1/10.PNG"
import ico11 from "../../../assets/AD1/11.PNG"
import ico12 from "../../../assets/AD1/12.PNG"
import ico13 from "../../../assets/AD1/13.PNG"
import ico14 from "../../../assets/AD1/14.PNG"
import ico15 from "../../../assets/AD1/15.PNG"
import ico16 from "../../../assets/AD1/16.PNG"
import ico17 from "../../../assets/AD1/17.PNG"
import ico18 from "../../../assets/AD1/18.PNG"
import FilesValueMe from "../../../assets/AD1/FilesValueMet.jpg"
import s from "./PageFiles.module.css"
import {FileLeftLineValue} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {Hrefs} from "../PageInfo/PageInfoPriceLife"

const hrefsArr = [
    {text: "Нормативно-правовые материалы", to: "/spec-value-of-life/normativno-pravovye-materialy", input: []},
    {
        text: "Информационно-методические материалы для специалистов",
        to: "/spec-value-of-life/metodicheskie-materialy-dlya-spetsialistov"
    },
    {
        text: "Информационные материалы для родителей и детей",
        to: "/spec-value-of-life/informatsionnye-materialy-dlya-roditelej"
    },
    {text: "Видеоматериалы", to: "/spec-value-of-life/video-price"},
    {text: "Полезные ссылки", to: "/spec-value-of-life/poleznye-ssylki"},
]
const Value_Met6 = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <div className={s.filesLeftPage}>
        <div className={s.newHeader}>
            <h2>
                Формирование культуры здорового и безопасного образа жизни
            </h2>

            <div className={s.filesLeftPage}>
                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr"}}>
                    <FileLeftLineValue text={respData[178]?.description} refers={respData[178]?.file} ico={ico10}/>
                    <FileLeftLineValue text={respData[179]?.description} refers={respData[179]?.file} ico={ico11}/>
                    <FileLeftLineValue text={respData[180]?.description} refers={respData[180]?.file} ico={ico12}/>
                    <FileLeftLineValue text={respData[181]?.description} refers={respData[181]?.file} ico={ico13}/>
                    <FileLeftLineValue text={respData[182]?.description} refers={respData[182]?.file} ico={ico14}/>
                    <FileLeftLineValue text={respData[530]?.description} refers={respData[530]?.file} ico={ico15}/>
                    <FileLeftLineValue text={respData[531]?.description} refers={respData[531]?.file} ico={ico16}/>
                    <FileLeftLineValue text={respData[534]?.description} refers={respData[534]?.file} ico={ico18}/>
                </div>
            </div>
        </div>
        <div>
            <div style={{marginTop: "72px"}}>
                <Hrefs hrefsArr={hrefsArr}/>
            </div>
        </div>
    </div>
}
export default Value_Met6