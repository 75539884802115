import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/bestIncSchool.png"

const News260724Reg = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            График вебинаров для специалистов организаций для детей-сирот и детей, оставшихся без попечения родителей
        </h2>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <a href="https://my.mts-link.ru/j/61788979/572195397">«Составление индивидуального плана развития и жизнеустройства ребенка»</a><br/>
            30 июля 2024 г.<br/>
            10.00 – 11.30
            <p></p>
            <a href="https://my.mts-link.ru/j/61788979/1168932321">«Инструменты медиатора для конструктивного разрешения конфликтов»</a><br/>
            2 августа 2024 г.<br/>
            10.00 – 12.00
            <p></p>
            <a href="https://my.mts-link.ru/j/61788979/968820388">«Профилактика употребления ПАВ среди обучающихся: технологии, практики, ресурсы»</a><br/>
            5 августа 2024 г.<br/>
            10.00 – 12.00
            <p></p>
            <a href="https://my.mts-link.ru/j/39914579/606540495">«Организация профессионального обучения детей-сирот и детей, оставшихся без попечения родителей»</a><br/>
            9 августа 2024 г.<br/>
            10.00 – 12.00
            <p></p>
            <a href="https://my.mts-link.ru/j/61788979/1012698576">«Эмоциональное выгорание, профессиональная деформация, их профилактика и оказание помощи»</a><br/>
            19 августа 2024 г.<br/>
            10.00 – 12.00
            <p></p>

        </div>
    </div>
}


export default News260724Reg;