import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/TeenageZoneFactory1.jpg"
import img2 from "../../assets/News/TeenageZoneFactory2.jpg"
import img3 from "../../assets/News/TeenageZoneFactory3.jpg"

const TeenageZoneFactory = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            В Белгородской области открылось пространство для подростков «Завод»
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p><strong>13 декабря в рамках стратегической программы «Подростки России» в городе Валуйки Белгородской области
                открылся подростковый центр «Завод».</strong><br/>
                Белгородская область вошла в число регионов, где программа будет работать в пилотном режиме, благодаря
                победе Валуйского центра во Всероссийском конкурсе, который проводил Федеральный центр развития программ
                социализации подростков.</p>
            <p>В церемонии открытия «Завода» приняли участие Уполномоченный при Президенте РФ по правам ребенка Мария
                Львова-Белова и руководитель Федерального центра развития программ социализации подростков Валерий
                Майоров. В режиме видеосвязи к присутствующим на мероприятии обратился Министр просвещения России Сергей
                Кравцов, который подчеркнул, что необходимо открывать еще больше таких площадок для молодёжи по всей
                стране. На «Заводе» создано пять тематических «цехов», зоны для творчества, психологической поддержки.
                Такая концепция направлена на помощь подросткам в освоении профессиональных навыков и выборе жизненного
                пути. Ежедневно центр смогут посещать около 50 подростков.</p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <ToNews/>
    </div>
}


export default TeenageZoneFactory;