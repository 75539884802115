import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News131223.jpg"
import img1 from "../../assets/News/News131223_1.jpg"
import img2 from "../../assets/News/News131223_2.jpg"
import img3 from "../../assets/News/News131223_3.jpg"
import img4 from "../../assets/News/News131223_4.jpg"

const News131223 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сопровождение служб медиации в специальных учебно-воспитательных учреждениях в октябре-ноябре 2023 года
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Вот и завершились мероприятия по сопровождению служб медиации специальных учебно-воспитательных учреждений,
            подведомственных Минпросвещения России в 2023 году, которые проводили сотрудники отдела медиации Центра
            защиты прав и интересов детей.

            <p></p>
            Мероприятия прошли в дистанционном формате в октябре:12 – 13, 19 – 20, 26 – 27 с Ишимбайским СУВУ,
            Рефтинским СУВУ, Себежским СУВУ, Каргатским СУВУ, Куртамышским СУВУ, Майкопским СУВУ, Санкт-Петербургским
            СУВУ, Абаканским СУВУ и Орловским СУВУ. А также 30 ноября и 1 декабря с Ишимбайским СУВУ, Омским СУВУ,
            Неманским СУВУ и Калтанским СУВУ.
            <p></p>

            С сотрудниками служб проводились консультации по текущему состоянию службы, случаям нарушения
            взаимоотношений между обучающимися, сотрудниками учреждения и родителями, обсуждались планы на будущий год.
            <p></p>

            С обучающимися на интерактивных занятиях были проведены деловые игры, направленные на формирование навыков
            ведения переговоров в ситуации неопределенности. Структура занятий была выстроена таким образом, чтобы
            создать условия для общения мальчиков и девочек из разных учреждений.
            <p></p>

            Встречи проходили в дружеской и уважительной атмосфере, обучающиеся и сотрудники положительно оценили
            интерактивный формат занятий.
            <p></p>

            Мероприятия проводили: начальник отдела медиации Центра защиты прав и интересов детей – Быкова Марина
            Викторовна и аналитик II категории отдела медиации – Козлов Анатолий Андреевич.
            <p></p>
        </div>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <div>
            <img src={img4}/>
        </div>
        <ToNews/>
    </div>
}


export default News131223;