import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import img1 from "../../assets/News/Web220420.jpeg";
import YouTube from "react-youtube";

const LifeStyle2020 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: О проведении Всероссийского конкурса социальной рекламы «Стиль жизни здоровье! 2020»
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <p>
                22 апреля 2020 года состоялся вебинар, посвященный проведению Всероссийского конкурса социальной рекламы
                «Стиль жизни – здоровье! 2020». На вебинаре рассматривались вопросы организации конкурса в субъектах
                Российской Федерации и порядок участия в нем.
            </p>
            <p>
                Заева Ольга Вячеславовна, аналитик Центра, ознакомила с основными этапами проведения конкурса,
                определила основные организационные моменты и правила предоставления конкурсных работ. Уточнила
                требования, предъявляемые к работам конкурсантов, дала ответы на часто задаваемые вопросы.
            </p>
            <p>
                В вебинаре приняли участие 1001 слушатель из 80 субъектов Российской Федерации.
            </p>
            <YouTube style={{textAlign: "center"}} videoId="yUWU9nOQGsA"/>
            <p></p>
        </div>
    </div>
}


export default LifeStyle2020