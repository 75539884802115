import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News2706242.jpg"

const News2706242 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Объявлены финалисты Всероссийского конкурса в сфере профессиональной деятельности специалистов органов опеки
            и попечительства в отношении несовершеннолетних граждан «На стороне ребенка»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            По итогам полуфинала Организатором Конкурса ФГБУ «Центр защиты прав и интересов детей» сформирован список
            участников по каждой номинации, допущенных к участию в финале.
            <p></p>
            Всего в финале Конкурса примут участие 20 специалистов и 12 органов опеки и попечительства.
            <p></p>
            Финал Конкурса пройдет в Москве 15 октября 2024 года.
            <p></p>
            Награждение победителей и лауреатов Конкурса состоится 17-18 октября 2024 г. в Москве на ежегодном
            Всероссийском совещании руководителей органов опеки и попечительства.
            <p></p>
            Ознакомиться со списками финалистов можно на <strong><a
            href="https://на-стороне-ребенка.рф/%d0%be%d0%b1%d1%8a%d1%8f%d0%b2%d0%bb%d0%b5%d0%bd%d0%b8%d1%8f/">сайте
            Конкурса</a></strong>.
        </div>
        <ToNews/>
    </div>
}


export default News2706242;