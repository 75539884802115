import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News220424.jpg"
import img1 from "../../assets/News/News220424_1.jpg"
import img2 from "../../assets/News/News220424_2.jpg"

const News220424 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Семинар-совещание по психологической безопасности образовательной среды в г. Липецке 18 апреля 2024 г.
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Семинар-совещание проводилось в школе №30 с руководителями муниципальных органов управления образованием,
            общеобразовательных организаций, профессиональных образовательных организаций в Липецке.
            <p></p>
            На мероприятии обсудили практические способы решения экстренных и кризисных ситуаций, влияние субкультур,
            профилактические работы с несовершеннолетними, совершившими правонарушения, профилактику экстремизма.
            <p></p>
            Участники семинар-совещания посетили Центр поддержки одаренных детей «Стратегия» и провели с его
            воспитанниками беседу-игру по информационной безопасности.
            <p></p>
            Начальник отдела медиации ФГБУ «Центр защиты прав и интересов детей» Анатолий Андреевич Козлов рассказал о
            возможностях медиативно-восстановительных технологий в рамках создания комфортной и уважительной среды в
            образовательной организации.
            <p></p>
            Отдельно было рассмотрено место служб медиации и примирения в общей системе психологической безопасности
            обучающихся.
            <p></p>
            Также в мероприятии приняли участие:
            <ul>
                <li>начальник управления образования и науки Липецкой области Инесса Шуйкова</li>
                <li>заместитель руководителя по научно-методической работе Федерального координационного центра по
                    обеспечению
                    психологической службы в системе образования Российской Федерации ФГБОУ ВО МГППУ, г.Москвы Ольга
                    Юрчук
                </li>
                <li>директор Института информационной и медиабезопасности Владимир Никишин</li>
                <li>специалисты управления федеральной службы безопасности России по Липецкой области, областного
                    Центра
                    «СемьЯ», комиссии по делам несовершеннолетних и защите их прав Липецкой области,
                    педагоги-психологи,
                    медиаторы.
                </li>
            </ul>
            Семинар-совещание стало одним из важных этапов в обеспечении необходимого уровня безопасности и
            психологического комфорта обучающихся в образовательных учреждениях Липецкой области.
        </div>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <ToNews/>
    </div>
}


export default News220424;