import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/FourthOnlineConfDecadeOfChildhood.jpg"

const FourthOnlineConfDecadeOfChildhood = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            IV Всероссийская онлайн конференция «Десятилетие детства: объединяющие механизмы в сфере детства»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>
                18 ноября Федеральный подростковый центр социализации подростков принял участие в IV Всероссийской
                онлайн конференции «Десятилетие детства: объединяющие механизмы в сфере детства», которая проводится при
                поддержке Министерства просвещения Российской Федерации.</p>
            <p>Участниками стали представители профильных министерств и ведомств, региональных и федеральных органов
                исполнительной власти, ведущие российские эксперты в сфере детства, сенаторы, члены Координационного
                совета при Правительстве РФ.</p>
            <p>На конференции обсудили роль семьи, адресную поддержку семей с детьми, роль научных
                исследований в повышении качества жизни таких семей, а также наиболее успешные практики реализации
                мероприятий Десятилетия детства.</p>
            <p>В числе спикеров был представлен Валерий Майоров, руководитель Федерального подросткового центра:</p>
            <p>«Эта конференция профильная для нас, так как мы занимаемся подростками – очень важным детским возрастом.
                Мы рассказали о подростковых центрах, которые при нашей поддержке открываются в регионах, показали видео
                и фото уже открывшихся центров и поделились планами на будущее. Хочется поблагодарить Министерство
                Просвещения за приглашение и возможность рассказать о важном в нашей работе и подвести итоги пленарного
                заседания финальным докладом».</p>
        </div>
        <ToNews/>
    </div>
}


export default FourthOnlineConfDecadeOfChildhood;