import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import IcoMed from "../../../assets/LogoOpecapng.png"
import {ArrowRightOutlined, CheckSquareFilled} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {NavLink} from "react-router-dom";
import {Formik, Field, Form} from "formik";
import ImgPopup from "../Popup/PopupImg"
import axios from "axios";



const Hotline6 = ({url, header, children, style = {}}) => {
    const [active, setActive] = useState(false)
    const [politics, setPolitics] = useState(false)
    const [isSubmited, setSubmittingForm] = useState(true)

    return <>
        <div className={s.page}>
            <div className={s.content}>
                <h2 style={{fontWeight: "normal", fontSize: "30px", color: "#3a4463"}}>
                Регистрация на <b>Платный</b> курс повышения квалификации
                </h2>
                <div>
<div style={{fontSize:"22px"}}>
«Споры родителей, связанные с воспитанием детей. Особенности рассмотрения споров, связанных с воспитанием детей, с участием иностранного элемента», 16 академических часов.
</div>
<p></p>
<img src={IcoMed} style={{ boxShadow:" 0 4px 11px rgb(0 0 0 / 30%)", borderRadius:"10px", height:"360px", width:"360px"}}/>
<p></p>
<div>
Дополнительная профессиональная программа предназначена для повышения квалификации работников органов опеки и попечительства, осуществляющих содействие обеспечению и защите прав и законных интересов детей, их социальных и иных государственных гарантий.
</div>
<div>
Программа подготовлена в соответствии с примерной дополнительной профессиональной программой повышения квалификации для работников органов опеки и попечительства, утвержденной приказом Минобрнауки России от 24.02.2015 № 121 (зарегистрирован Минюстом России 21.05.2015, регистрационный № 37361);с профессиональным стандартом «Специалист органа опеки и попечительства в отношении несовершеннолетних», утвержденным приказом 
Минтруда России от 18.11.2013 № 680н (зарегистрирован Минюстом России 26.12.2013, регистрационный № 30850).</div>
<div>
Форма обучения: очно-заочная, с применением электронного обучения и дистанционных образовательных технологий.
</div>
<div>
    <b>
    ПЛАТНОЕ ОБУЧЕНИЕ
    <div></div>
Стоимость: 7 000 руб. 
    </b>
</div>

                <p></p>
                <div>
                    <div className={s.hotlineHref}>
                        <input type="checkbox" onClick={() => {
                            politics? setPolitics(false): setPolitics(true)
                        }}/>
                        Я даю согласие согласие на обработку <a href="https://fcprc.ru/media/media/behavior/Приказ__39_от_29.05.2023.pdf">персональных данных</a>
                    </div>
                    <br/>
                    {politics ? <div onClick={() => {
                        setActive(true)
                    }} style={{
                        color: "white",
                        padding: "15px",
                        backgroundColor: "pink",
                        width: "230px",
                        marginBottom: "70px",
                        cursor: "pointer",
                        borderRadius: "10px"
                    }}>
                        Перейти к заполнению
                    </div> : <div style={{
                        color: "white",
                        padding: "15px",
                        backgroundColor: "#c97171",
                        width: "230px",
                        marginBottom: "70px",

                        borderRadius: "10px"
                    }}>
                        Перейти к заполнению
                    </div>}
                </div>
<p>
    <h3>

    </h3>
</p>
</div>
<ImgPopup active={active & isSubmited} setActive={setActive}>
                    <div className={s.form}>
                        
                        <Formik
                            initialValues={{email: '',}}

                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                   
                                      console.log(values)
                                      debugger
                                     axios.post("https://fcprc.ru/api/opecaedu", {'регионы': values.subject, 'образование': values.dir, 
                                       'место_работы': values.textT, 'должность': values.textQ, 'ФИО': values.name, 'почта': values.email, 'телефон': values.phone}, {
                                        xsrfCookieName: 'csrftoken',
                                         xsrfHeaderName: 'X-CSRFTOKEN',
                                     })
                                    setSubmittingForm(false)
                                    setSubmitting(false);
                                    setActive(false)
                                }, 400);
                            }}
                        >
                            {({errors, touched, isSubmitting}) => (
                                <Form>
                                    
                                    <div>
                                        <div>
                                            <div>
                                                <label fore="name" style={{fontWeight: "normal", fontSize: "22px", color: "#3a4463"}}>ФИО
                                                </label>
                                            </div>
                                            <Field type="name" name="name" 
                                                   placeholder="Ваше ФИО"/>
                                        </div>
                                        <div>
                                            <div>
                                                <label fore="phone" style={{fontWeight: "normal", fontSize: "22px", color: "#3a4463"}}>Номер телефона
                                                </label>
                                            </div>
                                            <Field type="phone" name="phone"
                                                   placeholder="Телефон для связи"/>
                                        </div>
                                        <div>
                                            <div>
                                                <label fore="email" style={{fontWeight: "normal", fontSize: "22px", color: "#3a4463"}}>EMAIL
                                                </label>
                                            </div>
                                            <Field type="email" name="email"
                                                   placeholder="E-mail для обратной связи"/>
                                        </div>
                                        
                                    </div>
                                    <div>
                                
                                            <div>
                                                <label fore="dir" style={{fontWeight: "normal", fontSize: "22px", color: "#3a4463"}}>Образование</label>
                                            </div>
                                            
                                            <Field component="select" name="dir" placeholder="Выберите">
                                                
                                               
                                                <option> Выберите </option>
                                                <option> Среднее профессиональное образование </option>
                                                <option> Высшее образование </option>
                                                    
                                                    
                                                
                                            </Field>
                                        </div>
                                    <div>
                                        <div>
                                            <label fore="textT" style={{fontWeight: "normal", fontSize: "22px", color: "#3a4463"}}>Место работы</label>
                                        </div>
                                        <Field component="textarea" type="text" name="textT" placeholder="..."/>
                                    </div>
                                    <div>
                                        <div>
                                            <label fore="textQ" style={{fontWeight: "normal", fontSize: "22px", color: "#3a4463"}}>Должность</label>
                                        </div>
                                        <Field component="textarea" type="text" name="textQ" placeholder="..."/>
                                    </div>
                                    <div>
                                        <div>
                                            <label fore="subject" style={{fontWeight: "normal", fontSize: "22px", color: "#3a4463"}}>Регион</label>
                                        </div>
                                        <Field type="text" name="subject" />
                                    </div>
                                    
                                    
                                    
                                    
                                    <div className={s.send} >
                                        <button>Отправить</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </ImgPopup>
                

                
            </div>
           
        </div>
    </>


}
/*

*/

export const HrefsBeh1 = ({hrefsArr, stylef = {}, to = "/"}) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>

            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <a href={to}>
                    {text}
                </a>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}

export default Hotline6