import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import logo from "../../../assets/News/Plen2020.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {NavLink} from "react-router-dom";
import file1 from "../../../assets/Files/Rezyapkina-G.V..pptx"
import file2 from "../../../assets/Files/CHirkina-R.V..ppt"
import file3 from "../../../assets/Files/masterseven.pdf"
import YouTube, { YouTubeProps } from "react-youtube";
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";



const MasterSeven = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2 >
                    Мастер-классы VII Всероссийского совещание КДН и ЗП
                </h2>
            </div>

            <div>
                <img src={logo}/>
            </div>

            <p style={{fontSize: "17px", color: "#212529"}} className={s.concept}>
                <p>
                    <strong>Мастер-класс № 1</strong>
                </p>
                <p><strong>Ведущий</strong>: Чиркина Римма Вячеславовна, заведующий кафедры юридической психологии и права факультета
                    «Юридическая психология» федерального государственного бюджетного образовательного учреждения
                    высшего образования «Московский государственный психолого-педагогический университет»</p>
                <p>«Ранняя профилактика проблемного поведения детей»</p>
                <p>
                    <strong>Мастер-класс № 2</strong>
                </p>
                <p>
                    <strong>Ведущий</strong>: Резапкина Галина Владимировна, старший научный сотрудник федерального института развития
                    образования федерального государственного бюджетного образовательного учреждения высшего образования
                    «Российская академия народного хозяйства и государственной службы при Президенте Российской
                    Федерации», сотрудник Центра практической психологии образования Московской области государственного
                    бюджетного образовательного учреждения высшего образования Московской области «Академия социального
                    управления»
                </p>
                <p>
                    «Проблемное поведение детей и подростков: причины, диагностика, профилактика»
                </p>
                <p>
                    <strong>Мастер-класс № 3</strong>
                </p>
                <p>
                    <strong>Ведущий</strong>: Ильгова Екатерина Владимировна, ректор федерального государственного бюджетного
                    образовательного учреждения высшего образования «Саратовская государственная юридическая академия»
                </p>
                <p>
                    «Использование информационных технологий и систем в деятельности комиссий по делам
                    несовершеннолетних и защите их прав: ответ на вызовы времени»
                </p>
                <p>
                    <strong>Мастер-класс № 4</strong>
                </p>
                <p>
                    <strong>Ведущий</strong>: Шилин Артем Юрьевич, старший преподаватель кафедры «Социальная коммуникация и организация
                    работы с молодежью» факультета
                </p>
                <p>
                    «Социальная коммуникация» федерального государственного бюджетного образовательного учреждения
                    высшего образования «Московский государственный психолого-педагогический университет»
                </p>
                <p>
                    «Социальная реклама как способ профилактики негативных явлений в подростковой среде»
                </p>
                <p><a href={file1}>Резяпкина Г.В.</a></p>
                <p><a href={file2}>Чиркина Р.В.</a></p>
                <p><a href={file3}>Шилин А.Ю.</a></p>
                <YouTube id="6N-a-Hofafs"/>
            </p>
        </div>
        <div style={{marginTop: "57px"}}>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право"/>

        </div>


    </div>


}

export const HrefsBeh1 = ({
                              hrefsArr, stylef = {}, to = "/"
                          }) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({
                        text, ul, input = null, to
                    }) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({
                              text, to
                          }) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({
                        text, stylesheet
                    }) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({
                         text, stylesheet
                     }) => {
    return <div className={s.whitebutton}>
        {text}
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default MasterSeven