import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import logo from "../../../assets/News/Plen2020.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {NavLink} from "react-router-dom";
import file1 from "../../../assets/Files/Ploshad1_1.pdf"
import file2 from "../../../assets/Files/Arefeva-L.G..pptx"
import file3 from "../../../assets/Files/Ploshad1_3.pdf"
import file4 from "../../../assets/Files/Ploshad1_4.pdf"
import file5 from "../../../assets/Files/Konovalov-A.YU..pptx"
import file6 from "../../../assets/Files/Popova-I.N..pptx"
import file7 from "../../../assets/Files/Trunov-D.V..pptx"
import YouTube from "react-youtube";
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";

const Ploshad1 = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2>
                    Площадка № 1 VII Всероссийского совещание КДН и ЗП
                </h2>
            </div>


            <p style={{fontSize: "17px", color: "#212529"}} className={s.concept}>
                <p><strong>
                    Дискуссионная площадка № 1
                    «Проектная деятельность в сфере профилактики асоциального поведения несовершеннолетних. Региональный
                    опыт реализации комплексов дополнительных мер по развитию системы профилактики безнадзорности и
                    правонарушений несовершеннолетних»
                </strong></p>
                <p><strong>Модератор:</strong></p>
                <p>
                    Костыряченко Юлия Александровна, заместитель директора Департамента государственной политики в сфере
                    защиты прав детей Минпросвещения
                </p>
                <p><strong>Сомодератор:</strong></p>
                <p>
                    Григорьева Наталья Тарасовна, аналитик федерального государственного бюджетного учреждения «Центр
                    защиты прав и интересов детей»
                </p>
                <p><strong>Участники:</strong></p>
                <p>
                    Костыряченко Юлия Александровна, заместитель директора Департамента государственной политики в сфере
                    защиты прав детей Минпросвещения
                </p>
                <p>
                    «Комплекс мер по развитию системы профилактики безнадзорности и правонарушений несовершеннолетних
                    как новая модель решения приоритетных задач в сфере защиты детства»
                </p>
                <p>
                    Попова Ирина Николаевна, ведущий научный сотрудник Научно-исследовательского центра социализации и
                    персонализации образования детей Федерального института развития образования федерального
                    государственного бюджетного образовательного учреждения высшего образования «Российская академия
                    народного хозяйства и государственной службы при Президенте Российской Федерации»
                </p>
                <p>
                    «Проектирование условий самореализации детей, находящихся в трудной жизненной ситуации, в сфере
                    дополнительного образования»
                </p>
                <p>
                    Ванданова Эльвира Леонидовна, ведущий научный сотрудник научно-исследовательского центр стратегии,
                    проектирования и правового обеспечения Федерального института развития образования федерального
                    государственного бюджетного образовательного учреждения высшего образования «Российская академия
                    народного хозяйства и государственной службы при Президенте Российской Федерации»
                </p>
                <p>
                    «Методология формирования профилактических программ: от теории к практике»
                </p>
                <p>
                    Еремина Ольга Львовна, заместитель губернатора Костромской области — председатель комиссии по делам
                    несовершеннолетних и защите их прав Костромской области
                </p>
                <p>
                    «Проектная деятельность в сфере профилактики асоциального поведения несовершеннолетних в Костромской
                    области»
                </p>
                <p>
                    Хохлова Наталья Александровна, директор департамента по вопросам семьи и демографии министерства
                    труда и социальной защиты Тульской области, заместитель председателя комиссии по делам
                    несовершеннолетних и защите их прав Тульской области
                </p>
                <p>
                    «Реализации регионального Комплекса мер по развитию системы профилактики безнадзорности и
                    правонарушений несовершеннолетних»
                </p>
                <p>
                    Арефьева Людмила Геннадьевна, начальник отдела Правительства Красноярского края по обеспечению
                    деятельности комиссии по делам несовершеннолетних и защите их прав края, заместитель председателя
                    комиссии по делам несовершеннолетних и защите их прав Красноярского края
                </p>
                <p>
                    «Комплекс мер по развитию системы профилактики безнадзорности и правонарушений несовершеннолетних
                    Красноярского края как новая модель решения приоритетных задач в сфере защиты детства»
                </p>
                <p>
                    Колузаева Наталья Георгиевна, начальник отдела по защите прав несовершеннолетних администрации
                    городского округа Ступино, заместитель председателя комиссии по делам несовершеннолетних и защите их
                    прав городского округа Ступино Московской области
                </p>
                <p>
                    «Инновация в работе комиссии по делам несовершеннолетних и защите их прав городского округа Ступино:
                    социальное проектирование — ориентир на проблемные вопросы»
                </p>
                <p>
                    Вайснер Наталья Анатольевна, член попечительского совета Благотворительного Фонда по оказанию помощи
                    лицам, отбывающим наказание в исправительных учреждениях «Забота», руководитель проекта «Ресурсный
                    центр», представитель автономной некоммерческой организации «Агентство стратегических инициатив по
                    продвижению новых проектов»
                </p>
                <p>
                    «Проект «Новые горизонты: комплексная помощь» — обеспечение преемственности воспитательной функции
                    при переходе несовершеннолетнего из следственного изолятора в воспитательную колонию»
                </p>
                <p>
                    Трунов Дмитрий Васильевич, начальник отдела дополнительного образования управления образования и
                    науки Тамбовской области
                </p>
                <p>
                    «Опыт реализации комплекса мер по организации продуктивной социально значимой деятельности
                    несовершеннолетних, находящихся в конфликте с законом, в Тамбовской области»
                </p>
                <p>
                    Коновалов Антон Юрьевич, руководитель направления «Школьные службы примирения» государственного
                    бюджетного учреждения города Москвы «Городской психолого-педагогический центр Департамента
                    образования и науки города Москвы»
                </p>
                <p>
                    «Взаимодействие служб примирения и комиссий по делам несовершеннолетних и защите их прав в рамках
                    восстановительного подхода»
                </p>
                <p>
                    Ильина Елена Геннадьевна, председатель Оренбургского регионального отделения общероссийской
                    общественно-государственной детско-юношеской организации «Российское движение школьников»,
                    председатель Оренбургской областной общественной организации «Федерация детских организаций», член
                    комиссии по делам несовершеннолетних и защите их прав Правительства Оренбургской области
                </p>
                <p>
                    «Детская общественная Правовая Палата — актуальный ресурс правового просвещения несовершеннолетних»
                </p>
                <p>
                    Амосов Вячеслав Александрович, директор государственного бюджетного учреждения дополнительного
                    образования «Центр эстетического воспитания детей Нижегородской области», председатель
                    Нижегородского регионального отделения общероссийской общественно-государственной детско-юношеской
                    организации «Российское движение школьников»
                </p>
                <p>
                    «Проектная деятельность в сфере детского и молодежного движения региона»
                </p>
                <p>
                    Медведева Ольга Григорьевна, председатель регионального отделения общероссийской
                    общественно-государственной детско-юношеской организации «Российское движение школьников» в
                    Республике Коми
                </p>
                <p>
                    «Система вовлечения в деятельность Российского движения школьников воспитанников детских домов Республики Коми»
                </p>
                <p><a href={file1}>Амосов В.А.</a></p>
                <p><a href={file2}>Арефьева Л.Г.</a></p>
                <p><a href={file3}>Ванданова Э.Л.</a></p>
                <p><a href={file4}>Ильина Е.Г.</a></p>
                <p><a href={file5}>Коновалов А.Ю.</a></p>
                <p><a href={file6}>Попов И.Н.</a></p>
                <p><a href={file7}>Трунов Д.В.</a></p>
                <YouTube id="vwhk7luNGM0"/>
            </p>
        </div>
        <div style={{marginTop: "57px"}}>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право"/>

        </div>


    </div>


}

export const HrefsBeh1 = ({
                              hrefsArr, stylef = {}, to = "/"
                          }) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({
                        text, ul, input = null, to
                    }) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({
                              text, to
                          }) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({
                        text, stylesheet
                    }) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({
                         text, stylesheet
                     }) => {
    return <div className={s.whitebutton}>
        {text}
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default Ploshad1