import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News1110242.jpg"

const News111024 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Всероссийское совещание руководителей органов опеки и попечительства 17 – 18 октября 2024 г.
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            17–18 октября 2024 г. в Московской области пройдет Всероссийское совещание руководителей органов опеки и
            попечительства.
            <p></p>
            Участники совещания обсудят важность поддержки родителей, способы восстановления детско-родительских
            отношений, помощь органов опеки и попечительства в разрешении споров о воспитании детей родителями в
            досудебном порядке. Они также поговорят об усыновлении как о приоритетной форме устройства детей-сирот и
            детей, оставшихся без попечения родителей.
            <p></p>
            Минпросвещения России ежегодно проводит Всероссийское совещание руководителей органов опеки и
            попечительства. В нем принимают участие руководители исполнительных органов субъектов Российской Федерации,
            осуществляющих государственное управление в сфере опеки и попечительства, а также представители федеральных
            органов исполнительной власти и общественных организаций, ведущие ученые и эксперты в сфере защиты прав
            детей.
            <p></p>
            В рамках мероприятия пройдет торжественная церемония награждения финалистов второго Всероссийского конкурса
            в сфере профессиональной деятельности специалистов органов опеки и попечительства в отношении
            несовершеннолетних граждан «На стороне ребенка», учредителем которого является Минпросвещения России,
            организатором – ФГБУ «Центр защиты прав и интересов детей» (список победителей и лауреатов будет размещен
            на <a style={{fontWeight: "bold"}} href="https://на-стороне-ребенка.рф/%d0%be%d0%b1%d1%8a%d1%8f%d0%b2%d0%bb%d0%b5%d0%bd%d0%b8%d1%8f/">сайте
            Конкурса</a> 16 октября 2024 г.)
            <p></p>
            Состязание призвано сформировать положительный имидж органов опеки и попечительства и отметить специалистов,
            добившихся позитивных результатов в своей профессиональной деятельности.
            <p></p>
            Ссылка на источник:<br/>
            <a style={{fontWeight: "bold"}} href="https://edu.gov.ru/press/8929/vserossiyskoe-soveschanie-rukovoditeley-organov-opeki-i-popechitelstva-startuet-17-oktyabrya/">https://edu.gov.ru/press/8929/vserossiyskoe-soveschanie-rukovoditeley-organov-opeki-i-popechitelstva-startuet-17-oktyabrya/</a>
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News111024;