import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News211223.png"

const News211223 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            19 декабря 2023 г. состоялся вебинар для ответственных секретарей и специалистов, обеспечивающих деятельность, комиссий по делам несовершеннолетних и защите их прав субъектов Российской Федерации
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            19 декабря 2023 г. ФГБУ «Центр защиты прав и интересов детей» был организован вебинар для ответственных
            секретарей и специалистов, обеспечивающих деятельность, комиссий по делам несовершеннолетних и защите их
            прав субъектов Российской Федерации при поддержке ФГБНУ «Институт изучения детства, семьи и воспитания» и
            ФГБОУ ВО «Саратовская государственная юридическая академия».
            <p></p>
            В вебинаре приняли участие ответственные секретари комиссий по делам несовершеннолетних и защите их прав, а
            также специалисты, обеспечивающие деятельность КДН и ЗП субъектов Российской Федерации.
            <p></p>
            В рамках вебинара были рассмотрены вопросы применения законодательства в сфере профилактики безнадзорности и
            правонарушений несовершеннолетних, в том числе актуальные вопросы, возникающие при принятии региональной
            комиссией по делам несовершеннолетних и защите их прав решения о допуске или недопуске лиц, имевших
            судимость, к педагогической деятельности, к предпринимательской деятельности и (или) трудовой деятельности в
            сферах с участием несовершеннолетних. Кроме того, на вебинаре были рассмотрены вопросы, возникающие у
            специалистов при организации процесса привлечения комиссиями по делам несовершеннолетних и защите их прав к
            административной ответственности граждан за уклонение от исполнения административного наказания.
            <p></p>
            Также участникам вебинара была раскрыта тема организации повышения квалификации специалистов, работающих в
            сфере профилактики безнадзорности и правонарушений несовершеннолетних, в том числе рассказано о широких
            возможностях обучения, его формах и содержании, предоставляемых Институтом изучения детства, семьи и
            воспитания, Саратовской государственной юридической академией и Центром защиты прав и интересов детей.
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News211223;