import React from "react";
import s from "./Default.module.css"
import ToNews from "../News/ToNews";
import img from "../../assets/News/News210824.jpg"

const News210824 = () => {

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Разработаны методические рекомендации по использованию медиативных технологий в социальных сетях
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Минпросвещения России разработало методические рекомендации для сотрудников образовательных организаций,
            специалистов в области воспитания и профилактики девиантного поведения несовершеннолетних по использованию
            медиативных технологий в социальных сетях «МЕДИАЦИЯ - ДЕТИ - СОЦСЕТИ».
            <p></p>
            Методические рекомендации посвящены вопросам профилактики социально-негативных явлений, в том числе
            агрессивного и деструктивного поведения несовершеннолетних в информационно-телекоммуникационной сети
            «Интернет»,
            а также формированию у детей, подростков и молодежи конструктивных и уважительных форм общения в социальных
            сетях.
            <p></p>
            Рекомендации подготовлены на основе разработок отдела медиации Центра защиты прав и интересов детей с учетом
            предложений Минцифры России, Минобрнауки России, МВД России, Росмолодежи, исполнительных органов субъектов
            Российской Федерации (Кемеровская и Нижегородская области, г. Санкт-Петербург), ФГБОУ ВО «Московский
            государственный психолого-педагогический университет», ФГАОУ ВО «Московский государственный юридический
            университет имени О.Е. Кутафина (МГЮА)» и Альянса по защите детей в цифровой среде.
            <p></p>
            <a target="_blank" style={{fontWeight: "bold"}}
               href="https://fcprc.ru/media/media/behavior/Письмо_Минпросвещения_России_о_рекомендациях__ДГ_1333_07_от_14.08.2024.pdf">Письмо
                Минпросвещения России о рекомендациях № ДГ 1333 07 от 14.08.2024</a>
            <p></p>
            <a target="_blank" style={{fontWeight: "bold"}}
               href="https://fcprc.ru/media/media/behavior/Рекомендации_по_использованию_медиативных_технологий_в_социальных_сетях.pdf">Рекомендации
                по использованию медиативных технологий в социальных сетях</a>
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News210824;