import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/StrengtheningTiesCreativeExchange.jpg"
import img1 from "../../assets/News/StrengtheningTiesCreativeExchange_1.jpg"
import img2 from "../../assets/News/StrengtheningTiesCreativeExchange_2.jpg"

const StrengtheningTiesCreativeExchange = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Федеральный центр развития программ социализации подростков представил проект «Творческий обмен» на форуме в
            Хакасии
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>21-23 апреля в Национальном музее Республики Хакасия состоялся X Республиканский семейный форум «Укрепляя
                связи». Федеральный центр развития программ социализации подростков представил проект «Творческий
                обмен», основная идея которого — трансляция опыта регионов по всей стране непосредственно от самих
                подростков.</p>
            <p>Первой участницей проекта «Творческий обмен» стала 16-летняя Мария Калинчук из Мариуполя, участница
                народного ансамбля танца «Радуга», начинающий педагог. На форуме Маша провела танцевальный мастер-класс.
                Ее поддерживал руководитель мариупольского подросткового центра «Территория нового поколения» Данил
                Грановский, а также дружественная команда центра «Борт» из Хакасии.</p>
            <p>
                Менеджер проекта «Подростковые центры» Федерального центра развития программ социализации подростков
                Ирина Халина выступила на дискуссионной панели с подростками и в формате «свободного микрофона» отвечала
                на вопросы слушателей. Одна из главных тем, которая нашла живой отклик у ребят, — насколько важно, чтобы
                рядом с подростком был человек, которому он сможет доверять. В попытках описать, какой именно помощи
                ребята ждут от этого человека, участники не смогли прийти к единому мнению. Ирина Халина поделилась
                своим опытом и отметила, что важных людей рядом может быть несколько, причем это могут быть как
                сверстники, так и взрослые.
            </p>
            <p>
                X Республиканский семейный форум состоялся при поддержке и прямом участии Уполномоченного по правам
                ребенка в Хакасии и Правительства Республики Хакасия. Эксперты обсудили вопросы, связанные с сохранением
                и развитием детско-родительских отношений и поддержкой подростков.
            </p>
        </div>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <ToNews/>
    </div>
}


export default StrengtheningTiesCreativeExchange;