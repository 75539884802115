import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News151223.png"

const News151223 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Подведены итоги Всероссийского конкурса социальной рекламы «Стиль жизни – Здоровье! 2023»
        </h2>
        <div style={{display: "flex", justifyContent: "center"}}>
            <img src={img} />
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Завершился федеральный этап Всероссийского конкурса социальной рекламы в области формирования культуры
            здорового образа жизни среди обучающихся «Стиль жизни – Здоровье! 2023».
            <p></p>
            Задачи конкурса заключаются в привлечении внимания обучающихся к социально значимым проблемам общества;
            пропаганде культуры безопасного и здорового образа жизни; просвещения детей и подростков в вопросах ведения
            здорового и безопасного образа жизни; создания условий для открытого, доверительного общения, возможностей
            самопроявления обучающихся в процессе творческой работы над проектом и т.д.
            <p></p>
            В конкурсе принимали участие обучающиеся образовательных организаций субъектов Российской Федерации двух
            возрастных групп: 8-12 лет и 13-18 лет.<br/>
            Участники представляли на конкурс работы в двух номинациях: «Социальный видеоролик по пропаганде здорового и
            безопасного образа жизни, направленный на профилактику зависимого поведения обучающихся» и «Наглядный
            раздаточный материал по пропаганде здорового и безопасного образа жизни, направленный на профилактику
            зависимого поведения обучающихся».
            <p></p>
            В региональном этапе Всероссийского конкурса приняли участие 9758 обучающихся образовательных организаций из
            63 субъектов Российской Федерации.
            <br/>
            На федеральном этапе конкурса рассмотрено 230 работ, подготовленных 605 участниками. В результате подведения
            итогов федерального этапа конкурса членами Жюри было определено 26 работ, занявших призовые места.
            Среди лауреатов и дипломантов конкурса участники из республик Башкортостан, Дагестан, Калмыкия и Мордовия;
            Алтайского, Камчатского, Краснодарского, Пермского краев; Архангельской, Астраханской, Брянской,
            Калининградской, Липецкой, Омской, Ростовской, Рязанской, Самарской, Сахалинской, Ярославской областей, а
            также Москвы и Санкт-Петербурга.
            <p></p>
            Более подробно с результатами конкурса можно ознакомиться на <strong><a href="https://2023.social.edu-contests.ru/">официальном сайте Всероссийского конкурса</a></strong>
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News151223;