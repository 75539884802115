import React from "react";
import s from "./Default.module.css"
import st from "../common/PageInfo/PageInfo.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img from "../../assets/Mediacia/vseros2.jpg"
import {HrefsBeh1} from "../common/PageInfo/PageInfo";
import Falldown, {FileLi} from "../common/FalldownList/Falldown";
import {NavLink} from "react-router-dom";
import {DownOutlined, ArrowRightOutlined} from "@ant-design/icons"


const SuicPage = ({children, header}) => {
    const [newsData, setNews] = useState([])
    const hrefsArr1 = [
        {text: "Программа", to: "/spec-materials-category/informatsionnye-materialy-dlya-roditelej"},
        {text: "Благодарственные письма", to: "/"},
    ]
    return <div className={s.newsrefs}>
        <div>

            <h2 style={{
                fontWeight: "normal",
                marginTop: "50px",
                color: "#3a4663",
                textAlign: "left",
                maxWidth: "1100px",
                margin: "40px auto",
                fontFamily: "Proxima Nova Lt",
                fontSize: "30px"
            }}>
                Ресурсы профилактики суицида
            </h2>
            <div className={s.pageWRef}>
                <div>
                    <div>
                        <h3 style={{fontSize: "24px", color: "#3a4663"}}>
                            Лучшие практики мероприятий в сфере профилактики суицида среди несовершеннолетних,
                            методические рекомендации и иные материалы
                        </h3>
                    </div>
                    <div>
                        <p>

                            Материалы подготовлены в рамках исполнения пункта 8 раздела I протокола заседания
                            Правительственной комиссии по делам несовершеннолетних и защите их прав от 25 сентября 2019
                            г. № 23.
                            <p>

                                При подготовке сводной информации учтены сведения, представленные Минпросвещения России,
                                Минздравом России, Минкомсвязью России, Минкультуры России, Роскомнадзором,
                                Роспотребнадзором, ФГБУ «Национальный медицинский исследовательский центр психиатрии и
                                наркологии имени В.П. Сербского», ФГБОУ ВО «Московский государственный
                                психолого-педагогический университет», ФГБНУ «Центр защиты прав и интересов детей», АНО
                                «Центр изучения и сетевого мониторинга молодежной среды».</p>
                        </p>

                    </div>
                    <p></p>
                    <div>
                        <Falldown
                            header={"Лучшие практики мероприятий в сфере профилактики суицида среди несовершеннолетних"}
                            style={{width: "600px", fontWeight: "200"}}>
                            <FileLi
                                text={"Лучшие практики мероприятий в сфере профилактики суицида среди несовершеннолетних.pdf (0.31 Мб)"}/>
                        </Falldown>
                        <Falldown
                            header={"Перечень методических рекомендаций и иных материалов в сфере профилактики суицида среди несовершеннолетних"}
                            style={{width: "600px"}}>
                            <FileLi
                                text={"Лучшие практики мероприятий в сфере профилактики суицида среди несовершеннолетних.pdf (0.31 Мб)"}/>
                        </Falldown>
                        <Falldown
                            header={"Информационные ресурсы, на которых систематически размещаются лучшие практики мероприятий и (или) методические рекомендации и иные материалы в сфере профилактики суицида среди несовершеннолетних"}
                            style={{width: "600px"}}>
                            <FileLi
                                text={"Лучшие практики мероприятий в сфере профилактики суицида среди несовершеннолетних.pdf (0.31 Мб)"}/>
                        </Falldown>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <p></p>
            <WhiteButton text={"Ценность жизни - обеспечение вопросов детской безопасности"} to="/value-of-life"/>
            <WhiteButton text="Информационная безопасность" to="/infSec"/>
            <WhiteButton text="Твой кибермаршрут" to="/secWay"/>
        </div>

    </div>
}
const WhiteButton = ({text, stylesheet, to}) => {
    return <div className={st.whitebutton}>
        <NavLink to={to}>

            {text}

        </NavLink>
        <div>
            <ArrowRightOutlined/>

        </div>
    </div>
}


export default SuicPage