import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News13072023.jpg"

const News13072023 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Фонд Тимченко начал прием заявок на конкурс «Туда, где семья», направленный на выявление и поддержку
            действующих эффективных инструментов и технологий работы с уязвимыми семьями с детьми на малых территориях
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Информируем об объявлении нового конкурса Фонда Тимченко «Туда, где семья».</p>

            <p> 6 июля 2023 года Фонд Тимченко начал прием заявок на конкурс «Туда, где семья», направленный на
                выявление и поддержку действующих эффективных инструментов и технологий работы с уязвимыми семьями с
                детьми на малых территориях*.
            </p>
            <p>Решение проблемы социального сиротства, улучшение благополучия детей и семей — в числе основных
                государственных приоритетов в России.
            </p>
            <p>Тем не менее в сфере защиты детства по-прежнему есть ряд проблем, которые сложно решить только мерами
                государственной поддержки: концентрация услуг в крупных городах и их недостаточное количество на малых
                территориях, недоверие со стороны общества к государственным и муниципальным службам защиты, низкая
                правовая культура и информированность сообщества, дефицит знаний.
            </p>
            <p>Необходима активизация местного сообщества!
            </p>
            <p>Именно на развитие этого подхода направлен новый конкурс. В нём будут поддержаны проекты, направленные на
                профилактику сиротства, семейного неблагополучия и жестокого обращения с детьми через работу и
                сопровождение следующих категорий семей с детьми на малых территориях:
            </p>
            <p>· в трудной жизненной ситуации или кризисе;
            </p>
            <p>· с риском или опытом жестокого обращения с ребенком или другими членами семьи;
            </p>
            <p>· с одним или двумя родителями с алкогольной, наркотической или иной зависимостью;
            </p>
            <p>· где хотя бы один из детей или родителей имеет ОВЗ или инвалидность в ситуации временного пребывания
                детей в организациях для детей-сирот и детей, оставшихся без попечения родителей, специализированных
                учреждениях для несовершеннолетних, замещающих семьях;
            </p>
            <p>· опекунов или попечителей с возможным риском вторичного сиротства ребенка;
            </p>
            <p>· с членами семьи — вынужденными переселенцами;
            </p>
            <p>· с членами семьи — участниками специальной военной операции.
            </p>
            <p>
                <strong>Заявки на участие в конкурсе принимаются с 6 июля по 21 августа 2023 года через онлайн-систему.</strong>
            </p>
            <p>
                Организации-победители получат финансовую поддержку до 2 млн руб., экспертную и методическую поддержку,
                возможность обучения и обмена опытом, а также участие в мероприятиях Фонда.
            </p>
            <p>
                <a href="https://contest.timchenkofoundation.org/tuda-gde-semya/?utm_source=site_ft&utm_medium=referral&utm_campaign=konkurs_tuda-gde-semya&utm_content=start-newspage&utm_term=textlink">Подробная
                    информация на сайте конкурса.</a>
            </p>
            <p>
                *К малым территориям относятся территории с населением менее 200 000 чел. К малым территориям не могут
                быть
                отнесены районы или муниципальные образования с населением менее 200 000 чел., но являющиеся частью
                крупной
                городской агломерации (деление крупных городов на районы и муниципалитеты).
            </p>
        </div>
        <ToNews/>
    </div>
}


export default News13072023;