import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/bestIncSchool.png"

const OFFF2022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            «Лучшая инклюзивная школа России – 2022»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>С 25 по 28 января на сайте «Усыновите.ру» проходил Всероссийский онлайн форум приемных семей. В нем
                приняли участие представители Федерального центра развития программ социализации подростков.</p>
            <p>Организаторами Форума выступил АНО Центр развития социальных проектов в рамках реализации гранта
                Президента Российской Федерации «Университет приемной семьи «Усыновите.ру» и при поддержке Министерства
                просвещения Российской Федерации. Форум стал всероссийской площадкой для презентации лучших практик в
                области дополнительного образования замещающих родителей.</p>
            <p>В пленарном заседании приняли участие Уполномоченный при Президенте Российской Федерации по правам
                ребенка Мария Львова-Белова, заместитель директора Департамента государственной политики в сфере защиты
                прав детей Министерства просвещения Российской Федерации Анастасия Аккуратова, председатель Союза семей,
                воспитывающих детей-сирот и детей, оставшихся без попечения родителей, координатор по работе с регионами
                Федерального центра развития программ социализации подростков Наталья Городиская.</p>
            <p>2023 год объявлен Годом педагога и наставника, поэтому главной темой Форума стали актуальные вопросы
                образования замещающих родителей и кандидатов в замещающие родители, а также вопросы образования
                детей-сирот, воспитывающихся в замещающих семьях, в том числе актуальные практики профориентации и
                выстраивания индивидуальных образовательных траекторий для детей такой категории.</p>
            <p>Руководитель Федерального центра развития программ социализации подростков Валерий Майоров рассказал о
                концепции создания сети подростковых центров в субъектах Российской Федерации. В настоящее время
                формируется общероссийская сеть подростковых пространств: это одновременно и досуговые клубы, и площадки
                профориентации, и места, где подростки могут получить психологическую помощь и поддержку. Свои двери для
                подростков уже открыли 18 центров, 15 из которых — победители Всероссийского конкурса программ
                социализации подростков, его провел Федеральный центр развития программ социализации подростков.</p>
            <p>Председатель Союза семей, воспитывающих детей-сирот и детей, оставшихся без попечения родителей,
                координатор по работе с регионами Федерального центра развития программ социализации подростков Наталья
                Городиская выступила модератором круглого стола «Мой ребенок вырос! Он — взрослый?», на котором
                обсуждались проблемы самостоятельной жизни и сопровождения выпускников Центров содействия семейному
                воспитанию к самостоятельной жизни в условиях ГБУ г. Москвы Центра социальной (постинтернатной)
                адаптации.</p>
            <p>Заместитель директора ФГБУ «Центр защиты прав и интересов детей» (Министерство просвещения РФ), член
                Экспертного совета Министерства просвещения РФ по вопросам опеки и попечительства в отношении
                несовершеннолетних Марина Лашкул рассказала о психолого-педагогической поддержке ближайших родственников
                в воспитании ребенка, оставшегося без попечения родителей, в своей семье и о нововведениях в
                законодательстве в вопросах опеки и попечительства в отношении несовершеннолетних.</p>
            <p>Аналитик ФГБУ «Центр защиты прав и интересов детей» Виталий Нечукин рассказал о работе общественной
                организации «Старшие Братья Старшие Сестры» и программе индивидуального наставничества детей,
                находящихся в трудной жизненной ситуации.</p>
            <p>Всероссийский онлайн форум приемных семей собрал в этом году рекордное количество участников — почти пять
                тысяч. Трансляцию Форума посмотрели более 28 тысяч человек.</p>
            <p>В результате широкого обсуждения в рамках Форума были определены проблемы и варианты их решения в области
                обучения и повышения родительских компетенций кандидатов и замещающих родителей, презентация и
                обсуждение образовательных модулей для приемных родителей детей с особенностями в развитии, приемных
                пап, а также актуальные проблемы совершенствования работы школ приемных родителей.</p>
        </div>
        <ToNews/>
    </div>
}


export default OFFF2022;