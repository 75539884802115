import React from "react";
import s from "./Default.module.css"
import { useEffect, useState } from "react";
import axios from "axios";
import img from "../../assets/img/NG.jpg"
import ToNews from "../News/ToNews";

const NG2023 = ({children, header}) => {
    const [newsData, setNews] = useState([])
    
    return<div className={s.page}>
        <h2 style={{fontWeight: "normal", marginTop:"50px", color: "#3a4663", textAlign:"left", maxWidth:"1100px", margin:"40px auto", fontFamily:"Proxima Nova Lt"}}>
        Поздравление с Новым годом и Рождеством!
        </h2>
        <div>
            <img src={img}/>
        </div>
        <ToNews />
    </div>
}




export default NG2023