import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img1 from "../../assets/VserosPhoto/AU1A9214.jpg"
import img2 from "../../assets/VserosPhoto/AU1A9216.jpg"
import img3 from "../../assets/VserosPhoto/AU1A9217.jpg"
import img4 from "../../assets/VserosPhoto/AU1A9219.jpg"
import img5 from "../../assets/VserosPhoto/AU1A9228.jpg"
import img6 from "../../assets/VserosPhoto/AU1A9233.jpg"
import img7 from "../../assets/VserosPhoto/AU1A9234.jpg"
import img8 from "../../assets/VserosPhoto/AU1A9235.jpg"
import img9 from "../../assets/VserosPhoto/AU1A9249.jpg"
import img10 from "../../assets/VserosPhoto/AU1A9250.jpg"
import img11 from "../../assets/VserosPhoto/AU1A9251.jpg"
import img12 from "../../assets/VserosPhoto/AU1A9253.jpg"
import img13 from "../../assets/VserosPhoto/AU1A9267.jpg"
import img14 from "../../assets/VserosPhoto/AU1A9268.jpg"
import img15 from "../../assets/VserosPhoto/AU1A9275.jpg"
import img16 from "../../assets/VserosPhoto/AU1A9282.jpg"
import img17 from "../../assets/VserosPhoto/AU1A9293.jpg"
import img18 from "../../assets/VserosPhoto/AU1A9294.jpg"
import img19 from "../../assets/VserosPhoto/AU1A9303.jpg"
import img20 from "../../assets/VserosPhoto/AU1A9302.jpg"
import img21 from "../../assets/VserosPhoto/AU1A9307.jpg"
import img22 from "../../assets/VserosPhoto/AU1A9310.jpg"
import img23 from "../../assets/VserosPhoto/AU1A9311.jpg"
import img24 from "../../assets/VserosPhoto/AU1A9313.jpg"
import img25 from "../../assets/VserosPhoto/AU1A9344.jpg"
import img26 from "../../assets/VserosPhoto/AU1A9347.jpg"
import img27 from "../../assets/VserosPhoto/AU1A9347.jpg"
import img28 from "../../assets/VserosPhoto/AU1A9351.jpg"
import img29 from "../../assets/VserosPhoto/AU1A9357.jpg"
import img30 from "../../assets/VserosPhoto/AU1A9359.jpg"
import img31 from "../../assets/VserosPhoto/AU1A9362.jpg"
import img32 from "../../assets/VserosPhoto/AU1A9366.jpg"
import img33 from "../../assets/VserosPhoto/AU1A9368.jpg"
import img34 from "../../assets/VserosPhoto/AU1A9369.jpg"
import img35 from "../../assets/VserosPhoto/AU1A9372.jpg"
import img36 from "../../assets/VserosPhoto/AU1A9375.jpg"
import img37 from "../../assets/VserosPhoto/AU1A9377.jpg"
import img38 from "../../assets/VserosPhoto/AU1A9379.jpg"
import img39 from "../../assets/VserosPhoto/AU1A9380.jpg"
import img40 from "../../assets/VserosPhoto/AU1A9381.jpg"
import img41 from "../../assets/VserosPhoto/AU1A9383.jpg"
import img42 from "../../assets/VserosPhoto/AU1A9384.jpg"
import img43 from "../../assets/VserosPhoto/AU1A9387.jpg"
import img44 from "../../assets/VserosPhoto/AU1A9391.jpg"
import img45 from "../../assets/VserosPhoto/AU1A9395.jpg"
import img46 from "../../assets/VserosPhoto/AU1A9397.jpg"
import img47 from "../../assets/VserosPhoto/AU1A9398.jpg"
import img48 from "../../assets/VserosPhoto/AU1A9402.jpg"
import img49 from "../../assets/VserosPhoto/AU1A9400.jpg"
import img50 from "../../assets/VserosPhoto/AU1A9404.jpg"
import img51 from "../../assets/VserosPhoto/AU1A9405.jpg"
import img52 from "../../assets/VserosPhoto/AU1A9406.jpg"
import img53 from "../../assets/VserosPhoto/AU1A9411.jpg"
import img54 from "../../assets/VserosPhoto/AU1A9414.jpg"
import img55 from "../../assets/VserosPhoto/AU1A9415.jpg"
import img56 from "../../assets/VserosPhoto/AU1A9417.jpg"
import img57 from "../../assets/VserosPhoto/AU1A9418.jpg"
import img58 from "../../assets/VserosPhoto/AU1A9419.jpg"
import img59 from "../../assets/VserosPhoto/AU1A9420.jpg"
import img60 from "../../assets/VserosPhoto/AU1A9421.jpg"
import img61 from "../../assets/VserosPhoto/AU1A9423.jpg"
import img62 from "../../assets/VserosPhoto/AU1A9425.jpg"
import img63 from "../../assets/VserosPhoto/AU1A9428.jpg"
import img64 from "../../assets/VserosPhoto/AU1A9430.jpg"
import img65 from "../../assets/VserosPhoto/AU1A9431.jpg"
import img66 from "../../assets/VserosPhoto/AU1A9432.jpg"
import img67 from "../../assets/VserosPhoto/AU1A9434.jpg"
import img68 from "../../assets/VserosPhoto/AU1A9435.jpg"
import img69 from "../../assets/VserosPhoto/AU1A9437.jpg"
import img70 from "../../assets/VserosPhoto/AU1A9439.jpg"



const NewSecondVserosPhoto = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.photopage}>
        <div>

            <h2 style={{
                fontWeight: "normal",
                marginTop: "50px",
                color: "#3a4663",
                textAlign: "left",
                maxWidth: "1100px",
                margin: "40px auto",
                fontFamily: "Proxima Nova Lt"
            }}>
                Фотоотчет
            </h2>
            <div className={s.gallery}>
                <div>
                    <img src={img1}/>
                </div>
                <div>
                    <img src={img2}/>
                </div>
                <div>
                    <img src={img3}/>
                </div>
                <div>
                    <img src={img4}/>
                </div>
                <div>
                    <img src={img5}/>
                </div>
                <div>
                    <img src={img6}/>
                </div>
                <div>
                    <img src={img7}/>
                </div>
                <div>
                    <img src={img8}/>
                </div>
                <div>
                    <img src={img9}/>
                </div>
                <div>
                    <img src={img10}/>
                </div>
                <div>
                    <img src={img11}/>
                </div>
                <div>
                    <img src={img12}/>
                </div>
                <div>
                    <img src={img13}/>
                </div>
                <div>
                    <img src={img14}/>
                </div>
                <div>
                    <img src={img15}/>
                </div>
                <div>
                    <img src={img16}/>
                </div>
                <div>
                    <img src={img17}/>
                </div>
                <div>
                    <img src={img18}/>
                </div>
                <div>
                    <img src={img19}/>
                </div>
                <div>
                    <img src={img20}/>
                </div>
                <div>
                    <img src={img21}/>
                </div>
                <div>
                    <img src={img22}/>
                </div>
                <div>
                    <img src={img23}/>
                </div>
                <div>
                    <img src={img24}/>
                </div>
                <div>
                    <img src={img25}/>
                </div>
                <div>
                    <img src={img26}/>
                </div>
                <div>
                    <img src={img27}/>
                </div>
                <div>
                    <img src={img28}/>
                </div>
                <div>
                    <img src={img29}/>
                </div>
                <div>
                    <img src={img30}/>
                </div>
                <div>
                    <img src={img31}/>
                </div>
                <div>
                    <img src={img32}/>
                </div>
                <div>
                    <img src={img33}/>
                </div>
                <div>
                    <img src={img34}/>
                </div>
                <div>
                    <img src={img35}/>
                </div>
                <div>
                    <img src={img36}/>
                </div>
                <div>
                    <img src={img37}/>
                </div>
                <div>
                    <img src={img38}/>
                </div>
                <div>
                    <img src={img39}/>
                </div>
                <div>
                    <img src={img40}/>
                </div>
                <div>
                    <img src={img41}/>
                </div>
                <div>
                    <img src={img42}/>
                </div>
                <div>
                    <img src={img43}/>
                </div>
                <div>
                    <img src={img44}/>
                </div>
                <div>
                    <img src={img45}/>
                </div>
                <div>
                    <img src={img46}/>
                </div>
                <div>
                    <img src={img47}/>
                </div>
                <div>
                    <img src={img48}/>
                </div>
                <div>
                    <img src={img49}/>
                </div>
                <div>
                    <img src={img50}/>
                </div>
                <div>
                    <img src={img51}/>
                </div>
                <div>
                    <img src={img52}/>
                </div>
                <div>
                    <img src={img53}/>
                </div>
                <div>
                    <img src={img54}/>
                </div>
                <div>
                    <img src={img55}/>
                </div>
                <div>
                    <img src={img56}/>
                </div>
                <div>
                    <img src={img57}/>
                </div>
                <div>
                    <img src={img58}/>
                </div>
                <div>
                    <img src={img59}/>
                </div>
                <div>
                    <img src={img60}/>
                </div>
                <div>
                    <img src={img61}/>
                </div>
                <div>
                    <img src={img62}/>
                </div>
                <div>
                    <img src={img63}/>
                </div>
                <div>
                    <img src={img64}/>
                </div>
                <div>
                    <img src={img65}/>
                </div>
                <div>
                    <img src={img66}/>
                </div>
                <div>
                    <img src={img67}/>
                </div>
                <div>
                    <img src={img68}/>
                </div>
                <div>
                    <img src={img69}/>
                </div>
                <div>
                    <img src={img70}/>
                </div>
                <p></p>
            </div>
        </div>
    </div>
}


export default NewSecondVserosPhoto