import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img from "../../assets/Mediacia/1.jpg"
import img2 from "../../assets/Mediacia/2.jpg"
import ToNews from "../News/ToNews";

const MediaciaBase = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt",
            fontSize: "30px"
        }}>
            Медиация — Основные положения
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div className={s.mediaciaBase}>
            Разногласия, споры, конфликты являются неотъемлемой частью нашей жизни. Умение предотвращать конфликты,
            эффективно урегулировать разногласия, способность извлекать пользу из конфликта, разрешая его с наименьшими
            потерями, необходимы современному человеку в быту и в повседневной профессиональной деятельности
            <p></p>
            Медиация — это альтернативный способ разрешения споров с участием третьей нейтральной стороны, при котором
            сами стороны, вовлеченные в спор, наделены силой и влиянием на процесс поиска решения, а также возможностью
            свободного волеизъявления при выработке договоренностей. Медиация — это особая форма посредничества, не
            предполагающая вынесения посредником суждения, решения по спору
            <h2 style={{
                fontWeight: "normal",
                marginTop: "50px",
                color: "#3a4663",
                textAlign: "left",
                maxWidth: "1100px",
                margin: "40px auto",
                fontFamily: "Proxima Nova Rg",
                fontSize: "28px"
            }}>
                Глоссарий
            </h2>
            <div>
                (извлечение из документа: Распоряжение Правительства Российской Федерации от 30 июля 2014 г. № 1430-р
                «Об
                утверждении Концепции развития до 2020 года сети служб медиации в целях реализации восстановительного
                правосудия в отношении детей, в том числе совершивших общественно опасные деяния, но не достигших
                возраста, с которого наступает уголовная ответственность в Российской Федерации»
            </div>
            <p>
            <span className={s.mediaciaBaseTermins}>
            Медиация
            </span>
                — способ разрешения споров мирным путем на основе выработки сторонами спора взаимоприемлемого решения
                при содействии нейтрального и независимого лица — медиатора
            </p>
            <p>
            <span className={s.mediaciaBaseTermins}>
            Медиативный подход
            </span>
                – подход, основанный на принципах медиации, предполагающий владение навыками позитивного осознанного
                общения, создающими основу для предот
                вращения и (или) эффективного разрешения споров и конфликтов в повседневных условиях без проведения
                медиации как полноценной процедуры
            </p>
            <p>
            <span className={s.mediaciaBaseTermins}>
            Восстановительное правосудие
            </span>
                — новый подход к отправлению правосудия, направленный прежде всего не на наказание виновного путем
                изоляции его от общества, а на восстановление материального, эмоционально-психологического (морального)
                и иного ущерба, нанесенного жертве, сообществу и обществу, на осознание и заглаживание вины,
                восстановление отношений, содействие реабилитации и ресоциализации правонарушителя
            </p>
            <p>
            <span className={s.mediaciaBaseTermins}>
            Восстановительный подход
            </span>
                — использование в практической деятельности, в частности в профилактической и коррекционной работе с
                детьми и подростками, в том числе при разрешении споров и конфликтов и после совершения правонарушений,
                умений и навыков, направленных на всестороннее восстановление отношений, доверия, материального и
                морального ущерба и др. </p>
        </div>
        <p>
            <h2 style={{
                fontWeight: "normal",
                marginTop: "50px",
                color: "#3a4663",
                textAlign: "left",
                maxWidth: "1100px",
                margin: "40px auto",
                fontFamily: "Proxima Nova Rg",
                fontSize: "28px"
            }}>
                Принципы медиации
            </h2>
        </p>
        <div className={s.mediacia}>

            <div>
                <p style={{marginBottom: "10px"}}>
                    <span className={s.mediaciaBaseTermins}>
                        Принцип добровольности
                    </span>
                </p>
                <div className={s.mediaciaBase}>
                    Процедура медиации является добровольной на всех этапах ее проведения, начиная с согласия сторон
                    принять участие в данном процессе и выборе посредника, до подписания медиативного соглашения. Каждая
                    из сторон, как и медиатор, в любой момент может отказаться от продолжения медиации, если посчитает
                    таковую нецелесообразной
                </div>
            </div>
            <div>
                <p style={{marginBottom: "10px"}}>
                <span className={s.mediaciaBaseTermins}>
                Принцип конфиденциальности
                </span>
                </p>
                <div className={s.mediaciaBase}>
                    По умолчанию все сведения, полученные в ходе процедуры, не подлежат разглашению ни посредником, ни
                    сторонами конфликта. (Договоренности и решения, достигнутые на медиации, могут быть раскрыты третьим
                    лицам только по взаимному согласию после письменного заявления сторон)

                </div>
            </div>
            <div>
                <p style={{marginBottom: "10px"}}>
                <span className={s.mediaciaBaseTermins}>
                    Принцип нейтральности
                </span>
                </p>
                <div className={s.mediaciaBase}>
                    Медиатор сохраняет независимость и беспристрастность как к предмету спора, так и к участникам
                    процедуры медиации: не принимает сторону кого-либо из участников независимо от статуса и
                    воздерживается от оценочных суждений и выводов относительно участников конфликта и причин спора
                </div>
            </div>
            <div>
                <p style={{marginBottom: "10px"}}>
                <span className={s.mediaciaBaseTermins}>
                    Принцип равенства и равноправия
                </span>
                </p>
                <div className={s.mediaciaBase}>
                    Сторонам в процедуре предоставляются равные возможности высказываться и быть выслушанными,
                    предлагать темы для обсуждения и вносить предложения по разрешению конфликта; они обладают равными
                    правами в обсуждении и в отстаивании своих интересов
                </div>
            </div>
            <div>
                <p style={{marginBottom: "10px"}}>
                <span className={s.mediaciaBaseTermins}>
                    Принцип открытости и прозрачности
                </span>
                </p>
                <div className={s.mediaciaBase}>
                    Медиатор разъясняет последовательность этапов медиации и суть процедуры, формирует понимание у
                    сторон их роли и роли медиатора
                </div>
            </div>
            <div>
                <p style={{marginBottom: "10px"}}>
                <span className={s.mediaciaBaseTermins}>
                    Принципы взаимного уважения и сотрудничества
                </span>
                </p>
                <div className={s.mediaciaBase}>
                    В целях поддержания конструктивной направленности переговоров сторонам предлагается придерживаться
                    уважительного стиля общения и не допускать взаимных оценок и оскорблений в ходе процедуры. Эти
                    условия являются необходимыми для создания на встрече доверительной и безопасной атмосферы
                </div>
            </div>
            <div>
                <p style={{marginBottom: "10px"}}>
                <span className={s.mediaciaBaseTermins}>
                Принцип активной ответственности
                </span>
                </p>
                <div className={s.mediaciaBase}>
                    Стороны, являясь «собственниками» конфликта, в равной степени ответственны за принятие и исполнение
                    совместных решений; медиатор способствует формированию ответственности, поддерживает их шаги в этом
                    направлении, в том числе проверяя прозрачность договоренностей и проясняя возможные риски их
                    неисполнения
                </div>
            </div>
            <p></p>
            <div>
                <BlueButtonMediacia/>
            </div>
            <p></p>
            <div>
                <img src={img2}/>
            </div>
        </div>
        <ToNews/>
    </div>
}

const BlueButtonMediacia = () => {
    return <div className={s.blueButtonMediaciaBase}>
        <a href="https://fcprc.ru/wp-content/uploads/2021/06/Osnovy-mediatsii-prezentatsiya-FGBU-TSentr-zashhity-prav-i-interesov-detej_compressed.pdf">Основы
            медиации - презентация ФГБУ Центр защиты прав и интересов детей</a>
    </div>
}

export default MediaciaBase