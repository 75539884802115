import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/SuvuNovember1.jpg"
import img2 from "../../assets/News/SuvuNovember2.jpg"
import img3 from "../../assets/News/SuvuNovember3.jpg"

const SuvuNovember = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сопровождение служб медиации СУВУ в ноябре и декабре
        </h2>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>В ноябре и декабре этого года прошли завершающие мероприятия по сопровождению работы служб медиации
                специальных учебно-воспитательных учреждений. В мероприятиях приняли участие специалисты и обучающиеся
                Майкопского, Ишимбайского, Щекинского, Раифского, Куртамышского, Абаканского, Калтанского, Орловского и
                Санкт-Петербургского СУВУ.</p>
            <p>В рамках консультаций специалисты служб медиации представили свой опыт работы по предупреждению и
                разрешению конфликтных ситуаций, а также по организации работы с обучающимися и их родителями.</p>
            <p>Специалисты ФГБУ «Центр защиты прав и интересов детей» предложили разобрать ситуацию, в которой
                обучающийся обращается к медиатору с жалобой на воспитателя, а впоследствии в доверительной беседе
                рассказывает о негативной ситуации дома. Применение медиативного подхода дает возможность ребенку
                сделать самостоятельные шаги в сложной ситуации с деликатной и уважительной поддержкой взрослого.</p>
            <p>На интерактивных занятиях обучающиеся познакомились с понятием «фильтры восприятия», обсудили, что важно
                в общении и что может этому мешать, подготовили ситуации для формирования «Я-сообщения», поработали в
                командах.
                Опыт проведения подобных мероприятий свидетельствует о необходимости продолжения такой работы.</p>
        </div>
        <div>
            <img src={img}/>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
    </div>
}


export default SuvuNovember;