import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/News2803242_1.png"
import img2 from "../../assets/News/News2803242_2.png"
import img3 from "../../assets/SUVU/3-1.png";

import pr1 from "../../assets/img/pr1.PNG"
import ch1 from "../../assets/img/ch1.PNG"
import SimpleImageSlider from "react-simple-image-slider";

const News2803242 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Рекомендации родителям и детям
        </h2>
        <SimpleImageSlider images={[pr1, ch1]} width={1000} height={600}
                           showBullets={true}
                           autoPlay={true}
                           autoPlayDelay={5}
                           showNavs={true}/>
        <p></p>
        <ToNews/>
    </div>
}


export default News2803242;