import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img from "../../assets/News/HLAQ.png"
import ToNews from "../News/ToNews";
import {NavLink} from "react-router-dom";

const HLAboutQuestions = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Круглый стол на тему: «Работа с детьми, имеющими опыт проживания в зоне военных действий»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>В целях помощи в урегулировании споров, разногласий и конфликтных ситуаций
                в образовательных организациях по поручению Министерства просвещения
                Российской Федерации в Центре защиты прав и интересов детей открыта<br></br> <strong>Горячая линия по
                    вопросам медиации и примирения в образовательных
                    организациях</strong></p>
            <p>
                <strong>Горячая линия адресована:</strong><br/>
                — специалистам органов государственной власти и местного самоуправления<br/>
                — руководителям образовательных организаций<br/>
                — педагогическим работникам, специалистам служб медиации и примирения<br/>
                — профильным общественным организациям<br/>
                — родительской общественности<br/>
                — детям, подросткам и молодежи<br/>
                Обратившиеся смогут получить консультацию по вопросам, связанным
                с разногласиями и спорами в образовательных организациях на основе
                использования медиативного и восстановительного подходов
            </p>
            <p>
                <strong>Формат и режим работы:</strong><br/>
                Получить консультацию можно в рабочие дни с <strong>9:00 до 18:00</strong> (по московскому
                времени) по телефону: <strong>8-800-222-34-17</strong>
            </p>
            <p>
                <strong>
                    Горячая линия по вопросам урегулирования конфликтов в школе, медиации и примирения в образовательных организациях
                </strong>
            </p>
            //место под кнопку

        </div>
        <ToNews/>
    </div>
}


export default HLAboutQuestions