import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/LifestyleHealth2022.jpeg"

const LifestyleHealth2022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Подведены итоги Всероссийского конкурса социальной рекламы «Стиль жизни – Здоровье! 2022».
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Завершился федеральный этап Всероссийского конкурса социальной рекламы в области формирования культуры
                здорового образа жизни среди обучающихся «Стиль жизни – Здоровье! 2022».</p>
            <p>Задачи конкурса заключаются в привлечении внимания обучающихся к социально значимым проблемам общества;
                пропаганде культуры безопасного и здорового образа жизни; просвещения детей и подростков в вопросах
                ведения здорового и безопасного образа жизни; создания условий для открытого, доверительного общения,
                возможностей самопроявления обучающихся в процессе творческой работы над проектом и т.д.</p>
            <p>В конкурсе принимали участие обучающиеся образовательных организаций субъектов Российской Федерации двух
                возрастных групп: 8-12 лет и 13-18 лет.</p>
            <p>Участники представляли на конкурс работы в двух номинациях: «Социальный видеоролик по пропаганде
                здорового и безопасного образа жизни, направленный на профилактику зависимого поведения обучающихся» и
                «Наглядный раздаточный материал по пропаганде здорового и безопасного образа жизни, направленный на
                профилактику зависимого поведения обучающихся».</p>
            <p>В региональном этапе Всероссийского конкурса приняли участие <strong>10 580
                обучающихся</strong> образовательных
                организаций из <strong>66 субъектов</strong> Российской Федерации.</p>
            <p>На федеральном этапе конкурса рассмотрено <strong>234 работы</strong>, подготовленных <strong>660
                участниками</strong>. В результате
                подведения итогов федерального этапа конкурса членами Жюри было определено <strong>30 работ</strong>,
                занявших призовые
                места.</p>
            <p>Среди лауреатов и дипломантов конкурса участники из Алтайского, Камчатского, Красноярского краев;
                Архангельской, Белгородской, Владимирской, Волгоградской, Воронежской, Иркутской областей, Кемеровской
                области — Кузбасса, Костромской, Мурманской, Новгородской, Орловской, Самарской, Сахалинской,
                Свердловской, Смоленской, Тамбовской, Тверской, Тульской областей, Санкт-Петербурга и Ямало-Ненецкого
                автономного округа.</p>
            <p>Более подробно с результатами конкурса можно ознакомиться на официальном <strong><a
                href="https://2022.social.edu-contests.ru/">сайте Всероссийского конкурса</a></strong>.</p>
        </div>
        <ToNews/>
    </div>
}


export default LifestyleHealth2022;