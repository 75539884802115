import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/VictoryDay2023.jpg"

const VictoryDay2023 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
           Поздравление Министра просвещения Российской Федерации Сергея Кравцова с Днем Победы
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Дорогие ветераны! Дорогие друзья! Поздравляю вас с Днём Победы!</p>
            <p>Этот праздник навеки вписан в историю нашей страны, в сердце каждого из нас. Мы не забудем подвиг
                поколения победителей, которые мужественно прошли через все испытания, отстояли свободу и независимость
                Родины, спасли мир от нацизма. Их твердая решимость, приверженность ценностям патриотизма, высоким
                духовным и нравственным идеалам служат примером для подрастающих поколений. Современные герои – солдаты
                и офицеры, которые самоотверженно защищают мирных граждан в ходе специальной военной операции. Они
                наследники тех воинов, что отстояли наше Отечество семьдесят восемь лет назад.</p>
            <p>
                Во все времена героями становятся во многом благодаря учителям, наставникам, чей бесценный вклад есть и
                в трудовых, и в ратных победах их воспитанников. Именно от мудрости, терпения, самоотдачи педагогов
                зависят личностное становление, гражданская и патриотическая позиция каждого молодого человека.
            </p>
            <p>
                Желаю вам и вашим родным крепкого здоровья, мира и благополучия!
            </p>
            <p>
                Министр просвещения Сергей Кравцов
            </p>
        </div>
        <ToNews/>
    </div>
}


export default VictoryDay2023;