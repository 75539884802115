import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/VebinarOsobennostiPPS.png"

const VebinarOsobennostiPPS = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар по теме: «Особенности психолого-педагогического сопровождения обучающихся с нарушениями речи»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>15 декабря 2022 года в 09:30 по московскому времени проводится вебинар по теме: «Особенности
                психолого-педагогического сопровождения обучающихся с нарушениями речи», для руководителей и
                специалистов психолого-медико-педагогических комиссий, центров психолого-педагогической, медицинской и
                социальной помощи, образовательных организаций.
                Участие в вебинаре бесплатное. Регистрация на мероприятие по <a
                    href="https://events.webinar.ru/19466565/835916023">ссылке</a></p>
        </div>
        <ToNews/>
    </div>
}


export default VebinarOsobennostiPPS;