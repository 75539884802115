import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News29061.png"

const News29062023 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Федеральный подростковый центр проведет круглый стол «Подростковый центр как часть городской инфраструктуры»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
        5 июля состоится круглый стол на тему «Подростковый центр как часть городской инфраструктуры».
        <p></p>
Организатор круглого стола — Федеральный подростковый центр. В мероприятии примут участие представители Минпросвещения России, Уполномоченного при Президенте РФ по правам ребенка, а также научно-экспертного сообщества и общественных объединений.
<p></p>
В рамках круглого стола состоится экспертное обсуждение влияния городской среды на социокультурное развитие подростков и открытие подросткового центра как части городской инфраструктуры.
<p></p>
Мероприятие пройдет на площадке Общественной палаты Российской Федерации по адресу: г. Москва, г. Москва, Миусская площадь, 7, стр. 1 в очном и дистанционном формате с 11.00 до 12.00 (по московскому времени).
            <p></p>
            Трансляция круглого стола пройдет в <a href="https://vk.com/video-217775650_456239114">социальной сети Вконтакте</a>.
        </div>
        <ToNews/>
    </div>
}


export default News29062023;