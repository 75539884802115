import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import img1 from "../../../assets/News/KDNHISTORY.png"
import img2 from "../../../assets/News/KDNHISTORY2.png"
import img3 from "../../../assets/News/KDNHISTORY3.png"
import img4 from "../../../assets/News/KDNHISTORY4.png"
import img5 from "../../../assets/News/KDNHISTORY5.png"
import img6 from "../../../assets/News/KDNHISTORY6.png"
import img7 from "../../../assets/News/KDNHISTORY7.png"
import img8 from "../../../assets/News/KDNHISTORY8.png"
import img9 from "../../../assets/News/KDNHISTORY9.png"
import img10 from "../../../assets/News/KDNHISTORY10.png"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {NavLink} from "react-router-dom";
import file1 from "../../../assets/Files/Plen2020.pdf"
import file2 from "../../../assets/Files/Nelyubov-S.A..pptx"
import YouTube from "react-youtube";
import {hrefsArrKDN} from "../FilePage/FilesKDNGos";
import {HrefsKDN} from "../FilePage/FilesKDNNews";


const NewHistoryKDN = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2>
                    История создания КДН и ЗП
                </h2>
                <p style={{fontSize: "19px", color: "#212529"}} className={s.concept}>
                    <p>После Октябрьской революции 1917 года советское государство
                        провозгласило и стало неуклонно претворять в жизнь новый принцип отношения
                        к детям – принцип социального воспитания. Отныне несовершеннолетние
                        признавались «детьми республики» и забота о ребенке стала «прямой обязанностью
                        государства», причем основное внимание уделялось детям обездоленным, сиротам
                        и беспризорникам. Борьба с беспризорностью и правонарушениями
                        несовершеннолетних стала рассматриваться как часть единого дела охраны детства.</p>
                    <p>Комиссия по делам несовершеннолетних и защите их прав (далее – комиссия,
                        КДН и ЗП) – российский специализированный государственный орган, призванный
                        координировать деятельность всех государственных органов и общественных
                        организаций, осуществляющих защиту прав и законных интересов
                        несовершеннолетних. </p>
                    <div>
                        <img src={img1}/>
                    </div>
                    <p>История создания комиссий исчисляется
                        с 14 января 1918 года – дня подписания Декрета
                        Совета Народных Комиссаров «О комиссиях для
                        несовершеннолетних», определяющего курс
                        молодого государства на социальное воспитание
                        детей и подростков, применение к ним мер
                        воспитательного и принудительного характера.
                        Созданные комиссии предназначались
                        для организации планомерной борьбы
                        с правонарушениями несовершеннолетних
                        и согласования деятельности заинтересованных
                        ведомств; находились в ведении Наркомата общественного призрения и состояли
                        из представителей трех ведомств: Наркомата общественного призрения,
                        прокуратуры и юстиции. Суды и тюремное заключение для несовершеннолетних
                        были упразднены, заключенные малолетние преступники освобождены.
                        Все уголовные дела о преступлениях подростков были изъяты из компетенции судов и переданы
                        комиссиям
                        по делам несовершеннолетних, в компетенцию которых
                        также входила работа по спасению голодающих детей, ликвидация беспризорности,
                        создание специальных детских учреждений для малолетних правонарушителей</p>
                    <p>Для организации работы
                        «комиссий о несовершеннолетних»
                        (так первоначально назывались
                        комиссии) постановлением народного
                        комиссара А.М. Коллонтай
                        от 20 января 1918 г. был создан Отдел
                        призрения несовершеннолетних.</p>
                    <div>
                        <img src={img2}/>
                    </div>
                    <p>В марте 1920 года Совет Народных Комиссаров принял декрет «О делах
                        несовершеннолетних, обвиняемых в общественно опасных преступлениях».
                        В нем вновь подтверждалась основная идея Декрета СНК от 14 января 1918 г. о том,
                        что все дела об общественно опасных деяниях несовершеннолетних подлежат
                        ведению комиссии по делам несовершеннолетних.</p>
                    <p>В июле 1920 года Советской властью утверждена первая инструкция о работе
                        комиссий (Постановление Наркомпроса РСФСР, Наркомздрава РСФСР,
                        Наркомюста РСФСР от 30 июля 1920 г. «Инструкция Комиссиям по делам
                        о несовершеннолетних». С этого момента основная задача комиссий была
                        определена как оказание медико-психологической, педагогической и социальной
                        помощи несовершеннолетним.</p>
                    <div>
                        <img src={img3}/>
                    </div>
                    <p>Декретом СНК от 22 мая 1925 г.
                        при наркомате просвещения РСФСР была
                        создана Центральная комиссия по делам
                        несовершеннолетних, предназначенная
                        для организации планомерной борьбы
                        с правонарушениями несовершеннолетних
                        и согласования деятельности
                        заинтересованных ведомств. Таким
                        образом определились координирующие функции КДН и ЗП и ее роль как организатора профилактической
                        работы</p>
                    <p>Следующий важный этап –
                        принятие Инструкции комиссии
                        для несовершеннолетних
                        12 июня 1926 года. Данная
                        Инструкция устанавливала
                        перечень тех преступлений,
                        которые подлежали
                        обязательному рассмотрению
                        представителями народного суда
                        в составе комиссий, и определяла процедуру такого рассмотрения.</p>
                    <div>
                        <img src={img4}/>
                    </div>
                    <p>11 июля 1931 года было принято постановление СНК РСФСР «Положение
                        о комиссии по делам несовершеннолетних», определившее две цели деятельности
                        указанной комиссии: охрана прав и интересов несовершеннолетних;
                        предупреждение и борьба с правонарушениями несовершеннолетних путем
                        применения мер медико-педагогического характера, правовой и материальной
                        помощи, ведения разъяснительной работы среди населения.</p>
                    <p>В итоге появились первые
                        позитивные результаты: беспризорность
                        и связанные с ней правонарушения детей
                        и подростков пошли на убыль. Так, только
                        за период 1931 – 1935 гг. число
                        осужденных несовершеннолетних
                        сократилось более чем в 2 раза.</p>
                    <div>
                        <img src={img5}/>
                    </div>
                    <p>Отправной точкой коренного
                        поворота государственной политики
                        воздействия на малолетних преступников
                        стало постановление ЦИК и Совнаркома
                        СССР от 7 апреля 1935 г.
                        «О мерах борьбы с преступностью среди несовершеннолетних». Ответственность за пять видов
                        преступлений (кража,
                        телесные повреждения, преступления, связанные с применением насилия, убийство,
                        покушение на убийство) устанавливалась теперь не с 16, а с 12 лет. Отменялась
                        статья 8 УК РСФСР, допуская отныне применение к детям, достигшим 12 лет,
                        не только лишения свободы, но и «высшую меру социальной защиты».
                    </p>
                    <p>Началась коренная реконструкция системы профилактики: была
                        ликвидирована Центральная комиссия по делам несовершеннолетних и детские
                        комиссии при республиканских и местных органах власти – важные
                        межведомственные координирующие профилактические мероприятия органы.
                        Рассмотрение дел о преступлениях подростков было передано в суд.</p>
                    <p>Комиссии по делам несовершеннолетних вновь возродились лишь в период
                        «хрущевской оттепели». Постановлением Совета Министров РСФСР в 1957 году
                        утверждено Положение о комиссиях по устройству детей и подростков.</p>
                    <p>В 1961 – 1962 гг. в качестве органов,
                        координирующих деятельность всех учреждений
                        и организаций по борьбе с беспризорностью,
                        устройству и охране прав детей и подростков,
                        повсеместно при районных (городских)
                        исполкомах местных Советов народных
                        депутатов и при Советах Министров союзных
                        и автономных республик комиссии были
                        воссозданы. Подчинение их непосредственно
                        органам государственного управления
                        обеспечило большую оперативность, а также
                        лучшие условия для взаимодействия в данной
                        сфере, для преодоления ведомственных
                        интересов ради достижения общей цели – сокращения преступности в стране,
                        охране прав детей и подростков.</p>
                    <div>
                        <img src={img6}/>
                    </div>
                    <p>Указом Президиума Верховного Совета РСФСР от 3 июня 1967 г. было
                        утверждено «Положение о комиссиях по делам несовершеннолетних» (далее – Положение о комиссиях),
                        в
                        котором комиссии были определены основным
                        координационным звеном в системе государственных органов и организаций
                        общественности, специально занимающихся воспитательной и профилактической
                        работой среди несовершеннолетних.</p>
                    <div>
                        <img src={img7}/>
                    </div>
                    <p>В течение последующих
                        лет Положение о комиссиях
                        неоднократно изменялось:
                        уточнялись функции,
                        расширялись полномочия
                        КДН и ЗП по борьбе
                        и предупреждению детской
                        безнадзорности. Позднее,
                        в 1970-х годах правовыми
                        актами была закреплена координирующая роль комиссии в системе
                        государственных органов и расширены ее контрольные функции. На КДН и ЗП была
                        также возложена обязанность по наблюдению за поведением осужденных
                        несовершеннолетних, в отношении которых применена судом отсрочка исполнения
                        приговора и условное осуждение. Лишь спустя 50 лет в июле 2018 года Положение
                        о комиссиях утратило сил.</p>
                    <div>
                        <img src={img8}/>
                    </div>
                    <p>В 1980 – 1984 годах с принятием Основ
                        Законодательства Союза ССР и союзных
                        республик об административных
                        правонарушениях, была расширена
                        компетенция КДН и ЗП в части рассмотрения
                        дел об административных правонарушениях
                        несовершеннолетних и их родителей.</p>
                    <p>Указ Президента Российской
                        Федерации от 6 сентября 1993 г. № 1338
                        «О профилактике безнадзорности
                        и правонарушений несовершеннолетних, защите их прав» закрепил главенствующую роль комиссий в
                        государственной
                        системе профилактики безнадзорности, беспризорности и правонарушений
                        несовершеннолетних.</p>
                    <p>Основным правовым актом в деятельности комиссий по делам
                        несовершеннолетних и защите их прав на сегодняшний день является Федеральный
                        закон от 24 июня 1999 г. № 120-ФЗ «Об основах системы профилактики
                        безнадзорности и правонарушений несовершеннолетних». А с 2013 года КДН и ЗП
                        в своей деятельности руководствуются также Примерным положением о комиссиях
                        по делам несовершеннолетних и защите их прав, утвержденным постановлением
                        Правительства Российской Федерации от 6 ноября 2013 г. № 995.</p>
                    <p>Опираясь на столетний опыт работы с «трудными» подростками, комиссии
                        по делам несовершеннолетних и защите их прав продолжают работу как
                        коллегиальные органы, координирующие деятельность органов и учреждений
                        системы профилактики безнадзорности и правонарушений несовершеннолетних
                        в соответствии с законодательством Российской Федерации в сфере защиты детства.</p>
                    <div>
                        <img src={img9}/>
                    </div>
                    <div>
                        <img src={img10}/>
                    </div>
                </p>
            </div>
        </div>
        <div style={{marginTop: "-3px"}}>
            <HrefsKDN hrefsArr={hrefsArrKDN}/>
        </div>


    </div>


}

export const HrefsBeh1 = ({
                              hrefsArr, stylef = {}, to = "/"
                          }) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({
                        text, ul, input = null, to
                    }) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({
                              text, to
                          }) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({
                        text, stylesheet
                    }) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({
                         text, stylesheet
                     }) => {
    return <div className={s.whitebutton}>
        {text}
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default NewHistoryKDN