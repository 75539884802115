import React, { useEffect, useState } from "react";
import SimpleImageSlider from "react-simple-image-slider";
import "./index.css"
import rob from "../../assets/Viktorina/robot3.png"
import axios from "axios";
import {Formik, Field, Form} from "formik";
import s from "../common/PageInfo/PageInfo.module.css"
import ImgPopup from "../common/Popup/PopupImg"


const EduTest = ({}) => {
    const [active, setActive] = useState(0)
    useEffect(() => {
    })
    return<div>
		
        <div onClick={() => {
            setActive(active+1)
        }}  className="block">
        </div>
        <div>
            <App/>
        </div>
    </div>
}

export function App() {
	const questions = [
		{
			questionText: 'Каким нормативно-правовым актом регулируется оказание психолого-педагогической, медицинской и социальной помощи обучающимся, испытывающим трудности в освоении основных общеобразовательных программ, развитии и социальной адаптации?',
			answerOptions: [
				{ answerText: 'Федеральный закон «О социальной защите инвалидов в Российской Федерации» от 24.11.1995 N 181-ФЗ, ст.11', isCorrect: false },
				{ answerText: 'Федеральный закон «Об образовании в Российской Федерации» от 29.12.2012 N 273-ФЗ, ст.79', isCorrect: false },
				{ answerText: 'Федеральный закон «Об образовании в Российской Федерации» от 29.12.2012 N 273-ФЗ, ст.42', isCorrect: true },
				{ answerText: 'Федеральный закон от 24.07.1998 N 124-ФЗ (ред. от 28.04.2023) «Об основных гарантиях прав ребенка в Российской Федерации», ст.7', isCorrect: false },
			],
			id:0
		},
		
		{
			questionText: 'Целью психолого-педагогического консилиума является:',
			answerOptions: [
				{ answerText: 'проектирование индивидуальных учебных планов, содержания и организации психолого- педагогического сопровождения обучающихся, испытывающих трудности в освоении основных общеобразовательных программ, развитии, социальной адаптации, в том числе обучающихся на дому', isCorrect: false },
				{ answerText: 'консультирование участников образовательных отношений', isCorrect: false },
				{ answerText: 'взаимодействие руководящих и педагогических работников организации, осуществляющей образовательную деятельность', isCorrect: false },
				{ answerText: 'организация коррекционно-развивающих занятий', isCorrect: false },
				{ answerText: 'создание оптимальных условий обучения, развития, социализации и адаптации обучающихся посредством психолого-педагогического сопровождения', isCorrect: true },
			],
			id:2
		},
		{
			questionText: 'Какую ошибку допустил педагог при подготовке стимульного материала для проведения психолого-педагогического обследования ребёнка старшего дошкольного возраста с нарушением зрения? ',
			answerOptions: [
				{ answerText: 'отобрал картинный материал с соблюдением требований к пропорциональности соотношений предметов по величине в соответствии с соотношением реальных объектов', isCorrect: false },
				{ answerText: 'оценил цветовую палитру изображений на соответствие реальным цветам и наличие контура', isCorrect: false },
				{ answerText: 'распечатал картинный материал на цветном принтере', isCorrect: false },
				{ answerText: 'заламинировал картинный материал', isCorrect: true },
			],
			id:3
		},
		{
			questionText: 'Что является целью сетевого взаимодействия образовательных организаций? ',
			answerOptions: [
				{ answerText: 'обеспечение качественного образования путем формирования сетевой модели обучения', isCorrect: true },
				{ answerText: 'расширение спектра образовательных услуг', isCorrect: false },
				{ answerText: 'использование в учебном процессе образовательных ресурсов и образовательных технологий', isCorrect: false },
				{ answerText: 'разработка АООП для обучающихся с ОВЗ', isCorrect: false },
			],
			id:6
		},
		{
			questionText: 'Что такое аутсорсинг?',
			answerOptions: [
				{ answerText: 'образовательный маршрут ребенка с ОВЗ', isCorrect: false },
				{ answerText: 'преемственность образовательного маршрута', isCorrect: false },
				{ answerText: 'привлечение сторонних организаций в целях реализации АООП', isCorrect: true },
				{ answerText: 'привлечение финансирования от сторонних организаций', isCorrect: false },
				{ answerText: 'помощь в психолого-педагогическом сопровождении семьи ребенка с особыми образовательными потребностями', isCorrect: false },
			],
			id:7
		},
		{
			questionText: 'Цифровое самоповреждение – это?',
			answerOptions: [
				{ answerText: 'поведение, когда индивид анонимно размещает или каким-то другим способом распространяет в сети оскорбительный, уничижительный и негативный контент о самом себе, это может быть также самооговор и даже компромат на самого себя', isCorrect: true },
				{ answerText: 'демонстративное самоповреждающее поведение индивида, транслируемое через социальные сети', isCorrect: false },
				{ answerText: 'поведение, когда индивид ищет сторонников самоповреждающего поведения в сети Интренет', isCorrect: false },
			],
			id:8
		},
		{
			questionText: 'Эффект Вертера – это? ',
			answerOptions: [
				{ answerText: 'это самоубийство на почве нарциссической влюбленности в самого себя', isCorrect: false },
				{ answerText: 'это самоубийство на почве неразделенной платонической любви', isCorrect: false },
				{ answerText: 'массовая волна подражающих самоубийств, которые совершаются после самоубийства, широко освещённого телевидением или другими СМИ, либо описанного в популярном произведении литературы или кинематографа', isCorrect: true },
			],
			id:9
		},
		{
			questionText: 'К антивитальным переживаниям относятся:',
			answerOptions: [
				{ answerText: 'активные формы проявления суицидальности, т.е. тенденция к самоубийству, глубина которой нарастает параллельно степени разработки плана ее реализации; продумывание способов суицида, время и места действия', isCorrect: false },
				{ answerText: 'размышления об отсутствии ценности жизни, которые выражаются в формулировках типа: «жить не стоит», «не живешь, а существуешь» и т.п., где еще нет четких представлений о собственной смерти, а имеется отрицание жизни', isCorrect: true },
				{ answerText: 'представления, фантазии на тему своей смерти, но не на тему лишения себя жизни как самопроизвольной активности. Примером этому являются высказывания: «хорошо бы умереть», «заснуть и не проснуться», «если бы со мной произошло что-нибудь, и я бы умер...» и т. д.', isCorrect: false },
			],
			id:10
		},
		{
			questionText: 'Феномен социального остракизма включает в себя следующие подконструкты:',
			answerOptions: [
				{ answerText: 'игнорирование, исключение', isCorrect: false },
				{ answerText: 'игнорирование, одиночество, отвержение', isCorrect: false },
				{ answerText: 'игнорирование, исключение, отвержение', isCorrect: true },
			],
			id:11
		},
		{
			questionText: 'Согласно модели К.Д. Вильямса, в случае субъективной остракизации у человека нарушается уровень следующих потребностей:',
			answerOptions: [
				{ answerText: 'потребность в одиночестве, самоуважении', isCorrect: false },
				{ answerText: 'потребность в самореализации', isCorrect: false },
				{ answerText: 'потребности в контроле и осмысленном существовании', isCorrect: true },
			],
			id:12
		},
		{
			questionText: 'Кого не относят к категории одаренных детей?',
			answerOptions: [
				{ answerText: 'учащиеся с необыкновенно высокими общими интеллектуальными способностями', isCorrect: false },
				{ answerText: 'учащиеся с признаками специальной умственной одаренности в определенной области наук и конкретными академическими способностями', isCorrect: false },
				{ answerText: 'учащиеся, не достигающие по каким-либо причинам успехов в обучении', isCorrect: true },
				{ answerText: 'учащиеся с высокими творческими способностями', isCorrect: false },
			],
			id:13
		},
		{
			questionText: 'Системное, развивающееся в течение жизни качество личности, которое определяет возможность достижения человеком более высоких, незаурядных результатов в одном или нескольких видах деятельности по сравнению с другими людьми – это?',
			answerOptions: [
				{ answerText: 'талант', isCorrect: true },
				{ answerText: 'одаренность', isCorrect: false },
				{ answerText: 'способность', isCorrect: false },
				{ answerText: 'трудолюбие', isCorrect: false },
			],
			id:14
		},
		{
			questionText: 'Дисинхронизация развития, свойственная многим одаренным детям заключается в…?',
			answerOptions: [
				{ answerText: 'опережающем развитии одних функций по отношению к другим', isCorrect: true },
				{ answerText: 'равенстве функций', isCorrect: false },
				{ answerText: 'отставании в социальном развитии', isCorrect: false },
				{ answerText: 'замедлении развития некоторых психических функций', isCorrect: false },
			],
			id:15
		},
		{
			questionText: 'Поведенческие проблемы одаренных детей проявляются в форме?',
			answerOptions: [
				{ answerText: 'асоциального поведения', isCorrect: false },
				{ answerText: 'агрессивности', isCorrect: false },
				{ answerText: 'депрессивного состояния', isCorrect: false },
				{ answerText: 'все ответы верны', isCorrect: true },
			],
			id:16
		},
		{
			questionText: 'Какой нормативный документ регламентирует права детей-сирот и детей, оставшихся без попечения родителей?',
			answerOptions: [
				{ answerText: 'Федеральный закон «Об основных гарантиях прав ребенка» от 24.07.1998 N 124-ФЗ', isCorrect: false },
				{ answerText: 'Федеральный закон «О дополнительных гарантиях по социальной поддержке детей-сирот и детей, оставшихся без попечения родителей» от 21.12.1996 N 159-ФЗ', isCorrect: true },
				{ answerText: 'Федеральный закон «Об образовании в Российской Федерации» от 29.12.2012 N273-ФЗ', isCorrect: false },
			],
			id:17
		},
		{
			questionText: 'Особые (дополнительные) образовательные потребности детей-мигрантов на основе причин возможных образовательных затруднений:',
			answerOptions: [
				{ answerText: 'недостаточный уровень владения русским языком, что препятствует успешному освоению образовательной программы и социализации', isCorrect: false },
				{ answerText: 'несоответствие уровня знаний, полученных в стране исхода, российским образовательным стандартам; разные требования учебных программ', isCorrect: false },
				{ answerText: 'эмоциональные трудности, вызванные переживанием миграционного стресса', isCorrect: false },
				{ answerText: 'отсутствие или нехватка соответствующих возрасту социальных навыков, по умолчанию наличествующих у представителей принимающего общества', isCorrect: false },
				{ answerText: 'ориентация на нормы и правила культуры страны и региона исхода, отличающиеся от принятых в регионе обучения в России', isCorrect: false },
				{ answerText: 'все ответы верны', isCorrect: true },
			],
			id:18
		},
		{
			questionText: 'Цель программы психолого-педагогического сопровождения процессов обучения, социальной, языковой и культурной адаптации детей иностранных граждан заключается в …',
			answerOptions: [
				{ answerText: 'обеспечении социальной инклюзии детей-мигрантов через оказание им дифференцированной психолого-педагогической поддержки и создание в образовательном пространстве определенных образовательных условий в соответствии с их особыми образовательными потребностями в сферах психологического благополучия, социальных навыков и культурной адаптации', isCorrect: true },
				{ answerText: 'методическом сопровождении педагогического коллектива в области психолого-педагогического сопровождения детей иностранных граждан', isCorrect: false },
				{ answerText: 'адаптации учебного материала, учебных программ в соответствии с особенностями детей', isCorrect: false },
				{ answerText: 'создании специальных условий обучения и воспитания обучающихся иностранных граждан', isCorrect: false },
			],
			id:19
		},
		{
			questionText: 'Перечислите цели Государственной программы Российской Федерации «Развитие образования». (выберите пять правильных вариантов ответа) ',
			answerOptions: [
				{ answerText: 'Вхождение Российской Федерации в число 10 ведущих стран мира по качеству общего образования', isCorrect: true, isChoosen: false },
				{ answerText: 'повышение ценности семейного образа жизни, сохранение духовно-нравственных традиций в семейных отношениях и семейном воспитании', isCorrect: false, isChoosen: false },
				{ answerText: 'выравнивание стартовых возможностей детей дошкольного возраста за счет обеспечения и сохранения 100 процентов доступности качественного дошкольного образования, в том числе присмотра и ухода за детьми', isCorrect: true, isChoosen: false },
				{ answerText: 'увеличение доли выпускников образовательных организаций, реализующих программы среднего профессионального образования, занятых по виду деятельности и полученным компетенциям, до 63,3 процента', isCorrect: true, isChoosen: false },
				{ answerText: 'формирование эффективной системы выявления, поддержки и развития способностей и талантов у детей и молодежи, основанной на принципах справедливости, всеобщности и направленной на самоопределение и профессиональную ориентацию всех обучающихся', isCorrect: true, isChoosen: false },
				{ answerText: 'разработка стандартов оказания профессиональной помощи обучающимся', isCorrect: false, isChoosen: false },
				{ answerText: 'развитие системы кадрового обеспечения сферы образования, позволяющей каждому педагогу повышать уровень профессионального мастерства на протяжении всей профессиональной деятельности', isCorrect: true, isChoosen: false },
			],
			id:2
		},
		{
			questionText: 'Какие формы организации помощи семье вы знаете? (Выберите три правильных варианта ответа)',
			answerOptions: [
				{ answerText: 'коллективные формы', isCorrect: true, isChoosen: false },
				{ answerText: 'формы контроля усвоения материала', isCorrect: false, isChoosen: false },
				{ answerText: 'индивидуальные формы', isCorrect: true, isChoosen: false },
				{ answerText: 'формы наглядного информационного обеспечения.', isCorrect: true, isChoosen: false },
			],
			id:5
		},
		{
			questionText: 'Участники межведомственного взаимодействия: (Выберите шесть правильных вариантов ответа)',
			answerOptions: [
				{ answerText: 'ПМПК', isCorrect: true, isChoosen: false },
				{ answerText: 'родители (законные представители)', isCorrect: false, isChoosen: false },
				{ answerText: 'БМСЭ', isCorrect: true, isChoosen: false },
				{ answerText: 'организации, реализующие программы ДО', isCorrect: true, isChoosen: false },
				{ answerText: 'образовательные организации СПО', isCorrect: true, isChoosen: false },
				{ answerText: 'студенты педагогических ВУЗов', isCorrect: false, isChoosen: false },
				{ answerText: 'центры психолого-медико-социального сопровождения', isCorrect: true, isChoosen: false },
				{ answerText: 'научные организации и университеты', isCorrect: true, isChoosen: false },
			],
			id:6
		},
	
	];
	const questionsWithFew = [
		{
			questionText: 'Перечислите цели Государственной программы Российской Федерации «Развитие образования». (выберите пять правильных вариантов ответа) ',
			answerOptions: [
				{ answerText: 'Вхождение Российской Федерации в число 10 ведущих стран мира по качеству общего образования', isCorrect: true, isChoosen: false },
				{ answerText: 'повышение ценности семейного образа жизни, сохранение духовно-нравственных традиций в семейных отношениях и семейном воспитании', isCorrect: false, isChoosen: false },
				{ answerText: 'выравнивание стартовых возможностей детей дошкольного возраста за счет обеспечения и сохранения 100 процентов доступности качественного дошкольного образования, в том числе присмотра и ухода за детьми', isCorrect: true, isChoosen: false },
				{ answerText: 'увеличение доли выпускников образовательных организаций, реализующих программы среднего профессионального образования, занятых по виду деятельности и полученным компетенциям, до 63,3 процента', isCorrect: true, isChoosen: false },
				{ answerText: 'формирование эффективной системы выявления, поддержки и развития способностей и талантов у детей и молодежи, основанной на принципах справедливости, всеобщности и направленной на самоопределение и профессиональную ориентацию всех обучающихся', isCorrect: true, isChoosen: false },
				{ answerText: 'разработка стандартов оказания профессиональной помощи обучающимся', isCorrect: false, isChoosen: false },
				{ answerText: 'развитие системы кадрового обеспечения сферы образования, позволяющей каждому педагогу повышать уровень профессионального мастерства на протяжении всей профессиональной деятельности', isCorrect: true, isChoosen: false },
			],
		},
		{
			questionText: 'Какие формы организации помощи семье вы знаете? (Выберите три правильных варианта ответа)',
			answerOptions: [
				{ answerText: 'коллективные формы', isCorrect: true, isChoosen: false },
				{ answerText: 'формы контроля усвоения материала', isCorrect: false, isChoosen: false },
				{ answerText: 'индивидуальные формы', isCorrect: true, isChoosen: false },
				{ answerText: 'формы наглядного информационного обеспечения.', isCorrect: true, isChoosen: false },
			],
		},
		{
			questionText: 'Участники межведомственного взаимодействия: (Выберите шесть правильных вариантов ответа)',
			answerOptions: [
				{ answerText: 'ПМПК', isCorrect: true, isChoosen: false },
				{ answerText: 'родители (законные представители)', isCorrect: false, isChoosen: false },
				{ answerText: 'БМСЭ', isCorrect: true, isChoosen: false },
				{ answerText: 'организации, реализующие программы ДО', isCorrect: true, isChoosen: false },
				{ answerText: 'образовательные организации СПО', isCorrect: true, isChoosen: false },
				{ answerText: 'студенты педагогических ВУЗов', isCorrect: false, isChoosen: false },
				{ answerText: 'центры психолого-медико-социального сопровождения', isCorrect: true, isChoosen: false },
				{ answerText: 'научные организации и университеты', isCorrect: true, isChoosen: false },
			],
		},
	]

	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [showScore, setShowScore] = useState(false);
	const [score, setScore] = useState(0);
	const [style, setStyle] = useState("button-app");
    const [active, setActive] = useState(false)
    let data = []
    let dataRight = [[0, 2, 3, 4, 6], [0, 2, 3], [0, 2, 3, 4, 6, 7]]
	const [isConcursStart, setStartConcurs] = useState(false)
	const [chooseBtn, setChooseBtn] = useState(false)
	const [del, setDel] = useState(false)
	
	const handleAnswerOptionClick =  (isCorrect ) => {
		if (isCorrect) {
			setScore(score + 1);
		}
		const nextQuestion = currentQuestion + 1;
		if (nextQuestion < questions.length) {
			setCurrentQuestion(nextQuestion);
		} else {
			setShowScore(true);
			if(score >= questions.length/2)
			{setActive(true)}
		}
};

	return (<>
		<h1 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1300px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
          Итоговое тестирование слушателей курса повышения квалификации "Организация психолого-педагогического сопровождения обучающихся»
Уважаемые коллеги, выберите правильный вариант ответа на вопрос. 
<div>
На вопрос 2 - пять правильных вариантов ответов; на вопрос 5 - три правильных варианта ответа; 
на вопрос 6 - шесть правильных вариантов ответов. Тестирование считается успешно пройденным, если верно выполнено более 
	50 процентов заданий. 
	
</div>

<div></div>
        </h1>

		<div className='blockVic'>
		
		{!isConcursStart 
		? 
		<div className='question-section'>
			<div style={{color:"white", backgroundColor:"#252d4a", padding:"20px", borderRadius:"15px", fontWeight:"20px"}}>
				Тест начинается здесь
				<p></p>
			<button className="button-app" onClick={() => setStartConcurs(true)}> Начать</button>
			</div>
		</div> 
		: <div className='apps'>
			{showScore ? (
				<div className='score-section'>
				Вы набрали {score} из {questions.length}
				<div>
				<div>
				<button onClick={() => {window.location.reload()}}  className="button-app"><a >Начать тест заново</a> </button> 
				</div>
				</div>
			</div>
			) : <div>{ (currentQuestion >= 17) ? <>
				<div className='question-section'>
						<div className='question-count'>
							<span>Вопросы {questions[currentQuestion].id}</span>/{questions.length}
						</div>
						<div className='question-text'>{questions[currentQuestion]?.questionText}</div>
					</div>
					<div className='answer-section'>
						{questions[currentQuestion]?.answerOptions.map((answerOption, index) => (
							<Buttn answerOption={answerOption} index={index} currentQuestion={currentQuestion}
							data={data} style={style} setStyle={setStyle} del={del} setDel={setDel}/> 
							))}
							<div onClick={() => {
								data = []
								  console.log(data)
								  setStyle=("button-app")
							}} >
								Очистить выбор
							</div>
							<div onClick={() => {
								data.sort(function(a, b) {
									return a - b;
								});
								setDel(true)
								console.log(data)
								let element
								handleAnswerOptionClick(element = (data?.length == dataRight[currentQuestion - 17]?.length) && data.every(function(element, index) {
									return element === dataRight[currentQuestion - 17][index];
								}))
							}} style={{color:"white"}}>
								Далее
							</div>
							
					</div>
					</> : <>
					<div className='question-section'>
						<div className='question-count'>
							<span>Вопрос {questions[currentQuestion].id + 1}</span>/{questions.length}
						</div>
						<div className='question-text'>{questions[currentQuestion].questionText}</div>
					</div>
					<div className='answer-section'>
						{questions[currentQuestion].answerOptions.map((answerOption) => (
							<button className="button-app" onClick={() => handleAnswerOptionClick(answerOption.isCorrect)}>{answerOption.answerText}</button>
							))}
					</div>
				</>
						}</div>
				
			}
            <ImgPopup active={active} setActive={setActive}>
            <div className={s.form}>
                            <div>
                                <h1>
                                    Форма для связи с прошедшим тестирование
                                </h1>
                            </div>
                            <Formik
                                initialValues={{email: '',}}
								
                                onSubmit={(values, {setSubmitting}) => {
									setTimeout(() => {
										axios.post('https://fcprc.ru/api/concurs', {"имя": values.имя, "телефон": values.телефон})
										setActive(false)
                                    }, 400);
                                }}
								>
                                {({errors, touched, isSubmitting}) => (
									<Form>
                                    <div>
                                        <div>
                                            <label fore="subject">ФИО
                                            </label>
                                        </div>
                                        <Field type="имя" name="имя" placeholder="Ваше ФиО"/>
                                    </div>
                                    <div>
                                        <div>
                                            <label fore="subject">Почта
                                            </label>
                                        </div>
                                        <Field type="телефон" name="телефон" placeholder="Почта"/>
                                    </div>
                                    Перед отправкой убедитесь в правильности заполнения полей, иначе мы не сможем с вами связаться
                                    <div className={s.send}>
                                        <button>Отправить</button>
                                    </div>
                                    </Form>)}
                                    </Formik>
                                    </div>
            </ImgPopup>
		</div>
		}
		
										</div>

										</>
	);
}
const Buttn = ({answerOption, index, data, del, setDel, currentQuestion }) => {
	const [style, setStyle] = useState("button-app");
	
	
	return<button className={style} onClick={() => {

		if(style === "button-app-true"){
			const indexes = data.indexOf(index);
		if (indexes > -1) { // only splice array when item is found
  				data.splice(indexes, 1); // 2nd parameter means remove one item only
	}

			setStyle("button-app")
		}
		else{
			data.push(index)

			setStyle("button-app-true")}
			
	}
	}>{answerOption.answerText}</button>
}

export default EduTest