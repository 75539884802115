import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News0406243.jpg"
import img1 from "../../assets/News/News0406243_1.jpg"
import img2 from "../../assets/News/News0406243_2.jpg"
import img3 from "../../assets/News/News0406243_3.jpg"
import img4 from "../../assets/News/News0406243_4.jpg"
import img5 from "../../assets/News/News0406243_5.jpg"
import SimpleImageSlider from "react-simple-image-slider";

const News0406243 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Проходит полуфинал Всероссийского конкурса в сфере профессиональной деятельности специалистов органов опеки
            и попечительства в отношении несовершеннолетних граждан «На стороне ребенка».
        </h2>
        <SimpleImageSlider images={[img1, img2, img3, img4, img5]} width={1000} height={1000}
                           showBullets={true}
                           autoPlay={true}
                           autoPlayDelay={5}
                           showNavs={true}/>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p></p>
            По итогам отборочного этапа, конкурсным испытанием которого было тестирование, в полуфинал Конкурса допущено
            84 (из 244, принимавших участие в отборочном этапе) специалистов и руководителей органов опеки и
            попечительства, специалистов исполнительных органов субъектов Российской Федерации, занимающихся
            формированием и ведением регионального банка данных о детях, оставшихся без попечения родителей.
            <p></p>
            Также в полуфинале Конкурса принимают участие 20 (из 40) органов опеки и попечительства. В отборочном этапе
            оценивались видеоролики, подготовленные органами опеки и попечительства, согласно выбранной номинации
            Конкурса.
            <p></p>
            Конкурсными испытаниями полуфинала стали написание эссе специалистами и решение кейсов в режиме онлайн –
            конференции органами опеки и попечительства.
            <p></p>
            Финал Конкурса пройдет в октябре текущего года в Москве, где финалисты Конкурса будут проходить экспертные
            интервью.
            <p></p>
            Экспертами Конкурса являются представители аппарата Уполномоченного при Президенте Российской Федерации по
            правам ребенка, Минпросвещения России, научного сообщества, государственных, некоммерческих,
            благотворительных и общественных организаций, победителей Конкурса в 2022 году.
            <p></p>
            <a style={{fontWeight: "bold"}} href="https://на-стороне-ребенка.рф">Подробная информация о Конкурсе
                размещена на сайте Конкурса</a>
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News0406243;