import React from "react";
import s from "./Default.module.css"
import { useEffect, useState } from "react";
import axios from "axios";
import img from "../../assets/img/Study.jpg"
import img2 from "../../assets/img/graduate2.png"
import img3 from "../../assets/img/graduate.png"
import img02 from "../../assets/img/02.png"
import img03 from "../../assets/img/03.png"
import img4 from "../../assets/img/Study.jpg"
import imgR4 from "../../assets/img/R4.jpg"
import logo1 from "../../assets/img/401.png"
import logo2 from "../../assets/img/302.png"
import logo3 from "../../assets/img/303.png"
import sertificate from "../../assets/img/04.png"

const EducationPage = ({children, header}) => {
    const [newsData, setNews] = useState([])
    let data = new FormData();
		data.append('email', 'name');

		let config = {
  			method: 'post',
  			maxBodyLength: Infinity,
  			url: 'https://fcprc.ru/api/email',
  			headers: { 
    			
  			},
  			data : data
};
    useEffect(() => {
       // axios.request(config)
    })
    return<div style={{backgroundColor:"#eaf1f4"}}>
        <div className={s.education}>
      
        <div>
            <img src={img}/>
        </div>
        <div className={s.eduBack}>
            <span>
       ФБГУ "Центр защиты прав и интересов детей" приглашает на курсы дополнительного образования по актуальным и востребованным направлениям подготовки.
            </span>
            <div style={{display:"flex", justifyContent:"space-around", backgroundColor:"#2c3444", maxWidth:"1200px", margin:"10px auto"}}>
                <div>
                    <img src={img02}/>
                </div>
                <div>
                    <img src={img03}/>
                </div>
            </div>
        </div>
        
        <div>
            <div className={s.sertificate}>
                <img src={sertificate}/>
                <div>
                <h1>
                Повышение квалификации
                </h1>
                <h2>
                Объем часов: от 16 до 250 часов
                </h2>
                <h3 >
Без отрыва от производства удостоверение
<div>
 о повышении квалификации установленного образца.
</div>
                </h3>
                </div>
            </div>
            <div style={{display:"flex", flexDirection:"column", justifyContent:"space-around", maxWidth:"1200px", margin:"10px auto"}}>
                
                <Course img={imgR4} 
                head={"Споры родителей, связанные с воспитанием детей. Особенности рассмотрения споров, связанных с воспитанием детей, с участием иностранного элемента"}>
                    <div className={s.edudesc}>
                    Программа повышения квалификации «Споры родителей, связанные с воспитанием детей. Особенности рассмотрения споров, связанных с воспитанием детей, с участием иностранного элемента» подготовлена в соответствии с примерной дополнительной профессиональной программой повышения квалификации для работников органов опеки и попечительства, утвержденной приказом Минобрнауки России от 24.02.2015 № 121 (зарегистрирован Минюстом России 21.05.2015, регистрационный № 37361);с профессиональным стандартом «Специалист органа опеки и попечительства в отношении несовершеннолетних», утвержденным приказом Минтруда России от 18.11.2013 № 680н (зарегистрирован Минюстом России 26.12.2013, регистрационный № 30850).

Для слушателей, выполнивших все требования по прохождению регистрации и предоставлению договора, а также документов для зачисления на программу, успешно прошедших обучение на курсе с выполнением итоговой контрольной работы, предусмотрена выдача удостоверения о повышении квалификации установленного образца на 16 часов
                    <p></p>
                    <h3>
                    Описание
                    </h3>
                    <div>
                    Споры родителей, связанные с воспитанием детей. Особенности рассмотрения споров, связанных с воспитанием детей, с участием иностранного элемента
<p></p>
Характеристики курса
<p></p>
Трудоемкость программы: 16 часов
<p></p>
Длительность (недель): 1
<p></p>
Учебная нагрузка по курсу: до 4 часов в день.
<p></p>
Язык курса: русский
<p></p>
Удостоверение установленного образца: да
<p></p>
                    </div>
                    </div>
                </Course>
                <Course img={imgR4} head={"Медиативно-восстановительные технологии в образовательной организации"}>
                Цель программы:
Целью программы является совершенствование (получение) новой компетенции, необходимой для профессиональной деятельности, и (или) повышение профессионального уровня в рамках имеющейся квалификации.
                </Course>
            </div>
        </div>
        <div className={s.eduguide}>
        <h3 style={{fontSize:"30px", marginBottom: "60px", fontWeight: "600", fontFamily: "Proxima Nova Lt", color: "#3a4663", textAlign: "center", maxWidth: "1200px"}}>
        Как пройти обучение?
            </h3>
            <div style={{maxWidth:"900px", display:"grid", gridTemplateRows:"1fr 1fr 1fr", textAlign:"start",}}>
                <div style={{gridTemplateColumns:"1fr 4fr", display:"grid"}}>
                    <div style={{marginLeft:"100px"}}>
                        <img src={logo1}/>
                    </div>
                    <div style={{marginLeft:"40px", display: "flex", alignItems:"center", fontSize:"24px", color:"black"}}>
                    Выберите программу из перечня образовательных курсов
                    </div>
                </div>
                <div style={{gridTemplateColumns:"1fr 4fr", display:"grid"}}>
                    <div style={{marginLeft:"100px"}}>
                        <img src={logo2}/>
                    </div>
                    <div style={{marginLeft:"40px", display: "flex", alignItems:"center", fontSize:"22px", color:"black"}}>
                
                    Зарегистрируйтесь на сайте образования (<a href="https://education.apkpro.ru/login">перейти на сайт</a>)
                    </div>
                </div>
                <div style={{gridTemplateColumns:"1fr 4fr", display:"grid"}}>
                    <div style={{marginLeft:"100px"}}>
                        <img src={logo3}/>
                    </div>
                    <div style={{marginLeft:"40px", display: "flex", alignItems:"center", fontSize:"24px", color:"black"}}>
                    Дождитесь приглашения на занятия
                    </div>
                </div>
                
            </div>
        </div>
        <div >
            <h3 style={{fontSize:"30px", marginBottom: "60px", fontWeight: "500", fontFamily: "Proxima Nova Lt", color: "#3a4663", textAlign: "center", maxWidth: "1200px"}}>
            Документы, выдаваемые слушателями после прохождения обучения
            </h3>
            <p></p>
            <div className={s.docs}>
                <img src={img2}/>
                <img src={img3}/>
            </div>
        </div>
    </div>
    </div>
}

const Course = ({head, img, description, children}) => {
    const [active, setActive] = useState(false)
    return<div className={active ? s.act : s.unact} onClick={() => {
        active ? setActive(false) : setActive(true)
    }}>
        <div className={s.eduHead}>
            {head}
            <div className={s.plus} style={{fontSize:"40px", width:"40px", borderRadius:"20px", height:"40px", rotate:`${active? "45deg":"0deg"}`}}>
                +
            </div>
        </div>
        {active?<div className={s.edudesc}>
            <img src={img}/>
            <div>{description}</div>
            {children}
        </div>:<></>}
        
    </div>
}


export default EducationPage