import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News0407.png"

const News04072023 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Определены победители Всероссийского конкурса программ социализации подростков в номинации «Социализация
            через трудовую занятость, искусство и спорт»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Подведены итоги во второй номинации Всероссийского конкурса программ социализации подростков. Экспертный
                совет завершил оценку конкурсных материалов по направлению «Социализация через трудовую занятость,
                искусство и спорт» и определил 15 программ-победителей.</p>
            <p>Отбор проходил в два этапа: на первом эксперты рассмотрели все поступившие заявки на участие, на втором
                состоялись очные собеседования с самыми яркими командами в формате видеовстреч.
            </p>
            <p> Победители в номинации «Социализация через трудовую занятость, искусство и спорт»:
            </p>
            <p>Муниципальное казенное учреждение «Центр молодежной политики» муниципального образования Крымский район
                (МКУ ЦМП). Подростковый центр «База»
            </p>
            <p> Муниципальное бюджетное учреждение дополнительного образования «Городской Дворец детского и юношеского
                творчества им. В.Г. Джарты города Макеевки». Центр «Альтернатива. Выбор за тобой!»
            </p>
            <p>АНО «ЖЭКА» (ПЦ «Выше крыши»)
            </p>
            <p> АНО «Центр социальной и правовой помощи детям «Старт в будущее». АРТКЕРАМА ПРОСТРАНСТВО "КУБА"»
            </p>
            <p> АНО «Всегда рядом» (Подростковый центр «Всегда Рядом»)
            </p>
            <p> БФ «Радость» (Подростковое пространство «Точка притяжения»)
            </p>
            <p> Муниципальное автономное учреждение «Центр молодежных инициатив»
            </p>
            <p>МКУК «Культурно-досуговый центр с.п.ст. Приближной». Центр социализации подростков «Казачок»
            </p>
            <p> Муниципальное бюджетное учреждение «Молодежный ресурсный центр» структурное подразделение Клуб по месту
                жительства «Факел» (МБУ «МРЦ» СП КМЖ «Факел»)
            </p>
            <p> Государственное автономное нетиповое образовательное учреждение Воронежской области «Региональный центр
                выявления, поддержки и развития способностей и талантов у детей и молодежи "Орион"»
            </p>
            <p> Муниципальное бюджетное учреждение города Новосибирска «Городской центр психолого-педагогической
                поддержки молодежи "Родник"»
            </p>
            <p> Государственное бюджетное учреждение Ямало-Ненецкого автономного округа «Центр социального обслуживания
                населения «Доверие» в муниципальном образовании город Салехард»
            </p>
            <p> Муниципальное казенное учреждение Белоярского района «Молодежный центр "Спутник"»
            </p>
            <p> Фонд социального, культурного и экономического развития Уфы «Общественный фонд развития города». Детское
                общественное пространство «Время детства»
            </p>
            <p> Военно-патриотический клуб «Десант» Отдела патриотической работы муниципального учреждения
                «Молодежно-спортивный центр»
            </p>
            <p> Поздравляем победителей и приглашаем принять участие в следующей номинации конкурса «Социализация через
                общение, творчество и поддержку». Прием заявок и материалов — с 17 июля по 14 августа 2023 года.
            </p>
            <p> Участие в конкурсе является добровольным и бесплатным. Одна заявка может участвовать в нескольких
                номинациях конкурса. Заявка на участие в конкурсе и конкурсные материалы направляются по электронному
                адресу: fpc_info@fcprc.ru.
            </p>
            <p> Ознакомиться с Положением о конкурсе можно здесь <a
                href="http://подростковыецентры.рф/bureaucracy">http://подростковыецентры.рф/bureaucracy</a>.
            </p>
            <p><a href="https://fcprc.ru/media/media/behavior/Протокол_Победители_2_номинация.pdf">Протокол заседания
                Организационного комитета Всероссийского конкурса программ социализации подростков</a></p>
        </div>
        <ToNews/>
    </div>
}


export default News04072023;