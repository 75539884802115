import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News111223.jpg"

const News111223 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Первые 46 специалистов успешно повысили квалификацию по программе «Введение в медиацию. Медиативный и
            восстановительный подходы», 72 часа.
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <strong>Борис Борисович Гусев</strong>, директор Центра подписал первые удостоверения о повышении квалификации. Центр получил
            лицензию на образовательную деятельность 21 сентября 2022 года, затем продолжилась разработка дополнительных
            профессиональных программ, усовершенствование портала ДПО <a href="https://dpo.fcprc.ru/"><strong>https://dpo.fcprc.ru/</strong></a> и первые группы начали свое
            обучение 25 сентября 2023 года по программе «Введение в медиацию. Медиативный и восстановительный подходы»,
            72 часа.
            <p></p>
            «Обучение специалистов проводилось в очно-заочной форме, с применением исключительно электронного обучения,
            дистанционных образовательных технологий» <strong>Илья Николаевич Ковалев</strong>, заместитель директора-руководитель Центра
            поддержки информационно-образовательных ресурсов и администрирования.
            <p></p>
            «На обучение по программе повышения квалификации было зачислено 57 специалистов специальных
            учебно-воспитательных учреждений открытого или закрытого типа подведомственных Министерству Просвещения РФ.
            Успешно завершили и прошли итоговую аттестацию 46 специалистов. Это были наши первые совместные шаги по
            дороге электронно-дистанционного обучения, благодаря обучающимся специалистам мы развиваемся и стремимся к
            идеалу. С наступающим 2024 годом, желаю реализации задуманного, здоровья и счастья!» <strong>Оксана Борисовна
            Волжина</strong>, начальник отдела дополнительного профессионального образования.
            <p></p>
           <strong>Благодарим преподавателей курса</strong> «Введение в медиацию. Медиативный и восстановительный подходы», они
            применяли интерактивные методы обучения для активного участия всех в процессе обучения, обучающиеся
            выполняли практические задания и упражнения:
            <ul>
                <li><strong>Марина Викторовна Быкова, начальник отдела медиации;</strong>
                </li>
                <li>
                    <strong> Анатолий Андреевич Козлов, сотрудник отдела медиации;</strong>
                </li>
                <li>
                    <strong>  Антон Юрьевич Коновалов, сотрудник отдела медиации.</strong>
                </li>
            </ul>
        </div>
        <ToNews/>
    </div>
}


export default News111223;