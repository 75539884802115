import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img1 from "../../assets/News/WorkOrganizationVICH.jpeg";
import YouTube from "react-youtube";

const OgranichenieNakazanie = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: «Ограничение и наказание: эффективность и воспитание»
        </h2>
        <div>
            <p>
                30 мая 2020 года состоялся вебинар, посвященный одной из актуальных тем – гармоничному и бережному
                воспитанию детей. Ведущий вебинара, Горнова Татьяна Сергеевна, аналитик ФГБУ «Центр защиты прав и
                интересов детей», кандидат педагогических наук, рассмотрела различия в понятиях «наказание» и
                «ограничение», раскрыла последствия воспитательных воздействий
                на детей, поделилась бережными способами воспитания. Вебинар посетили 505 слушателей из 16 регионов
                Российской Федерации, Германии, Турции, Украины.
            </p>
            <p>
                Ссылка на вебинар: <a href="https://www.youtube.com/watch?v=HF_wi3DU658">https://www.youtube.com/watch?v=HF_wi3DU658</a>
            </p>
            <p></p>
        </div>
    </div>
}


export default OgranichenieNakazanie