import React from "react";
import ico from "../../../assets/arrow.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {useSelector, useDispatch} from "react-redux";
import {actions} from "../../../Redux/Blank.js"
import {useNavigate} from "react-router-dom";
import {BlankComponent} from "../../defaultPages/Blank"

const hrefsArr = [
    {text: "Нормативная правовые-материалы", to: "/kdnRights", input: []},
    {text: "Методологические материалы для специалистов", to: "/kdnDev"},
    {
        text: "Информационные материалы для родителей",
        to: "/spec-materials-category/informatsionnye-materialy-dlya-roditelej"
    },
    {text: "Конкурсы и проекты", to: "/kdnGos"},
    {text: "Советуем прочитать"},
    {text: "Полезные ссылки", to: "/spec-materials-category/poleznye-ssylki-goryachie-linii"},
    {text: "Получить помощь, консультацию"},
]
const FilesKDNRead = () => {
    const [respData, setData] = useState([])

    const dispatch = useDispatch()

    const setBlankPageData = (data) => dispatch(actions.addCommentsActioncreator(data))
    const history = useNavigate()


    return <>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Советуем прочитать
        </h2>


        <div className={s.filesLeftPage}>
            <div>
                <div onClick={() => {
                    setBlankPageData({text: "text", img: "img", children: "ff"})
                    history("/blank")
                }}>
                    d
                </div>
                <BlankComponent text1={"fgdfg"} ico={ico} img={ico}
                                header="Азарова, Л. А. Девиантное поведение и его профилактика. — Минск: ГИУСТ БГУ, 2009. — 164 с"/>
                <FileLeftLine
                    text={"Азарова, Л. А. Девиантное поведение и его профилактика. — Минск: ГИУСТ БГУ, 2009. — 164 с"}
                    refers={respData[0]?.file} ico={ico}/>
                <FileLeftLine
                    text={"Александров А. А., Соколова И. С. Структура отношений в семьях суицидентов по данным экспериментально-психологическрого исследования. В кн.: Кризисные состояния. Суицидальное поведение. — СПб, 2000. — С. 39−41."}
                    ico={ico}/>
                <FileLeftLine
                    text={"Амбрумова А. Г. Возрастные аспекты суицидального поведения //Сравнительно-возрастные исследования в суицидологии (Сб. науч. тр.), под ред. В. В. Ковалева. — М., 1989. — С. 8−24."}
                    refers={respData[0]?.file} ico={ico}/>
                <FileLeftLine
                    text={"Андреева А. Д. Как помочь ребенку пережить горе. // Вопросы психологии. — 1991. — N2"}
                    refers={respData[0]?.file} ico={ico}/>
                <FileLeftLine
                    text={"Андрюхин Н. Г. Обзор российского законодательства в сфере профилактики суицида // Психологические аспекты детского суицида: технологии профилактики: сборник материалов научно-практической конференции / Сост. и науч. ред. Н. Ю. Синягина, Н. В. Зайцева, Е. Г. Артамонова. — М.: АНО ЦНПРО, 2013. — С. 5−12."}
                    refers={respData[0]?.file} ico={ico}/>
                <FileLeftLine
                    text={"Бандура, А. Подростковая агрессия. Изучение воспитания и семейных отношений. — М.: Апрель Пресс: ЭКСМО-Пресс, 2000. — 509 с."}
                    refers={respData[0]?.file} ico={ico}/>
                <FileLeftLine
                    text={"Банников Г. С., Федунина Н. Ю., Павлова Т. С., Вихристюк О. В., Летова А. В., Баженова М. Д. Ведущие механизмы самоповреждающего поведения у подростков: по материалам мониторинга в образовательных организациях // Консультативная психология и психотерапия. 2016. Том 24. № 3. С. 42−68. doi:10.17 759/cpp.2 016 240 304"}
                    refers={respData[0]?.file} ico={ico}/>
                <FileLeftLine text={"Бережковская Е. Чем и как увлекаются подростки // Школьный психолог, 2005, № 1"}
                              refers={respData[0]?.file} ico={ico}/>
                <FileLeftLine text={"Биркин А. А. Психокоррекционная работа с суицидентами. — Москва, 2005."}
                              refers={respData[0]?.file} ico={ico}/>
                <FileLeftLine
                    text={"Богачева Т.Ю. Интернет-технологии как современный ресурс в профилактике детского суицида. Психологические аспекты детского суицида: технологии профилактики: Сборник материалов международной научно-практической конференции. / Сост. и науч. ред. Н. Ю. Синягина, Н. В. Зайцева, Е. Г. Артамонова. М.: АНО «ЦНПРО», 2013. С. 26−30."}
                    refers={respData[0]?.file} ico={ico}/>
                <FileLeftLine
                    text={"Богачева Т. Ю. Анализ использования Интернет технологий в профилактике детского суицида. // Электронный журнал «Общество. Культура. Наука. Образование». — 2012. — Вып. 1"}
                    refers={respData[0]?.file} ico={ico}/>
                <FileLeftLine
                    text={"Богачева Т.Ю. Internet Security for Children and Analysis of Child Suicide Prevention by means of Internet throughout the Russian Federation // Образование личности. — 2014. — № 1.- С. 31−35."}
                    refers={respData[0]?.file} ico={ico}/>
                <FileLeftLine
                    text={"Бондарев В. Г. Суицидальное поведение у консультативных больных // Материалы XIII съезда психиатров России. М., 2000. — с. 312."}
                    refers={respData[0]?.file} ico={ico}/>
                <FileLeftLine text={"Бурлачук, Л. Ф. Психодиагностика. / Л. Ф. Бурлачук — СПб.: Питер, 2002. — 352 с."}
                              refers={respData[0]?.file} ico={ico}/>
                <FileLeftLine
                    text={"Вагин Ю. Р. Профилактика суицидального и аддиктивного поведения у подростков. — Пермь, 1999."}
                    refers={respData[0]?.file} ico={ico}/>
                <FileLeftLine
                    text={"Вассерман, Л. И. Совладание со стрессом: теория и психодиагностика: Учебно-методическое пособие] / Л. И. Вассерман, В. А. Абабков, Е. А. Трифонова, / Под науч.Ред. проф. Л. И. Вассермана — СПб.: Речь, 2010. — 192 с."}
                    refers={respData[0]?.file} ico={ico}/>
                <FileLeftLine
                    text={"Войцех В. Ф. К проблеме раннего прогноза суицидального поведения // 5-е клинические павловские чтения («Кризисные состояния. Суицидальное поведение»).- СПб., 2002. С. 13−18."}
                    refers={respData[0]?.file} ico={ico}/>
                <FileLeftLine text={"Вроно Е. М. Поймите своего ребенка. — М.: Дрофа, 2002.— 224 с."}
                              refers={respData[0]?.file} ico={ico}/>
                <FileLeftLine text={""} refers={respData[0]?.file} ico={ico}/>
                <FileLeftLine text={""} refers={respData[0]?.file} ico={ico}/>
                <FileLeftLine text={""} refers={respData[0]?.file} ico={ico}/>
                <FileLeftLine text={""} refers={respData[0]?.file} ico={ico}/>
                <FileLeftLine text={""} refers={respData[0]?.file} ico={ico}/>
                <FileLeftLine text={""} refers={respData[0]?.file} ico={ico}/>
                <FileLeftLine text={""} refers={respData[0]?.file} ico={ico}/>
                <FileLeftLine text={""} refers={respData[0]?.file} ico={ico}/>
            </div>
            <div>
                <Hrefs hrefsArr={hrefsArr}/>
            </div>
        </div>
    </>
}

export default FilesKDNRead