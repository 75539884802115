import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/news030823.jpg"

const News030823 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Определены победители викторины по финансовой грамотности
        </h2>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>По итогам проведения онлайн викторины по финансовой грамотности,
                организованной ФГБУ «Центр защиты прав и интересов детей» при поддержке Банка России в период с 2 по 30 июня 2023 г.,
                определены 10 победителей.</p>
            <p>Участие в викторине принимали дети от 12 лет, которые смогли проверить свои знания в финансовой сфере.</p>
            <p>На страницу викторины пользователи перешли 1988 раз, ответили верно на все 10 вопросов 394 участников, средний балл – 7,8 балла.</p>
            <p>По условиям викторины первым 10 участникам, правильно ответивших на все вопросы викторины,
                направлены сертификаты победителей викторины и памятные сувениры.</p>
        </div>
        <div>
            <img src={img}/>
        </div>
        <ToNews/>
    </div>
}


export default News030823;