import React from "react";
import s from "./Header.module.css"
//import logo from "../../assets/SVG/logo 2.png"
import logo from "../../assets/SVG/Logo.svg"
import gor1 from "../../assets/img/gor-lin-a1.png"
import gor2 from "../../assets/img/gor-lin-a2.png"
import gor3 from "../../assets/img/mediac.gif"
import ico1 from "../../assets/SVG/01.svg"
import ico2 from "../../assets/SVG/02.svg"
import ico22 from "../../assets/SVG/03.svg"
import ico4 from "../../assets/SVG/08.svg"
import ico3 from "../../assets/SVG/04.svg"
import ico5 from "../../assets/SVG/05.svg"
import ico6 from "../../assets/SVG/06.svg"
import help1 from "../../assets/img/RFMP.png"
import MenuOutlined from "@ant-design/icons"
import {useState} from "react";
import "./style.css";
import {NavLink} from "react-router-dom";
import HeaderMobile from "./HeaderMobile";
import {SearchOutlined, EyeOutlined} from "@ant-design/icons"
import Finder, {Search} from "./Finder/Finder";
import Icon from "@ant-design/icons";
import VKSvg from '../../assets/SVG/vk.svg';
import TgSvg from '../../assets/SVG/telegram.svg';
import address from "../../assets/img/09c1.svg"


export const HeaderBlock = ({text, img, refs = "/"}) => {
    return <>
        <div className={s.findBlock}>
            <NavLink to={refs}>
                <div>
                    <img src={img}/>
                </div>
                <div>
                    {text}
                </div>
            </NavLink>
        </div>
    </>
}

export const HeaderBlockA = ({text, img, refs = "/"}) => {
    return <>
        <div className={s.findBlock}>
            <a href={refs}>
                <div>
                    <img src={img}/>
                </div>
                <div>
                    {text}
                </div>
            </a>
        </div>
    </>
}

const Header = ({find, setFind, setStyle, setFont}) => {
    const [active, setActive] = useState(false)
    const [activeVision, setActiveVision] = useState(false)
    const [activeFind, setActiveFind] = useState(false)
    const [activeMobilePagePopup, setActiveMobilePage] = useState(false)


    return <>
        {activeVision ? <Vision setColor={setStyle} setFont={setFont} setActive={setActive}/> : <></>}
        <header className={s.rows}>
            <div className={s.minpros}>

                <div className={s.centerHeader}>

                    <div className={s.minText}>
                        <img src={help1}/>
                        <div>
                            <a href="https://edu.gov.ru/">МИНИСТЕРСТВО ПРОСВЕЩЕНИЯ РОССИЙСКОЙ ФЕДЕРАЦИИ</a>
                        </div>
                    </div>
                    {/*<div className={s.teleB}>*/}
                    {/*    <div className={s.telegram}>*/}
                    {/*        <div className={s.telHead}>Мы теперь в Телеграм!</div>*/}
                    {/*        <div className="font-style-2 txt-secondary mb-8">*/}
                    {/*            Подпишитесь, чтобы следить за новостями*/}
                    {/*        </div>*/}
                    {/*        <a href="https://t.me/fcprcgov" className={s.telButton} target="_blank">Подписаться</a>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className={s.minProsBtns}>
                        <span className={s.search} onClick={() => {
                            setActiveFind(true)
                        }}>
                            <SearchOutlined style={{fontSize: "20px", marginRight: "5px"}}/>
                            <Finder/>
                        </span>
                        <span className={s.vision} onClick={() => {
                            setActiveVision(true)
                        }}>
                            <EyeOutlined style={{fontSize: "20px", marginRight: "5px"}}/>
                            Версия для слабовидящих
                        </span>
                        <span className={s.socIcon} style={{marginLeft: "15px"}}>
                        <a href="https://t.me/fcprcgov">
                            <svg width="28px" height="28px" viewBox="13 45 120 120" fill="currentColor"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path
                                        d="M76.33 132.14L62.5 143.73L58.59 144.26L49.84 114.11L19.06 104L113.82 67.8799L118.29 67.9799L103.36 149.19L76.33 132.14ZM100.03 83.1399L56.61 109.17L61.61 130.5L62.98 130.19L68.2 113.73L102.9 83.4799L100.03 83.1399Z"
                                    ></path>
                                </g>
                            </svg>
                        </a>
                    </span>
                        <span className={s.socIcon} style={{marginLeft: "8px"}}>
                        <a href="https://ok.ru/group/70000007188014">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="800px" height="800px"
                                 viewBox="0 0 14 14" role="img" focusable="false" aria-hidden="true"><path
                                d="M 8.2624472,9.720363 C 8.8999741,9.575357 9.5089991,9.323347 10.062522,8.975332 10.479539,8.696321 10.591544,8.132297 10.312533,7.71528 10.044521,7.314263 9.5104991,7.193258 9.0949821,7.438768 c -1.2750533,0.797534 -2.8951208,0.797534 -4.170174,0 C 4.5012905,7.171757 3.9422672,7.298762 3.674756,7.72128 c 0,10e-4 0,0.002 -10e-4,0.0025 -0.2670111,0.423518 -0.1400058,0.983041 0.2835118,1.250052 l 10e-4,10e-4 c 0.552523,0.347515 1.1610483,0.600025 1.7980749,0.744031 l -1.7325722,1.732572 c -0.3535147,0.347515 -0.360015,0.915039 -0.014001,1.268553 l 0.015001,0.015 C 4.1967778,12.911996 4.4297875,13 4.6617972,13 4.8943069,13 5.1268165,12.912 5.2993237,12.734989 l 1.7105713,-1.702071 1.7020711,1.703071 c 0.360015,0.347514 0.935039,0.338014 1.283053,-0.0225 0.3390139,-0.351515 0.3390139,-0.909038 0,-1.260052 L 8.2624472,9.720363 Z M 7.009895,7.194258 C 8.7199661,7.192258 10.105024,5.8072 10.107524,4.097629 10.107524,2.390058 8.7174661,1 7.009895,1 5.3023239,1 3.9122659,2.390058 3.9122659,4.098629 3.9147659,5.8087 5.3003238,7.193758 7.009895,7.194758 Z m 0,-4.378682 c 0.7080295,10e-4 1.2815534,0.575024 1.2820534,1.282553 0,0.70803 -0.5740239,1.281553 -1.2820534,1.282554 C 6.3023655,5.379683 5.7288416,4.806659 5.7273416,4.098629 5.7283416,3.3901 6.3023655,2.816576 7.009895,2.815076 Z"/>
                            </svg>
                        </a>
                    </span>
                        <span className={s.socIcon} style={{marginLeft: "8px"}}>
                        <a href="https://vk.com/fcprcru">
                            <svg fill="currentColor" width="28px" height="28px" viewBox="-2 0 30 30" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg">
                                <title>vk</title>
                                <path
                                    d="M16.563 15.75c-0.5-0.188-0.5-0.906-0.531-1.406-0.125-1.781 0.5-4.5-0.25-5.656-0.531-0.688-3.094-0.625-4.656-0.531-0.438 0.063-0.969 0.156-1.344 0.344s-0.75 0.5-0.75 0.781c0 0.406 0.938 0.344 1.281 0.875 0.375 0.563 0.375 1.781 0.375 2.781 0 1.156-0.188 2.688-0.656 2.75-0.719 0.031-1.125-0.688-1.5-1.219-0.75-1.031-1.5-2.313-2.063-3.563-0.281-0.656-0.438-1.375-0.844-1.656-0.625-0.438-1.75-0.469-2.844-0.438-1 0.031-2.438-0.094-2.719 0.5-0.219 0.656 0.25 1.281 0.5 1.813 1.281 2.781 2.656 5.219 4.344 7.531 1.563 2.156 3.031 3.875 5.906 4.781 0.813 0.25 4.375 0.969 5.094 0 0.25-0.375 0.188-1.219 0.313-1.844s0.281-1.25 0.875-1.281c0.5-0.031 0.781 0.406 1.094 0.719 0.344 0.344 0.625 0.625 0.875 0.938 0.594 0.594 1.219 1.406 1.969 1.719 1.031 0.438 2.625 0.313 4.125 0.25 1.219-0.031 2.094-0.281 2.188-1 0.063-0.563-0.563-1.375-0.938-1.844-0.938-1.156-1.375-1.5-2.438-2.563-0.469-0.469-1.063-0.969-1.063-1.531-0.031-0.344 0.25-0.656 0.5-1 1.094-1.625 2.188-2.781 3.188-4.469 0.281-0.5 0.938-1.656 0.688-2.219-0.281-0.625-1.844-0.438-2.813-0.438-1.25 0-2.875-0.094-3.188 0.156-0.594 0.406-0.844 1.063-1.125 1.688-0.625 1.438-1.469 2.906-2.344 4-0.313 0.375-0.906 1.156-1.25 1.031z"></path>
                            </svg>
                        </a>
                    </span>
                    </div>

                </div>
            </div>
            {activeFind ? <><Search find={find} setFind={setFind}/></> : <div className={s.picture}>
                <div>
                    <NavLink to="/">
                        <img src={logo}/>
                    </NavLink>

                </div>
                <div className={s.mobileAdd} onClick={() => {
                    activeMobilePagePopup ? setActiveMobilePage(false) : setActiveMobilePage(true)
                }}>
                    <MenuOutlined style={{color: "red"}}/>menu
                </div>

                <div className={s.hotline}>
                    <div className={s.block}>
                        <img src={gor1}/>
                        <a href="/hotline1">
                            <span>
                                <b>Горячая линия </b>
                                Тестирование обучающихся
                            </span>
                        </a>
                    </div>
                    <div className={s.block}>
                        <img src={gor2}/>
                        <a href="https://frcopeca.ru/goryachaya-liniya-po-voprosam-realizatsii-zhilishhnyh-prav-detej-sirot/">
                            <span>
                                <b>Горячая линия </b>
                                Жилищные права
                            </span>
                        </a>
                    </div>
                    <div className={s.block}>
                        <img src={gor3}/>
                        <a href="/hotline3">
                            <span>
                                <b>Горячая линия </b>
                                Примирение и медиация
                            </span>
                        </a>
                    </div>
                </div>

            </div>}

            <div className={s.findBack}>
                <div className={s.finder}>
                    <div onMouseMove={() => {
                        setActive(true)
                    }} onMouseOut={() => {
                        setActive(false)
                    }} className={s.about}>

                        <img src={ico1}/>
                        <span>

            О центре 
          </span>

                        <nav
                            className={`menu ${active ? "active" : "inactive"}`}>
                            <ul>
                                <li>
                                    <NavLink to="/aboutUs">Информация</NavLink>
                                </li>
                                <li>

                                    <a href="/news">Новости</a>
                                </li>
                                <li>
                                    <a href="/structure">Структура центра</a>
                                </li>
                                <li>
                                    <NavLink to="/maindir">Ключевые направления</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/reports">Отчеты</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/files-ustav">Документы</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/gosexercise">Государственное задание</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/openshops">Открытые закупки</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/pfhd">ПФХД</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/files-income">Сведения о доходах</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/files-anticor">Противодействие коррупции</NavLink>
                                </li>
                                <li>
                                    <a target="_blank"
                                        //href="https://fcprc.ru/media/media/behavior/Доклад_об_антимонопольном_комплаенсе_в_ФГБУ_Центр_защиты_прав_и_интересов_x6rfilT.pdf">Антимонопольное
                                       href="https://fcprc.ru/antimonopolnoe-zakonodatelstvo">Антимонопольное
                                        законодательство</a>
                                </li>
                                <li>
                                    <a href="https://fcprc.ru/media/media/behavior/Приказ__39_от_29.05.2023.pdf">Политика
                                        обработки персональных данных</a>
                                </li>
                                <li>
                                    <a href="https://fcprc.ru/media/media/behavior/Приказ__38_от_29.05.2023.pdf">Положение
                                        об обработке и защите персональных данных</a>
                                </li>
                                <li>
                                    <NavLink to="/contacts">Контакты</NavLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <HeaderBlockA text="Образование" img={ico22} refs="/education"/>
                    <HeaderBlockA text="Наши эксперты" img={ico3}
                                  refs="https://fcprc.ru/media/media/behavior/Our_Experts.pdf"/>
                    <HeaderBlock text="Методические разработки" img={ico5} refs="/metodicheskie-razrabotki/"/>
                    <HeaderBlock text="Обращения граждан" img={ico6} refs="/citsendata"/>
                </div>
                {activeMobilePagePopup ?
                    <div><HeaderMobile active={activeMobilePagePopup} setActive={setActiveMobilePage}/></div> :
                    <div></div>}
            </div>

        </header>
    </>
}
const Vision = ({setFont, setColor, setActive}) => {
    return <div className={s.back}>
        <div className={s.body}>
            <div className={s.choose}>
                Размер шрифта
                <span style={{marginLeft: "15px", fontSize: "14px"}} onClick={() => setFont("14px")}>
                A
              </span>
                <span style={{marginLeft: "15px", fontSize: "18px"}} onClick={() => setFont("14px")}>
                A
              </span>
                <span style={{marginLeft: "15px", fontSize: "20px"}} onClick={() => setFont("14px")}>
                A
              </span>
            </div>
            <div className={s.choose}>
                Цвет
                <span className={s.chooseB1} style={{
                    marginLeft: "15px",
                    fontSize: "18px",
                    backgroundColor: "black !important",
                    color: "white"
                }} onClick={() => setColor("AppB")}>
                B
              </span>
                <span className={s.chooseB2} style={{marginLeft: "15px", fontSize: "18px", color: "yellow !important"}}
                      onClick={() => setColor("AppBY")}>
                B
              </span>
                <span className={s.chooseB3} style={{marginLeft: "15px", fontSize: "18px"}}
                      onClick={() => setColor("")}>
                B
              </span>
                <span className={s.chooseB4} style={{marginLeft: "15px", fontSize: "18px"}}
                      onClick={() => setColor("AppBlue")}>
                B
              </span>
            </div>
            <div className={s.choose}>
                Изображения
            </div>
            <div className={s.default} onClick={() => {
                setColor("App")
                setActive(false)
            }}>
                Обычная версия сайта
            </div>
        </div>
    </div>
}

const NewAddress = () => {
    return (
        <div className={s.newAddress}>
            <div className={s.info}>
                <img src={address} style={{maxHeight: "30px", paddingRight: "6px"}} alt=""/>
                Почтовый адрес изменен на&nbsp;<span style={{fontWeight: "normal", color: "#333333"}}> 115093, 119435, г. Москва, ул. Погодинская, д.8/1</span>
            </div>
        </div>
    )
}

export default Header
