import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/bestIncSchool.png"

const News270421 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сопровождение служб медиации СУВУ в апреле
        </h2>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            8-9 апреля в Неманском, Раифском и Санкт-Петербургском СУВУ и 15-16 апреля в Майкопском, Себежском и
            Щёкинском СУВУ прошли мероприятия по сопровождению служб медиации, состоявшие из групповых консультаций
            сотрудников служб и интерактивных занятий с обучающимися.
            <p></p>
            В течение двухдневного вебинара медиаторы ФГБУ «Центр защиты прав и интересов детей» и специалисты служб
            медиации обсуждали вопросы, связанные с организацией работы и поддержанием бесконфликтного пространства
            внутри СУВУ, а воспитанники учились работать с эмоциональным состоянием в конфликте и следовать принципам
            медиации при разборе тематических кейсов и в игровом взаимодействии.
            <p></p>
            Занятия прошли в продуктивной доброжелательной атмосфере
        </div>
        <ToNews/>
    </div>
}


export default News270421;