import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import logo from "../../../assets/Behavior/infosec.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {NavLink} from "react-router-dom";
import {DownOutlined} from "@ant-design/icons"
import st from "./PageInfo.module.css";


const hrefsArr = [
    {text: "Нормативная правовые-материалы", to: "/kdnRights", input: []},
    {text: "Методологические материалы для специалистов", to: "/kdnDev"},
    {text: "Информационные материалы для родителей", to: "/spec-materials-category/informatsionnye-materialy-dlya-roditelej"},
    {text: "Конкурсы и проекты", to: "/kdnGos"},
    {text: "Советуем прочитать", to: "/behRead"},
    {text: "Полезные ссылки", to: "/spec-materials-category/poleznye-ssylki-goryachie-linii"},
    {text: "Получить помощь, консультацию"},
]
const PagePriceLifeInfSec = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2>
                    Информационная безопасность
                </h2>
            </div>

            <div>
                <img src={logo}/>
            </div>
            <p>
                <p>
                    <h2 style={{fontSize: "24px", fontFamily: "Proxima Nova Lt", color: "#3a4663"}}>
                        Сопровождение детей и подростков в интернет-пространстве
                    </h2>
                </p>
                <p style={{lineHeight: "1.5"}}>Жизнь современного ребенка невозможно представить без интернета. Интернет используется как средство
                    обучения и познания окружающего мира, как способ общения и взаимодействия, инструмент адаптации и
                    самореализации, и т. п. Современный школьник все больше времени проводит в Сети. Наряду с
                    предоставлением широчайших возможностей для познавательного и социального развития школьников все
                    более
                    широкое использование Сети Интернет влечет за собой рост рисков для эмоционального благополучия,
                    психологического здоровья и социальной адаптации обучающихся.</p>
                <p style={{lineHeight: "1.5"}}>Наряду с угрозами развития Интернет-зависимости, увлеченности детей играми в интернете, сегодня
                    набирают
                    обороты новые угрозы, свидетельствующие о целенаправленном разрушающем воздействии на незрелую
                    психику
                    школьников: это — вовлечение в экстремистские и иные опасные сообщества и группы, пропагандирующие
                    агрессию, деструктивное поведение, и представляющие угрозу социальной адаптации и даже жизни детей и
                    подростков. Высокие темпы распространения интернет-рисков и тревожные тенденции влияния
                    интернет-активности на социализацию обучающихся показывают необходимость специальной
                    психолого-педагогической помощи детям и подросткам в их преодолении и актуализируют проблему
                    организации
                    профилактической работы в данном направлении.</p>
            </p>

        </div>
        <div>
            <p></p>
            <Hrefs/>
            <WhiteButton text="Ресурсы профилактики суицида" to="/suicide"/>
            <WhiteButton text="Ценность жизни — обеспечение вопросов детской безопасности" to="/value-of-life"/>
            <WhiteButton text="Твой кибермаршрут" to="/secWay"/>
        </div>


    </div>


}

export const Hrefs = ({hrefs}) => {
    return <div className={s.hrefs}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} href={el.href} to={el.to}/>

            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, href, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({text, stylesheet}) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({text, stylesheet, to}) => {
    return <div className={st.whitebutton}>
        <NavLink to={to}>

            {text}

        </NavLink>
        <div>
            <ArrowRightOutlined/>

        </div>
    </div>
}

export default PagePriceLifeInfSec