const ADD_COMMENT = 'ADD-COMMENT'
const UPDATE_COMMENT_TEXT = 'UPDATE-COMMENT-TEXT'

let initialState = {
    img: null,
    text: "null", 
    children: null
}


export const actions = {
    addCommentsActioncreator: (data) => ( {type: ADD_COMMENT, data  } )
}


const blankReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_COMMENT: {       
            let stateCopy = { ...state};
            stateCopy.img = action.data.img;
            stateCopy.text = action.data.text;
            stateCopy.children = action.data.children;           
            debugger  
            return stateCopy;
        }
        
        default:
            return state;
    }

}

export const setBlankPageData = (pageData) => (dispatch) => {
    debugger
    let {img, text, children} = pageData
    dispatch(actions.addCommentsActioncreator(img, text, children))
}



export default blankReducer;