import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img1 from "../../assets/News/ParentResources.jpg";
import YouTube from "react-youtube";

const ParentResources = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: Ресурсы родителя
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <p>
                28 апреля 2020 года в рамках работы Московского международного салона образования – 2020 состоялся
                вебинар, посвященный актуальной теме родительских ресурсов. Ведущий вебинара, Горнова Татьяна Сергеевна,
                аналитик ФГБУ «Центр защиты прав и интересов детей», кандидат педагогических наук, в своём выступлении
                раскрыла механизмы восстановления ресурсов родителей. Коснулась не менее важного аспекта – влияние
                дефицита ресурса родителя на развитие ребенка. В работе вебинара приняли участие 772 слушателя из 56
                субъектов Российской Федерации.
            </p>
            <p>Ссылка на вебинар: <a href="https://www.youtube.com/watch?v=wFaP68oOUIk">https://www.youtube.com/watch?v=wFaP68oOUIk</a></p>
            <p></p>
        </div>
    </div>
}


export default ParentResources