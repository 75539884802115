import React from "react";
import ico from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1} from "../PageInfo/PageInfo"
import Falldown, {Falldown2, FileLi, HrefLi} from "../FalldownList/Falldown";
import {FileLeftLineKDN} from "./FilesKDNNews";

const hrefsArr = [
    {text: "Регламентирующие документы", to: "/regDocks"},
    {text: "Методическое сопровождение", to: "/met"},
    {text: "Часто задаваемые вопросы", to: "/sptfaq"},
]
const MetWay = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/aboutus").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <div className={s.newHeader}>
        <div className={s.filesLeftPage}>
            <div>
                <h2>
                    Регламентирующие документы
                </h2>
                <FileLeftLineKDN text={respData[150]?.description} refers={respData[140]?.file} ico={ico}/>
            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArr}/>
            </div>
        </div>
    </div>
}
export default MetWay