import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/Putin2406.jpg"

const Putin2406 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Президент Российской Федерации Владимир Путин выступил с обращением к гражданам России
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Обращение к гражданам России.</p>
            <p>Обращаюсь к гражданам России, к личному составу Вооружённых Сил, правоохранительных органов и специальных
                служб, к бойцам и командирам, которые сейчас сражаются на своих боевых позициях, отбивают атаки
                противника, делают это героически – знаю, сегодня ещё раз ночью говорил с командующими всех направлений.
                Обращаюсь и к тем, кого обманом или угрозами втянули в преступную авантюру, толкнули на путь тяжкого
                преступления – вооружённого мятежа.</p>
            <p>Россия сегодня ведёт тяжелейшую борьбу за своё будущее, отражает агрессию неонацистов и их хозяев. Против
                нас направлена фактически вся военная, экономическая, информационная машина Запада. Мы боремся за жизнь
                и безопасность наших людей, за наш суверенитет и независимость. За право быть и оставаться Россией –
                государством с тысячелетней историей.</p>
            <p>Эта битва, когда решается судьба нашего народа, требует единения всех сил, единства, консолидации и
                ответственности. Когда в сторону должно быть отброшено всё, что ослабляет нас, любые распри, которыми
                могут воспользоваться и пользуются наши внешние враги, чтобы подорвать нас изнутри.</p>
            <p>И потому действия, которые раскалывают наше единство, – это, по сути, отступничество от своего народа, от
                боевых товарищей, которые сражаются сейчас на фронте. Это удар в спину нашей стране и нашему народу.</p>
            <p>Именно такой удар был нанесён по России в 1917 году, когда страна вела Первую мировую войну. Но победу у
                неё украли. Интриги, склоки, политиканство за спиной армии и народа обернулись величайшим потрясением,
                разрушением армии и распадом государства, утратой громадных территорий. В итоге – трагедией гражданской
                войны.</p>
            <p>Русские убивали русских, братья – братьев, а корыстную выгоду извлекали разного рода политические
                авантюристы да зарубежные силы, которые делили страну, рвали её на части.</p>
            <p>Мы не дадим этому повториться. Защитим и наш народ, и нашу государственность от любых угроз. В том числе
                – от внутреннего предательства.</p>
            <p>А то, с чем мы столкнулись – это именно предательство. Непомерные амбиции и личные интересы привели к
                измене. К измене и своей стране, и своему народу, и тому делу, за которое бок о бок с другими нашими
                частями и подразделениями сражались и погибали бойцы и командиры группы «Вагнер». Герои, которые
                освобождали Соледар и Артёмовск, города и посёлки Донбасса, сражались и отдавали свои жизни за
                Новороссию, за единство русского мира. Их имя и славу тоже предали те, кто пытается организовать мятеж,
                толкает страну к анархии и братоубийству. К поражению, в конечном итоге, и капитуляции.</p>
            <p>Повторю, любая внутренняя смута – смертельная угроза для нашей государственности, для нас, как нации. Это
                удар по России, по нашему народу. И наши действия по защите Отечества от такой угрозы будут жёсткие.
                Все, кто сознательно встал на путь предательства, кто готовил вооружённый мятеж, встал на путь шантажа и
                террористических методов, понесут неминуемое наказание, ответят и перед законом, и перед нашим
                народом.</p>
            <p>Вооружённые Силы и другие государственные органы получили необходимые приказы, дополнительные меры
                антитеррористического характера вводятся сейчас в Москве, Московской области, ряде других регионов.
                Будут также предприняты решительные действия по стабилизации ситуации в Ростове-на-Дону. Она остаётся
                сложной, фактически заблокирована работа органов гражданского и военного управления.</p>
            <p>Как Президент России и Верховный Главнокомандующий, как гражданин России сделаю всё, чтобы отстоять
                страну, защитить конституционный строй, жизни, безопасность и свободу граждан.</p>
            <p>Тот, кто организовал и готовил военный мятеж, кто поднял оружие на боевых товарищей, – предали Россию. И
                ответят за это. А тех, кого пытаются втянуть в это преступление, призываю не совершать роковую и
                трагическую, неповторимую ошибку, сделать единственно правильный выбор – прекратить участие в преступных
                действиях.</p>
            <p>Верю, что мы сбережём и отстоим то, что дорого и свято для нас, и вместе с нашей Родиной мы одолеем любые
                испытания, станем ещё сильнее.</p>
        </div>
        <iframe style={{margin: "0 auto"}} src="https://vk.com/video_ext.php?oid=-30558759&id=456245442&hash=b7709fc761f429c8&hd=1" width="640"
                height="360" allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameBorder="0"
                allowFullScreen></iframe>
        <ToNews/>
    </div>
}




export default Putin2406;