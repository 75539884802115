import React from "react";
import s from "./Default.module.css"
import ToNews from "../News/ToNews";
import img from "../../assets/News/News260924.jpg"
import img1 from "../../assets/News/News260924_1.jpg"

const News260924 = ({children, header}) => {

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Какими компетенциями и личностными качествами должны обладать специалисты, занимающиеся подростками,
            обсудили на круглом столе.
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            24 сентября команда Федерального подросткового центра приняла участие в круглом столе по теме: «Исследование
            личностно-профессионального портрета специалиста, работающего в сфере социализации подростков: от
            обоснованной теории к модели компетенций». На мероприятии также выступили специалисты социальной сферы и
            образования в области организации воспитательной и профилактической работы с подростками и молодежью,
            представители высших и средних профессиональных педагогических учреждений, занимающихся подготовкой
            специалистов, работающих с подростками и молодежью в социальной сфере и образовании.
            <p></p>
            Своими наблюдениями поделились представители проектов: «Россия – страна возможностей», «Росдетцентр»,
            «Хорошевская школа» и «Городской психолого-педагогический центр Департамента образования г. Москвы».
            <p></p>
            Во время круглого стола был поднят ряд важных вопросов:
            <ul>
                <li>Какими компетенциями и личностными качествами должны обладать специалисты, занимающиеся
                    подростками;
                </li>
                <li>Как будет происходить подготовка специалистов для подростковых центров и уличных служб;</li>
                <li>Какие компетенции должен иметь специалист для работы с девиантным поведением подростков;</li>
                <li>Карьерные возможности молодёжи, доступность к обучающим курсам и сотрудничество с сайтом hh.ru.</li>
                <li>Какие ценности необходимо воспитывать в молодёжи, для последующей эффективности в работе;</li>
                <li>Как взаимодействовать с подрастающим поколением, чтобы стать им другом и наставником;</li>
                <li>Возможно ли ввести в стране Единую воспитательную экосистему;</li>
                <li>Как сделать родительские чаты эффективными и внедрить в них фандрайзинг;</li>
                <li>Что такое Госпаблики и какова их роль;</li>
                <li>Как помогут конкурсные гранты в поддержке новых молодёжных проектов;</li>
                <li>Важность цифровых компетенций среди специалистов;</li>
                <li>Необходимость владения современными технологиями для организации развивающей среды и совместной
                    деятельности с несовершеннолетними.
                </li>

            </ul>
            Старший координатор направления по взаимодействию с образовательными организациями АНО «Россия – страна
            возможностей» Андрей Кайсин отметил, что исследования профиля надпрофессиональных компетенций обучающихся
            студентов, свидетельствуют о прогрессивном развитии навыков, необходимых для успешной профессиональной
            деятельности. Это подчеркивает важность практического обучения и активного вовлечения студентов в будущую
            профессию на протяжении всего периода обучения.
            <p></p>
            В завершении мероприятия участники пришли к выводу, что ключевую роль в работе с подростками играют
            личностные качества специалиста, что приведёт к возможной смене парадигмы в области образования и
            профессиональной подготовки специалистов по работе с несовершеннолетними. Важными характеристиками портрета
            специалиста являются: коммуникативная компетентность, эмоциональный интеллект, современные подходы к работе
            в организации профилактической работы и знание основ возрастной психологии. Тем не менее, именно
            человеческое ядро - основа для эффективной работы с подростками.
        </div>
        <div>
            <img src={img1}/>
        </div>
        <ToNews/>
    </div>
}


export default News260924;