import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News08022022.jpg"
import YouTube from "react-youtube";

const News08022022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Поздравление Министра просвещения Российской Федерации Сергея Кравцова с Днём российской науки
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Сегодня мы отмечаем День российской науки – праздник, который объединяет наше настоящее и будущее.
                Наука давно вышла за пределы исследовательских институтов, перестала быть исключительно «взрослой
                работой», она молодеет день ото дня.</p>
            <p>Её развитие – дело не только серьёзных учёных, но и их юных помощников, современных школьников и
                студентов. Мы видим, какой интерес ребята проявляют к научной деятельности, как они совершают первые, но
                очень важные открытия, как стремятся учиться и развивать свои знания.</p>
            <p>
                Всё это происходит и благодаря фундаментальной российской научной школе, и благодаря тем перспективам,
                которые сегодня открываются перед молодыми людьми.
            </p>
            <p>
                В их распоряжении современные детские технопарки, центры «IT-куб» и «Точка роста». Школьники из
                отдалённых районов получают возможность заниматься в мобильных «Кванториумах», их обучение курируют
                ведущие специалисты технопарков.
            </p>
            <p>
                Достижения разных наук позволяют использовать передовые технологии и на уроках. Цифровая образовательная
                среда расширяет границы образовательного процесса: наглядные интерактивные занятия, видеоматериалы,
                электронные библиотеки – всё это есть в арсенале современных педагогов и школьников.
            </p>
            <p>
                Поддержка и развитие талантов – один из приоритетов нашей работы. Мы гордимся каждым достижением юных учёных и исследователей, видим, какую поддержку оказывают им взрослые коллеги.
                Многое зависит и от вас, дорогие педагоги. Вы не только даёте знания, но и вдохновляете ребят, помогаете им раскрыть свои способности, поддерживаете их во всех начинаниях.
                Желаю всем вам новых открытий и успехов!
            </p>
            <p>
                Министр просвещения Сергей Кравцов
            </p>
            <YouTube videoId="7jyAVIQbsws" style={{width: "100%"}}/>
        </div>
        <ToNews/>
    </div>
}


export default News08022022;