import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/FestivalOfPractitionersMasterForum.jpg"

const FestivalOfPractitionersMasterForum = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Фестиваль практиков социальных театров «Мастер форум»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>6-7 декабря 2022 года в городе Калуга при поддержке Министерства Культуры Российской Федерации и
                Правительства Калужской области планируется проведение всероссийского Фестиваля практиков социальных
                театров «Мастер форум». Данное мероприятие проводится в рамках реализуемой Уполномоченным при Президенте
                Российской Федерации по правам ребёнка стратегической программы «Подростки России», поддержанной
                Президентом Российской Федерации Путиным В.В. и направленной на развитие системы работы с подростками, в
                том числе «группы риска».
            </p>
            <p>Ознакомиться с информацией о фестивале можно по <a href="https://fcprc.ru/wp-content/uploads/2022/11/Informatsionnaya-spravka-Forum-Kaluga-teatry.docx">ссылке</a></p>
        </div>
        <ToNews/>
    </div>
}


export default FestivalOfPractitionersMasterForum;