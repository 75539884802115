import { Form, Formik, Field } from "formik";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import s from "./Finder.module.css"
import axios from "axios";
import { ArrowRightOutlined } from "@ant-design/icons";


const FindPage = ({res}) => {
    const [newsData, setNews] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/main").then(
            res => {
                let data = res.data.reverse()
                setNews(res.data)
                
            }
        )
    }, [])
    return<div>

<h2 style={{fontWeight: "normal", marginTop:"50px", color: "#3a4663", textAlign:"left", maxWidth:"1100px", margin:"40px auto", fontFamily:"Proxima Nova Lt"}}>
       Результат поиска
        </h2>
        <div>
            {newsData.map(el => <div className={s.blockFind}>
                <a href={el.file}>
                    {el.description}
                </a>
                <div className={s.resBtn}>
                    Подробнее <ArrowRightOutlined style={{color: "red", paddingLeft:"15px"}}/>
                </div>
            </div>)}
        </div>
    </div>
}
export default FindPage