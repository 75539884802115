import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/SupportMediationSEI2022.jpg"
import img2 from "../../assets/News/WPRS2022.jpg"

const WPRS2022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинары для родителей обучающихся специальных учебно-воспитательных учреждений в 2022 году
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>15 и 16 декабря состоялись пилотные вебинары «Медиативные технологии как способ договориться с ребенком»
                для родителей обучающихся специальных учебно-воспитательных учреждений, подведомственных Минпросвещения
                России (СУВУ).</p>
            <p>Вебинары провели сотрудники отдела медиации ФГБУ «Центр защиты прав и интересов детей».</p>
            <p>Вебинары включали в себя базовые знания по медиации и медиативным технологиям, а также возможности их
                применения во взаимоотношениях с ребенком.</p>
            <p>Ведущие вебинара вместе с участниками рассмотрели причины конфликтов, возможные их последствия и
                различные способы разрешения спорных ситуаций.</p>
            <p>Центральной частью вебинаров был практический разбор конфликтной ситуации между родителем и ребенком из
                позиции медиативного подхода.</p>
            <p>Родители отметили важность темы и написали впечатления и комментарии, вот некоторые из них:</p>
            <p>«Большое вам спасибо за такой вебинар, было бы хорошо, если бы это было чаще»;</p>
            <p>«Вебинар оставил только положительные эмоции. Классно! Восторг, восхищение Вашей работой!»;</p>
            <p>«Все прослушала, было интересно очень, много полезной информации. Спасибо большое за такой вебинар»;</p>
            <p>«Спасибо вам за ваш труд и работу с нашими детьми»;</p>
            <p>«Узнала о медиации благодаря вам, если возникнут какие-либо трудности, могу обратиться к вам! Спасибо! И
                ещё бы вебинары…».</p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <ToNews/>
    </div>
}


export default WPRS2022;