import React from "react";
import ico from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {hrefsArrKDN} from "./FilesKDNGos";
import {FileLeftLineKDN, HrefsKDN} from "./FilesKDNNews";

const hrefsArr = [
    {text: "Новости и события", to: "/kdnnews"},
    {text: "Мероприятия", input: [], to: "/kdnivents"},
    {text: "О КДНиЗП", input: [], to: "/kdnHistory"},
    {text: "Правительственная КДНиЗП", to: "/kdnGos"},
    {text: "Методологические материалы", to: "/kdnDev"},
    {text: "Нормативная правовая база", to: "/kdnRights"},
    {text: "Всероссийские совещания КДНиЗП", input: ["2021 год", "Контакты"], to: "/kdnInternational"},
    {text: "Контакты", to: "/kdnContacts"},
    {text: "Актуальные интернет-ресурсы по вопросам профилактики", to: "/kdnActiveQ"},
    {text: "Реестр НКО", to: "/kdnReestr"},
    {text: "Сведения по форме ФСН №1-КДН", to: "/kdnInfoForm"},

]
const FilesKDNRights = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/kdn").then(
            res => {
                setData(res.data)
            })

    }, [])
    console.log(respData)
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Нормативная правовая база
                </h2>
                <div>
                    <FileLeftLineKDN text={respData[80]?.description} refers={respData[80]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[45]?.description} refers={respData[45]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[81]?.description} refers={respData[81]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[82]?.description} refers={respData[82]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[83]?.description} refers={respData[83]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[84]?.description} refers={respData[84]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[85]?.description} refers={respData[85]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[86]?.description} refers={respData[86]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[87]?.description} refers={respData[87]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[46]?.description} refers={respData[46]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[48]?.description} refers={respData[48]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[49]?.description} refers={respData[49]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[65]?.description} refers={respData[65]?.file} ico={ico}/>
                </div>
            </div>
            <div style={{marginTop: "-3px"}}>
                <HrefsKDN hrefsArr={hrefsArrKDN}/>
            </div>
        </div>
    </>
}
export default FilesKDNRights