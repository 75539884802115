import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img from "../../assets/News/news12.05.png"
import ToNews from "../News/ToNews";
import {NavLink} from "react-router-dom";

const WinnerProtocol = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Определены победители в первой номинации Всероссийского конкурса программ социализации подростков
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p></p>


            Подведены итоги в первой номинации Всероссийского конкурса программ социализации подростков. Экспертный
            совет завершил оценку конкурсных материалов по направлению «Социализация через патриотизм, семейные ценности
            и духовно-нравственное воспитание» и определил 15 программ-победителей.

            <p></p>
            Отбор проходил в два этапа: на первом эксперты рассмотрели все поступившие заявки на участие, на втором
            состоялись очные собеседования с самыми яркими командами в формате видеовстреч.

            <p></p>

            Победители в номинации «Социализация через патриотизм, семейные ценности и духовно-нравственное воспитание»:
            <p></p>
            <strong>
                Подростковый досуговый центр Муниципального автономного учреждения дополнительного образования Центра
                детского творчества города Фурманова «#ТЫвЦЕНТРЕ»
            </strong>
            <p></p>
            Программа «Программа экспериментального вариативного пространства поддержки и социализации подростков на
            территории Фурмановского муниципального района Ивановской области "#ТЫвЦЕНТРЕ"» (Ивановская область, г.
            Фурманов)


            <p></p>
            <strong>

                Муниципальное автономное образовательное учреждение дополнительного образования «Центр детского
                творчества»
            </strong>
            <div></div>
            Программа «ПодРОСТок» (Еврейская автономная область, г. Биробиджан)

            <p></p>
            <strong>

                Центр военно-патриотического, духовно-нравственного, культурно-эстетического и спортивного воспитания
                подростков РО ДОСААФ России Курской области
            </strong>
            <div></div>
            Программа «Боевая смена ДОСААФ» (Курская область, г. Курск),


            <p></p>
            <strong>

                Муниципальное бюджетное образовательное учреждение дополнительного образования «Детский (подростковый)
                центр» городского округа «Город Якутск»
            </strong>
            <div></div>
            Программа «Креативное пространство социализации подростков “УРАСА”» на территории городского округа «город
            Якутск» (Республика Саха (Якутия), г. Якутск)


            <p></p>
            <strong>

                Муниципальное бюджетное учреждение «Новгородский молодежный центр»
            </strong>
            <div></div>
            Программа «КухнЯ (Когда Уютно ХулигаНЯм)» (Новгородская область, г. Великий Новгород)

            <p></p>
            <strong>

                Муниципальное автономное учреждение дополнительного образования «Детско-юношеский центр "Спутник"»
            </strong>
            <div></div>

            Программа «Программа оборонно-спортивного лагеря «Академия мужества» (Свердловская область, г. Екатеринбург)


            <p></p>
            <strong>

                Муниципальное бюджетное многопрофильное учреждение досуга для молодежи Молодежный центр «Горизонт»
            </strong>
            <div></div>
            Программа «Креативное пространство социализации подростков на базе МБМУ ДМ МЦ «Горизонт» «Я вижу — Я живу»
            (Московская область, г. Коломна)

            <p></p>
            <strong>

                Центр «Меркурия» для подростков в трудной жизненной ситуации
            </strong>
            <div></div>
            Программа «Программа социализации подростков в трудной жизненной ситуации на базе подросткового центра
            "Меркурия"» (Томская область, г. Томск)


            <p></p>
            <strong>

                Муниципальное бюджетное учреждение дополнительного образования детей «Дом детского творчества №4 города
                Орла» "Детские (подростковые клубы) по месту жительства (подростковые центры) «Ровесник», «Мечта»
            </strong>
            <div></div>
            Программа «Программа воспитания и социализации "МОДЕЛИРУЕМ БУДУЩЕЕ"» (Орловская область, г. Орел)


            <p></p>
            <strong>

                Муниципальное бюджетное образовательное учреждение дополнительного образования «Детско-подростковый
                центр "Содружество"»
            </strong>
            <div></div>
            Программа «Программа социализации подростков "АРТЕЛЬ"» (Калужская область, г. Калуга)


            <p></p>
            <strong>

                Центр подростков и молодежи "КОСМОС"
            </strong>
            <div></div>
            Программа «Программа социализации подростков "Я-космос"» (Челябинская область, г. Чебаркуль)


            <p></p>
            <strong>

                Муниципальное бюджетное учреждение «Молодежный центр "ГОР.СОМ 35"»
            </strong>
            <div></div>
            Программа «Муниципальный штаб "Волонтеры Победы"» (Вологодская область, г. Вологда)


            <p></p>
            <strong>

                Подростковое пространство «Заходи»
            </strong>
            <div></div>
            Программа «Программа социализации подростков на территории Каргопольского муниципального округа "ЗАХОДИ"»
            (Архангельская область, г. Каргополь)


            <p></p>
            <strong>

                Подростковый центр «Контакт»
            </strong>
            <div></div>
            Программа «Программа «Пульс времени» (инициативная патриотическая группа) на базе подросткового центра
            «Контакт» муниципального бюджетного учреждения "Центр развития молодежных инициатив"» (Астраханская область,
            г. Астрахань)


            <p></p>
            <strong>

                Свободное пространство для подростков «при_ДЕЛЕ»
            </strong>
            <div></div>
            Программа «Неоконченное путешествие "Остров бывшей каторги"» (Сахалинская область, г. Невельск).

            <p></p>
            Мы поздравляем победителей и приглашаем принять участие в следующей номинации конкурса «Социализация через
            трудовую занятость, искусство и спорт». Прием заявок и материалов начнется 15 мая.

            <p></p>
            <a href={"https://fcprc.ru/media/media/behavior/протокол_победители.pdf"} target="_blank">
                Протокол заседания Организационного комитета Всероссийского конкурса программ социализации подростков
            </a>

        </div>
        <ToNews/>
    </div>
}


export default WinnerProtocol