import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/TheWayOfHooligan.png"

const TheWayOfHooligan = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Круглый стол «Межведомственное взаимодействие и межотраслевое партнерство в рамках организации работы с
            несовершеннолетними в конфликте с законом. Проект “Путь хулигана”»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>20 апреля состоится круглый стол на тему «Межведомственное взаимодействие и межотраслевое партнерство в
                рамках организации работы с несовершеннолетними в конфликте с законом. Проект “Путь хулигана”».</p>
            <p>Организатор круглого стола — Федеральный подростковый центр. В мероприятии примут участие представители
                Минпросвещения России, Уполномоченного при Президенте РФ по правам ребенка, Следственного комитета РФ,
                ФСИН России, региональных органов исполнительной власти, научного сообщества и общественных
                организаций.</p>
            <p>В рамках круглого стола состоится презентация проекта «Путь хулигана», экспертное обсуждение проекта и
                эффективных программ социализации подростков, совершивших правонарушение.</p>
            <p>Мероприятие пройдет на площадке ФГБУ «Центр защиты прав и интересов детей» Минпросвещения России по
                адресу: г. Москва, ул. Нижняя Красносельская, д. 5, стр. 4, в очном и <a
                    href="https://events.webinar.ru/48429317/1594269953">дистанционном формате</a> с
                11.00 до
                13.00 (по московскому времени).</p>
        </div>
        <ToNews/>
    </div>
}


export default TheWayOfHooligan;