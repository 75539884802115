import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News08072022.jpg"

const News08072022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вниманию специалистов! Разработаны рекомендации для родителей и памятки для подростков, направленные на
            обеспечение безопасности в детско-подростковой среде
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>
                Специалистами отдела профилактики девиантного поведения Центра защиты прав и интересов разработаны
                рекомендации для родителей «Формула безопасного детства» и памятка для подростков «Формула твой
                безопасности», направленные на предотвращение вовлечения несовершеннолетних в зависимое (аддиктивное)
                поведение, профилактику рискованного, деструктивного и аутодеструктивного поведения.</p>
            <p>Материалы разработаны в целях расширения педагогической компетентности родителей (законных
                представителей), содействия формированию благополучных взаимоотношений в семье и направлены на поддержку
                сохранения благополучных и доверительных детско-родительских отношений в период взросления.</p>
            <p>Приложением к материалам служат памятки для детей и родителей, формат которых позволяет использовать их
                при организации профилактической деятельности для рассылки в информационных мессенджерах.</p>
            <p>Подготовленные материалы размещены на сайте Центра в разделе <a
                href="https://fcprc.ru/materials-category/informatsionno-metodicheskie-materialy-dlya-roditelej/">«Методические
                материалы»</a></p>
        </div>
        <ToNews/>
    </div>
}


export default News08072022;