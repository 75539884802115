import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/bestIncSchool.png"

const News280624 = ({children, header}) => {

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Правительственную комиссию по делам несовершеннолетних и защите их прав возглавил Заместитель Председателя
            Правительства Российской Федерации Д.Н. Чернышенко
        </h2>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Заместитель Председателя Правительства Российской Федерации Дмитрий Николаевич Чернышенко назначен
            председателем Правительственной комиссии по делам несовершеннолетних и защите их прав вместо вице-премьера
            Татьяны Алексеевны Голиковой. Соответствующее распоряжение подписано Председателем Правительства Российской
            Федерации Михаилом Владимировичем Мишустиным 25 июня 2024 года № 1632-р
            <p></p>
            <strong><a href="https://fcprc.ru/media/media/behavior/Состав_ПКДН_и_ЗП__на_25_июня_2024_г.docx">Состав ПКДН и ЗП</a></strong>
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News280624;