import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News020424.png"

const News020424 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сборник работ победителей Всероссийского Конкурса социальной рекламы «Стиль жизни — здоровье! 2023»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            ФГБУ «Центр защиты прав и интересов детей» подготовил сборник работ победителей Всероссийского Конкурса
            социальной рекламы в области формирования культуры здорового и безопасного образа жизни «Стиль жизни —
            здоровье! 2023».
            <p></p>
            Победителями стали ребята из разных уголков нашей страны от Камчатки до Калининграда! <a
            href="https://fcprc.ru/media/media/mediacia/Каталог_работ_победителей_Конкурса_Стиль_жизни_-_здоровье_2023.pdf"><strong>Предлагаем
            ознакомиться с их творческими проектами.</strong></a>
            <p></p>
            Поздравляем победителей! Благодарим всех участников, а также педагогов и родителей за помощь и поддержку в
            подготовке конкурсных работ!
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News020424;