import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News280524.jpg"

const News280524 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Онлайн-встреча ко Дню защиты детей «Медиативная беседа: дети для взрослых» 31 мая 2024 года
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Приближается День защиты детей, в честь чего отдел медиации ФГБУ «Центр защиты прав и интересов детей»
            проводит онлайн-встречу «Медиативная беседа: дети для взрослых».
            <p></p>
            На встрече мы расскажем Вам об одном из форматов медиативной игры, который проводился нашим отделом медиации
            для обучающихся специальных учебно-воспитательных учреждений, и сами дети выступят в качестве экспертов и
            расскажут, каково им было участвовать в подобных мероприятиях и почему важно развивать коммуникативные
            навыки.
            <p></p>
            31 мая в 11:30 (мск)
            <p></p>

            Зарегистрироваться и подключиться можно по ссылке:<br/>
            <a style={{color: "blue"}} href="https://my.mts-link.ru/j/46737693/762279599">https://my.mts-link.ru/j/46737693/762279599</a>
            <p></p>
            Также мы поделимся с Вами шаблоном медиативной игры, который вы сможете заполнить и который поможет вам
            сконструировать своё занятие.
            <p></p>
            Встреча будет интересна для педагогов-психологов, учителей, специалистов и волонтеров служб медиации и
            примирения, советников по воспитанию, родителей.
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News280524;