import React from "react";
import s from "./News.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import arr from "../../assets/arrow.svg"

const WebsBlock = ({children, img, header}) => {
    const [newsData, setNews] = useState([])
    useEffect(() => {

        axios.get("https://fcprc.ru/api/webinars").then(
            res => {
                let data = res.data.reverse()
                setNews(data)
            }
        )
    }, [])
    return <div className={s.newsPage}>
        <div className={s.newHeader}>
            <h2>
                Вебинары, мастер-классы
            </h2>
        </div>
        <div className={s.newsList}>
            {newsData ? newsData.map(el =>
                <NewsSquare img={el.img} header={el.header} refs={el.ref}/>
            ) : <div></div>}
        </div>

    </div>
}

const NewsSquare = ({img = "", header = "", text, refs = "/"}) => {
    return <div className={s.one}>
        <div>
            <div className={s.icon}>
                <a href={refs}>
                    <img src={img}/>
                </a>
            </div>
        </div>
        <div style={{overflow: "hidden", height: "43px", marginTop: "15px"}}>
            <a href={refs}>
                {header}
            </a>
        </div>
        <a href={refs}>
            <button className={s.subBtn}>
                Читать далее <img src={arr} style={{width: "20px"}}/>
            </button>
        </a>
        <p></p>
    </div>
}


export default WebsBlock