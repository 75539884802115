import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News2905242.jpg"

const News2905242 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            III Международный фестиваль «Медиация. Молодость. Будущее!» 7 июня 2024
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            7 июня 2024 года пройдёт III Международный фестиваль «Медиация. Молодость. Будущее!», организованный по
            инициативе института непрерывного образования ГАОУ ВО «Московского городского педагогического университета»
            и поддержке членов Экспертного совета по развитию служб медиации и примирения в образовательных
            организациях, ФГБУ «Центр защиты прав и интересов детей», аппаратов Уполномоченных по правам ребёнка в
            городе Москве и Московской области
            <p></p>
            Фестиваль нацелен на формирование профессионального сообщества, объединяющего медиаторов и специалистов
            служб медиации и примирения, популяризация гуманистических идей и технологий медиации и примирения в
            современном обществе.
            <p></p>
            В фестивале примут участие сотрудники отдела медиации Центра в качестве экспертов и ведущих мастер-классов.
            <p></p>
            Почётными гостями фестиваля станут:
            <ul>
                <li>Гусев Борис Борисович, директор ФГБУ «Центр защиты прав и интересов детей»</li>

                <li>Бельская Ирина Александровна, кандидат юридических наук, доцент, учредитель, управляющей партнер, член
                экспертного Совета и медиатор Центра управления бизнес-конфликтом «Мост», член Высшего экспертного
                совета
                кафедры «Медиация» Академии социальных технологий, учредитель и член Правления международного Клуба
                    независимых медиаторов. г. Минск, Республика Беларусь</li>

                <li>Ярославская Ольга Владимировна, Уполномоченный по правам ребёнка в городе Москве</li>
            </ul>
            На Фестивале будут:
            <ul>
                <li>
                    презентация опыта в области успешных практик обучения взрослых медиативно-восстановительным
                    технологиям
                </li>
                <li>
                    популяризация профессии медиатора среди молодёжи
                </li>
                <li>
                    проведение авторских мастер-классов
                </li>
            </ul>
            К участию приглашаются медиаторы, координаторы, руководители служб медиации и примирения в образовательных
            организациях, и организациях социальной сферы, команды юных волонтёров и медиаторов, а также все, кому
            интересна тема медиации и примирения.
            <p></p>
            Адрес проведения очной части Фестиваля: г. Москва, ул. Садовая-Самотечная, д. 8., ГАОУ ВО г. Москвы
            «Московский городской педагогический университет» (МГПУ)
            <p></p>
            Время: с 11.00 по 16.00 (мск).
            <p></p>
            Ссылка на онлайн-подключение будет направлена в день мероприятия после регистрации.
            <p></p>
            Регистрация участников Фестиваля: <a
            style={{color: "blue"}}>https://forms.yandex.ru/u/66004f75c417f35a7f45f81f</a>
            <p></p>
            Подробная информация: <a
            style={{color: "blue"}}>https://www.mgpu.ru/event/iii-mezhdunarodnyj-festival-mediatsiya-molodost-budushhee</a>
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News2905242;