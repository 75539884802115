import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import logo from "../../../assets/Behavior/conceptions.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {NavLink} from "react-router-dom";
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";
import adobe from "../../../assets/Behavior/adobe.png"
import presen from "../../../assets/Behavior/presen.jpg"


const LifestylePosobie = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2>
                    Электронное наглядное пособие по работе с обучающимися «Стиль жизни — здоровье»

                </h2>
            </div>

            <div style={{justifyContent:"space-around", display:'flex   '}}>
                <img src={adobe} style={{width:"100px"}}></img>
                <img src={presen} style={{width:"100px"}}/>


            </div>
            <div style={{marginLeft: "25px", justifyContent:"space-around", display:'flex   '}}>
                <a className={s.posobie} href="https://fcprc.ru/media/media/behavior/Naglyadno-metodicheskie-materialy-TSentra-2_Gcx0V2g.pdf">Открыть PDF</a>
                <a className={s.posobie} href="https://disk.yandex.ru/d/MYeimhq-kpUJjA">Смотреть презентацию</a>
            </div>

            <div style={{fontSize: "17px", color: "#212529"}} className={s.concept}>
                <p><strong>Наглядно-методические материалы электронного пособия «Стиль жизни – здоровье» предназначены
                    для
                    проведения серии занятий по формированию здорового и безопасного образа жизни и профилактики
                    аддиктивного поведения среди обучающихся 14–16 лет. Разработано ФГБУ «Центр защиты прав и интересов
                    детей»</strong></p>
                <p style={{color: "red"}}>Перед запуском пособий может появится предупреждение операционной системы.
                    Проигнорируйте его и нажмите «все равно запустить».</p>
            </div>
        </div>
        <div style={{marginTop: "57px"}}>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право" to="/tvoyo-pravo"/>
        </div>


    </div>


}

export const HrefsBeh1 = ({hrefsArr, stylef = {}, to = "/"}) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({text, stylesheet}) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({text, stylesheet, to}) => {
    return <div className={s.whitebutton}>
        <NavLink to={to}>
            {text}
        </NavLink>
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default LifestylePosobie