import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News291024_1.jpg"
import img2 from "../../assets/News/News291024_2.png"
import img3 from "../../assets/News/News291024_3.png"

const News291024 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Всероссийское совещание «Ресоциализация обучающихся специальных учебно-воспитательных учреждений и меры их
            поддержки», 2024 г.
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            18 октября 2024 года в Доме Правительства Московской области было проведено Всероссийское совещание
            организованное Департаментом государственной политики в сфере защиты прав детей Министерства просвещения
            Российской Федерации по теме
            «Ресоциализация обучающихся специальных учебно-воспитательных учреждений и меры их поддержки».
            <p></p>
            Совещание стало площадкой для обмена опытом работы федеральных специальных учебно-воспитательных учреждений
            по актуальным вопросам социализации обучающихся.
            Участниками совещания стали руководители и выпускники специальных учебно-воспитательных учреждений,
            представители исполнительных органов субъектов Российской Федерации, осуществляющих государственное
            управление в сфере образования, курирующие вопросы профилактики девиантного поведения несовершеннолетних,
            функционирования и развития деятельности специальных учебно-воспитательных учреждений.
            <p></p>
            <div>
                <img src={img2}/>
            </div>
            Министр просвещения Российской Федерации Сергей Сергеевич Кравцов направил приветственные слова участникам
            совещания.
            <p></p>
            Директор Департамента государственной политики в сфере защиты прав детей Минпросвещения России Лариса
            Павловна Фальковская выступила с докладом на тему «Комплекс мер по сопровождению успешной социализации
            обучающихся специальных учебно-воспитательных учреждений: от нормативного регулирования до практических
            решений».
            <p></p>
            Своим опытом пребывания в специальном учебно-воспитательном учреждении поделились выпускники СУВУ. Директора
            специальных учебно-воспитательных учреждений обменялись опытом работы, обсудили вопросы, возникающие в
            работе по темам:
            <ul>
                <li>Специальное учебно-воспитательное учреждение как центр компетенций профилактической работы.</li>
                <li>Социальное партнерство в решении задач социализации выпускников специального учебно-воспитательного
                    учреждения.
                </li>
                <li>Индивидуальная траектория развития как основа успешной социализации обучающихся специальных
                    учебно-воспитательных учреждений.
                </li>
            </ul>
            <div>
                <img src={img3}/>
            </div>
            <p></p>
            На Всероссийском совещании также были определены новые задачи в решении вопросов ресоциализации обучающихся,
            имеющих девиантное поведение. Проведенное совещание способствует взаимодействию и обмену опытом по созданию
            поддерживающей среды для молодежи, а также подчеркивает необходимость совместной работы для системной
            профилактики, социализации и ресоциализации детей и подростков из групп риска.
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News291024;