import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/bestcentercomp2022.jpg"

const ResultsBestCenterCompetition = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Подведены итоги первого Всероссийского Конкурса «Лучший центр психолого-педагогической, медицинской и
            социальной помощи – 2022»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Конкурс организован Министерством просвещения Российской Федерации и проведен среди государственных
                (региональных) и муниципальных ППМС-центров ФГБУ «Центр защиты прав и интересов детей».</p>
            <p>Для участия в конкурсе поступила 71 заявка из 55 субъектов РФ, из них 28 от региональных центров
                психолого-педагогической, медицинской и социальной помощи и 43 от муниципальных центров
                психолого-педагогической, медицинской и социальной помощи.</p>
            <p>Данный Конкурс организован с целью:</p>
            <p>- выявления лучшего опыта работы среди ППМС-центров;</p>
            <p>- распространения лучших практик организации оказания психолого-педагогической, медицинской и социальной
                помощи несовершеннолетним и родителям (законным представителям) несовершеннолетних;</p>
            <p>- повышения качества оказания психолого-педагогической помощи несовершеннолетним и родителям (законным
                представителям) несовершеннолетних;</p>
            <p>- определения критериев оценки качества и доступности ППМС-центров;</p>
            <p>- анализа существующих практик организации деятельности ППМС-центр на территории субъектов Российской
                Федерации, в соответствии с ранее определенными критериями;</p>
            <p>- распространения лучших практик организации оказания качественной, доступной и эффективной
                психолого-педагогической, медицинской и социальной помощи несовершеннолетним и родителям (законным
                представителям) несовершеннолетних, среди педагогического сообщества и организаций, осуществляющих
                образовательную деятельность;</p>
            <p>- привлечения внимания органов исполнительной власти в сфере образования к вопросам развития
                ППМС-центров,
                организации оказания качественной психолого-педагогической, медицинской и социальной помощи
                несовершеннолетним и родителям (законным представителям) несовершеннолетних, организациям,
                осуществляющим образовательную деятельность.</p>
            <p><strong>Конкурс проводился по следующим номинациям:</strong></p>
            <p>– «Лучший региональный центр психолого-педагогической, медицинской
                и социальной помощи – 2022»;</p>
            <p>– «Лучший муниципальный центр психолого-педагогической, медицинской
                и социальной помощи – 2022».</p>
            <p>Конкурс включал два этапа: региональный и федеральный:</p>
            <ol>
                <li>первый (региональный) этап проводился до 25 сентября 2022 г. органами исполнительной власти
                    субъектов
                    Российской Федерации, осуществляющими государственное управление в сфере образования;
                </li>
                <li>второй (федеральный) этап проходил с 1 октября по 30 октября 2022 г.</li>
            </ol>
            <p>Торжественная церемония награждения победителей Конкурса <strong>«Лучший центр психолого-педагогической,
                медицинской и социальной помощи – 2022»</strong> состоялась 14 ноября в ходе Всероссийской конференции
                по
                вопросам психолого-педагогического сопровождения обучающихся.</p>
            <p><strong>Победителями в номинации «Лучший муниципальный ППМС центр» стали:</strong></p>
            <p><strong>I место</strong> – Государственное бюджетное учреждение дополнительного образования Центр
                психолого-педагогической, медицинской и социальной помощи Калининского района Санкт-Петербурга.</p>
            <p><strong>II место</strong> – Муниципальное бюджетное образовательное учреждение «Образовательный центр
                «Созвездие»»
                Московская область, г. Красногорск.</p>
            <p><strong>III место</strong> – Муниципальное бюджетное учреждение «Центр психолого-педагогической,
                медицинской и социальной
                помощи Калининского района. г. Челябинска».</p>
            <p><strong>Победителями в номинации «Лучший региональный ППМС центр» стали:</strong></p>
            <p><strong>I место</strong> – Государственное бюджетное учреждение Новосибирской области – Центр
                психолого-педагогической,
                медицинской и социальной помощи детям «Областной центр диагностики и консультирования». Новосибирская
                область, г. Новосибирск.</p>
            <p><strong>II место</strong> – Государственное бюджетное учреждение города Москвы «Городской
                психолого-педагогический центр
                Департамента образования и науки города Москвы», г. Москва</p>
            <p><strong>III место</strong> – Государственная организация образования «Кузбасский региональный центр
                психолого-педагогической, медицинской и социальной помощи «Здоровье и развитие личности». Кемеровская
                область – Кузбасс, г. Кемерово.</p>
            <p>Победители Конкурса награждены дипломами, сертификатами и подпиской на научно-методические журналы.</p>
        </div>
        <ToNews/>
    </div>
}


export default ResultsBestCenterCompetition;