import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News220224_main.jpg"
import img1 from "../../assets/News/News220224_1.jpg"
import img2 from "../../assets/News/News220224_2.jpg"

const News220224 = ({children, header}) => {

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Открытый вебинар на тему: "Конструктивное общение с техникой «Я-сообщение»".
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Часто бывает, что во время решения рабочих задач или личного взаимодействия возникает определенная
            напряженность из-за разного мнения и способов его отстаивания. Что может помочь Вам и собеседнику в такой
            ситуации?
            <p></p>
            <strong>28 февраля в 14:00-15:30 (мск)</strong>
            <p></p>
            Состоится вебинар Центра защиты прав и интересов детей, на котором:
            <ul>
                <li>вы познакомитесь со структурой техники ненасильственного общения «Я-сообщение»;</li>
                <li>увидите возможности применения данной техники в общении со взрослыми и детьми;</li>
                <li>а также узнаете об имеющихся на базе Центра защиты прав и интересов детей образовательных программах
                    и их
                    особенностях.
                </li>
            </ul>
            Регистрация и подключение по ссылке: <a
            href="https://my.mts-link.ru/19466565/915776220"><strong>https://my.mts-link.ru/19466565/915776220</strong></a>
            <p></p>
            Ведущий: Козлов Анатолий Андреевич - медиатор, педагог-психолог, начальник отдела медиации Центра, член
            ассоциации профессиональных медиаторов «Единство».
            <p></p>
            Для участников вебинара разработан методический сюрприз.
            <p></p>
        </div>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <ToNews/>
    </div>
}


export default News220224;