import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img1 from "../../assets/News/PreventionOfChildMortality.jpg";
import YouTube from "react-youtube";

const PreventionOfChildMortality = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: «Организация работы по предотвращению детской смертности» для педагогов-психологов
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <p>
                В рамках Всемирного дня борьбы с самоубийствами (10 сентября) ФГБНУ «Центр защиты прав и интересов
                детей» по поручению Департамента государственной политики в сфере защиты прав детей Минпросвещения
                России провел вебинар для педагогов-психологов образовательных организаций, центров
                психолого-педагогической, медицинской и социальной помощи, а также заместителей директоров
                образовательных учреждений по воспитательной работе.
            </p>
            <p>
                В ходе вебинара обсуждался круг вопросов, определяющих задачи по профилактике суицидального поведения
                детей и подростков в образовательных организациях, комплексный опыт по предотвращению суицидов, а также
                основные признаки суицидального поведения подростков и меры своевременного реагирования в целях
                предотвращения суицида.
            </p>
            <YouTube style={{textAlign: "center"}} videoId="oaozXW2akb8"/>
            <p></p>
        </div>
    </div>
}


export default PreventionOfChildMortality