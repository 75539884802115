import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News140523.jpg"

const News140523 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Обучение по медиации и восстановительным технологиям
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Если у вас есть желание и необходимость понимать и разрешать конфликты мирным путем, выстраивать добрые и
            доверительные отношения с учениками и их родителями, а также организовать в школе службу медиации
            (примирения), то этот курс для вас!
            <p></p>
            Наш Центр приглашает на программу повышения квалификации «Медиативно-восстановительные технологии в
            образовательной организации», <strong>36 академических часов</strong>.
            <p></p>
            <span style={{color: "blue", fontWeight: "bold"}}>Записаться на курс можно до 23 мая 2024 года: <a
                style={{color: "blue", fontWeight: "bold"}}
                href="https://dpo.fcprc.ru/registration">https://dpo.fcprc.ru/registration</a></span>
            <p></p>
            <strong>Период обучения:</strong> с 04 июня по 5 июля 2024 г.
            <p></p>
            <strong>Формат обучения:</strong> онлайн занятия. Все занятия будут проводиться с личным участием
            преподавателя.
            <p></p>
            После прохождения обучения, вы получите <strong>удостоверение о повышение
            квалификации</strong> установленного образца.
            <p></p>
            <strong>Преподаватель: Анатолий Андреевич Козлов</strong>, медиатор, педагог-психолог, начальник отдела
            медиации ФГБУ
            «Центр
            защиты прав и интересов детей», член Ассоциации профессиональных медиаторов «Единство».
            <p></p>
            <strong>Эта программа для вас</strong>, если вы желаете ближе познакомиться с медиативно-восстановительным
            подходом и
            узнать
            об особенностях его применения в работе с несовершеннолетними и в условиях образовательной организации.
            <p></p>
            <strong> После прохождения обучения вы сможете:</strong>
            <ul>
                <li>разбираться в основах медиативно-восстановительного подхода и конфликтологического знания;</li>
                <li>проводить медиативно-восстановительную беседу между обучающимися, коллегами, родителями;</li>
                <li>использовать медиативные и восстановительные технологии, такие как: «Петля понимания», «Активное
                    слушанье», «Рефрейминг»;
                </li>
                <li>понимать возможности создания и развития службы медиации или примирения.</li>
            </ul>
            Программа включает в себя преподавательский опыт сотрудников отдела медиации ФГБУ «Центр защиты прав и
            интересов детей», а также опыт в области консультативной, экспертной и организационно-методической
            работы по
            развитию системы служб медиации и примирения в Российской Федерации.
            <p></p>
            <strong>Стоимость обучения:</strong> 15 000 рублей.
            <p></p>
            <a style={{color: "blue"}} href="https://dpo.fcprc.ru/mediationcourse4">Подробнее о курсе</a>
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News140523;