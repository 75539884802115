import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/PreventionTobacco2022.png"

const PreventionTobacco2022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            19 декабря 2022 года состоялся Всероссийский вебинар по вопросам организации системной профилактической
            работы в образовательной организации, направленной на предупреждение потребления алкоголя, табака и иной
            никотинсодержащей продукции среди обучающихся
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>19 декабря 2022 года на вебинарной платформе ФГБУ «Центр защиты прав и интересов детей» состоялся
                Всероссийский вебинар на тему: «Организация системной профилактической работы в образовательной
                организации, направленной на предупреждение потребления алкоголя, табака и иной никотинсодержащей
                продукции среди обучающихся».</p>
            <p>В вебинаре приняли участие специалисты органов исполнительной власти субъектов Российской Федерации,
                осуществляющих государственное управление в сфере образования, региональные операторы, ответственные за
                организацию профилактической работы с обучающимися в образовательных организациях субъектов Российской
                Федерации, из 83 субъектов Российской Федерации. Всего в вебинаре приняло участие 2048 человек.</p>
            <p>Юлия Александровна Костыряченко, заместитель директора Департамента государственной политики в сфере
                защиты прав детей Министерства просвещения Российской Федерации, поприветствовала участников вебинара и
                осветила общие вопросы организации профилактики потребления алкоголя, табака и иной никотинсодержащей
                продукции среди обучающихся. Юлия Александровна отметила, что в профилактике потребления алкоголя и
                табака важна роль личного примера педагогических работников; все меры воспитательного характера будут
                сведены на нет в случае, например, публичного потребления никотина педагогическими работниками.</p>
            <p>Галина Михайловна Сахарова, главный научный сотрудник отдела общественного здоровья и демографии ФГБУ
                «Центральный научно-исследовательский институт организации и информатизации здравоохранения» Минздрава
                России, доктор медицинских наук, профессор, рассказала об особенностях потребления табачной и
                никотинсодержащей продукции среди молодежи, описав динамику и тенденции этого явления. По словам Галины
                Михайловны, снижение распространенности потребления табака сопровождается ростом потребления кальяна и
                электронных сигарет (с 2009 по 2021 год). Отмечается и низкая информированность о мерах, направленных на
                снижение потребления табачной и никотинсодержащей продукции.</p>
            <p>Выступившая далее Елена Геннадьевна Деменко, заведующая филиалом Центра профилактики зависимого поведения
                ГБУЗ «Московский научно-практический центр наркологии Департамента здравоохранения города Москвы»,
                врач-психиатр-нарколог, обозначила условия эффективности реализации программ профилактики аддиктивного
                поведения в образовательных организациях. Как сообщила Елена Геннадьевна, поддержка в семье стремления
                подростка к достижениям в области образования является защитой от употребления алкоголя. Елена
                Геннадьевна особо отметила, что демонстрация в профилактических материалах предметов, состояний и
                ситуаций, связанных с потреблением психоактивных веществ (ПАВ) — части наркосодержащих растений, шприцы,
                таблетки, алкоголь, эпизоды употребления ПАВ, люди в состоянии опьянения и т. д. — относятся по
                экспертному мнению к пропаганде потребления ПАВ.</p>
            <p>Ольга Вячеславовна Заева, начальник отдела профилактики девиантного поведения несовершеннолетних ФГБУ
                «Центр защиты прав и интересов детей», выступила с сообщением на тему организации профилактической
                работы с обучающимися образовательной организации в части предотвращения потребления алкоголя, табака и
                иной никотинсодержащей продукции. Ольга Вячеславовна подчеркнула, что семья призвана усиливать защитные
                механизмы и ресурсы, обеспечивать психологическое благополучие личности детей и подростков, и именно
                поэтому так необходима профилактическая работа образовательных организаций с родителями.</p>
            <p>Спикеры и слушатели отметили высокую практическую значимость представленной информации.</p>
            <p><strong>Материалы Всероссийского вебинара</strong></p>
            <p style={{fontStyle: "Italic"}}><a href="https://fcprc.ru/wp-content/uploads/2022/12/Prezentatsiya-Saharovoi-G.-M..pdf">Презентация Сахаровой Г.М.</a></p>
            <p style={{fontStyle: "Italic"}}><a href="https://fcprc.ru/wp-content/uploads/2022/12/Prezentatsiya-Demenko-E.-G..pdf">Презентация Деменко Е.Г.</a></p>
            <p style={{fontStyle: "Italic"}}><a href="https://fcprc.ru/wp-content/uploads/2022/12/Prezentatsiya-Zaevoi-O.-V..pdf">Презентация Заевой О.В.</a></p>
            <p style={{fontStyle: "Italic"}}><a href="https://www.youtube.com/watch?v=J98-pFcjfGk">Видеозапись вебинара</a></p>
        </div>
        <ToNews/>
    </div>
}


export default PreventionTobacco2022;