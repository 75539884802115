import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/MediationYouthFuture.jpg"
import img2 from "../../assets/News/MediationYouthFuture2.jpg"
import img3 from "../../assets/News/MediationYouthFuture3.jpg"
import img4 from "../../assets/News/MediationYouthFuture4.jpg"
import img5 from "../../assets/News/MediationYouthFuture5.jpg"
import img6 from "../../assets/News/MediationYouthFuture6.jpg"
import img7 from "../../assets/News/MediationYouthFuture7.jpg"
import img8 from "../../assets/News/MediationYouthFuture8.jpg"


const MediationYouthFuture = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            I Международный фестиваль «Медиация. Молодость. Будущее!»
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>2-3 февраля 2022 года состоялся I Международный фестиваль «Медиация. Молодость. Будущее!», цель которого
                – формирование профессионального сообщества, объединяющего медиаторов и специалистов служб медиации и
                примирения, популяризация гуманистических идей и технологий медиации и примирения в образовании,
                социальной сфере.</p>
            <p>Фестиваль был организован Институтом непрерывного образования ГАОУ ВО «Московский городской
                педагогический университет» при поддержке аппарата Уполномоченного при Президенте Российской Федерации
                по правам ребенка, Уполномоченного по правам ребенка Московской области, ФГБУ «Центр защиты прав и
                интересов детей», членов Экспертного совета по развитию служб медиации и примирения в образовательных
                организациях Минпросвещения России.</p>
            <p>
                Фестиваль состоял из нескольких блоков: конкурс профессионального мастерства для специалистов,
                работающих в службах медиации и примирения, презентация эффективных медиативных и восстановительных
                практик в формате мастер-классов координаторов и специалистов служб медиации и примирения, проведение
                панельной дискуссии и тематических секций по актуальным проблемам популяризация гуманистических идей и
                технологий медиации и примирения в образовании и социальной сфере.
            </p>
            <p>
                Фестиваль проходил в дистанционном формате, охватив более 300 участников: медиаторы, координаторы,
                руководители служб медиации и примирения общеобразовательных организаций, специальных
                учебно-воспитательных учреждений, подведомственных Минпросвещения России, профессиональных
                образовательных организаций, члены некоммерческих и государственных организаций социальной сферы.
                Фестиваль собрал 47 регионов России и такие страны как: Армения, Беларусь, Испания, Казахстан, Киргизия,
                Кыргызстан, Латвия, Молдова.
            </p>
            <p>
                Исполняющий обязанности директора Центра защиты прав и интересов детей <strong>Борис Борисович Гусев</strong> выступил в
                роли председателя Жюри конкурса профессионального мастерства Фестиваля. Начальник отдела медиации <strong>Марина
                Викторовна Быкова</strong> приняла участие в качестве спикера в панельной дискуссии, а также была членом Жюри
                конкурса и руководителем тематической секции «Эффективные практики деятельности служб медиации в СУВУ».
                Аналитик отдела медиации <strong>Анатолий Андреевич Козлов</strong> выступил ведущим фестиваля и тематической секции
                «Медиация в карьере. Взгляд в будущее!».
            </p>
            <p>
                Центр защиты прав и интересов детей благодарит организаторов и участников Фестиваля и надеется на
                дальнейшее сотрудничество в развитии служб медиации в образовательных организациях и популяризации
                гуманистических идей и технологий медиации и восстановительного правосудия в образовании и социальной
                сфере.
            </p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <div>
            <img src={img4}/>
        </div>
        <div>
            <img src={img5}/>
        </div>
        <div>
            <img src={img6}/>
        </div>
        <div>
            <img src={img7}/>
        </div>
        <div>
            <img src={img8}/>
        </div>
        <ToNews/>
    </div>
}


export default MediationYouthFuture;