import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/NSR.jpg"

const NSR2022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            19-20 октября 2022 года откроется отборочный этап Всероссийского конкурса в сфере профессиональной
            деятельности специалистов органов опеки и попечительства в отношении несовершеннолетних граждан «На стороне
            ребенка».
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>В отборочном туре примут участие успешно прошедшие регистрацию 164 специалиста органа опеки и
                попечительства в пяти номинациях, и 47 органов опеки и попечительства в трех номинациях.
                Списки участников, допущенных к участию в отборочном этапе размещены на официальном сайте Конкурса <strong><a href="https://на-стороне-ребенка.рф/объявления/">на-стороне-ребенка.рф</a></strong> в разделе
                «Объявления».</p>
        </div>
        <ToNews/>
    </div>
}


export default NSR2022;