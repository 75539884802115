import React from "react";
import s from "./Default.module.css"
import ToNews from "../News/ToNews";
import img from "../../assets/News/News150724.jpg"
import img1 from "../../assets/News/News150724_1.jpg"
import img2 from "../../assets/News/News150724_2.jpg"
import img3 from "../../assets/News/News150724_3.jpg"
import img4 from "../../assets/News/News150724_4.jpg"
import img5 from "../../assets/News/News150724_5.jpg"

const News150824 = () => {

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Лекторий Общества «Знание» о медиации стартует в Москве
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            17 августа 2024 г. в Москве откроется лекторий «Медиация: благополучие ребенка в семье и школе»,
            организованный Российским обществом «Знание» совместно с Национальным исследовательским университетом
            «Высшая школа экономики»
            <p></p>
            В программу лектория войдет базовый модуль из четырех встреч на площадке Высшей школы экономики Покровском
            бульваре.
            <p></p>
            Лекторий будет представлять специально подготовленный комплекс практических инструментов и информации о
            медиации и ненасильственном общении для тех, кто хочет разобраться в вопросах разрешения и предотвращения
            конфликтов в школьной среде и семейных отношениях.
            <p></p>
            Приглашенные эксперты в сфере медиации познакомят слушателей с эффективными методами разрешения конфликтов,
            обсудят с точки зрения различных наук природу конфликтов и подходы к созданию гармоничной и безопасной
            образовательной среды, помогут семьям научиться находить компромиссы, понимать и учитывать интересы друг
            друга.
            <p></p>
            По итогам участия в лекциях и мастер-классах слушатели смогут получить сертификаты и будут не только знать о
            том, такое медиация и где можно получить бесплатные услуги профессионального медиатора, но также смогут
            использовать некоторые медиативные и восстановительные технологии для решения и предупреждения своих
            конфликтных ситуаций.
            <p></p>
            Участие бесплатное, но требуется предварительная регистрация на <a
            href="https://popularscience.hse.ru/polls/947509109.html" style={{color: "red", fontWeight: "bold"}}>сайте
            проекта</a>.
            <p></p>
            Подробнее о лектории «Медиация: благополучие ребенка в семье и школе» можно узнать <a
            href="https://popularscience.hse.ru/mediation" style={{color: "red", fontWeight: "bold"}}>здесь</a>.
            <p></p>
        </div>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <div>
            <img src={img4}/>
        </div>
        <div>
            <img src={img5}/>
        </div>
        <ToNews/>
    </div>
}


export default News150824;