import React from "react";
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, WhiteButton} from "../PageInfo/PageInfo"
import Falldown, {Falldown2, FileLi, HrefLi} from "../FalldownList/Falldown";
import {hrefsArrFPF} from "./FilesPublicationsForms";

const ISNPRVP4 = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.newHeader}>
            <h2> 
                IV. Развитие кадрового потенциала системы профилактики безнадзорности и правонарушений несовершеннолетних
            </h2>
        </div>
        <div className={s.filesLeftPage} style={{textAlign: "left", fontSize: "15px", color: "#212529"}}>
            <div className={s.improvement} style={{width: "700px", lineHeight: "1.5"}}>
                <p>Развитие кадрового потенциала системы профилактики безнадзорности и правонарушений несовершеннолетних
                    является неотъемлемой частью всей деятельности, направленной на предупреждение возникновения
                    девиантных форм поведения среди детей и подростков.</p>
                <p>Развитие кадрового потенциала системы профилактики безнадзорности и правонарушений несовершеннолетних
                    должно включать:</p>
                <p>— организацию мероприятий по повышению профессионального уровня, приобретению новых компетенций
                    руководителями и специалистами органов и учреждений системы профилактики безнадзорности и
                    правонарушений несовершеннолетних;</p>
                <p>— распространение эффективного опыта профессионального образования и дополнительного
                    профессионального образования специалистов, работающих в органах и учреждениях системы профилактики
                    безнадзорности и правонарушений несовершеннолетних;</p>
                <p>— развитие деятельности педагогических сообществ (методические объединения, сетевые сообщества,
                    ассоциации, лаборатории и др.) по актуальным вопросам профилактики безнадзорности и правонарушений
                    несовершеннолетних.</p>
                <p><strong>Результаты реализации Концепции за 2017 год</strong></p>
                <p><a href="https://fcprc.ru/media/media/behavior/Fajl-3.pdf">Аналитическая информация об организации в
                    субъектах Российской Федерации профессионального образования и дополнительного профессионального
                    образования специалистов, работающих в органах и учреждениях системы профилактики безнадзорности и
                    правонарушений несовершеннолетних (п.31).pdf ( 0.3 Мб)</a>
                </p>
                <p><strong>Результаты реализации Концепции за 2018 год</strong></p>
                <p><a href="https://fcprc.ru/media/media/behavior/fajl4.pdf">Аналитическая справка п.30.pdf ( 0.3
                    Мб)</a>
                </p>
                <p><strong>Результаты реализации Концепции за 2019 год</strong></p>
                <p><strong>Результаты реализации Концепции за 2020 год</strong></p>
                <p><a href="https://fcprc.ru/media/media/behavior/falj5.pdf">Методические рекомендации по развитию
                    деятельности педагогических сообществ по актуальным вопросам профилактики безнадзорности и
                    правонарушений несовершеннолетних (п. 33) .pdf ( 0.3 Мб)</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/falj6.pdf">Реестр межрегиональных стажировочных площадок образовательных организаций высшего
                    образования для проведения курсов повышения квалификации руководителей и специалистов органов и
                    учреждений системы профилактики безнадзорности и правонарушений несовершеннолетних (п. 32) .pdf ( 2
                    Мб)</a></p>
            </div>
            <div>
                <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            </div>
        </div>
    </>
}
export default ISNPRVP4