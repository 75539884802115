import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News040722.jpg"

const News040722 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            В Пскове завершился международный фестиваль «Другое искусство»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Инклюзивные коллективы и проекты из Пскова и Псковской области, Петербурга, Москвы, Великого Новгорода,
                Краснодара, Брянска, Иркутска, Орска, Республики Беларусь стали участниками V международного фестиваля
                «Другое искусство», который прошел в Пскове. Мероприятие проводилось при поддержке Минпросвещения
                России.</p>
            <p>«В этом году наш фестиваль также особенный: он отчасти посвящен родителям особенных детей. Пользуясь
                случаем, я хотел бы всем мамам, папам, бабушкам, дедушкам, которые несут эту миссию и ответственность,
                от чистого сердца сказать огромное человеческое спасибо за ваше неравнодушие, за то тепло и любовь,
                которые вы дарите своим детям. Нашему фестивалю быть! В добрый путь. Мы обязательно встретимся в
                следующем году», – обратился к участникам Андрей Турчак, секретарь Генерального совета партии «Единая
                Россия», первый заместитель Председателя Совета Федерации, один из основателей фестиваля «Другое
                искусство».</p>
            <p>В 2022 году среди участников фестиваля – известные деятели искусства, которые уделяют внимание в своем
                творчестве инклюзивной тематике. Так, в составе проекта «Наивно? Очень» со спектаклем «Интернат»
                выступили Нелли Уварова и Евгений Редько; Анвар Либабов представил свой спектакль «Где-то когда-то»,
                поставленный с воспитанниками Филимонковского дома-интерната; Борис Павлович, театральный режиссер,
                лауреат национальной театральной премии «Золотая маска», участвовал в фестивале вместе с театральной
                компанией «Разговоры» фонда «Альма Матер», которая представила мюзикл «Жаль, что тебя здесь нет»;
                благодаря мастер-классу музыканта Романа Столяра гости фестиваля смогли выйти на сцену в составе хора
                мюзикла «Жаль, что тебя здесь нет».</p>
            <p>В программу фестиваля вошло несколько десятков мероприятий, в числе которых выставка известного российского художника Геннадия Устюгова, показы фильмов «Уличное освещение» (режиссер Владислав Иконников) и «Лорик» (режиссер Алексей Злобин), спектакли «African story» театра «Свежий Ветер» (Республика Беларусь), «А у нас в Новгородчине диво-то было» народного театра «Жест» (Великий Новгород), «Тоже Я» иркутской студии «О» (режиссер Андрей Афонин), фотовыставка с участием петербургского тележурналиста Юрия Кузнецова, выставка картин из бисера и вокальное выступление Карины Жаковой (Брянск), фотовыставка «Посмотри на меня» театральной школы «Инклюзион» из Орска, выступление инклюзивного состава псковской рок-группы «Отцы и дети», мастер-класс и лекция сотрудников мастерских «Простые вещи» (Санкт-Петербург).</p>
        </div>
        <ToNews/>
    </div>
}


export default News040722;