import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/SupportOfMediationServices2022.jpg"
import img2 from "../../assets/News/SupportOfMediationServices2022_2.png"
import img3 from "../../assets/News/SupportOfMediationServices2022_3.png"
import img4 from "../../assets/News/SupportOfMediationServices2022_4.png"

const SupportOfMediationServices2022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сопровождение служб медиации в специальных учебно- воспитательных учреждениях в октябре 2022 года
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Отделом медиации ФГБУ «Центр защиты прав и интересов детей» в октябре 2022 года проведен комплекс
                мероприятий по сопровождению служб медиации в специальных учебно-воспитательных
                учреждениях,подведомственных Минпросвещения России (СУВУ). Мероприятия включали групповые консультации
                специалистов служб и интерактивные занятия с обучающимися.</p>
            <p>Занятия и консультации прошли в дистанционном формате 20 – 21 октября (Известковское СУВУ, Раифское
                СУВУ, Себежское СУВУ, Ишимбайское СУВУ), 27 – 28 октября (Щекинское СУВУ, Орловское СУВУ, Куртамышское
                СУВУ, Рефтинское СУВУ).</p>
            <p>С сотрудниками служб медиации СУВУ в рамках консультаций рассматривалось актуальное состояние службы и
                планы на 2022 год. В практической части встречи проходил анализ конфликтной ситуации между обучающимися.
                Результатом анализа стал вывод специалистов, что зачастую конфликт, обозначаемый как национальный в
                итоге оказывается межличностным. Рассматривались варианты урегулирования и профилактики подобных
                случаев.</p>
            <p>Интерактивные занятия с обучающимися из разных СУВУ включали в себя знакомство с техниками медиации в
                условиях деловой игры, события которой происходят в космическом пространстве.</p>
            <p>Участники занятий оценили новые формы взаимодействия и отметили, что в таком виде материал усваивается
                проще и интересней.</p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <div>
            <img src={img4}/>
        </div>
        <ToNews/>
    </div>
}


export default SupportOfMediationServices2022;