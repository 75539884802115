import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News291123.jpg"

const News291123 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Всероссийский вебинар для педагогических работников по профилактике распространения<br/>ВИЧ-инфекции среди
            обучающихся
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            30 ноября 2023 г. состоялся Всероссийский вебинар для педагогических работников образовательных организаций
            «Деятельность образовательных организаций по профилактике распространения ВИЧ-инфекции среди обучающихся».

            <p></p>
            В рамках вебинара были представлены следующие темы:
            <p></p>
            Оксана Александровна Драганова, заместитель начальника отдела профилактики асоциального поведения
            несовершеннолетних Департамента государственной политики в сфере защиты прав детей Минпросвещения России,
            поприветствовала участников вебинара и осветила общие вопросы профилактики распространения ВИЧ-инфекции
            среди обучающихся в системе общего и профессионального образования Российской Федерации.
            <p></p>
            Ольга Вячеславовна Заева, начальник отдела профилактики девиантного поведения несовершеннолетних ФГБУ «Центр
            защиты прав и интересов детей», выступила с докладом о подходах к организации деятельности образовательных
            организаций по профилактике распространения ВИЧ-инфекции среди обучающихся. Ольга Вячеславовна также
            подчеркнула важность участия педагогов во <strong><a href="http://опрос-педагогов-о-вич.рф/">Всероссийском
            опросе педагогов о профилактике ВИЧ-инфекции среди
            обучающихся</a></strong>.
            <p></p>
            Ольга Николаевна Кирьянова, директор Благотворительного фонда помощи детям с социально значимыми
            заболеваниями «Дети плюс», описала опыт работы Фонда в профилактике распространения ВИЧ-инфекции среди
            обучающихся, и описала ресурсы и ограничения, предъявляемые к содержанию профилактических мероприятий. Ольга
            Николаевна напомнила участникам вебинара о необходимости предупреждения дискриминации обучающихся с ВИЧ.
            <p></p>

            Вебинар собрал педагогических работников со всей России, которые активно участвовали в обсуждении, отвечали
            на вопросы спикеров и задавали вопросы самостоятельно. Мероприятие помогло расширить понимание о
            необходимости профилактики распространения ВИЧ-инфекции среди обучающихся и позволило распространить
            передовые практики ФГБУ «Центр защиты прав и интересов детей» и Благотворительного фонда помощи детям с
            социально значимыми заболеваниями «Дети плюс» в этой сфере.
            <p></p>
            В вебинаре приняли участие 4862 педагогических работника общеобразовательных и профессиональных
            образовательных организаций из 80 субъектов Российской Федерации.
            <p></p>
            Организаторы выражают благодарность всем спикерам и участникам вебинара за их ценный вклад и активное
            участие в обсуждении. Такие мероприятия играют важную роль в повышении осведомленности и эффективности
            работы педагогических работников в области профилактики распространения
            ВИЧ-инфекции среди обучающихся.

            <p></p>
            <strong><a href="https://disk.yandex.ru/i/k0MP5h4R3LLryg">Видеозапись вебинара</a></strong>
            <p></p>
            <strong><a href="https://fcprc.ru/media/media/behavior/Презентация_Заевой.pdf">Презентация Заевой О. В.</a></strong>
            <p></p>
            <strong><a href="https://fcprc.ru/media/media/behavior/Презентация_Кирьяновой.pdf">Презентация Кирьяновой О. Н.</a></strong>
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News291123;