import React from "react";
import s from "./Default.module.css"
import {useState} from "react";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News220624.jpg"

const News220624 = () => {

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Определены победители викторины по финансовой грамотности!
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            По итогам проведения онлайн викторины по финансовой грамотности, организованной ФГБУ «Центр защиты прав и
            интересов детей» при поддержке ГУ Банка России по ЦФО в период с 1 по 10 июня 2024 г., определены
            победители.
            <p></p>
            Участие в викторине принимали дети от 12 лет, которые смогли проверить свои знания в финансовой сфере.
            <p></p>
            В этом году викторина побила все рекорды! Участники старались получить звание победителя, демонстрируя свои
            знания и эрудицию. По условиям, первые десять участников, правильно ответивших на все вопросы, должны были
            получить сертификаты победителей и памятные сувениры. В конкурсе приняли участие 1988 детей.
            <p></p>
            Однако, страсть к победе зажгла сердца участников настолько, что два последних финалиста нажали кнопку
            "Отправить ответ" одновременно! Это редкий случай, когда судьба викторины зависела от доли секунды. Обычно,
            устройство фиксирует время ответа с точностью до миллисекунды, но в этот раз, система не смогла определить
            победителя.
            <p></p>
            Оргкомитет викторины, после долгих обсуждений, принял решение не оставлять никого в обиде. В итоге, в этом
            году у нас 11 победителей, что еще раз подтверждает, что участники викторины - настоящие интеллектуальные
            гиганты! Все получат заслуженные сертификаты и памятные сувениры, напоминающие о их участии в этой
            захватывающей интеллектуальной битве.
            <p></p>
            Мы поздравляем всех победителей и желаем им дальнейших успехов!
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News220624;