import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News05122019.jpg"
import img2 from "../../assets/News/News051220192.jpg"
import img3 from "../../assets/News/News051220193.jpg"
import img4 from "../../assets/News/News051220194.jpg"
import img5 from "../../assets/News/News051220195.jpg"
import img6 from "../../assets/News/News051220196.jpg"
import img7 from "../../assets/News/News051220197.jpg"
import img8 from "../../assets/News/News051220198.jpg"
import img9 from "../../assets/News/News051220199.jpg"
import img10 from "../../assets/News/News0512201910.jpg"
import img11 from "../../assets/News/News0512201911.jpg"
import img12 from "../../assets/News/News0512201912.jpg"
import img13 from "../../assets/News/News0512201913.jpg"
import img14 from "../../assets/News/News0512201914.jpg"
import img15 from "../../assets/News/News0512201915.jpg"
import img16 from "../../assets/News/News0512201916.jpg"
import YouTube from "react-youtube";

const News05122019 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Подведены итоги Всероссийского конкурса «Доброшкола»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>29 ноября 2019 года в рамках Всероссийской конференции «Итоги реализации федерального проекта
                «Современная школа» национального проекта «Образование» в 2019 году» состоялось торжественное
                награждение победителей Всероссийского конкурса «Доброшкола».</p>
            <p>Конкурс проводился в целях выявления лучших практик образовательных организаций, осуществляющих
                образовательную деятельность по адаптированным основным общеобразовательным программам, по формированию
                развивающего, обучающего пространства посредством дизайна школы в условиях обновления инфраструктуры
                школ.</p>
            <p>Конкурс проводился по пяти номинациям:</p>
            <p>1. «Лучший развивающий класс (учебный кабинет)»;<br/>
                2. «Лучшее развивающее пространство школы»;<br/>
                3. «Лучшее развивающее пространство учебной мастерской»;<br/>
                4. «Лучшее развивающее пространство школьной библиотеки»;<br/>
                5. «Лучший кабинет для коррекционно-развивающих занятий».</p>
            <p>Конкурс состоял из двух этапов: регионального и федерального</p>
            <p>На конкурс было подано 90 заявок из 38 субъектов Российской Федерации. На федеральном этапе экспертиза
                конкурсных работ проводилась экспертным советом.</p>
            <p>По итогам экспертизы получены следующие результаты:</p><br/>
        </div>
        <div style={{fontSize: "20px", fontFamily: "Proxima Nova Lt"}}>
            <p>Номинация «Лучший развивающий класс (учебный кабинет)»:</p>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p><strong>1 место</strong> Государственное казенное образовательное учреждение Ростовской области
                «Николаевская специальная
                школа-интернат»</p>
            <p><strong>2 место</strong> Государственное казенное общеобразовательное учреждение «Специальная
                (коррекционная)
                общеобразовательная школа — интернат № 36 города Ставрополя» Ставропольский край</p>
            <p><strong>3 место</strong> Государственное бюджетное общеобразовательное учреждение «Бирская коррекционная
                школа-интернат
                для обучающихся с тяжелыми нарушениями речи» Республика Башкортостан</p>
        </div>
        <div style={{fontSize: "20px", fontFamily: "Proxima Nova Lt"}}>
            <p>Номинация «Лучшее развивающее пространство школы»:</p>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>1 место «Государственное казенное общеобразовательное учреждение» Специальная (коррекционная)
                общеобразовательная школа-интернат № 4» Ставропольского края</p>
            <p>2 место Государственное бюджетное учреждение Калининградской области общеобразовательной организации для
                детей с ограниченными возможностями здоровья «Багратионовская общеобразовательная школа-интернат № 5»
                Калининградской области</p>
            <p>3 место Государственное казенное общеобразовательное учреждение Ростовской области «Ростовская
                специальная школа-интернат № 42»</p>
        </div>
        <div style={{fontSize: "20px", fontFamily: "Proxima Nova Lt"}}>
            <p>Номинация «Лучшее развивающее пространство учебной мастерской»:</p>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>1 место Государственное бюджетное учреждение Калининградской области общеобразовательной организации для
                обучающихся, воспитанников с ограниченными возможностями здоровья «Калининградская средняя
                общеобразовательная школа-интернат»2 место Государственное казенное общеобразовательное учреждение
                Ростовской области «Николаевская специальная школа-интернат»</p>
            <p>2 место Государственное казенное общеобразовательное учреждение Ростовской области «Николаевская
                специальная школа-интернат»</p>
            <p>3 место Государственное казенное общеобразовательное учреждение Ростовской области «Ростовская
                специальная школа-интернат № 41»</p>
        </div>
        <div style={{fontSize: "20px", fontFamily: "Proxima Nova Lt"}}>
            <p>Номинация «Лучший кабинет для коррекционно-развивающих занятий»:</p>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>1 место Областное государственное казенное общеобразовательное учреждение для детей, нуждающихся в
                психолого-педагогической и медико-социальной помощи, «Ивановский областной центр
                психолого-медико-социального сопровождения»</p>
            <p>2 место Государственное казенное общеобразовательное учреждение Калужской области «Калужская школа для
                обучающихся с ограниченными возможностями здоровья «Гармония»</p>
            <p>3 место Государственное казенное общеобразовательное учреждение Ростовской области «Волгодонская
                специальная школа-интернат «Восхождение»</p>
            <p>В номинации «Лучшее развивающее пространство школьной библиотеки» была подана только одна заявка.
                Экспертный совет и организаторы конкурса приняли решение наградить участника специальным призом:
                Государственное общеобразовательное казенное учреждение Иркутской области «Специальная (коррекционная)
                школа г. Усть-Илимска».</p>
            <p>Победителей поздравила Татьяна Юрьевна Синюгина, заместитель Министра просвещения Российской Федерации, и вручила памятные знаки и ценные призы.</p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <div>
            <img src={img4}/>
        </div>
        <div>
            <img src={img5}/>
        </div>
        <div>
            <img src={img}/>
        </div>
        <div>
            <img src={img6}/>
        </div>
        <div>
            <img src={img7}/>
        </div>
        <div>
            <img src={img8}/>
        </div>
        <div>
            <img src={img9}/>
        </div>
        <div>
            <img src={img10}/>
        </div>
        <div>
            <img src={img11}/>
        </div>
        <div>
            <img src={img12}/>
        </div>
        <div>
            <img src={img13}/>
        </div>
        <div>
            <img src={img14}/>
        </div>
        <div>
            <img src={img15}/>
        </div>
        <div>
            <img src={img16}/>
        </div>
        <YouTube videoId="3-ZFoKBrzHM"  style={{width: "64vw"}}/>
        <ToNews/>
    </div>
}


export default News05122019;