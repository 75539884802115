import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/bestIncSchool.png"

const News24062022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            28 июня 2022 г. в 9:30 по московскому времени состоится вебинар для руководителей и специалистов Центров
            психолого-педагогической, медицинской и социальной помощи (ППМС-центр): «Деятельность ППМС-центров в
            современных условиях».
        </h2>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>28 июня 2022 г. Федеральный ресурсный центр ПМПК в 9:30 по московскому времени проводит вебинар для
                руководителей и специалистов Центров психолого-педагогической, медицинской и социальной помощи
                (ППМС-центр): «Деятельность ППМС-центров в современных условиях».</p>
            <p>В рамках вебинара планируется осветить вопросы нормативно-правового регулирования деятельности
                ППМС-центров; сопровождения вынужденных переселенцев (опыт Ростовской области), а также анализ отчетов
                ППМС-центров за 2020 год и заполнение формы мониторинга деятельности ППМС-центров за 2021 год.</p>
            <p>На вебинаре будет представлен опыт города Москвы в организации деятельности центра
                психолого-педагогической, медицинской и социальной помощи, а также опыт сетевого взаимодействия
                ППМС-центра с образовательными организациями.</p>
            <p>Кроме того, участники мероприятия будут проинформированы о подготовке и проведении Конкурса «Лучший Центр психолого-педагогической, медицинской и социальной помощи – 2022».</p>
            <p>Участие в вебинаре бесплатное.</p>
            <p>Регистрация на мероприятие по следующей ссылке:</p>
            <p><a href="https://events.webinar.ru/41646479/11674591">https://events.webinar.ru/41646479/11674591</a></p>
        </div>
        <ToNews/>
    </div>
}


export default News24062022;