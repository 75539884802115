import React, {useState} from "react";
import {Formik, Field, Form, ErrorMessage} from "formik";
import s from "./AboutUs.module.css"
import arr from "../../assets/arrow.svg"
import axios from "axios";

const Citizen = ({}) => {
    const [politics, setPolitics] = useState(false)
    const [notClicked, setNotClicked] = useState(true)
    function validateEmail(value) {
        let error;
        if (!value) {
            error = 'Обязательное поле';
        }
        return error;
    }
    const [file, setFile] = useState("")
    const [file1, setFile1] = useState("")
    const onMainPhotoSelected = (e) => {
        if(e.target.files.length){
            setFile(e.target.files[0])
        }
    }
    const onMainPhotoSelected1 = (e) => {
        if(e.target.files.length){
            setFile1(e.target.files[0])
        }
    }

    return <div>
        <div className={s.newHeader}>
            <h2>
                Обращение граждан
            </h2>
        </div>
       
        <div className={s.formCitizen}>
            <Formik
                initialValues={{name: '', lastname: ''}}

                onSubmit={(values, {setSubmitting}) => {
                    debugger
                    axios.post("https://fcprc.ru/api/citizen", {'тема_обращения': values.theme, 'отдел_обращения': values.dir, 
                                       'место_работы': values.textT, 'текст_обращения': values.textf, 'ФИО': values.name + ' ' + values.lastname + ' ' + values.surname ,
                                        'почта': values.email, 'телефон': values.phone, 'file': file, 'file2': file1}, {
                                            xsrfCookieName: 'csrftoken',
                                            xsrfHeaderName: 'X-CSRFTOKEN',
                                         headers: {
                                            "Content-Type": "multipart/form-data",
                                          }
                                     })
                }}
            >
                {({errors, touched, isSubmitting}) => (
                    <Form>
                        <div className={s.topnames}>
                            <div>
                                <div>
                                    <label fore="name">Имя</label>
                                </div>
                                <Field type="name" name="name" placeholder="Введите имя" validate={validateEmail}/>
                                {errors.name && touched.name && <div>{errors.name}</div>}
                            </div>
                            <div>
                                <div>
                                    <label fore="lastname">Фамилия</label>
                                </div>
                                <Field type="lastname" name="lastname" placeholder="Введите фамилию"
                                       validate={validateEmail}/>
                                {errors.lastname && touched.lastname && <div>{errors.lastname}</div>}
                            </div>
                            <div>
                                <div>
                                    <label fore="surname">Отчество</label>
                                </div>
                                <Field type="surname" name="surname" placeholder="Введите отчество"/>
                            </div>
                        </div>
                        <div className={s.downfields}>
                            <div>
                                <div>
                                    <div style={{marginTop: "15px"}}>
                                        <label fore="email">Электронная почта</label>
                                    </div>
                                    <Field type="email" name="email" placeholder="Введите адрес почты"/>
                                </div>
                            </div>
                            <div>
                                <div style={{marginTop: "15px"}}>
                                    <label fore="dir">Отдел куда обращение</label>
                                </div>
                                <Field component="select" name="dir" placeholder="Выберите" className={s.choose}>
                                    <option value="Не выбрано">Выберите</option>
                                    <option value="ФГБУ «Центр защиты прав и интересов детей»">ФГБУ «Центр защиты прав и интересов детей»</option>
                                    {/*<option value="ФРЦ психолого-медико-педагогической комиссии">ФРЦ психолого-медико-педагогической комиссии</option>*/}
                                    <option value="ФРЦ в сфере защиты прав детей, оставшихся без попечения
                                        родителей">ФРЦ в сфере защиты прав детей, оставшихся без попечения
                                        родителей
                                    </option>
                                </Field>
                            </div>
                            <div>
                                <div style={{marginTop: "15px"}}>
                                    <label fore="theme">Тема обращения</label>
                                </div>
                                <Field type="text" name="theme" placeholder="Введите тему обращения"/>
                            </div>
                            <div>
                                <div style={{marginTop: "15px"}}>
                                    <label fore="textf">Текст обращения</label>
                                </div>
                                <Field component="textarea" type="text" name="textf"
                                       placeholder="Введите текст обращения"/>
                            </div>
                        </div>
                        <div style={{textAlign: "start"}}>
                            <div style={{marginTop: "15px"}}>

                                <label fore="file1">Прикрепить файл (pdf)</label>
                            </div>
                            <input type="file" name="file1" onChange={onMainPhotoSelected}/>
                            <input id="file" name="file2" type="file" onChange={onMainPhotoSelected1}/>
                        </div>
                        <div style={{fontWeight: "normal", marginTop: "50px", margin: "40px auto", textAlign: "left"}}>



                            <div className={s.editedHotlineHref}>
                                <input type="checkbox" onClick={() => {
                                    politics ? setPolitics(false): setPolitics(true)
                                }}/>
                                Я даю согласие согласие на обработку <a href="https://fcprc.ru/media/media/behavior/20-1-29-04-2021_CIi14fS.pdf">персональных данных</a>
                            </div>
                           <button type="submit"  className={s.subBtn} onClick={() =>setNotClicked(false) }>
                                Отправить <img src={arr} style={{width: "20px"}}/>
                            </button>

                            
                            
                            {notClicked? <></> : <div style={{fontWeight: "bold", marginTop: "50px", margin: "40px auto", textAlign: "left"}}>Обращение было отправлено</div>}

                            <div
                                style={{
                                    padding: "20px 35px 30px 35px",
                                    backgroundColor: "#fdebf2",
                                    marginTop: "60px",
                                    fontSize: "17px",
                                    color: "#333333",
                                    fontFamily: "Proxima Nova Lt"
                                }}>
                                В соответствии с Методическими рекомендации по режиму труда органов государственной
                                власти, органов местного самоуправления и организаций с участием государства,
                                разработанными Министерством труда и социальной защиты Российской Федерации, ФГБУ «Центр
                                защиты прав и интересов детей» временно ограничивает личный прием граждан и рекомендует
                                обращаться в письменной форме.
                            </div>
                            <p></p>
                            <div className={s.rules}>
                                <div>
                                    В обращении в адрес ФГБУ «Центр защиты прав и интересов детей» может содержаться
                                    заявление, жалоба, предложение или запрос.
                                    <div>
                                        <ul>
                                            <li>
                                                Устные обращения граждан в виде телефонограмм в ФГБУ «Центр защиты прав
                                                и интересов детей» направляются по каналам сотовой связи по телефону: <a
                                                href="tel:+74994440806">+7
                                                (499) 444-08-06</a>, доб. 100-08.
                                            </li>
                                        </ul>
                                    </div>
                                    <p>
                                        Гражданин в своем обращении в ФГБУ «Центр защиты прав и интересов детей» в
                                        обязательном порядке указывает свои фамилию, имя, отчество (последнее — при
                                        наличии), адрес электронной почты, если ответ должен быть направлен в форме
                                        электронного документа, или почтовый адрес, если ответ должен быть направлен в
                                        письменной форме, излагает суть предложения, заявления или жалобы, ставит личную
                                        подпись и дату. В случае необходимости в подтверждение своих доводов гражданин
                                        прилагает к обращению соответствующие документы и материалы либо их копии.
                                        <div>

                                            Просим с пониманием отнестись к указанным требованиям законодательства,
                                            внимательно заполнить все предложенные реквизиты и четко формулировать суть
                                            проблемы. Это значительно ускорит рассмотрение обращения.
                                        </div>

                                    </p>
                                    <div>

                                        <h1 style={{fontFamily: "Proxima Nova Lt", fontWeight: "100"}}>
                                            Гражданин при рассмотрении его обращения в ФГБУ «Центр защиты прав и
                                            интересов детей» имеет право:
                                        </h1>
                                        <ul>
                                            <li>
                                                представлять дополнительные документы и материалы либо обращаться с
                                                просьбой об их истребовании, в том числе в электронной форме;

                                            </li>
                                            <li>
                                                знакомиться с документами и материалами, касающимися рассмотрения
                                                обращения, если это не затрагивает права, свободы и законные интересы
                                                других лиц и если в указанных документах и материалах не содержатся
                                                сведения, составляющие государственную или иную охраняемую федеральным
                                                законом тайну; получать письменный ответ по существу поставленных в
                                                обращении вопросов;

                                            </li>
                                            <li>
                                                получать уведомление о переадресации обращения в государственный орган,
                                                орган местного самоуправления или должностному лицу, в компетенцию
                                                которых входит решение поставленных в обращении вопросов;

                                            </li>
                                            <li>
                                                обращаться с жалобой на принятое по обращению решение или на действие
                                                (бездействие) в связи с рассмотрением обращения в административном и
                                                (или) судебном порядке в соответствии с законодательством Российской
                                                Федерации;

                                            </li>
                                            <li>
                                                обращаться с заявлением о прекращении рассмотрения обращения.

                                            </li>
                                        </ul>
                                    </div>
                                    <div>

                                        <h1 style={{fontFamily: "Proxima Nova Lt", fontWeight: "100"}}>
                                            Обращение не рассматривается при:

                                        </h1>
                                        <ul>
                                            <li>
                                                отсутствии фамилии и имени заявителя;

                                            </li>
                                            <li>
                                                наличии в тексте нецензурных или оскорбительных выражений;

                                            </li>
                                            <li>
                                                наличии в тексте угрозы жизни, здоровью и имуществу должностного лица, а
                                                также членов его семьи;

                                            </li>
                                            <li>
                                                наличии в тексте вопроса, на который заявителю уже давался письменный
                                                ответ по существу в связи с ранее направленными обращениями;

                                            </li>

                                        </ul>
                                        <p>
                                            При рассмотрении обращения не допускается разглашение сведений, содержащихся
                                            в обращении, а также сведений, касающихся частной жизни гражданина, без его
                                            согласия. Информация о персональных данных авторов обращений, направленных в
                                            электронном виде, хранится и обрабатывается с соблюдением требований
                                            законодательства Российской Федерации о персональных данных.


                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    </div>
}

export default Citizen