import React from "react";
import Falldown, {
    Falldown2,
    HrefLi,
    FileLi,
    Falldown2Mediacia,
    HrefLiDop,
    FalldownDop
} from "../common/FalldownList/Falldown";
import s from "./Mediacia.module.css"
import mediacia from "../../assets/Directions/4_1.svg"
import {useEffect, useState} from "react";
import axios from "axios";
import ico2 from "../../assets/img/MediaciaMail.svg"
import ico3 from "../../assets/img/MediaciaPhone.svg"


const TestMediacia = () => {
    const [respData, setData] = useState()

    useEffect(() => {
        axios.get("https://fcprc.ru/api/mediacia").then(
            res => {
                setData(res.data)
            })

    }, [])

    return <>
        <div className={s.superHeader}>
            <img src={mediacia}/>
            <h2>
                Отдел медиации
            </h2>
        </div>

        <div className={s.mCont}>

            <a href="mailto:mediacia@fcprc.ru"><img src={ico2} alt=""/>mediacia@fcprc.ru</a>

            <a href="tel:84994440806,10011"><img src={ico3} alt=""/>8(499)444-08-06 доб. 10011</a>

        </div>
        <div className={s.newHeader}>
            {/*О медиации */}
            {(respData ? respData[0] : false) ?
                <Falldown style={{margin: "0 auto"}} url={respData[0].img} header={respData[0].theme}>
                    {respData[0].files ? respData[0].files.map(file => <FileLi href={file.file}
                                                                               text={file.name}/>) : <></>}
                    {respData[0].refs ? respData[0].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    {respData[0].children ? respData[0].children.map(child =>
                        <Falldown2 href={child.ref} header={child.theme}>

                            {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                            {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                        </Falldown2>) : <></>}
                </Falldown> : <div></div>}

            {/*Медиация. Нормативно-правовые акты*/}
            {(respData ? respData[1] : false) ?
                <Falldown style={{margin: "0 auto"}} url={respData[1].img} header={respData[1].theme}>
                    {respData[1].files ? respData[1].files.map(file => <FileLi href={file.file}
                                                                               text={file.name}/>) : <></>}
                    {respData[1].refs ? respData[1].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    {respData[1].children ? respData[1].children.map(child =>
                        <Falldown2 href={child.ref} header={child.theme}>

                            {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                            {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                        </Falldown2>) : <></>}
                </Falldown> : <div></div>}

            {/*Горячая линия медиации и примирения в образовательных организациях*/}
            {(respData ? respData[11] : false) ?
                <Falldown style={{margin: "0 auto"}} url={respData[11].img} header={respData[11].theme}>
                    {respData[11].files ? respData[11].files.map(file => <FileLi href={file.file}
                                                                                 text={file.name}/>) : <></>}
                    {respData[11].refs ? respData[11].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    {respData[11].children ? respData[11].children.map(child =>
                        <Falldown2 href={child.ref} header={child.theme}>
                            {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                            {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                        </Falldown2>) : <></>}
                </Falldown> : <div></div>}

            {/*Библиотека Практик Медиатора*/}
            {(respData ? respData[14] : false) ?
                <Falldown style={{margin: "0 auto"}} url={respData[14].img} header={respData[14].theme}>
                    {respData[14].files ? respData[14].files.map(file => <FileLi href={file.file}
                                                                                 text={file.name}/>) : <></>}
                    {respData[14].refs ? respData[14].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    {respData[14].children ? respData[14].children.map(child =>
                        <Falldown2 href={child.ref} header={child.theme}>
                            {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                            {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                        </Falldown2>) : <></>}
                </Falldown> : <div></div>}

            {/*Службы медиации в образовательных организациях и в организациях для детей-сирот и детей, оставшихся без попечения родителей*/}
            {(respData ? respData[2] : false) ?
                <Falldown style={{margin: "0 auto"}} url={respData[2].img} header={respData[2].theme}>
                    {respData[2].files ? <>
                        <FileLi href={respData[2].files[1].file} text={respData[2].files[1].name}/>
                        <FileLi href={respData[2].files[0].file}
                                text={respData[2].files[0].name}/>
                    </> : <></>}
                    {respData[2].refs ? respData[2].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    {respData[2].children ? respData[2].children.map(child =>
                        <Falldown2 href={child.ref} header={child.theme}>
                            {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                            {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                        </Falldown2>) : <></>}
                </Falldown> : <div></div>}

            {/*Дополнительное профессиональное образование по медиации и восстановительным технологиям*/}
            {(respData ? respData[12] : false) ?
                <Falldown style={{margin: "0 auto"}} url={respData[13].img} header={respData[13].theme}>
                    {respData[13].files ? respData[13].files.map(file => <FileLi href={file.file}
                                                                                 text={file.name}/>) : <></>}
                    {respData[13].refs ? respData[13].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    {respData[13].children ? respData[13].children.map(child =>
                        <Falldown2 href={child.ref} header={child.theme}>

                            {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                            {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                        </Falldown2>) : <></>}
                </Falldown> : <div></div>}


            {/*Службы медиации в специальных учебно-воспитательных учреждениях, подведомственных Минпросвещения России*/}
            {(respData ? respData[3] : false) ?
                <Falldown style={{margin: "0 auto"}} url={respData[3].img} header={respData[3].theme}>
                    {respData[3].files ? respData[3].files.map(file => <FileLi href={file.file}
                                                                               text={file.name}/>) : <></>}
                    {respData[3].refs ? respData[3].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}


                    {/*2024 год*/}
                    {respData[3].children[4] ?
                        <Falldown2Mediacia href={respData[3].children[4].ref} header={respData[3].children[4].theme}>
                            {respData[3].children[4].files ? respData[3].children[4].files.map(file => <FileLi
                                href={file.file}
                                text={file.name}/>) : <></>}
                            {respData[3].children[4].refs ?
                                <>
                                    <HrefLi
                                        href={respData[3].children[4].refs[6].ref}
                                        text={respData[3].children[4].refs[6].name}/>
                                    <HrefLi
                                        href={respData[3].children[4].refs[5].ref}
                                        text={respData[3].children[4].refs[5].name}/>
                                    <HrefLi
                                        href={respData[3].children[4].refs[4].ref}
                                        text={respData[3].children[4].refs[4].name}/>
                                    <HrefLi
                                        href={respData[3].children[4].refs[3].ref}
                                        text={respData[3].children[4].refs[3].name}/>
                                    <HrefLi
                                        href={respData[3].children[4].refs[2].ref}
                                        text={respData[3].children[4].refs[2].name}/>
                                    <HrefLi
                                        href={respData[3].children[4].refs[1].ref}
                                        text={respData[3].children[4].refs[1].name}/>
                                    <HrefLi
                                        href={respData[3].children[4].refs[0].ref}
                                        text={respData[3].children[4].refs[0].name}/>
                                </> : <></>}
                        </Falldown2Mediacia> : <></>}


                    {/*2023 год*/}
                    {respData[3].children[0] ?
                        <Falldown2Mediacia href={respData[3].children[0].ref} header={respData[3].children[0].theme}>
                            {respData[3].children[0].files ? respData[3].children[0].files.map(file => <FileLi
                                href={file.file}
                                text={file.name}/>) : <></>}
                            {respData[3].children[0].refs ?
                                <>
                                    <HrefLi
                                        href={respData[3].children[0].refs[7].ref}
                                        text={respData[3].children[0].refs[7].name}/>
                                    <HrefLi
                                        href={respData[3].children[0].refs[6].ref}
                                        text={respData[3].children[0].refs[6].name}/>
                                    <HrefLi
                                        href={respData[3].children[0].refs[5].ref}
                                        text={respData[3].children[0].refs[5].name}/>
                                    <HrefLi
                                        href={respData[3].children[0].refs[4].ref}
                                        text={respData[3].children[0].refs[4].name}/>
                                    <HrefLi
                                        href={respData[3].children[0].refs[0].ref}
                                        text={respData[3].children[0].refs[0].name}/>

                                    <HrefLi
                                        href={respData[3].children[0].refs[1].ref}
                                        text={respData[3].children[0].refs[1].name}/>
                                    <HrefLi
                                        href={respData[3].children[0].refs[2].ref}
                                        text={respData[3].children[0].refs[2].name}/>

                                    <HrefLi
                                        href={respData[3].children[0].refs[3].ref}
                                        text={respData[3].children[0].refs[3].name}/>
                                </> : <></>}
                        </Falldown2Mediacia> : <></>}

                    {/*2022 год*/}
                    {respData[3].children[1] ?
                        <Falldown2Mediacia href={respData[3].children[1].ref} header={respData[3].children[1].theme}>
                            {respData[3].children[1].files ? respData[3].children[1].files.map(file => <FileLi
                                href={file.file}
                                text={file.name}/>) : <></>}
                            {respData[3].children[0].refs ?
                                <>
                                    <HrefLi
                                        href={respData[3].children[1].refs[8].ref}
                                        text={respData[3].children[1].refs[8].name}/>
                                    <HrefLi
                                        href={respData[3].children[1].refs[0].ref}
                                        text={respData[3].children[1].refs[0].name}/>
                                    <HrefLi
                                        href={respData[3].children[1].refs[1].ref}
                                        text={respData[3].children[1].refs[1].name}/>
                                    <HrefLi
                                        href={respData[3].children[1].refs[2].ref}
                                        text={respData[3].children[1].refs[2].name}/>
                                    <HrefLi
                                        href={respData[3].children[1].refs[3].ref}
                                        text={respData[3].children[1].refs[3].name}/>
                                    <HrefLi
                                        href={respData[3].children[1].refs[4].ref}
                                        text={respData[3].children[1].refs[4].name}/>


                                    <HrefLi
                                        href={respData[3].children[1].refs[5].ref}
                                        text={respData[3].children[1].refs[5].name}/>
                                    <HrefLi
                                        href={respData[3].children[1].refs[6].ref}
                                        text={respData[3].children[1].refs[6].name}/>
                                    <HrefLi
                                        href={respData[3].children[1].refs[7].ref}
                                        text={respData[3].children[1].refs[7].name}/>

                                </> : <></>}
                        </Falldown2Mediacia> : <></>}

                    {/*2021 год*/}
                    {respData[3].children[2] ?
                        <Falldown2Mediacia href={respData[3].children[2].ref} header={respData[3].children[2].theme}>
                            {respData[3].children[2].files ? respData[3].children[2].files.map(file => <FileLi
                                href={file.file}
                                text={file.name}/>) : <></>}
                            {respData[3].children[2].refs ? respData[3].children[2].refs.map(ref => <HrefLi
                                href={ref.ref}
                                text={ref.name}/>) : <></>}
                        </Falldown2Mediacia> : <></>}

                    {/*Обучение*/}
                    {respData[3].children[3] ?
                        <Falldown2Mediacia href={respData[3].children[3].ref} header={respData[3].children[3].theme}>
                            {respData[3].children[3].files ? respData[3].children[3].files.map(file => <FileLi
                                href={file.file}
                                text={file.name}/>) : <></>}
                            {respData[3].children[3].refs ? respData[3].children[3].refs.map(ref => <HrefLi
                                href={ref.ref}
                                text={ref.name}/>) : <></>}
                        </Falldown2Mediacia> : <></>}

                </Falldown> : <div></div>}

            {/*Просветительская деятельность (форумы, конференции, совещания)*/}
            {(respData ? respData[4] : false) ?
                <Falldown style={{margin: "0 auto"}} url={respData[4].img} header={respData[4].theme}>
                    {respData[4].files ? respData[4].files.map(file => <FileLi href={file.file}
                                                                               text={file.name}/>) : <></>}
                    {respData[4].refs ? respData[4].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    {respData[4].children ? respData[4].children.map(child =>
                        <Falldown2 href={child.ref} header={child.theme}>
                            {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                            {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                        </Falldown2>) : <></>}
                </Falldown> : <div></div>}

            {/*Участие в мероприятиях партнеров*/}
            {(respData ? respData[5] : false) ?
                <Falldown style={{margin: "0 auto"}} url={respData[5].img} header={respData[5].theme}>
                    {respData[5].files ? respData[5].files.map(file => <FileLi href={file.file}
                                                                               text={file.name}/>) : <></>}
                    {respData[5].refs ? <>
                        <HrefLi href={respData[5].refs[19].ref} text={respData[5].refs[19].name}/>
                        <HrefLi href={respData[5].refs[18].ref} text={respData[5].refs[18].name}/>
                        <HrefLi href={respData[5].refs[17].ref} text={respData[5].refs[17].name}/>
                        <HrefLi href={respData[5].refs[16].ref} text={respData[5].refs[16].name}/>

                        <HrefLi href={respData[5].refs[15].ref} text={respData[5].refs[15].name}/>
                        <HrefLi href={respData[5].refs[14].ref} text={respData[5].refs[14].name}/>
                        <HrefLi href={respData[5].refs[2].ref} text={respData[5].refs[2].name}/>
                        <HrefLi href={respData[5].refs[1].ref} text={respData[5].refs[1].name}/>
                        <HrefLi href={respData[5].refs[13].ref} text={respData[5].refs[13].name}/>

                        <HrefLi href={respData[5].refs[12].ref} text={respData[5].refs[12].name}/>
                        <HrefLi href={respData[5].refs[11].ref} text={respData[5].refs[11].name}/>
                        <HrefLi href={respData[5].refs[10].ref} text={respData[5].refs[10].name}/>
                        <HrefLi href={respData[5].refs[0].ref} text={respData[5].refs[0].name}/>


                        <HrefLi href={respData[5].refs[3].ref} text={respData[5].refs[3].name}/>
                        <HrefLi href={respData[5].refs[4].ref} text={respData[5].refs[4].name}/>
                        <HrefLi href={respData[5].refs[5].ref} text={respData[5].refs[5].name}/>
                        <HrefLi href={respData[5].refs[6].ref} text={respData[5].refs[6].name}/>
                        <HrefLi href={respData[5].refs[7].ref} text={respData[5].refs[7].name}/>
                        <HrefLi href={respData[5].refs[8].ref} text={respData[5].refs[8].name}/>
                        <HrefLi href={respData[5].refs[9].ref} text={respData[5].refs[9].name}/>

                    </> : <></>}
                    {respData[5].children ? respData[5].children.map(child =>
                        <Falldown2 href={child.ref} header={child.theme}>
                            {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                            {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                        </Falldown2>) : <></>}
                </Falldown> : <div></div>}

            {/*Мониторинг служб медиации*/}
            {(respData ? respData[6] : false) ?
                <Falldown style={{margin: "0 auto"}} url={respData[6].img} header={respData[6].theme}>
                    {respData[6].files ? respData[6].files.map(file => <FileLi href={file.file}
                                                                               text={file.name}/>) : <></>}
                    {respData[6].refs ? respData[6].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    {respData[6].children ? respData[6].children.map(child =>
                        <Falldown2 href={child.ref} header={child.theme}>
                            {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                            {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                        </Falldown2>) : <></>}
                </Falldown> : <div></div>}

            {/*Реестр НКО, применяющих медиативные и восстановительные технологии*/}
            {(respData ? respData[7] : false) ?
                <Falldown style={{margin: "0 auto"}} url={respData[7].img} header={respData[7].theme}>
                    {respData[7].files ? respData[7].files.map(file => <FileLi href={file.file}
                                                                               text={file.name}/>) : <></>}
                    {respData[7].refs ? respData[7].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    {respData[7].children ? respData[7].children.map(child =>
                        <Falldown2 href={child.ref} header={child.theme}>
                            {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                            {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                        </Falldown2>) : <></>}
                </Falldown> : <div></div>}

            {/*Список организаций, реализующих программы подготовки медиаторов*/}
            {(respData ? respData[12] : false) ?
                <Falldown style={{margin: "0 auto"}} url={respData[12].img} header={respData[12].theme}>
                    {respData[12].files ? respData[12].files.map(file => <FileLi href={file.file}
                                                                                 text={file.name}/>) : <></>}
                    {respData[12].refs ? respData[12].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    {respData[12].children ? respData[12].children.map(child =>
                        <Falldown2 href={child.ref} header={child.theme}>
                            {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                            {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                        </Falldown2>) : <></>}
                </Falldown> : <div></div>}

            {/*Взаимодействие с КДНиЗП*/}
            {(respData ? respData[8] : false) ?
                <Falldown style={{margin: "0 auto"}} url={respData[8].img} header={respData[8].theme}>
                    {respData[8].files ? <>
                        <FileLi href={respData[8].files[0].file} text={respData[8].files[0].name}/>
                        <FileLi href={respData[8].files[1].file}
                                text="Методические рекомендации, содержащие порядок действий органов и учреждений системы профилактики безнадзорности и правонарушений несовершеннолетних с использованием восстановительного подхода в случаях отказов родителей (законных представителей) несовершеннолетних от предлагаемых мероприятий в рамках проводимой индивидуальной профилактической работы (2019)"/>
                    </> : <></>}
                    {respData[8].refs ? respData[8].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    {respData[8].children ? respData[8].children.map(child =>
                        <Falldown2 href={child.ref} header={child.theme}>
                            {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                            {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                        </Falldown2>) : <></>}
                </Falldown> : <div></div>}

            {/*Взаимодействие с органами опеки и попечительства*/}
            {(respData ? respData[9] : false) ?
                <Falldown style={{margin: "0 auto"}} url={respData[9].img} header={respData[9].theme}>
                    {respData[9].files ? respData[9].files.map(file => <FileLi href={file.file}
                                                                               text={file.name}/>) : <></>}
                    {respData[9].refs ? respData[9].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    {respData[9].children ? respData[9].children.map(child =>
                        <Falldown2 href={child.ref} header={child.theme}>
                            {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                            {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                        </Falldown2>) : <></>}
                </Falldown> : <div></div>}

            {/*«Школа юного медиатора»*/}
            {(respData ? respData[10] : false) ?
                <Falldown style={{margin: "0 auto"}} url={respData[10].img} header={respData[10].theme}>
                    {respData[10].files ? respData[10].files.map(file => <FileLi href={file.file}
                                                                                 text={file.name}/>) : <></>}
                    {respData[10].refs ? respData[10].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}

                    {/*2024 год*/}
                    {respData[10].children[3] ?
                        <Falldown2Mediacia href={respData[10].children[3].ref} header={respData[10].children[3].theme}>
                            {respData[10].children[3].files ? respData[10].children[3].files.map(file => <FileLi
                                href={file.file}
                                text={file.name}/>) : <></>}
                            {respData[10].children[3].refs ? respData[10].children[3].refs.map(ref => <HrefLi
                                href={ref.ref}
                                text={ref.name}/>) : <></>}
                            <p></p>
                        </Falldown2Mediacia> : <></>}

                    {/*2023 год*/}
                    {respData[10].children[2] ?
                        <Falldown2Mediacia href={respData[10].children[2].ref} header={respData[10].children[2].theme}>
                            {respData[10].children[2].files ? respData[10].children[2].files.map(file => <FileLi
                                href={file.file}
                                text={file.name}/>) : <></>}
                            {respData[10].children[2].refs ? respData[10].children[2].refs.map(ref => <HrefLi
                                href={ref.ref}
                                text={ref.name}/>) : <></>}
                        </Falldown2Mediacia> : <></>}

                    {/*2022 год*/}
                    {respData[10].children[0] ?
                        <Falldown2Mediacia href={respData[10].children[0].ref} header={respData[10].children[0].theme}>
                            {respData[10].children[0].files ?
                                <>
                                    <FileLi
                                        href={respData[10].children[0].files[1].file}
                                        text={respData[10].children[0].files[1].name}/>
                                    <FileLi
                                        href={respData[10].children[0].files[0].file}
                                        text={respData[10].children[0].files[0].name}/>
                                    <FileLi
                                        href={respData[10].children[0].files[2].file}
                                        text={respData[10].children[0].files[2].name}/>
                                    <FileLi
                                        href={respData[10].children[0].files[3].file}
                                        text={respData[10].children[0].files[3].name}/>

                                </> : <></>}
                            {respData[10].children[0].files ? respData[10].children[0].refs.map(ref => <HrefLi
                                href={ref.ref}
                                text={ref.name}/>) : <></>}

                        </Falldown2Mediacia> : <></>}

                    {/*2021 год*/}
                    {respData[10].children[1] ?
                        <Falldown2Mediacia href={respData[10].children[1].ref} header={respData[10].children[1].theme}>
                            {respData[10].children[0].files ?
                                <>
                                    <FileLi
                                        href={respData[10].children[0].files[1].file}
                                        text={respData[10].children[0].files[1].name}/>
                                    <FileLi
                                        href={respData[10].children[0].files[0].file}
                                        text={respData[10].children[0].files[0].name}/>
                                    <FileLi
                                        href={respData[10].children[0].files[2].file}
                                        text={respData[10].children[0].files[2].name}/>
                                    <FileLi
                                        href={respData[10].children[0].files[3].file}
                                        text={respData[10].children[0].files[3].name}/>


                                </> : <></>}
                            {respData[10].children[1].refs ? respData[10].children[1].refs.map(ref => <HrefLi
                                href={ref.ref}
                                text={ref.name}/>) : <></>}
                        </Falldown2Mediacia> : <></>}


                </Falldown> : <div></div>}


            <p></p>
        </div>
    </>
}


export default TestMediacia