import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/bestIncSchool.png"

const News020421 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сопровождение служб медиации СУВУ в марте
        </h2>
        <div style={{fontFamily: "Proxima Nova Lt"}}>

            В марте специалисты ФГБУ «Центр защиты прав и интересов детей» продолжили работу по сопровождению служб
            медиации в СУВУ. Были проведены двухдневные дистанционные групповые мероприятия в Ишимбайском, Куртамышском,
            Рефтинском СУВУ (11-12 марта), Калтанском, Каргатском, Омском СУВУ (18-19 марта), Мончегорском и Орловском
            СУВУ (25-26 марта).
            <p></p>
            Первый день был посвящен консультации сотрудников по вопросам организации работы службы, рассмотрению
            текущего положения дел и обмену опытом между участниками.
            <p></p>
            Во второй день обучающиеся из разных СУВУ пробовали разбирать спорные ситуации, предлагая свои варианты
            решения и оценивая варианты, озвученные сверстниками. Анализируя презентационные материалы, воспитанники
            пытались определять чувства и эмоциональное состояние конфликтующих, после чего учились базовым техникам
            работы с эмоциями. Занятия прошли в атмосфере доверия и взаимопонимания.
            <p></p>
            На апрель запланировано проведение мероприятий в Раифском, Санкт-Петербургском, Неманском, Себежском,
            Щёкинском и Майкопском СУВУ.
        </div>
        <ToNews/>
    </div>
}


export default News020421;