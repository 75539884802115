import React from "react";
import ico from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, WhiteButton} from "../../common/PageInfo/PageInfo"
import {ArrowRightOutlined} from "@ant-design/icons";
import {FileLeftLineKDN} from "./FilesKDNNews";


export const hrefsArr = [
    {
        text: "Нормативно-правовые документы",
        input: [{text: "Федеральные законы", to: "/spec-materials-category/federalnye-zakony"}, {
            text: "Стратегии",
            to: "/spec-materials-category/strategii"
        }, {text: "Концепции", to: "/spec-materials-category/kontseptsii"}, {
            text: "Иные регламентирующие документы",
            to: "/spec-materials-category/inye-reglamentiruyushhie-dokumenty"
        }],
        to: "/spec-materials-category/normativno-pravovye-dokumenty"
    },
    {
        text: "Материалы для специалистов",
        input: [{
            text: "Материалы антинаркотического содержания, разработанные в субъектах Российской Федерации",
            to: "/spec-materials-category/materialy-antinarkoticheskogo-soderzhaniya-razrabotannye-v-subektah-rossijskoj-federatsii/"
        }, {
            text: "Методические материалы по профилактике аддиктивного поведения",
            to: "/spec-materials-category/metodicheskie-materialy-po-profilaktike-addiktivnogo-povedeniya"
        }, {text: "Статьи и публикации", to: "/spec-materials-category/stati-i-publikatsii"}],
        to: "/spec-materials-category/metodicheskie-materialy-dlya-spetsialistov/"
    },
    {
        text: "Материалы для родителей",
        input: [{
            text: "Материалы антинаркотического содержания, разработанные в субъектах Российской Федерации",
            to: "/spec-materials-category/materialy-antinarkoticheskogo-soderzhaniya-razrabotannye-v-subektah-rossijskoj-federatsii-informatsionnye-materialy-dlya-roditelej"
        }],
        to: "/spec-materials-category/informatsionnye-materialy-dlya-roditelej"
    },
    {
        text: "Материалы для подростков и молодёжи",
        to: "/spec-materials-category/materialy-dlya-podrostkov-i-molodezhi",
        input: [{
            text: "Информационные материалы для обучающихся",
            to: "/spec-materials-category/informatsionnye-materialy-dlya-obuchayushhihsya"
        }, {
            text: "Видеоролики, направленные на профилактику зависимого поведения обучающихся и формирование здорового образа жизни",
            to: "/spec-materials-category/videoroliki-po-formirovaniyu-navykov-protivostoyaniya-riskam-addiktivnogo-povedeniya-i-bezopasnogo-zdorovogo-obraza-zhizni"
        }]
    },
    {
        text: "Эффективная региональная политика",
        input: [{
            text: "Материалы антинаркотического содержания, разработанные в субъектах Российской Федерации",
            to: "/spec-materials-category/materialy-antinarkoticheskogo-soderzhaniya-razrabotannye-v-subektah-rossijskoj-federatsii-luchshie-praktiki"
        }],
        to: "/spec-materials-category/luchshie-praktiki"
    },
    {text: "Мероприятия", to: "/spec-materials-category/meropriyatiya"},
    {text: "Полезная информация", to: "/spec-materials-category/poleznye-ssylki-goryachie-linii"},
]
export const PinkButton = ({text, stylesheet, refs}) => {
    return <div className={s.pinkbutton}>
        <a href={refs}>

            {text}
        </a>

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const FilesNPD = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Нормативно-правовые документы
                </h2>
                <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                    Федеральные законы
                </h1>
                <p></p>
                <FileLeftLineKDN text={respData[207]?.description} refers={respData[207]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[208]?.description} refers={respData[208]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[209]?.description} refers={respData[209]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[210]?.description} refers={respData[210]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[211]?.description} refers={respData[211]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[212]?.description} refers={respData[212]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[213]?.description} refers={respData[213]?.file} ico={ico}/>

                <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                    Стратегии
                </h1>

                <FileLeftLineKDN text={respData[8]?.description} refers={respData[8]?.file} ico={ico}/>
                <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>

                    Концепции
                </h1>

                <FileLeftLineKDN text={respData[255]?.description} refers={respData[255]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[9]?.description} refers={respData[9]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[204]?.description} refers={respData[204]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[206]?.description} refers={respData[206]?.file} ico={ico}/>
                <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                    Иные регламентирующие документы
                </h1>

                <FileLeftLineKDN text={respData[12]?.description} refers={respData[12]?.file} ico={ico}/>
                <FileLeftLineKDN text="Приказ Минпросвещения России 19.09.2023 № 703"
                                 refers="https://fcprc.ru/media/media/behavior/Приказ_Минпросвещения_России_19.09.2023__703.pdf"
                                 ico={ico}/>
                <FileLeftLineKDN text={respData[14]?.description} refers={respData[14]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[203]?.description} refers={respData[203]?.file} ico={ico}/>


            </div>

            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArr}/>
                <PinkButton text="Материалы по профилактике ВИЧ/СПИДa" stylesheet={"pinkbutton"}
                            refs="/spec-materials-category/metodicheskie-materialy-po-profilaktike-vich-spida/"/>
                <PinkButton text="Инструкция по подготовке материалов по профилактике"
                            refs="fcprc.ru/media/media/behavior/07-6523-s-Pr.pdf"/>
                <WhiteButton text="Перейти к опросу родителей по информированности о профилактике ПАВ"
                             refs="http://опрос-родителей-о-пав.рф/"/>
                <WhiteButton text="Перейти к опросу молодёжи о ВИЧ" refs="http://опрос-молодежи-о-вич.рф/"/>
                <WhiteButton text="Перейти к опросу педагогов о профилактике ВИЧ-инфекции среди обучающихся"
                             refs="http://опрос-педагогов-о-вич.рф/"/>
            </div>
        </div>
    </>
}


export default FilesNPD