import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News131223.jpg"

const News141223 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинары для родителей обучающихся специальных учебно-воспитательных учреждений в декабре 2023 года
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            6 и 7 декабря 2023 года отдел медиации Центра защиты прав и интересов детей провел заключительные в этом
            году два вебинара для родителей (законных представителей) обучающихся специальных учебно-воспитательных
            учреждений, подведомственных Минпросвещения России по теме «Медиативные технологии как способ договориться с
            ребенком».

            <p></p>
            Структура вебинаров была построена так, чтобы слушатели смогли составить для себя структурированное
            представление о конфликте, его сущности, причинах возникновения, последствиях деструктивных споров.
            <p></p>
            Затем следовало знакомство с медиативным подходом, принципами и техниками медиации, которые способны помочь
            в выстраивании уважительного и дружественного общения в семье.
            <p></p>

            Хочется отметить, что ценность данных вебинаров заключается в том, что благодаря им удается реализовать
            комплексную работу. Обучающийся знакомиться с ненасильственными способами общения и разрешения конфликтов в
            училище и одновременно с ним родители также приобщаются к такому формату взаимодействия на наших вебинарах.
            <p></p>

            Медиативные технологии органично встраиваются в повседневную жизнь семьи и достаточно просты в освоении.
            <p></p>

            Данные вебинары для родителей проводятся нашим Центром уже второй год, и родители, и законных представители
            обучающихся СУВУ принимают в них участие, по собственному желанию.
            <p></p>

            Ведущий вебинаров: аналитик отдела медиации Центра защиты прав и интересов детей – Тюльканов Сергей
            Леонидович.
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News141223;