import React from "react";
import ico from "../../../assets/fileicored.svg"
import icoBlue from "../../../assets/arrow_blue.svg"
import s from "./PageFiles.module.css"
import {FileLeftLineSUVU} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1} from "../PageInfo/PageInfo"
import {Calendar} from "react-calendar";
import {useMediaQuery} from "react-responsive";


const hrefsArr = [
    {text: "Новости", to: "/spec-suvu/normativno-pravovye-dokumenty/"},
    {text: "Нормативное регулирование деятельности СУВУ", to: "/spec-suvu/normativno-pravovye-dokumenty/"},
    {
        text: "Информационно-методические материалы",
        to: "/spec-suvu/informatsionno-analiticheskie-materialy/"
    },
    {text: "Всероссийские мероприятия", to: "/spec-suvu/meropriyatiya/"},
    {text: "Люди. События. Факты.", to: "/spec-suvu/ludi-fakti-sobitiya/"},
]
const FilesSuvuVseros = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/suvu").then(
            res => {
                setData(res.data)
            })
    }, [])

    const isBig = useMediaQuery({ query: "(min-width: 769px)" });
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Календарь мероприятий
                </h2>
                {/*VK видео*/}
                {isBig && <iframe src="https://vk.com/video_ext.php?oid=-217392453&id=456239022&hash=ebcbbaae1f6f370b&hd=2"
                        width="700px" height="380px" allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                        frameBorder="0" allowFullScreen></iframe>}
                {!isBig && <iframe src="https://vk.com/video_ext.php?oid=-217392453&id=456239022&hash=ebcbbaae1f6f370b&hd=2"
                        width="100%" height="380px" allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                        frameBorder="0" allowFullScreen></iframe>}
                <h2>
                    Всероссийские мероприятия
                </h2>
                <FileLeftLineSUVU
                    text={"Календарь мероприятий СУВУ 2024"}
                    refers={"https://fcprc.ru/media/media/behavior/Календарь_мероприятий_СУВУ_2024.pdf"}
                    ico={icoBlue}/>
                <FileLeftLineSUVU
                    text={"Сопровождение служб медиации в специальных учебно-воспитательных учреждениях в июне 2022 года"}
                    refers={"/news/soprovozhdenie-sluzhb-mediatsii-v-spetsialnyh-uchebno-vospitatelnyh-uchrezhdeniyah-v-iyune-2022-goda"}
                    ico={icoBlue}/>
                <FileLeftLineSUVU
                    text={"Вебинар «Медиативные практики в работе с несовершеннолетними: содействие социализации» 20 мая 2022 года"}
                    refers={"https://spu30.ru/news/vebinar-mediativnye-praktiki-v-rabote-s-nesovershennoletnimi-sodeystvie-socializacii"}
                    ico={icoBlue}/>
                <FileLeftLineSUVU
                    text={"Сопровождение служб медиации в специальных учебно-воспитательных учреждениях в мае 2022 года"}
                    refers={"/news/soprovozhdenie-sluzhb-mediatsii-v-spetsialnyh-uchebno-vospitatelnyh-uchrezhdeniyah-v-mae-2022-goda"}
                    ico={icoBlue}/>
                <FileLeftLineSUVU
                    text={"Сопровождение служб медиации в специальных учебно-воспитательных учреждениях в апреле 2022 года"}
                    refers={"/news/soprovozhdenie-sluzhb-mediatsii-v-spetsialnyh-uchebno-vospitatelnyh-uchrezhdeniyah-v-aprele-2022-goda"}
                    ico={icoBlue}/>
                <FileLeftLineSUVU text={"Сопровождение служб медиации СУВУ в марте"}
                                  refers={"/news/soprovozhdenie-sluzhb-mediatsii-suvu-v-marte-3"} ico={icoBlue}/>
                <FileLeftLineSUVU text={"Сопровождение служб медиации СУВУ в ноябре и декабре"}
                                  refers={"/suvu/soprovozhdenie-sluzhb-mediatsii-suvu-v-noyabre-i-dekabre"}
                                  ico={icoBlue}/>
                <FileLeftLineSUVU text={"Сопровождение служб медиации СУВУ в сентябре-октябре"}
                                  refers={"/suvu/soprovozhdenie-sluzhb-mediatsii-suvu-v-sentyabre-oktyabre"}
                                  ico={icoBlue}/>
                <FileLeftLineSUVU
                    text={"12 мая состоялся обучающий вебинар, который дал старт мониторингу специальных условий содержания несовершеннолетних, безопасности деятельности учреждений"}
                    refers={"/suvu/12-maya-sostoyalsya-obuchayushhij-vebinar-kotoryj-dal-start-monitoringu-spetsialnyh-uslovij-soderzhaniya-nesovershennoletnih-bezopasnosti-deyatelnosti-uchrezhdenij"}
                    ico={icoBlue}/>
                <FileLeftLineSUVU
                    text={"Министерством просвещения Российской Федерации утвержден график проведения подведомственными специальными учебно-воспитательными учреждениями (далее – СУВУ) общественно значимых мероприятий в сфере образования, науки и молодежной политики в 2021 году"}
                    refers={"/suvu/ministerstvom-prosveshheniya-rossijskoj-federatsii-utverzhden-grafik-provedeniya-podvedomstvennymi-spetsialnymi-uchebno-vospitatelnymi-uchrezhdeniyami-dalee-suvu-obshhestvenno-znachimyh-meropriyatij-2"}
                    ico={icoBlue}/>
                <FileLeftLineSUVU
                    text={"16 апреля 2021 года состоялось Всероссийское совещание по вопросам организации деятельности специальных учебно-воспитательных учреждений (СУВУ) на тему «Ресурсы профессионального образования в ресоциализации детей с девиантным поведением»"}
                    refers={"/suvu/16-aprelya-2021-goda-sostoyalos-vserossijskoe-soveshhanie-po-voprosam-organizatsii-deyatelnosti-spetsialnyh-uchebno-vospitatelnyh-uchrezhdenij-suvu-na-temu-resursy-professionalnogo-obrazovaniya-v-reso"}
                    ico={icoBlue}/>
            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArr}/>
            </div>
        </div>
    </>
}
export default FilesSuvuVseros