import {useLocation} from "react-router-dom";
import {useEffect} from "react";

export const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
        const trackPageView = (url) => {
            if (window.ym) {
                window.ym(97235151, 'hit', url);
            }
        };

        trackPageView(location.pathname);
    }, [location]);
};