import React from "react";
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import img from "../../../assets/Behavior/beh3.png"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1} from "../PageInfo/PageInfo"

const hrefsArr = [
    {
        text: "Нормативно-правовые документы",
        input: [{text: "Федеральные законы", to: "/spec-materials-category/federalnye-zakony"}, {
            text: "Стратегии",
            to: "/spec-materials-category/strategii"
        }, {text: "Концепции", to: "/spec-materials-category/kontseptsii"}, {
            text: "Иные регламентирующие документы",
            to: "files-addect-behavior"
        }],
        to: "/spec-materials-category/normativno-pravovye-dokumenty"
    },
    {
        text: "Материалы для специалистов",
        input: [{
            text: "Материалы антинаркотического содержания, разработанные в субъектах Российской Федерации",
            to: ""
        },
            {
                text: "Методические материалы по профилактике аддиктивного поведения",
                to: "/"
            }, {text: "Статьи и публикации", to: ""}],
        to: ""
    },
    {text: "Материалы для родителей", to: "/spec-materials-category/informatsionnye-materialy-dlya-roditelej"},
    {
        text: "Материалы для подростков и молодёжи", to: "",
        input: [{
            text: "Информационные материалы для обучающихся",
            to: ""
        }, {
            text: "Видеоролики, направленные на профилактику зависимого поведения обучающихся и формирование здорового образа жизни",
            to: ""
        }]
    },
    {text: "Мероприятия", to: ""},
    {text: "Полезная информация", to: "/spec-materials-category/poleznye-ssylki-goryachie-linii"},
]
const TvoyoP = () => {
    const [respData, setData] = useState([])


    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Твоё право
                </h2>
                <img src={img} style={{maxWidth: "700px"}}/>
                <div style={{textAlign: "left", fontFamily: "Proxima Nova Lt", fontSize: "18px"}}>
                    Согласно официальной статистике, на настоящий момент в Российской Федерации не снижается количество
                    выявленных нарушений прав детей. Ежегодно почти 100 тысяч человек официально признаются потерпевшими
                    от преступлений, 20 тысяч несовершеннолетних разыскиваются органами внутренних дел как пропавшие без
                    вести, из них 8% в возрасте до 14 лет.
                    <p></p>


                    В то же время продолжает оставаться высоким уровень преступности среди несовершеннолетних: за
                    январь-июль 2016 года 31,5 тысячи преступлений совершены несовершеннолетними или при их соучастии.
                    Удельный вес от всех расследованных преступлений — 4,2%.
                    <p></p>


                    Поэтому вопрос защиты прав несовершеннолетних, формирования правовой культуры и юридической
                    грамотности обучающихся, родителей и педагогов становится одной из приоритетных задач государства.
                    Особое значение он имеет в отношении несовершеннолетних, вступивших в конфликт с законом.
                    <p></p>


                    Для обеспечения благополучного и безопасного детства, ставшего одним из основных национальных
                    приоритетов Российской Федерации определены ключевые задачи, указанные в Национальной стратегии
                    действий в интересах детей на 2012 — 2017 годы, утвержденной Указом Президента Российской Федерации
                    от 1 июня 2012 г. № 761 «О Национальной стратегии действий в интересах детей на 2012 — 2017 годы».
                    Этими задачами являются: защита прав каждого ребенка, создание эффективной системы профилактики
                    правонарушений, совершаемых в отношении детей, и правонарушений самих детей.
                    <p></p>


                    С целью создания условий для успешной социализации (ресоциализации) несовершеннолетних, формирования
                    у них готовности к саморазвитию, самоопределению и ответственному отношению к своей жизни была
                    разработана Концепция развития системы профилактики безнадзорности и правонарушений
                    несовершеннолетних на период до 2020 года, утвержденная Председателем Правительства Российской
                    Федерации Д. Медведевым 22 марта 2017 г. № 520-р.

                    <p></p>
                    <ul>

                        Для достижения цели Концепции необходимо решение следующих задач:
                        <li>

                            снижение количества правонарушений, совершенных несовершеннолетними, в том числе повторных;
                        </li>
                        <li>

                            реализация права каждого ребенка жить и воспитываться в семье, укрепление института семьи;
                        </li>
                        <li>

                            защита прав несовершеннолетних, создание условий для формирования достойной жизненной
                            перспективы;
                        </li>
                        <li>

                            совершенствование механизмов управления органами и учреждениями системы профилактики
                            безнадзорности и правонарушений несовершеннолетних, включая повышение эффективности
                            межведомственного взаимодействия;
                        </li>
                        <li>

                            совершенствование имеющихся и внедрение новых технологий и методов профилактической работы с
                            несовершеннолетними, в том числе расширение практики применения технологий
                            восстановительного подхода с учетом эффективной практики субъектов Российской Федерации;
                        </li>
                        <li>

                            повышение уровня профессиональной компетентности специалистов органов и учреждений системы
                            профилактики безнадзорности и правонарушений несовершеннолетних.
                        </li>
                    </ul>

                </div>
            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArr}/>
            </div>
        </div>
    </>
}
export default TvoyoP