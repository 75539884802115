import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News010622.jpg"

const News010622 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Итоги Всероссийской научно-практической конференции «На защите детства: стратегии, практики, ресурсы»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p><strong>31 мая – 1 июня 2022 года</strong> Министерством просвещения Российской Федерации проведена
                Всероссийская
                научно-практическая конференция по актуальным вопросам профилактики девиантного поведения
                несовершеннолетних <strong>«На защите детства: стратегии, практики, ресурсы»</strong>, цель которой –
                обсуждение
                эффективных механизмов выявления, предупреждения и устранения причин и условий, способствующих
                формированию девиантного поведения несовершеннолетних, с учетом современных исследований и успешной
                практики профилактической работы. Оператор конференции – федеральное государственное бюджетное
                учреждение «Центр защиты прав и интересов детей».</p>
            <p>В этом году проведение конференции приурочено к Международному дню защиты детей. Комбинированный формат
                проведения позволил присоединиться к мероприятиям конференции более <strong>3 000
                    участников</strong> из <strong>85 субъектов</strong>
                Российской Федерации.</p>
            <p>Среди участников конференции: представители Администрации Президента Российской Федерации; аппарата
                Уполномоченного при Президенте Российской Федерации по правам ребенка; заинтересованных федеральных
                органов государственной власти; органов и учреждений системы профилактики безнадзорности и
                правонарушений несовершеннолетних субъектов Российской Федерации; образовательных, научных и
                общественных организаций.</p>
            <p>В <strong>1-ый день – 31 мая</strong> – ПРОБЛЕМНО-ПОИСКОВЫЙ в рамках работы конференции прошли панельная
                дискуссия
                <strong>«Векторы профилактики»</strong>, фокус-сессия <strong>«От актуальных задач – к решениям и
                    ресурсам»</strong>, совещание по
                вопросам усиления координирующей роли федеральных органов власти в совершенствовании межведомственного
                взаимодействия в системе воспитания и профилактики, состоялась работа федеральной экспертной группы.</p>
            <p>Большой интерес у участников конференции вызвал открытый марафон мастер-классов <strong>«В поиске
                идей»</strong>,
                затронувший вопросы профилактики суицидов в детской среде, формирования социально-личностной
                жизнеспособности подростков с девиантным поведением, цифровой социализации детей и подростков,
                выстраивания доверия с ребенком, развития осознанного родительства, организации в практической
                деятельности педагога-психолога антибуллинговых мероприятий и многое другое.</p>
            <p><strong>2-й день</strong> работы конференции – <strong>1 июня</strong> был обозначен в программе как <strong>ПЛЕНАРНЫЙ</strong>. В этот день в
                очно-дистанционном формате с участием представителей Администрации Президента Российской Федерации,
                аппарата Уполномоченного при Президенте Российской Федерации по правам ребенка, заинтересованных
                федеральных органов власти, органов и учреждений системы профилактики безнадзорности и правонарушений
                несовершеннолетних субъектов Российской Федерации, научного сообщества и общественности прошла пленарная
                сессия <strong>«Стратегия, эффекты, перспективы»</strong>.</p>
            <p>Завершилась конференция видеомарафоном <strong>«Лента региональных практик»</strong>,
                в котором приняли участие <strong>48 субъектов</strong> Российской Федерации, представивших передовой опыт работы по
                профилактике девиантного поведения несовершеннолетних, интересные практики работы с детьми.</p>
            <p>По завершении мероприятия участники получили возможность внести предложения в резолюцию Всероссийской
                научно-практической конференции.</p>
            <p>Работа конференции в заочном формате продлится <strong>до декабря 2022 года</strong>.</p>
            <p>Также будет продолжено наполнение Ленты региональных практик.</p>
            <p>На сайте Центра защиты прав и интересов детей будут размещены все информационные, фото-, видео- и
                презентационные материалы конференции.</p>
        </div>
        <ToNews/>
    </div>
}


export default News010622;