import React from "react";
import ico from "../../../assets/arrow.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import kdnFile from '../../../assets/Files/form_kdn.zip'
import kdnFile2 from '../../../assets/Files/forma2.zip'
import kdnFile3 from '../../../assets/Files/1-KDN-sub.7z'
import kdnFile4 from '../../../assets/Files/1-KDN_2023_subiekty_RF.zip'
import {hrefsArrKDN} from "./FilesKDNGos";
import {FileLeftLineKDN, HrefsKDN} from "./FilesKDNNews";

const hrefsArr = [
    {text: "Новости и события", to: "/kdnnews"},
    {text: "Мероприятия", input: [], to: "/kdnivents"},
    {text: "О КДНиЗП", input: [], to: "/kdnHistory"},
    {text: "Правительственная КДНиЗП", to: "/kdnGos"},
    {text: "Методологические материалы", to: "/kdnDev"},
    {text: "Нормативная правовая база", to: "/kdnRights"},
    {text: "Всероссийские совещания КДНиЗП", input: ["2021 год", "Контакты"], to: "/kdnInternational"},
    {text: "Контакты", to: "/kdnContacts"},
    {text: "Актуальные интернет-ресурсы по вопросам профилактики", to: "/kdnActiveQ"},
    {text: "Реестр НКО", to: "/kdnReestr"},
    {text: "Сведения по форме ФСН №1-КДН", to: "/kdnInfoForm"},

]
const FilesKDNInfo = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/kdn").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>

        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Сведения по форме ФСН №1-КДН
                </h2>
                <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                    2023 год
                </h1>
                <FileLeftLineKDN text={"Форма №1-КДН по субъектам Российской федерации за 2023 год"} refers={kdnFile4}
                                 ico={ico}/>
                <FileLeftLineKDN text="Форма № 1-КДН сводные данные по Российской Федерации за 2023 год"
                                 refers="https://fcprc.ru/media/media/behavior/1-KDN_2023.xlsx" ico={ico}/>
                <FileLeftLineKDN text="Коды субъектов к форме ФСН № 1-КДН" refers="https://fcprc.ru/media/media/behavior/Kody_subiektov_.xls" ico={ico}/>
                <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                    2022 год
                </h1>
                <FileLeftLineKDN text={"Форма №1-КДН по субъектам Российской федерации за 2022 год"} refers={kdnFile3}
                                 ico={ico}/>
                <FileLeftLineKDN text={respData[66]?.description}
                                 refers={respData[66]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[63]?.description} refers={respData[63]?.file} ico={ico}/>
                {/*<FileLeftLine text={"1-КДН за 2022 год"} refers={"https://disk.yandex.ru/d/afiLebF_cGhpkA"} ico={ico}/>*/}
                <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                    2021 год
                </h1>
                <FileLeftLineKDN text={"Форма № 1-КДН сведения по субъектам Российской Федерации за 2021 год"}
                                 refers={kdnFile2} ico={ico}/>
                <FileLeftLineKDN text={respData[58]?.description} refers={respData[58]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[59]?.description} refers={respData[59]?.file} ico={ico}/>

                <div>
                    <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                        2020 год
                    </h1>
                    <div>
                        <FileLeftLineKDN text={respData[60]?.description} refers={respData[60]?.file} ico={ico}/>
                        <FileLeftLineKDN text={respData[61]?.description} refers={respData[61]?.file} ico={ico}/>
                        <FileLeftLineKDN text={"Форма № 1-КДН сведения по субъектам Российской Федерации за 2020 год"}
                                         refers={kdnFile} ico={ico}/>

                    </div>
                    {/*1*/}
                </div>
            </div>
            <div style={{marginTop: "-3px"}}>
                <HrefsKDN hrefsArr={hrefsArrKDN}/>
            </div>
        </div>

    </>
}
export default FilesKDNInfo