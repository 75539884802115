import React from "react";
import s from "./Default.module.css"
import ToNews from "../News/ToNews";
import img from "../../assets/News/News3005242.jpg"
import img1 from "../../assets/News/News3005242_1.jpg"
import img2 from "../../assets/News/News3005242_2.jpg"
import img3 from "../../assets/News/News3005242_3.jpg"
import img4 from "../../assets/News/News3005242_4.jpg"

const News3005242 = ({children, header}) => {

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Подписано соглашение о взаимодействии в развитии и поддержке наставничества детей и молодежи Российской
            Федерации в рамках круглого стола «Диалог на равных» 30 мая 2024 г.
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Директор ФГБУ «Центр защиты прав и интересов детей» Борис Гусев выступил экспертом на круглом столе «Диалог
            на равных», приуроченном к Международному дню защиты детей.
            <p></p>
            Были раскрыты и пересмотрены актуальные вопросы, касающиеся развития детей в современном мире:
            <ul>
                <li>Психологическое благополучие детей.</li>
                <li> Безопасная и эффективная цифровая грамотность детей.</li>
                <li>Информационные потоки и критическое мышление.</li>
                <li>Подготовка детей к будущим профессиям.</li>
            </ul>
            Круглый стол «Диалог на равных» состоялся 30 мая 2024 года во Всероссийском центре развития художественного
            творчества и гуманитарных технологий.

            <p></p>
            В рамках круглого стола «Диалог на равных» ФГБУ «Центр защиты прав и интересов детей» и Всероссийское
            общественное движение наставников детей и молодежи «Наставники России» договорились о сотрудничестве и
            взаимодействии в развитии и поддержке наставничества детей и молодежи Российской Федерации и было подписано
            СОГЛАШЕНИЕ.
            <p></p>

        </div>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <div>
            <img src={img4}/>
        </div>
        <ToNews/>
    </div>
}


export default News3005242;