import React from "react";
import ico from "../../../assets/fileicored.svg"
import arrowIco from "../../../assets/arrow.svg"
import s from "./PageFiles.module.css"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, WhiteButton} from "../PageInfo/PageInfo"
import {hrefsArr, PinkButton} from "./FilesNPD";
import {FileLeftLineKDN} from "./FilesKDNNews";

const FilesAddictiveBehavior = () => {
    const [respData, setData] = useState([])
    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })
    }, [])
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Методические материалы по профилактике<br/> аддиктивного поведения
                </h2>
                <FileLeftLineKDN
                    text="Памятка по созданию информационно-просветительского контента, призванного содействовать реализации антинаркотической политики и повышению уровня осведомленности граждан о рисках, связанных с потреблением наркотиков (разработана МРГ ГАК во исполнение подпункта 1.2.2 протокола заседания ГАК № 52 от 5 июля 2023 г.)"
                    refers="https://fcprc.ru/media/media/behavior/Pamiatka_egvrhcZ.pdf"
                    ico={ico}/>
                <FileLeftLineKDN
                    text={respData[590]?.description} refers={respData[590]?.file}
                    ico={ico}/>
                <FileLeftLineKDN
                    text={respData[583]?.description} refers={respData[583]?.file}
                    ico={ico}/>
                <FileLeftLineKDN
                    text={respData[584]?.description} refers={respData[584]?.file}
                    ico={ico}/>
                <FileLeftLineKDN text="Памятка для педагогов «Простые правила: профилактика употребления табака и иной никотиносодержащей продукции среди обучающихся»" refers="https://fcprc.ru/media/media/mediacia/ПРОСТЫЕ_ПРАВИЛА_профилактика_курения2023.pdf" ico={ico}/>
                <FileLeftLineKDN
                    text={respData[519]?.description} refers={respData[519]?.file}
                    ico={ico}/>
                
                <FileLeftLineKDN text={respData[229]?.description} refers={respData[229]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[230]?.description} refers={respData[230]?.file} ico={ico}/>

            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArr}/>
                <PinkButton text="Материалы по профилактике ВИЧ/СПИДa" stylesheet={"pinkbutton"}
                            refs="/spec-materials-category/metodicheskie-materialy-po-profilaktike-vich-spida/"/>
                <PinkButton text="Инструкция по подготовке материалов по профилактике"
                            refs="fcprc.ru/media/media/behavior/07-6523-s-Pr.pdf"/>
                <WhiteButton text="Перейти к опросу родителей по информированности о профилактике ПАВ"
                             refs="http://опрос-родителей-о-пав.рф/"/>
                <WhiteButton text="Перейти к опросу молодёжи о ВИЧ" refs="http://опрос-молодежи-о-вич.рф/"/>
                <WhiteButton text="Перейти к опросу педагогов о профилактике ВИЧ-инфекции среди обучающихся"
                             refs="http://опрос-педагогов-о-вич.рф/"/>
            </div>
        </div>
    </>
}
export default FilesAddictiveBehavior
