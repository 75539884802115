import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/SupportOfMediationServicesJune2022.jpeg"
import img2 from "../../assets/News/SupportOfMediationServicesJune2022_2.jpeg"

const SupportOfMediationServicesJune2022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сопровождение служб медиации в специальных учебно-воспитательных учреждениях в июне 2022 года
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>С 8 по 10 июня 2022 года специалисты <strong>отдела медиации Центра защиты прав и интересов
                детей</strong> провели три ознакомительных <strong>вебинара по теме: «Альтернативные методы
                предупреждения и разрешения конфликтов в специальных учебно-воспитательных учреждениях»</strong> для
                сотрудников служб и подразделений специальных учебно-воспитательных учреждений (далее – СУВУ), которые
                ранее не принимали участие в программах повышения квалификации по медиации и восстановительному подходу.
            </p>
            <p>
                <strong>Целью</strong> вебинаров являлось приобретение и актуализация знаний об альтернативных методах
                предупреждения и разрешения конфликтных ситуаций в СУВУ.
            </p>
            <p>
                В ходе мероприятий специалисты Центра знакомили сотрудников СУВУ с понятием конфликта и способами его
                разрешения. Ведущие вебинара совместно с участниками рассмотрели принципы и <strong>технологии медиации
                для
                предупреждения и разрешения конфликтов</strong> на примере конкретных ситуаций (между обучающимися,
                между
                обучающимся и учителем СУВУ).
            </p>
            <p>
                В заключении каждого вебинара разбирался вопрос использования медиативного подхода для эффективного
                взаимодействия сотрудников различных служб и подразделений СУВУ.
            </p>
            <p>
                По итогам вебинаров участники поделились своими впечатлениями, оставив отзывы. Например:
            </p>
            <p>
                — «Это было достаточно информативно и прекрасная возможность встретиться с коллегами из других СУВУ».
            </p>
            <p>
                — «Было очень интересно. До вебинара я считал, что всё это уже знаю, поэтому скептически подходил к
                материалу. Но теперь, когда я получил систематизированную теоретическую и практическую информация, я
                смогу её более профессионально применять. Мне как многодетному отцу эти знания пригодятся и дома. Пользы
                от этих вебинаров много. Я считаю, что в очном формате было бы намного интересней».
            </p>
            <p>
                — «Нам понравилось всё. Было насыщенно, отрыто. Пообщались, обменялись своими знаниями умениями и
                навыками. Для нас это было первое такое общение с другими СУВУ. В дальнейшем хотелось бы, чтобы мы чаще
                виделись и делились новыми впечатлениями и знаниями».
            </p>
            <p>
                — «Для меня это было очень полезно. Конечно, я стараюсь конфликтов избегать. Всё это знакомо, но вся
                информация систематизировалась, всё последовательно. Я буду этим пользоваться в своей деятельности».
            </p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <ToNews/>
    </div>
}


export default SupportOfMediationServicesJune2022;