import React from "react";
import s from "./Default.module.css"
import style from "../common/PageInfo/PageInfo.module.css"

import {useEffect, useState} from "react";
import axios from "axios";
import img from "../../assets/Mediacia/vseros2.jpg"
import docIco from "../../assets/img/doc.svg"
import PageImg from "../../assets/Behavior/4.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {NavLink} from "react-router-dom";

const NewSecondVseros = ({children, header}) => {
    const [newsData, setNews] = useState([])
    const hrefsArr1 = [
        {
            text: "Сборник материалов II Всероссийского совещания",
            to: "https://fcprc.ru/media/media/behavior/Sbornik-materialov-II-Vserossijskogo-soveshhaniya-sluzhb-primireniya-i-mediatsii.pdf"
        },
        {text: "Программа II Всероссийского совещания", to: "https://fcprc.ru/media/media/behavior/Programma.pdf"},
        {
            text: "Рекомендации по подготовке и оформлению материалов, направляемых в сборник II Всероссийского совещания школьных служб примирения и медиации",
            to: "https://fcprc.ru/media/media/behavior/rekomendatsii.pdf"
        },
        {text: "Фотоотчет", to: "https://fcprc.ru/fotootchet"},
        {text: "Запись Пленарного заседания", to: "https://youtu.be/sV0DUIIsP9A"},
        {
            text: "Запись Круглого стола № 1 «Медиативная культура и восстановительные практики в системе образования»",
            to: "https://vk.com/video-182917553_456239070"
        },
        {
            text: "Запись Круглого стола № 2 «Подготовка специалиста в области восстановительного подхода и медиации в системе образования: современные вызовы и решения»",
            to: "https://vk.com/video-182917553_456239071"
        },
        {
            text: "Запись Круглого стола № 3 «Реализация восстановительных практик и медиации как элемента профилактики правонарушений в подростковой среде»",
            to: "https://vk.com/video-182917553_456239072"
        },
        {
            text: "Запись Круглого стола № 4 «Восстановительные практики и медиация в социальной сфере: ребенок-семья-школа»",
            to: "https://vk.com/video-182917553_456239073"
        },
        {
            text: "Запись Круглого стола № 5 «Управленческие механизмы поддержки сети служб медиации и примирения»",
            to: "https://vk.com/video-182917553_456239074"
        },
        {text: "Запись Секции «Марафон практик»", to: "https://vk.com/video-182917553_456239075"},
        {text: "Запись Завершающего общего заседания", to: "https://youtu.be/a1KgAM5BUVE"}
    ]
    return <div className={s.newsrefs}>
        <div>

            <h2 style={{
                fontWeight: "normal",
                marginTop: "50px",
                color: "#3a4663",
                textAlign: "left",
                maxWidth: "1100px",
                margin: "40px auto",
                fontFamily: "Proxima Nova Lt"
            }}>
                II Всероссийское совещание школьных служб примирения и медиации
            </h2>
            <div className={s.pageWRef}>
                <div>
                    <div>
                        <img src={img}/>
                    </div>
                    <div>
                        <p>
                            15 ноября 2022 г. состоялось II Всероссийское совещание школьных служб примирения и
                            медиации. В мероприятии приняли участие члены Правительственной комиссии по делам
                            несовершеннолетних и защите их прав, представители аппарата Уполномоченного по правам
                            ребенка в Российской Федерации, федеральных органов исполнительной власти, общественных,
                            научных организаций и экспертных сообществ, представителей органов исполнительной власти
                            субъектов Российской
                            Федерации, осуществляющих государственное управление в сфере образования, образовательных
                            организаций, подведомственных
                            Минпросвещения России.
                            <p>
                                Оператор Всероссийского совещания – ФГБУ «Центр защиты прав и интересов детей».
                            </p>
                            <p>
                                Форматы проведения:
                            </p>
                            <p>
                                -очный и дистанционный (пленарные доклады, работа круглых столов и секции «Марафон
                                практик»);
                            </p>
                            <p>
                                -заочный (публикация материалов в электронном сборнике).
                            </p>
                            <p>
                                Всероссийское совещание проводилось Минпросвещения России во исполнение пункта 8
                                Межведомственного плана комплексных мероприятий по реализации Концепции развития сети
                                служб медиации в целях реализации восстановительного правосудия в отношении детей, в том
                                числе совершивших общественно опасные деяния, но не достигших возраста, с которого
                                наступает уголовная ответственность в Российской Федерации, до 2025 года, утвержденного
                                протоколом заседания Правительственной комиссии по делам несовершеннолетних и защите их
                                прав от 25 сентября 2019 г. № 23.
                            </p>
                        </p>

                    </div>
                </div>
            </div>
        </div>
        <div>
            <HrefsBeh1 hrefsArr={hrefsArr1}/>
        </div>

    </div>
}
export const HrefsBeh1 = ({hrefsArr, stylef = {}, to = "/"}) => {
    return <div className={style.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <a href={to} target="_blank">
                    {text}
                </a>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}

export default NewSecondVseros