import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/ММСОEXPO2023.jpg"

const ММСОEXPO2023 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            С 23 по 24 марта 2023 года на площадке ВДНХ пройдет международный Салон образования – ММСО.EXPO-2023
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>
                В рамках объявленного Президентом Российской Федерации В.В. Путиным Года педагога и наставника с 23 по
                24 марта 2023 года на площадке ВДНХ (г. Москва, пр. Мира, домовладение 119, выставочный павильон №57)
                пройдет Московский международный Салон образования — ММСО.ЕХРО-2023 (далее — ММСО).</p>
            <p>В этом году ММСО состоится в десятый раз и предоставит возможность открытого диалога руководителей
                государственных структур, образовательных и экспертных организаций, представителей педагогической и
                родительской общественности. Среди ключевых мероприятий: форум «Глокализация — новая реальность»;</p>
            <p>сессия «Год педагога и наставника — профессия и общество: социальный аспект» при участии педагогов,
                представителей и студентов педагогических вузов;<br/>
                Форум классных руководителей — самая масштабная в стране площадка для развития горизонтальных связей
                классных руководителей;<br/>
                «Профтех» — пространство для развития профессионального обучения с акцентом на раннюю профессиональную
                ориентацию школьников и новый федеральный проект «Профессионалитет».</p>
            <p>Дополнительно сообщаем, что в соответствии с распоряжением Правительства Российской Федерации от 18
                февраля 2023 г. № 399-р, ММСО стал одним из важных событий Плана основных мероприятий по проведению в
                Российской Федерации Года педагога и наставника. Учитывая высокую социальную значимость, Минпросвещения
                России рекомендует принять участие в мероприятиях ММСО, а также просит проинформировать профессиональное
                сообщество, педагогическую и родительскую общественность, разместив информационные материалы на
                официальных сайтах и в официальных аккаунтах социальных сетей.</p>
            <p>Также для формирования деловой программы просим направить на почту <a
                href="mailto:mediaplan@edu.gov.ru">mediaplan@edu.gov.ru</a> (с пометкой
                ММСО-2023) не позднее 7 марта 2023 года информацию об уже запланированных вашим учреждением активностях
                в рамках ММСО, а также представить предложения по темам и спикерам для сессий, круглых столов и иных
                мероприятий, которые целесообразно провести в рамках ММСО.</p>
            <p>Традиционно на полях ММСО будет организовано выставочное пространство, посвященное развитию
                образовательной среды и образовательных возможностей. Среди направлений данных пространств
                ММСО.ЕХРО-2023 — выставка современных подходов к обучению и воспитанию «Инфраструктура и
                предметно-развивающая среда детского сада», выставка учебного оборудования и средств обучения
                «Образовательная среда — ключ к образовательным результатам». Деловая программа также будет включать
                спецпроект «ММСО.Регионы» и события в формате «Час региона».</p>
            <p>Более подробная информация о событиях ММСО, деловой и выставочной программе представлена на официальном
                сайте.</p>
            <p>Контактное лицо от организаторов — Ачканова Нина Владимировна, e-mail: <a
                href="mailto:info@mmco-expo.ru">info@mmco-expo.ru</a>,<br/>
                Тел.: +7 (926) 268-26-84.</p>
        </div>
        <ToNews/>
    </div>
}


export default ММСОEXPO2023;