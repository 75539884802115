import React from "react";
import icoBlue from "../../../assets/arrow_blue.svg"
import icoPdf from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {FileLeftLineSUVU, FileLeftLineSUVUWithDate, FileLeftLineSUVUWithDateAndFilesOrRefs} from "./FilesVeiw"
import {HrefsBeh1} from "../PageInfo/PageInfo"
import CalendarSuvu from "../CalendarSuvu/Calendar"


const hrefsArr = [
    {text: "Новости", to: "/spec-suvu/news-suvu/"},
    {text: "Нормативное регулирование деятельности СУВУ", to: "/spec-suvu/normativno-pravovye-dokumenty/"},
    {
        text: "Информационно-методические материалы",
        to: "/spec-suvu/informatsionno-analiticheskie-materialy/"
    },
    {text: "Всероссийские мероприятия", to: "/spec-suvu/meropriyatiya/"},
    {text: "Люди. События. Факты.", to: "/spec-suvu/ludi-fakti-sobitiya/"},
]
const FilesSuvNews = () => {

    return <>
        <div className={s.filesLeftPage}>

            <div className={s.newHeader}>
                <h2>
                    Новости
                </h2>

                <FileLeftLineSUVU
                    text={"21 февраля состоялось Всероссийское совещание по вопросам совершенствования деятельности специальных учебно-воспитательных учреждений «Векторы новых возможностей профилактики девиантного поведения обучающихся»,"}
                    refers={"https://fcprc.ru/suvu/21-fevralya-sostoyalos-vserossijskoe-soveshhanie-po-voprosam-sovershenstvovaniya-deyatelnosti-spetsialnyh-uchebno-vospitatelnyh-uchrezhdenij-vektory-novyh-vozmozhnostej-profilaktiki-deviantnogo-povede/"}
                    ico={icoBlue}/>
                <FileLeftLineSUVU
                    text={"21 февраля состоится Всероссийское совещание по вопросам совершенствования деятельности специальных учебно-воспитательных учреждений «Векторы новых возможностей профилактики девиантного поведения обучающихся»"}
                    refers={"https://fcprc.ru/suvu/21-fevralya-sostoitsya-vserossijskoe-soveshhanie-po-voprosam-sovershenstvovaniya-deyatelnosti-spetsialnyh-uchebno-vospitatelnyh-uchrezhdenij-vektory-novyh-vozmozhnostej-profilaktiki-deviantnogo-povede/"}
                    ico={icoBlue}/>
                <FileLeftLineSUVUWithDateAndFilesOrRefs
                    date={"20 июня 2022"}
                    text={"Всероссийский конкурс профессионального мастерства для педагогических работников специальных учебно-воспитательных учреждений"}
                    refers={"https://fcprc.ru/suvu/vserossijskij-konkurs-professionalnogo-masterstva-dlya-pedagogicheskih-rabotnikov-spetsialnyh-uchebno-vospitatelnyh-uchrezhdenij/"}
                    ico={icoBlue}
                    icoFile={icoPdf}
                    fileName={"Программа Конкурса"}
                    fileRefers={"https://fcprc.ru/wp-content/uploads/2022/06/Programma-Konkursa_Pedagog-goda-3.pdf"}
                />
                <FileLeftLineSUVUWithDate
                    date={"26 июля 2021"}
                    text={"Министерством просвещения Российской Федерации утвержден график проведения подведомственными специальными учебно-воспитательными учреждениями (далее – СУВУ) общественно значимых мероприятий в сфере образования, науки и молодежной политики в 2021 году"}
                    refers={"https://fcprc.ru/suvu/ministerstvom-prosveshheniya-rossijskoj-federatsii-utverzhden-grafik-provedeniya-podvedomstvennymi-spetsialnymi-uchebno-vospitatelnymi-uchrezhdeniyami-dalee-suvu-obshhestvenno-znachimyh-meropriyatij-v/"}
                    ico={icoBlue}/>
                <FileLeftLineSUVUWithDate
                    date={"31 августа 2020"}
                    text={"Всероссийское «августовское» педагогическое совещание в режиме видеоконференцсвязи «Перспективы и ресурсы развития деятельности специальных учебно-воспитательных учреждений»"}
                    refers={"https://fcprc.ru/news/28-avgusta-v-rezhime-videokonferentssvyazi-sostoyalos-vserossijskoe-avgustovskoe-pedagogicheskoe-soveshhanie-perspektivy-i-resursy-razvitiya-deyatelnosti-spetsialnyh-uchebno-vospitatelnyh-uchrezhdenij/"}
                    ico={icoBlue}/>
                <FileLeftLineSUVUWithDate
                    date={"7 июля 2020"}
                    text={"В августе 2020 года запланировано проведение мониторинга деятельности по оказанию психолого-педагогической, социально-педагогической поддержки в СУВУ, подведомственных Минпросвещения России\n" +
                        "Мониторинг организован в целях изучения, анализа, обобщения и распространения эффективного опыта по оказанию поддержки воспитанникам СУВУ сотрудниками учреждений..."}
                    refers={"https://fcprc.ru/suvu/v-avguste-2020-goda-zaplanirovano-provedenie-monitoringa-deyatelnosti-po-okazaniyu-psihologo-pedagogicheskoj-sotsialno-pedagogicheskoj-podderzhki-v-suvu-podvedomstvennyh-minprosveshheniya-rossii/"}
                    ico={icoBlue}/>
                <FileLeftLineSUVUWithDate
                    date={"15 мая 2020"}
                    text={"15 мая состоялось совещание в режиме видеоконференцсвязи по вопросам деятельности специальных учебно-воспитательных учреждений, подведомственных Минпросвещения России, в условиях сложившейся эпидемиологической ситуации"}
                    refers={"https://fcprc.ru/news/15-maya-sostoyalos-soveshhanie-v-rezhime-videokonferentssvyazi-po-voprosam-deyatelnosti-spetsialnyh-uchebno-vospitatelnyh-uchrezhdenij-podvedomstvennyh-minprosveshheniya-rossii-v-usloviyah-slozhivshej/"}
                    ico={icoBlue}/>
                <FileLeftLineSUVUWithDate
                    date={"20 марта 2020"}
                    text={"Сотрудники Центра приняли участие в заседании Межведомственной рабочей группы по разработке концепции единого информационного ресурса защиты прав детей"}
                    refers={"https://fcprc.ru/news/sotrudniki-tsentra-prinyali-uchastie-v-zasedanii-mezhvedomstvennoj-rabochej-gruppy-po-razrabotke-kontseptsii-edinogo-informatsionnogo-resursa-zashhity-prav-detej/"}
                    ico={icoBlue}/>
                <FileLeftLineSUVUWithDate
                    date={"27 февраля 2020"}
                    text={"26 февраля состоялось совещание в режиме видеоконференцсвязи по вопросам имущественно-финансового обеспечения деятельности и мероприятиях по сопровождению развития социально-психологических служб специальных учебно-воспитательных учреждений, подведомственных Минпросвещения России"}
                    refers={"https://fcprc.ru/news/26-fevralya-sostoyalos-soveshhanie-v-rezhime-videokonferentssvyazi-po-voprosam-imushhestvenno-finansovogo-obespecheniya-deyatelnosti-i-meropriyatiyah-po-soprovozhdeniyu-razvitiya-sotsialno-psihologich/"}
                    ico={icoBlue}/>
            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArr}/>
            </div>
        </div>
    </>
}
export default FilesSuvNews