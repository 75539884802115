import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News2805242.jpeg"
import img1 from "../../assets/News/News2805242_1.jpeg"

const News2805242 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Рабочее совещание по подготовке программы повышения квалификации по профилактике суицидального поведения
            несовершеннолетних
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            24 мая 2024 года на базе ФГБНУ «Институт изучения детства, семьи
            и воспитания» прошло рабочее совещание по разработке программы повышения квалификации по профилактике
            суицидального поведения несовершеннолетних, в котором приняла участие аналитик II категории отдела
            сопровождения вопросов безопасности детства Центра Бочкова Виолетта Владимировна.
            <p></p>
            В рамках совещания обсуждались вопросы наполнения модулей актуальными тематическими блоками, в том числе,
            дополнения лекционных материалов программы практико-ориентированными разработками (схемы, алгоритмы, кейсы,
            конспекты, сценарии, программы, иные материалы), необходимыми в осуществлении эффективной профилактической
            работы
            с участниками образовательных отношений в субъектах Российской Федерации.
            <p></p>
            «Необходимо, чтобы в результате прохождения курса у целевой аудитории сформировалось четкое понимание о зоне
            ответственности, формах и механизмах работы каждого субъекта профилактической работы, а у педагогов и
            родителей были инструменты работы с детьми по данному направлению», — отметила Наталья Агре, директор ФГБНУ
            «Институт изучения детства, семьи и воспитания».
            <p></p>
            В работе совещания также приняли участие сотрудники ПДНиЦВСНП ГУОООП МВД России, ФГБУ «НМИЦ психиатрии и
            наркологии
            им. В.П. Сербского» Минздрава России, Московской академии Следственного комитета имени А.Я. Сухарева, АНО
            «ЦИСМ»,
            Федерального координационного центра по обеспечению психологической службы в системе образования РФ ФГБОУ ВО
            «МГППУ»,
            сотрудники отдела экспертного сопровождения ФЦ «Профилактика» ФГБНУ «Институт изучения детства, семьи и
            воспитания».
            <p></p>
            Более подробная информация о мероприятии размещена по ссылке: <a style={{color: 'red'}} href="https://t.me/institut_vospitaniya/8182">Институт воспитания</a>
            <p></p>
        </div>
        <div>
            <img src={img1}/>
        </div>
        <ToNews/>
    </div>
}


export default News2805242;