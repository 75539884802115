import React from "react";
import ico from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, PinkButton, WhiteButton} from "../PageInfo/PageInfo"
import {hrefsArr} from "./FilesNPD";
import {FileLeftLineKDN} from "./FilesKDNNews";

const FilesChild = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    console.log(respData)
    return <div>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Материалы для подростков и молодежи
                </h2>
                <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                    Информационные материалы для обучающихся
                </h1>
                <FileLeftLineKDN text="Каталог работ победителей Конкурса «Стиль жизни — здоровье! 2023»"
                                 refers="https://fcprc.ru/media/media/mediacia/Каталог_работ_победителей_Конкурса_Стиль_жизни_-_здоровье_2023.pdf"
                                 ico={ico}/>
                <FileLeftLineKDN text={respData[246]?.description} refers={respData[246]?.file} ico={ico}/>
                <FileLeftLineKDN
                    text={"Буклет «Поговорим о чувствах. Памятка по конструктивному общению для родителей и подростков» (Хабаровский край)"}
                    refers={""} ico={ico}/>
                <FileLeftLineKDN text={respData[245]?.description} refers={respData[245]?.file} ico={ico}/>
                <div>
                    <h1 style={{
                        color: "#005090",
                        fontSize: "22px",
                        fontFamily: "Proxima Nova Lt",
                        textAlign: "start"
                    }}>
                        Видеоролики, направленные на профилактику зависимого поведения обучающихся и формирование
                        здорового
                        образа жизни
                    </h1>
                </div>


                <FileLeftLineKDN text={"Видеоролик «Реальная жизнь – без наркотиков!» (Московская область)"}
                                 refers={"https://youtu.be/I2xes_zfaIk"} ico={ico}/>
                <FileLeftLineKDN text={"Видеоролик «Твой выбор» (Московская область)"}
                                 refers={"https://youtu.be/dbzLRC1PJTE"} ico={ico}/>

            </div>

            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArr}/>
                <PinkButton text="Материалы по профилактике ВИЧ/СПИДa" stylesheet={"pinkbutton"}
                            refs="/spec-materials-category/metodicheskie-materialy-po-profilaktike-vich-spida"/>
                <PinkButton text="Инструкция по подготовке материалов по профилактике"
                            refs="https://fcprc.ru/media/media/behavior/07-6523-s-Pr.pdf"/>
                <WhiteButton text="Перейти к опросу родителей по информированности о профилактике ПАВ"
                             refs="http://опрос-родителей-о-пав.рф/"/>
                <WhiteButton text="Перейти к опросу молодёжи о ВИЧ" refs="http://опрос-молодежи-о-вич.рф/"/>
                <WhiteButton text="Перейти к опросу педагогов о профилактике ВИЧ-инфекции среди обучающихся"
                             refs="http://опрос-педагогов-о-вич.рф/"/>
            </div>
        </div>
    </div>
}

export default FilesChild