import React from "react";
import s from "./News.module.css"
import img from "../../assets/img/imgSubscr.png"
import air from "../../assets/img/air.svg"
import img1 from "../../assets/img/vk.png"
import img2 from "../../assets/img/ok.png"
import img3 from "../../assets/img/mir.webp"
import { ArrowLeftOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { useState } from "react";

const ToNews = () => {
    return<div>
        <div className={s.topBlock}>
            <Btn />
            <div>
                Поделитесь с коллегами и друзьями:
                <img src={img1}/>
                <img src={img2}/>
                <img src={img3}/>
            </div>
        </div>
        <div>
            <Falldown />

        </div>
    </div>
}
const Falldown = ({url = null, header="Подпишитесь на нашу рассылку", children, style={}}) => {
    const [active2, setActive2] = useState(false)
      return <div  className={s.fall2} style={{marginBottom: "40px"}}>
        <div className={s.block} onClick={() => {active2 ? setActive2(false) : setActive2(true)}} onBlur={() => {setActive2(false)}}>
        <div style={{width: "800px", marginTop: "20px"}}>
          <span style={{fontSize:"24px"}}>
            <img src={air} style={{color:"red"}}/>
          {header}
          </span>
          <p>
          и получайте только самые свежие и полезные новости Центра
          </p>
          <div>

          <SendingMess/>
          </div>
          <p>
            <input type="checkbox" style={{width: "40px", height:"30px", marginTop: "0px", paddingBottom:"40px"}}/>
            <span>Я даю согласие на обработку своих <a href="http://fcprc.ru/media/media/behavior/20-1-29-04-2021.pdf">
                 персональных данных </a></span>
          </p>

        </div>
       
        <div className={s.ico} style={{backgroundImage: `url(${img})`, zIndex:3, opacity: 1,}}>
        
         </div>
         </div>         
             
          </div>
  
  }

const Btn = () => {
    return<NavLink to="/news">
    <div className={s.btn}>
        <ArrowLeftOutlined style={{color: "red", paddingRight:"10px"}}/>
        Назад к списку новостей
    </div>
    </NavLink>
}

const SendingMess = () => {
    return<div className={s.sendingMess}>
        <input />
        <button>
            Подписаться
        </button>
    </div>
}

export default ToNews