import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img1 from "../../assets/Mediacia/VDCOrlenok1.jpg"
import img2 from "../../assets/Mediacia/VDCOrlenok2.jpg"
import YouTube from "react-youtube";

const VDCOrlenok = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            ВДЦ «Орлёнок». Дипломатия дружбы
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <p>
                18 мая 2021 года накануне Дня детских общественных объединений (День Всесоюзной пионерской организации имени В. И. Ленина в СССР) в историческом центре Москвы (Новая площадь, 8) состоялась встреча сотрудников ФГБУ «Центр защиты прав и интересов детей» с заместителем директора Научно-практического центра «Союз пионерских организаций – Федерация детских организаций» (СПО-ФДО) Ириной Игоревной Фришман. В ходе встречи стороны обсудили возможность включения в план одной из смен в ВДЦ «Орленок» серии тематических интерактивных занятий для детей по основам медиации и навыкам бесконфликтного общения. Было отмечено, что дополнительное образование в области разрешения конфликтных ситуаций не только позволит детям и подросткам получить полезные жизненные навыки и будет способствовать их позитивной социализации, но и может стать важным компонентом в организации содержательного досуга детей.
            </p>
            <p>
            Мероприятие прошло в атмосфере доверия и взаимопонимания. Всеми присутствующими была выражена заинтересованность в развитии и укреплении дальнейшего сотрудничества.
            </p>
        </div>
        <div>
            <img src={img2}/>
        </div>
    </div>
}


export default VDCOrlenok