import s from "./Mediacia.module.css";
import ch1 from "../../assets/img/ch1.PNG";
import pr1 from "../../assets/img/pr1.PNG";
import React from "react";

const Rules = () => {
    return (
        <div style={{fontFamily: "Montserrat"}}>
            <div className={s.main}>
                <h2>
                    Будьте осторожны, сохраняйте спокойствие и тщательно проверяйте любую информацию!
                </h2>

                <img src={ch1} style={{width: "100%", maxWidth: "1200px"}}/>
                <p></p>
                <img src={pr1} style={{width: "100%", maxWidth: "1200px"}}/>
                <p></p>
            </div>
        </div>
    )
}
export default Rules