import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News040423.jpg"
import img2 from "../../assets/News/News040423_2.jpg"
import img3 from "../../assets/News/News040423_3.jpg"
import img4 from "../../assets/News/News040423_4.jpg"
import img5 from "../../assets/News/News050424_new.jpg"
import img6 from "../../assets/News/News040423_6.jpg"
import img7 from "../../assets/News/5ДПО курсы Центр 2024 04 026 (1).jpg"

const News050424 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Повышение квалификации для педагогических работников общеобразовательных организаций: педагогов, педагогов-психологов, специалистов сферы профилактики.
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Наш Центр приглашает на программу повышения квалификации <strong>«Психологические проблемы
            детско-подросткового
            возраста: профилактика и коррекция в общеобразовательных организациях»</strong>, 20 академических часов.
            <p></p>
            <strong>УСПЕЙТЕ ЗАПИСАТЬСЯ НА ОБУЧЕНИЕ ДО 05 МАЯ 2024 ГОДА</strong>
            <p></p>
            Программа курса:
            <ul>
                <li>1. Социально-психологические особенности подросткового возраста.</li>

                <li>2. Распространенные нарушения в поведении детей и подростков.</li>

                <li>3. Основы диагностики и оказания психолого-педагогической помощи в общеобразовательной
                    организации.
                </li>

                <li>4. Модели профилактики девиантного поведения.</li>

                <li>5. Итоговая аттестация.</li>
            </ul>


            Стоимость обучения: 10 000 рублей.
            <p></p>
            Программа включает в себя опыт и знания сотрудников отделов профилактики девиантного поведения
            несовершеннолетних и сопровождения вопросов безопасности детства ФГБУ «Центр защиты прав и интересов детей»,
            а также опыт в области консультативной, экспертной и организационно-методической работы по развитию системы
            профилактики девиантного поведения в Российской Федерации.
            <p></p>
            Записаться на курс: <u><a href="https://dpo.fcprc.ru/registration">https://dpo.fcprc.ru/registration</a></u>
            <p></p>
            Период обучения: <strong>20 мая - 14 июня 2024 года</strong>
            <p></p>
            Формат обучения: онлайн занятия. Все занятия будут проводиться с личным участием преподавателей
            <p></p>
            После прохождения обучения, вы получите удостоверение о повышении квалификации
            <p></p>
            Преподаватели:
            <ul>
                <li><strong>Артамонова Елена Геннадьевна</strong>, заместитель директора по развитию системы профилактики девиантного поведения, к.псх.н.
                </li>
                <li><strong>Дубровский Роман Геннадьевич</strong>, аналитик II категории, практикующий психолог, к.социол.н</li>
                <li><strong>Бочкова Виолетта Владимировна</strong>, аналитик II категории, психолог</li>
            </ul>


            <p></p>
            Подробнее о курсе <u><a
            href="https://dpo.fcprc.ru/pdpcourse2">https://dpo.fcprc.ru/pdpcourse2</a></u>
            <p></p>
            Направить свой запрос на обучение специалистов по другим интересующим вас темам можно на электронную
            почту <u><a href="mailto:dpo@fcprcgov.ru">dpo@fcprcgov.ru</a></u>
            <p></p>
            <div>
                <img src={img2}/>
            </div>
            <div>
                <img src={img3}/>
            </div>
            <div>
                <img src={img4}/>
            </div>
            <div>
                <img src={img7}/>
            </div>
            <div>
                <img src={img6}/>
            </div>
        </div>
        <ToNews/>
    </div>
}


export default News050424;