import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img from "../../assets/Mediacia/websIs.png"

const NewFunctionality = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: Обучающий вебинар «Функционал обновленного портала Десятилетия детства»
        </h2>
        <div>
            <p>
                Министерство просвещения Российской Федерации в рамках подготовительного этапа запуска обновленного
                портала <a href="https://10let.edu.gov.ru/">Десятилетия детства</a> проводит 10 июня 2022 г. в 10:00
                часов (мск.) обучающий вебинар для
                ответственных лиц органов исполнительной власти субъектов Российской Федерации по теме «Функционал
                обновленного портала Десятилетия детства» (программа прилагается).
            </p>
            <p>
                В рамках мероприятия участники ознакомятся с обновленной версией портала, его дополнительными
                функциональными возможностями, особенностями работы в личных кабинетах, а также смогут задать все
                интересующие вопросы по работе портала.
            </p>
            <ul>
                <li>
                    <a href="https://events.webinar.ru/41646463/11521791">Ссылка на регистрацию участников</a>
                </li>
            </ul>
            <p>
                Тестовое подключение возможно за 30 минут до начала вебинара.
            </p>
        </div>
    </div>
}


export default NewFunctionality