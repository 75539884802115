import React from "react";
import ico from "../../../assets/fileicored.svg"
import ico2 from "../../../assets/link.ico"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, PinkButton, WhiteButton} from "../../common/PageInfo/PageInfo"
import {NavLink} from "react-router-dom";
import {ArrowRightOutlined} from "@ant-design/icons";
import {hrefsArr} from "./FilesNPD";
import {FileLeftLineKDN} from "./FilesKDNNews";

const FilesForParents = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    console.log(respData)
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Материалы для родителей
                </h2>

                <FileLeftLineKDN text={respData[235]?.description} refers={respData[235]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[236]?.description} refers={respData[236]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[237]?.description} refers={respData[237]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[238]?.description} refers={respData[238]?.file} ico={ico}/>
                <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                    Материалы антинаркотического содержания, разработанные в субъектах Российской Федерации
                </h1>
                <FileLeftLineKDN text={respData[586]?.description} refers={respData[586]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[101]?.description} refers={respData[101]?.file} ico={ico}/>
                <FileLeftLineKDN
                    text="Статья для родителей «Как реагировать на «колючее» поведение ребенка» (Алтайский край)"
                    refers="https://портал-для-родителей-алтайский-край.рф/article/roditelyam-podrostka-i-starsheklassnika/kak-reagirovat-na-kolyuchee-povedenie-podrostka.html"
                    ico={ico2}/>
                <FileLeftLineKDN text={respData[239]?.description} refers={respData[239]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[240]?.description} refers={respData[240]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[37]?.description} refers={respData[37]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[242]?.description} refers={respData[242]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[243]?.description} refers={respData[243]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[244]?.description} refers={respData[244]?.file} ico={ico}/>

            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArr}/>
                <PinkButton text="Материалы по профилактике ВИЧ/СПИДa" stylesheet={"pinkbutton"}
                            refs="/spec-materials-category/metodicheskie-materialy-po-profilaktike-vich-spida/"/>
                <PinkButton text="Инструкция по подготовке материалов по профилактике"
                            refs="fcprc.ru/media/media/behavior/07-6523-s-Pr.pdf"/>
                <WhiteButton text="Перейти к опросу родителей по информированности о профилактике ПАВ"
                             refs="http://опрос-родителей-о-пав.рф/"/>
                <WhiteButton text="Перейти к опросу молодёжи о ВИЧ" refs="http://опрос-молодежи-о-вич.рф/"/>
                <WhiteButton text="Перейти к опросу педагогов о профилактике ВИЧ-инфекции среди обучающихся"
                             refs="http://опрос-педагогов-о-вич.рф/"/>
            </div>
        </div>
    </>
}

export default FilesForParents