import React from "react";
import ico8 from "../../../assets/AD1/8.PNG"
import ico9 from "../../../assets/AD1/9.PNG"
import ico17 from "../../../assets/AD1/17.PNG"
import ico2833 from "../../../assets/AD1/2.833.png"
import ico2834 from "../../../assets/AD1/2.834.PNG"
import s from "./PageFiles.module.css"
import {FileLeftLineValue} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {Hrefs} from "../PageInfo/PageInfoPriceLife"

const hrefsArr = [
    {text: "Нормативно-правовые материалы", to: "/spec-value-of-life/normativno-pravovye-materialy", input: []},
    {
        text: "Информационно-методические материалы для специалистов",
        to: "/spec-value-of-life/metodicheskie-materialy-dlya-spetsialistov"
    },
    {
        text: "Информационные материалы для родителей и детей",
        to: "/spec-value-of-life/informatsionnye-materialy-dlya-roditelej"
    },
    {text: "Видеоматериалы", to: "/spec-value-of-life/video-price"},
    {text: "Полезные ссылки", to: "/spec-value-of-life/poleznye-ssylki"},
]
const Value_Met5 = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <div className={s.filesLeftPage}>
        <div className={s.newHeader}>
            <h2>
                Формирование культуры здорового и безопасного образа жизни
            </h2>

            <div className={s.filesLeftPage}>
                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr"}}>
                    <FileLeftLineValue text={respData[176]?.description} refers={respData[176]?.file} ico={ico9}/>
                    <FileLeftLineValue text={respData[177]?.description} refers={respData[177]?.file} ico={ico8}/>
                    <FileLeftLineValue text={respData[532]?.description} refers={respData[532]?.file} ico={ico17}/>
                    <FileLeftLineValue
                        text="Методические рекомендации «Профилактические мероприятия в образовательных организациях, направленные на формирование у обучающихся позитивного мышления, принципов здорового образа жизни, предупреждения суицидального поведения, в том числе с учетом влияния дистанционного формата обучения»"
                        refers="https://fcprc.ru/media/media/behavior/Профилактические_мероприятия_в_образовательных_организациях_направленные__TMYz3DA.pdf"
                        ico={ico2833}/><FileLeftLineValue
                        text="МЕТОДИЧЕСКИЕ МАТЕРИАЛЫ по проведению профилактических мероприятий с обучающимися образовательных организаций, направленные на формирование у них позитивного мышления, принципов здорового образа жизни, предупреждение суицидального поведения"
                        refers="https://fcprc.ru/media/media/mediacia/MM_Uchimsya_upravlyat_soboi.pdf"
                        ico={ico2834}/>
                </div>
            </div>
        </div>
        <div>
            <div style={{marginTop: "72px"}}>
                <Hrefs hrefsArr={hrefsArr}/>
            </div>
        </div>
    </div>
}
export default Value_Met5