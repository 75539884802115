import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/Hague Conventions Seminar1.jpg"
import img2 from "../../assets/News/Hague Conventions Seminar2.jpeg"
import img3 from "../../assets/News/Hague Conventions seminar3.jpeg"

const HagueConventionsSeminar = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            «Научно-методический семинар по реализации Гаагских конвенций 1980 года и 1996 года»
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>13-14 октября 2022 г. состоялся ежегодный научно-методический семинар с участием международных экспертов,
                посвященный реализации Гаагской конвенции о гражданско-правовых аспектах международного похищения детей
                от 25 октября 1980 г. и Гаагской конвенции о юрисдикции, применимом праве, признании, исполнении и
                сотрудничестве в отношении родительской ответственности и мер по защите детей от 19 октября 1996 г.,
                организаторами которого выступили Минпросвещения России совместно с ФГБУ «Центр защиты прав и интересов
                детей».</p>
            <p>В этом году семинар стал площадкой не только для обмена опытом между специалистами, вовлеченными в
                процесс разрешения международных семейных споров, но и позволил обсудить правоприменительные проблемы
                реализации Гаагских конвенций с представителями научного сообщества.</p>
            <p>Формат мероприятия был несколько изменен и, помимо традиционного пленарного заседания, включал в себя
                образовательную часть и тематический вебинар о применении процедуры медиации, что позволило участникам
                более глубоко рассмотреть вопросы применения Гаагских конвенций и способы мирного разрешения семейных
                споров.</p>
            <p>В семинаре приняли участие более 280 человек, в том числе представители Минпросвещения России, МИД
                России, Минюст России, МВД России, ФССП России, судейского и научного сообществ, органов исполнительной
                власти субъектов Российской Федерации, осуществляющих управление в сфере опеки и попечительства в
                отношении несовершеннолетних граждан.</p>
            <p>С основным докладом, в рамках пленарного заседания, выступила заместитель директора Департамента
                государственной политики в сфере защиты прав детей Минпросвещения России Анастасия Аккуратова. В своем
                выступлении Анастасия Аккуратова подытожила результаты работы Центрального органа Российской Федерации в
                рамках реализации Гаагских «детских» конвенций и разрешения трансграничных семейных споров, была
                приведена статистическая информация и отмечены основные тенденции в работе с иностранными государствами,
                учитывая условия текущей международной повестки.</p>
            <p>Также были заслушаны доклады представителей МИД России, Главного управления по вопросам миграции МВД
                России, Аппарата Уполномоченного при Президенте Российской Федерации по правам ребенка, Минюста России,
                НИИ Университета прокуратуры Российской Федерации и судьи Центрального районного суда г.
                Новосибирска.</p>
            <p>В образовательной части прозвучали доклады представителей научного сообщества: сотрудников
                Исследовательского центра частного права имени С.С. Алексеева при Президенте Российской Федерации (ИЦЧП)
                – Наталии Тригубович и Ольги Хазовой, Института законодательства и сравнительного правоведения при
                Правительстве Российской Федерации – Марины Шелютто, а также преподавателей Московского государственного
                юридического университета имени О.Е. Кутафина (МГЮА) – Олеси Засемковой и Оксаны Савенко, заведующей
                кафедры международного права Южного федерального университета — Анны Тарасовой.</p>
            <p>Участниками были затронуты вопросы, возникающие при реализации положений Гаагских конвенций 1980 и 1996
                годов, актуальные проблемы и пути их решения.</p>
            <p>Тематический вебинар был посвящен возможностям и рискам применения процедуры медиации в международных
                семейных спорах.</p>
            <p>При подведении итогов участники семинара акцентировали внимание на необходимости совершенствования
                методик и механизмов проведения медиации в трансграничных семейных спорах. ФГБУ «Центр защиты прав и
                интересов детей» выразил свою готовность продолжить работу в данном направлении.</p>
            <p>Запись семинара доступна по <a href="https://youtu.be/sNbjL7VC368.">ссылке</a>.</p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <ToNews/>
    </div>
}


export default HagueConventionsSeminar;