import React from "react";

import s from "./PageFiles.module.css"
import {FileLeftLineValue} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {Hrefs} from "../PageInfo/PageInfoPriceLife"
import ico1 from "../../../assets/AD1/1.1.PNG";
import ico2 from "../../../assets/AD1/1.2.PNG";
import ico3 from "../../../assets/AD1/1.3.PNG";
import ico4 from "../../../assets/AD1/1.4.PNG";
import ico5 from "../../../assets/AD1/1.5.PNG";

const hrefsArr = [
    {text: "Нормативно-правовые материалы", to: "/spec-value-of-life/normativno-pravovye-materialy", input: []},
    {text: "Информационно-методические материалы для специалистов", to: "/spec-value-of-life/metodicheskie-materialy-dlya-spetsialistov"},
    {
        text: "Информационные материалы для родителей и детей",
        to: "/spec-value-of-life/informatsionnye-materialy-dlya-roditelej"
    },
    {text: "Видеоматериалы", to: "/spec-value-of-life/video-price"},
    {text: "Полезные ссылки", to: "/spec-value-of-life/poleznye-ssylki"},
]
const Value_Met1 = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <div className={s.filesLeftPage}>
        <div className={s.newHeader}>
            <h2>
                Профилактика суицидов среди несовершеннолетних
            </h2>
            <div className={s.filesLeftPage}>
                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr"}}>
                    <FileLeftLineValue text={respData[572]?.description} refers={respData[572]?.file} ico={ico1}/>
                    <FileLeftLineValue text={respData[573]?.description} refers={respData[573]?.file} ico={ico2}/>
                    <FileLeftLineValue text={respData[574]?.description} refers={respData[574]?.file} ico={ico3}/>
                    <FileLeftLineValue text={respData[575]?.description} refers={respData[575]?.file} ico={ico4}/>
                    <FileLeftLineValue text={respData[576]?.description} refers={respData[576]?.file} ico={ico5}/>
                </div>
            </div>
        </div>
        <div>
            <div style={{marginTop: "72px"}}>
                <Hrefs hrefsArr={hrefsArr}/>
            </div>
        </div>
    </div>
}
export default Value_Met1