import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import img from "../../assets/News/ExamsWiStress.png"

const ExamsWiStress = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: «На экзамены – без стресса!»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div>
            <p>
                16 мая 2021 года состоялся вебинар, посвященный вопросам психологической готовности обучающихся к
                экзаменам. Ведущие вебинара, Дубровский Роман Геннадьевич, аналитик ФГБУ «Центр защиты прав и интересов
                детей», психолог, кандидат социологических наук и Горнова Татьяна Сергеевна, аналитик ФГБУ «Центр защиты
                прав и интересов детей», кандидат педагогических наук, в рамках работы вебинара рассказали
                о причинах возникновения стресса и возможных путях борьбы с ним, а также представили практические
                рекомендации для детей и родителей
                по профилактике стресса в период экзаменов.
            </p>
            <p>
                Ссылка на вебинар: <a
                href="https://www.youtube.com/watch?v=fq1-KcPBcn8">https://www.youtube.com/watch?v=fq1-KcPBcn8</a>
            </p>
            <p></p>
        </div>
    </div>
}


export default ExamsWiStress