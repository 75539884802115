import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/ChildDefence2021.jpg"
import logo from "../../../assets/Behavior/conceptions.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {NavLink} from "react-router-dom";
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";
import YouTube from "react-youtube";


const Section20212 = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2>
                    Секция 2 «Личность и социум. Профилактика асоциального поведения детей и подростков»
                </h2>
            </div>

            <p style={{fontSize: "17px", color: "#212529"}} className={s.concept}>
                <YouTube videoId="8GhR_BUcpGo"/>
                <p><a href="https://fcprc.ru/media/media/behavior/badaeva.pdf">Бадаева Л.В.</a></p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/bojarkina.pdf">Бояркина И.А.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/gerasimova.pdf">Герасимова В.В.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/dmitrieva.pdf">Дмитриева О.А.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/emelina.pdf">Емелина Т.А.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/korobkova.pdf">Коробкова В.В.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/medvedeva.pdf">Медведева О.Г.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/mjazina.pdf">Мязина С.Е.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/pliska-malysheva.pdf">Плиска А.В., Малышева И.С.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/polubarkina.pdf">Полубаркина Л.В.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/rogaleva.pdf">
                        Рогалева Е.И.
                    </a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/sekretareva.pdf">Секретарева Н.В.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/starodubova.pdf">Стародубова А.В.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/trunov.pdf">Трунов Д.В.</a>
                </p>
                <p>
                    <a href="https://fcprc.ru/media/media/behavior/shetina.pdf">Щетинина Е.В.</a>
                </p>
            </p>
        </div>
        <div style={{marginTop: "57px"}}>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право"/>

        </div>

    </div>


}

export const HrefsBeh1 = ({hrefsArr, stylef = {}, to = "/"}) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({text, stylesheet}) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({text, stylesheet}) => {
    return <div className={s.whitebutton}>
        {text}
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default Section20212