import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News010822.jpg"

const News010822 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Начинает работу Федеральный центр развития программ социализации подростков
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>По инициативе Уполномоченного при Президенте Российской Федерации по правам ребенка
                М.А. Львовой-Беловой и в соответствии с Распоряжением Президента Российской Федерации
                В.В. Путина от 15 июля 2022 года № 234-рп на базе федерального государственного бюджетного
                учреждения «Центр защиты прав и интересов детей» создан и начинает работу <strong>Федеральный центр
                    развития программ социализации подростков (ФЦ РПСП)</strong> для обеспечения образовательных,
                экспертно-методических и координирующих функций по работе
                специалистов с подростками.</p>
            <p>Деятельность центра будет направлена на выстраивание в стране эффективной системы мер
                по взаимодействию всех заинтересованных сторон, ориентированных на работу с детьми подросткового
                возраста. Будет оказываться содействие по созданию подростковых пространств, доступных программ досуга и
                позитивной занятости.</p>
            <p>ФЦ РПСП займется разработкой и реализацией программ повышения квалификации, созданием
                и распространением тематического просветительского и образовательного контента, созданием актуальных
                информационных пособий, ресурсной и экспертной поддержкой региональных команд специалистов.</p>
            <p>В рамках реализации деятельности ФЦ РПСП запланированы ключевые задачи:</p>
            <p>• методическое обеспечение работы сети подростковых центров в субъектах Российской Федерации;</p>
            <p>• обучение, повышение квалификации специалистов, работающих с детьми и подростками, в том числе
                требующими особого педагогического внимания;</p>
            <p>• развитие служб уличной социальной работы с подростками, а также социальной и психолого-педагогической
                работы в сети Интернет;</p>
            <p>• методическое обеспечение работы с детьми, находящимися в конфликте с законом, попавшими
                под влияние деструктивных субкультур;</p>
            <p>• содействие интеграции в социум детей, переживших опыт боевых действий.</p>
        </div>
        <ToNews/>
    </div>
}


export default News010822;