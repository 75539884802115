import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News260523.png"

const News260523 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Представители 12 регионов России обсудили участие во Всероссийской уличной акции #ДарюТепло
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>25 мая состоялась установочная встреча, на которой обсуждалось проведение Всероссийской уличной акции
                #ДарюТепло.</p>
            <p>На встрече присутствовали представители 12 регионов: Республики Саха (Якутия), Республики Хакасия,
                Алтайского края, Саратовской, Белгородской, Новосибирской, Калужской, Тверской, Курской, Архангельской,
                Новгородской областей, Санкт-Петербурга.</p>
            <p>Акция #ДарюТепло пройдет 1 июня, в Международный день защиты детей. Ее цели:<br/>

                — привлечь внимание к проблеме подростков на улице;<br/>

                — преобразовать текущую социальную ситуацию подростка в более ресурсную с помощью построения
                развивающего контакта;<br/>

                — проинформировать подростка об альтернативных способах проведения свободного времени в ресурсном ключе
                (мероприятия, клубы, секции и пр.).</p>
            <p>В ходе встречи с представителями регионов обсуждались вопросы создания уличной службы, формирования
                команды, определения маршрута работы специалистов уличной службы, составления списка мероприятий для
                маршрутизации подростков. </p>
            <p>Итоговая встреча по подготовке и проведению акции #ДарюТепло состоится 30 мая, в 12.00 (по московскому
                времени). Темы для обсуждения: восстановительный подход и этический кодекс в работе с подростками,
                правила безопасности при осуществлении выхода участников уличной акции, выстраивание первичного
                контакта.</p>
            <p>Желающие принять участие во Всероссийской уличной акции #ДарюТепло могут написать координатору проекта
                Екатерине Велицкой <a href="mailto:velitskaia.e@fcprcgov.ru">velitskaia.e@fcprcgov.ru</a></p>
        </div>
        <ToNews/>
    </div>
}


export default News260523;