import React from "react";
import s from "./Falldown.module.css"
import "./Falldown.css"
import ico1 from "../../assets/img/09c.svg"
import ico2 from "../../assets/img/10c.svg"
import ico3 from "../../assets/img/11c.svg"
import vk from "../../assets/SVG/vk-vk-svgrepo-com.svg"
import tg from "../../assets/SVG/telegram-svgrepo-com.svg"
import ok from "../../assets/SVG/odnok.svg"


const Contacts = () => {
    return <div>

        <div className={s.contacts}>
            <div className={s.newHeader}>
                <h2>
                    Контакты
                </h2>
            </div>
            <li>
                <img src={ico1} style={{color: "black"}}/> <a target="_balnk"
                href="https://yandex.ru/maps/213/moscow/house/pogodinskaya_ulitsa_8k1/Z04YcwFoT0wCQFtvfXtycnplZA==/?ll=37.570680%2C55.734169&z=16.98">Фактический
                адрес: 119435, г. Москва, ул. Погодинская, д.8/1</a>
            </li>
            <li>
                <img src={ico1}/> <a target="_balnk"
                href="https://yandex.ru/maps/213/moscow/house/pogodinskaya_ulitsa_8k1/Z04YcwFoT0wCQFtvfXtycnplZA==/?ll=37.570680%2C55.734169&z=16.98">Почтовый
                адрес: 119435, г. Москва, ул. Погодинская, д.8/1</a>
            </li>
            <li>
                <img src={ico1}/> <a target="_balnk"
                href="https://yandex.ru/maps/213/moscow/?z=17&mode=search&text=115093%2C+г.+Москва%2C+ул.+Люсиновская%2C+д.+51">Юридический
                адрес: 115093, г. Москва, ул. Люсиновская, д. 51</a>
            </li>
            <li>
                <img src={ico3}/> <a target="_balnk" href="tel:+74994440806,100%2008">+7 (499) 444-08-06, доб. 100 08</a>
            </li>
            <li>
                <img src={ico2}/> <a target="_balnk" href="mailto:fcprc@yandex.ru">fcprc@yandex.ru</a>
            </li>
            <li>
                <a target="_blank" href="https://vk.com/fcprcru"  style={{marginRight: "10px"}}>
                    <img src={vk} style={{margin:"0", padding: "10px", paddingTop:"15px", paddingBottom: "15px", border: "1px solid #bbcbdb", borderRadius: "40px", width:"50px"}}/>
                </a>
                <a target="_blank" href="https://t.me/fcprcgov"  style={{marginRight: "10px"}}>
                    <svg style={{
                        margin: "0",
                        padding: "10px",
                        paddingTop: "15px",
                        paddingBottom: "15px",
                        border: "1px solid #bbcbdb",
                        borderRadius: "40px",
                        width: "50px"}} width="40px" height="40px" viewBox="13 45 120 120" fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <path
                                d="M76.33 132.14L62.5 143.73L58.59 144.26L49.84 114.11L19.06 104L113.82 67.8799L118.29 67.9799L103.36 149.19L76.33 132.14ZM100.03 83.1399L56.61 109.17L61.61 130.5L62.98 130.19L68.2 113.73L102.9 83.4799L100.03 83.1399Z"
                            ></path>
                        </g>
                    </svg>
                </a>
                <a target="_blank" href="https://ok.ru/group/70000007188014">
                    <svg style={{
                        margin: "0",
                        padding: "10px",
                        paddingTop: "15px",
                        paddingBottom: "15px",
                        border: "1px solid #bbcbdb",
                        borderRadius: "40px",
                        width: "50px"}} width="43" height="40" viewBox="0 0 48 47" fill="none" xmlns="http://www.w3.org/2000/svg">

                        <path
                            d="M24.3156 23.5522C26.5635 23.5522 28.7091 22.6326 30.1396 21.1C31.6722 19.5674 32.5917 17.5239 32.5917 15.2761C32.5917 13.0283 31.6722 10.8826 30.1396 9.45217C28.6069 8.02174 26.5635 7 24.3156 7C22.0678 7 19.9222 7.91957 18.4917 9.45217C16.9591 10.8826 16.0396 13.0283 16.0396 15.2761C16.0396 17.5239 16.9591 19.6696 18.4917 21.1C19.9222 22.6326 22.0678 23.5522 24.3156 23.5522ZM21.6591 12.5174C22.3743 11.8022 23.2939 11.3935 24.3156 11.3935C25.3374 11.3935 26.2569 11.8022 26.9722 12.5174C27.6874 13.2326 28.0961 14.1522 28.0961 15.1739C28.0961 16.1957 27.6874 17.1152 26.9722 17.8304C26.3591 18.5457 25.3374 18.9543 24.3156 18.9543C23.2939 18.9543 22.3743 18.5457 21.6591 17.8304C20.9439 17.1152 20.5352 16.1957 20.5352 15.1739C20.5352 14.1522 21.0461 13.2326 21.6591 12.5174Z"
                            fill="orange"/>
                        <path
                            d="M35.4527 26.9239L33.0005 23.5522C32.8984 23.3478 32.5918 23.3478 32.3875 23.5522C30.1397 25.4935 27.2788 26.8218 24.2136 26.8218C21.1484 26.8218 18.3897 25.5957 16.0397 23.5522C15.8353 23.45 15.631 23.45 15.4266 23.5522L13.0766 26.9239C12.9745 27.1283 12.9745 27.3326 13.0766 27.4348C15.1201 29.0696 17.4701 30.1935 20.0245 30.8065L14.9158 39.9C14.8136 40.2065 14.9158 40.5131 15.2223 40.5131H20.2288C20.4332 40.5131 20.5353 40.4109 20.5353 40.2065L24.1114 32.1348L27.6875 40.2065C27.6875 40.3087 27.8918 40.5131 27.994 40.5131H33.0005C33.3071 40.5131 33.5114 40.2065 33.3071 39.9L28.1984 30.8065C30.6505 30.1935 33.1027 29.1718 35.1462 27.4348C35.6571 27.2304 35.6571 27.0261 35.4527 26.9239Z"
                            fill="orange"/>
                    </svg>
                </a>
            </li>

        </div>
    </div>
}

export default Contacts