import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import {CaretRightOutlined} from "@ant-design/icons"
import icon from "../../assets/dasdas.png"
import {YMaps, Map, Placemark} from '@pbe/react-yandex-maps';
import {ReestrNecomOrgMediacia} from "../../assets/Reestrs/reestr2023mediacia"
import {suvuArray} from "../../assets/Reestrs/suvuArray";

const mapState = {center: [55.751574, 37.573856], zoom: 10};

const placeMarks = [
    {
        coordinates: [46.443765, 47.960029],
        description: suvuArray[0][0].col3,
    },
    {
        coordinates: [48.987243, 131.553207],
        description: suvuArray[0][1].col3,
    },
    {
        coordinates: [55.025859, 22.024903],
        description: suvuArray[0][2].col3,
    },
    {
        coordinates: [53.567193, 87.271698],
        description: suvuArray[0][3].col3,
    },
    {
        coordinates: [58.540775, 48.902038],
        description: suvuArray[0][4].col3,
    },
    {
        coordinates: [54.926865, 64.464389],
        description: suvuArray[0][5].col3,
    },
    {
        coordinates: [67.937593, 32.899207],
        description: suvuArray[0][6].col3,
    },
    {
        coordinates: [55.179159, 80.292058],
        description: suvuArray[0][7].col3,
    },
    {
        coordinates: [55.000988, 73.401980],
        description: suvuArray[0][8].col3,
    },
    {
        coordinates: [56.268166, 28.476136],
        description: suvuArray[0][9].col3,
    },
    {
        coordinates: [45.063521, 39.710863],
        description: suvuArray[0][10].col3,
    },
    {
        coordinates: [53.476685, 56.033700],
        description: suvuArray[0][11].col3,
    },
    {
        coordinates: [55.905659, 48.730846],
        description: suvuArray[0][12].col3,
    },
    {
        coordinates: [53.712839, 91.354146],
        description: suvuArray[0][13].col3,
    },
    {
        coordinates: [59.776654, 30.592388],
        description: suvuArray[0][14].col3,
    },
    {
        coordinates: [53.778484, 81.318572],
        description: suvuArray[1][0].col3,
    },
    {
        coordinates: [51.509291, 128.134973],
        description: suvuArray[1][1].col3,
    },
    {
        coordinates: [62.595489, 39.851072],
        description: suvuArray[1][2].col3,
    },
    {
        coordinates: [61.644280, 40.369939],
        description: suvuArray[1][3].col3,
    },
    {
        coordinates: [47.958268, 43.661824],
        description: suvuArray[1][4].col3,
    },
    {
        coordinates: [59.205245, 38.521772],
        description: suvuArray[1][5].col3,
    },
    {
        coordinates: [52.256364, 117.720750],
        description: suvuArray[1][6].col3,
    },
    {
        coordinates: [52.561201, 103.874801],
        description: suvuArray[1][7].col3,
    },
    {
        coordinates: [45.839456, 39.014875],
        description: suvuArray[1][8].col3,
    },
    {
        coordinates: [59.139562, 28.070619],
        description: suvuArray[1][9].col3,
    },
    {
        coordinates: [54.818403, 38.122911],
        description: suvuArray[1][10].col3,
    },
    {
        coordinates: [43.831313, 132.003910],
        description: suvuArray[1][11].col3,
    },
    {
        coordinates: [54.485696, 53.895692],
        description: suvuArray[1][12].col3,
    },
    {
        coordinates: [52.085498, 108.373412],
        description: suvuArray[1][13].col3,
    },
    {
        coordinates: [55.841872, 49.034872],
        description: suvuArray[1][14].col3,
    },
    {
        coordinates: [49.394796, 40.270252],
        description: suvuArray[1][15].col3,
    },
    {
        coordinates: [53.170683, 48.662287],
        description: suvuArray[1][16].col3,
    },
    {
        coordinates: [47.312172, 142.025075],
        description: suvuArray[1][17].col3,
    },
    {
        coordinates: [56.756177, 60.623409],
        description: suvuArray[1][18].col3,
    },
    {
        coordinates: [61.333420, 73.460002],
        description: suvuArray[1][19].col3,
    },
    {
        coordinates: [55.286671, 61.385279],
        description: suvuArray[1][20].col3,
    },
    {
        coordinates: [55.335689, 85.765080],
        description: suvuArray[2][0].col3,
    },
    {
        coordinates: [54.213909, 90.855770],
        description: suvuArray[2][1].col3,
    },
    {
        coordinates: [55.839042, 37.525899],
        description: suvuArray[2][2].col3,
    },
    {
        coordinates: [56.268251, 43.888981],
        description: suvuArray[2][3].col3,
    },
    {
        coordinates: [51.161986, 54.980031],
        description: suvuArray[2][4].col3,
    },
    {
        coordinates: [58.298475, 57.819048],
        description: suvuArray[2][5].col3,
    },
    {
        coordinates: [57.966883, 56.169750],
        description: suvuArray[2][7].col3,
    },
    {
        coordinates: [56.003311, 29.955617],
        description: suvuArray[2][8].col3,
    },
    {
        coordinates: [43.109685, 44.619796],
        description: suvuArray[2][9].col3,
    },
    {
        coordinates: [60.015673, 30.307020],
        description: suvuArray[2][10].col3,
    },
    {
        coordinates: [56.741312, 85.386602],
        description: suvuArray[2][11].col3,
    },
    {
        coordinates: [55.763199, 60.720858],
        description: suvuArray[2][12].col3,
    },
    {
        coordinates: [51.482572, 40.450598],
        description: suvuArray[2][13].col3,
    },

];

const SUVUNavigator = ({children, header}) => {
    const [newsData, setNews] = useState([])


    const mapState = {center: [58.677300, 87.306715], zoom: 3};

    //const reestrSliced = new Array(ReestrNecomOrgMediacia.filter(el => el.col1 = "Центральный федеральный округ"))
    //const reestrSliced1 = ReestrNecomOrgMediacia.filter(el => el.col1 = " Северо-Западный федеральный округ")
    //const reestrSliced2 = ReestrNecomOrgMediacia.filter(el => el.col1 = "Южный федеральный округ")
    //const reestrSliced3 = ReestrNecomOrgMediacia.filter(el => el.col1 = "Северо-Кавказский федеральный округ")
    //const reestrSliced4 = ReestrNecomOrgMediacia.filter(el => el.col1 = "Приволжский федеральный округ")
    //const reestrSliced5 = ReestrNecomOrgMediacia.filter(el => el.col1 = "Уральский федеральный округ")
    //const reestrSliced6 = ReestrNecomOrgMediacia.filter(el => el.col1 = "Сибирский федеральный округ")
    //const reestrSliced7 = ReestrNecomOrgMediacia.filter(el => el.col1 = "Дальневосточный федеральный округ")
    return <div>
        <div className={s.newHeader}>
            <h2>
                Реестр некоммерческих организаций, применяющих восстановительные, а также медиативные технологии
            </h2>
        </div>

        <div className={s.greyBlock}>
            {suvuArray.map(el => <div>
                    <Region reestrSlice={el}/>

                </div>
            )}
        </div>

        <p></p>
        <div style={{maxWidth: "1200px", margin: "0 auto", width: "90%"}}>
            <YMaps>
                <Map modules={['geoObject.addon.balloon']} state={mapState} width="100%" height="400px">
                    {placeMarks.map((point, index) => (
                        <Placemark
                            key={index}
                            geometry={point.coordinates}
                            properties={{
                                balloonContent: point.description
                            }}
                        />
                    ))}
                </Map>
            </YMaps>
        </div>

        <p></p>
    </div>
}


const Region = ({reestrSlice}) => {
    const [active, setActive] = useState(false)

    return <div style={{paddingTop: "20px"}}>
        <div className={s.item} onClick={() => {
            active ? setActive(false) : setActive(true)
        }}>
            <strong>
               {reestrSlice[0].col1}
            </strong><CaretRightOutlined
            style={{
                rotate: `${active ? "0deg" : "90deg"}`,
                transition: "all 1s",
                marginLeft: "8px",
                fontSize: "20px"
            }}/>
        </div>
        {active ? <>{reestrSlice.map(((oblasti, id) => <Location col2={oblasti.col2} col3={oblasti.col3}
                                                                 col4={oblasti.col4} col5={oblasti.col5}
                                                                 col6={oblasti.col6} col7={oblasti.col7}
                                                                 col8={oblasti.col8} col9={oblasti.col9}/>))}   </> :
            <div></div>}
    </div>
}

const ShowedLocation = ({oblasti, id, reestrSlice}) => {
    const [activeRegion, setActiveRegion] = useState(false)
    return <div>

        <div onClick={() => {
            activeRegion ? setActiveRegion(false) : setActiveRegion(true)
        }} style={{textAlign: "center"}}>
            <div style={{paddingBottom: "30px"}} className={s.region}><img src={icon} style={{
                paddingRight: "4px",
                height: "26px"
            }}/>{oblasti.col2}<CaretRightOutlined
                style={{
                    rotate: `${activeRegion ? "0deg" : "90deg"}`,
                    transition: "all 1s",
                    marginLeft: "8px",
                    fontSize: "20px"
                }}/></div>
            {activeRegion ? <div style={{paddingBottom: "30px", marginTop: "-15px"}}>
                {oblasti.map(((centers) => <Location col3={centers.col3} col4={centers.col4} col5={centers.col5}
                                                     col6={centers.col6}
                                                     col7={centers.col7}
                                                     col8={centers.col8.split(',').map(line =>
                                                         <span>{line}<br/></span>)}
                                                     col9={centers.col9.split(',').map(line => <a
                                                         href={`mailto:${line}`}>{line}</a>)}/>))
                }
            </div> : <></>
            }

        </div>
    </div>
}


const Location = ({col2, col3, col4, col5, col6, col7, col8, col9}) => {
    const col7Split = col7.split(", ")
    return <div className={s.locations}>
        <div className={s.lockationsCol}>
            {col2}
        </div>
        <div className={s.lockationsCol}>
            {col3}
        </div>
        <div className={s.lockationsCol}>
            {col4}
        </div>
        <div className={s.lockationsCol}>
            {col5}
        </div>
        <div className={s.lockationsCol}>
            {col6}
        </div>
        <div className={s.lockationsCol} style={{wordBreak: "break-all"}}>
            <a href={col7Split[0]}>{col7Split[0]} </a>
            <div>
                <a href={col7Split[1]}>{col7Split[1]} </a>
            </div>
            <div>
                <a href={col7Split[2]}>{col7Split[2]} </a>
            </div>
        </div>
        <div className={s.lockationsCol}>
            {col8}
        </div>
        <div className={s.lockationsCol} style={{wordBreak: "break-word"}}>
            {col9}
        </div>
    </div>
}
export default SUVUNavigator;