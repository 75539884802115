import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/Обложка0606.jpg"
import img1 from "../../assets/News/1zona.jpg"
import img2 from "../../assets/News/2zona.jpg"
import img3 from "../../assets/News/3zona.jpg"
import img4 from "../../assets/News/4zona.jpg"
import img5 from "../../assets/News/5zona.jpg"
import img6 from "../../assets/News/6zona.jpg"


const VserosZona = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Всероссийское совещание по вопросам ресоциализации подростков, находящихся в конфликте с законом
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>18-19 мая 2023 года в Республике Башкортостан прошло Всероссийское совещание по вопросам ресоциализации подростков, находящихся в конфликте с законом. В рамках совещания прошли пленарные сессии, мастер-классы, фокус-сессии. Приняли участие Уполномоченный при Президенте Российской Федерации по правам ребенка Мария Алексеевна Львова-Белова, директор Департамента государственной политики в сфере защиты прав детей Лариса Павловна Фальковская, министр образования и науки Республики Башкортостан Хажин Айбулат Вакилович, представители прокуратуры, МВД, уполномоченные по правам ребенка субъектов России, руководители специальных учебно-воспитательных учреждений (далее – СУВУ), руководители и специалисты федеральных и региональных учреждений системы образования,  социальной сферы и системы профилактики.</p>
            <p>Для участников совещания на площадке Ишимбайского СУВУ силами сотрудников и обучающихся были проведены интерактивные экскурсии и мастер-классы. Все отметили слаженную работу коллектива и воспитанниц, заинтересованность и уважительное отношение к друг другу.</p>
            <p>На фокус-сессии были обозначены ресурсы СУВУ в ресоциализации подростков, находящихся в конфликте с законом. С докладом «Восстановительные технологии в профилактической работе» выступила Марина Викторовна Быкова, начальник отдела медиации ФГБУ «Центр защиты прав и интересов детей». На пленарных сессиях вызвали большой интерес доклады о современных научных подходах к работе с подростками, совершившими правонарушения и национальных исследованиях качества образования в области девиантного поведения.</p>
            <p>Обсуждение документального фильма «Не пересечь черту» показало большую заинтересованность все присутствующих в необходимости поднимать вопросы ресоциализации обучающихся СУВУ, дальнейшей поддержки, работы с семьей, конструктивного межведомственного взаимодействия.</p>
            <p></p>
            <div className={s.threeImg}>
                <div>
                    <img src={img1}/>
                </div>
                <div>
                    <img src={img2}/>
                </div>
                <div>
                    <img src={img3}/>
                </div>
                <div>
                    <img src={img4}/>
                </div>
                <div>
                    <img src={img5}/>
                </div>
                <div>
                    <img src={img6}/>
                </div>
            </div>
        </div>
        <ToNews/>
    </div>
}


export default VserosZona;