import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News241024_1.jpg"
import img2 from "../../assets/News/News241024_2.png"

const News241024 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Второй Всероссийский конкурс «На стороне ребенка» 2024 г. и Всероссийское совещание руководителей органов
            опеки и попечительства
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            17-18 октября 2024 г. в Москве прошло Всероссийское совещание руководителей органов опеки и попечительства,
            объединившее представителей федеральных и региональных органов власти, уполномоченных по правам ребенка
            субъектов Российской Федерации, общественных организаций, а также ведущих ученых и экспертов в сфере защиты
            прав детей.
            <p></p>
            На мероприятии были представлены мини-лекции по вопросам, связанным с защитой прав детей, организована
            работа секций, посвященных, в том числе вопросам о роли органов опеки и попечительства в оказании семьям с
            детьми помощи, направленной на поддержку и укрепление родительства и восстановление детско-родительских
            отношений, о мотивационных мерах по развитию форм семейного устройства, усыновления детей, оставшихся без
            попечения родителей, об участии органов опеки в разрешении споров о воспитании детей родителями в досудебном
            порядке.
            <p></p>
            В рамках Всероссийского совещания состоялась торжественная церемония закрытия второго Всероссийского
            конкурса в сфере профессиональной деятельности специалистов органов опеки и попечительства в отношении
            несовершеннолетних граждан «На стороне ребенка» и награждение победителей и лауреатов.
            <p></p>
            Награды победителям вручали Статс-секретарь - заместитель Министра просвещения Российской Федерации Корнеев
            Андрей Алексеевич и директор Департамента государственной политики в сфере защиты прав детей Минпросвещения
            России Фальковская Лариса Павловна.
            <p></p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <a style={{fontWeight: "bold"}} href="https://edu.gov.ru/press/8962/nagrazhdeny-pobediteli-i-laureaty-vserossiyskogo-konkursa-na-storone-rebenka/">
                НОВОСТЬ НА САЙТЕ МИНПРОСВЕЩЕНИЯ РОССИИ
            </a>
            <p></p>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <a style={{fontWeight: "bold"}} href="https://disk.yandex.ru/d/YIbsJ4lnQvUDOQ/">
                Материалы и фотографии Всероссийского совещания руководителей органов опеки и попечительства
            </a>
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News241024;