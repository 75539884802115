import React from "react";
import Falldown from "../common/FalldownList/Falldown";
import img21 from "../../assets/img/1.png"
import s from "./Behavior.module.css"
import {NavLink} from "react-router-dom";
import ico1 from "../../assets/Behavior/1.png"
import ico2 from "../../assets/Behavior/2.png"
import ico3 from "../../assets/Behavior/3.png"
import doc from "../../assets/img/doc.svg"
import {ArrowRightOutlined} from "@ant-design/icons"
import logo from "../../assets/Directions/5_1.svg"


const Behavior = () => {
    return <div style={{marginBottom: "40px"}}>
        <div >
            <div style={{maxWidth: "1200px", margin:"0 auto"}}>
                <h2 style={{fontWeight: "normal", display: "flex", justifyContent: "center", flexWrap: "wrap"}}>
                    <div style={{width: "100%"}}>
                        <div style={{margin: "0 auto", width: "110px"}}>
                            <img src={logo} style={{width: "110px"}}/>
                        </div>
                    </div>

                    <div style={{
                        fontSize: "30px",
                        fontFamily: "Proxima Nova Lt",
                        color: "#3A4663",
                        textAlign: "center"
                    }}>Отдел профилактики девиантного поведения</div>
                </h2>

            </div>
            <div className={s.fall}>
                <div style={{marginTop: "30px"}}>
                    <div className={s.block}>
                        <div className={s.blockHead}>
                            <div className={s.text}>
                                <img src={doc}/>
                                Ответственное поведение - ресурс здоровья
                            </div>

                            <div className={s.button} style={{textDecoration: "none"}}>
                                <div>
                                    <NavLink to="/responsible-behavior">
                                        Перейти <ArrowRightOutlined style={{color: "red", width: "7px"}}/>
                                    </NavLink>
                                </div>
                            </div>

                        </div>
                        <div className={s.ico} style={{backgroundImage: `url(${ico1})`, zIndex: 3, opacity: 0.7}}>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

}


export default Behavior