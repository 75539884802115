import React from "react";
import ico from "../../../assets/fileicored.svg"
import ico1 from "../../../assets/link.ico"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, WhiteButton} from "../PageInfo/PageInfo"
import Falldown, {Falldown2, FileLi, HrefLi} from "../FalldownList/Falldown";
import {FileLeftLineKDN} from "./FilesKDNNews";

export const hrefsArrFPF = [
    {
        text: "Программа мониторинга реализации Концепции",
        to: "/spec-conception/programma-monitoringa-realizatsii-kontseptsii"
    },
    {
        text: "Формы мониторинга реализации Концепции",
        input: [{text: "2021 год", to: "/spec-conception/2021-god/"}, {
            text: "2020 год",
            to: "/spec-conception/2020-god"
        }, {text: "2018/19 учебный год", to: "/spec-conception/2018-19-uchebnyj-god"}, {
            text: "2017/18 учебный год",
            to: "/spec-conception/2017-18-uchebnyj-god"
        }],
        to: "/spec-conception/formy-monitoringa-realizatsii-kontseptsii"
    },
    {
        text: "Материалы по исполнению Концепции развития системы профилактики безнадзорности и правонарушений несовершеннолетних    ",
        to: "/spec-conception/plan-meropriyatij-kontseptsii/"
    },
    {text: "Ответственные исполнители", to: "/spec-conception/otvetstvennye-ispolniteli"},
    {text: "Всероссийские конференции ", to: "/spec-conception/vserossijskie-konferentsii"},
    {text: "Лента региональных практик ", to: "/spec-conception/lenta-pactic/"},
]
const FilesPublicationsForms = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Формы мониторинга реализации Концепции
                </h2>

                <Falldown header="2021 год" style={{maxWidth: "700px"}}>
                    <FileLeftLine text={respData[266]?.description} refers={respData[266]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={respData[265]?.description} refers={respData[265]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={respData[262]?.description} refers={respData[262]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={respData[261]?.description} refers={respData[261]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={respData[263]?.description} refers={respData[263]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={respData[264]?.description} refers={respData[264]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                </Falldown>
                <Falldown header="2020 год" style={{maxWidth: "700px"}}>
                    <FileLeftLine text={respData[267]?.description} refers={respData[267]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={respData[268]?.description} refers={respData[268]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={respData[269]?.description} refers={respData[269]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={respData[270]?.description} refers={respData[270]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                </Falldown>
                <Falldown header="2018/19 учебный год" style={{maxWidth: "700px"}}>
                    <FileLeftLine text={respData[271]?.description} refers={respData[271]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={respData[272]?.description} refers={respData[272]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={respData[273]?.description} refers={respData[273]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={respData[274]?.description} refers={respData[274]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={respData[275]?.description} refers={respData[275]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={respData[276]?.description} refers={respData[276]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={respData[277]?.description} refers={respData[277]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={respData[278]?.description} refers={respData[278]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={respData[279]?.description} refers={respData[279]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={respData[280]?.description} refers={respData[280]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={respData[281]?.description} refers={respData[281]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                </Falldown>
                <Falldown header="2017/18 учебный год" style={{maxWidth: "700px"}}>
                    <FileLeftLine text={respData[282]?.description} refers={respData[282]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={respData[283]?.description} refers={respData[283]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={respData[284]?.description} refers={respData[284]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={respData[285]?.description} refers={respData[285]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={respData[286]?.description} refers={respData[286]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={respData[287]?.description} refers={respData[287]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={respData[288]?.description} refers={respData[288]?.file} ico={ico}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                    <FileLeftLine text={"Перейти на сайт передачи результатов мониторинга субъектами РФ"}
                                  refers={"http://monitoring.fcprc.ru/"} ico={ico1}
                                  styles={{minWidth: "400px", maxWidth: "600px"}}/>
                </Falldown>
            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
                <WhiteButton text="Твое право" to="/tvoyo-pravo"/>
            </div>
        </div>
    </>
}
export default FilesPublicationsForms