import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/IAmMentor2023.jpg"
import img2 from "../../assets/News/IAmMentor2023_2.jpg"
import img3 from "../../assets/News/IAmMentor2023_3.jpg"
import img4 from "../../assets/News/IAmMentor2023_4.jpg"
import img5 from "../../assets/News/IAmMentor2023_5.jpg"
import img6 from "../../assets/News/IAmMentor2023_6.jpg"

const IAmMentor2023 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Медиация на Всероссийском форуме «Я – наставник» 23-24 марта 2023 года
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>
                В рамках объявленного президентом Российской Федерации В.В. Путиным Года педагога и наставника, 23-24
                марта 2023 года на площадке Конгресс-центра «Олимп-холл» в г. Москве прошел Всероссийский форум «Я –
                наставник».</p>
            <p>Цель форума – cоздание условий для открытой дискуссии о совершенствовании системы наставничества как
                эффективного механизма воспитания и обучения детей с целью развития высоконравственной личности,
                разделяющей российские традиционные духовные ценности, обладающей актуальными знаниями и умениями,
                способной реализовать свой потенциал в условиях современного общества, готовой к мирному созиданию и
                защите Родины.</p>
            <p>В форуме приняли участие представители образовательных организаций (педагоги, воспитатели, психологи,
                вожатые), представители детских и молодежных общественных организаций, представители родительского
                сообщества, представители министерств и ведомств, депутатский корпус, Герои России, ветераны боевых
                действий.</p>
            <p>В рамках форума работали дискуссионные площадки, тематические секции по обмену опытом и постановки задач
                на будущее.</p>
            <p>Модератором секции «Наставничество в СУВУ» выступила Лариса Павловна Фальковская, директор Департамента
                государственной политики в сфере защиты прав детей Минпросвещения России. В секции приняли участие
                директора СУВУ, обучающие и выпускники СУВУ, представители организаций социальной сферы, духовенства и
                другие заинтересованные лица. От ФГБУ «Центр защиты прав и интересов детей» (далее – Центр) приняли
                участие Борис Борисович Гусев, директор Центра и Марина Викторовна Быкова, начальник отдела медиации
                Центра.</p>
            <p>Марина Викторовна выступила в качестве спикера и рассказала участникам секции о вкладе сотрудников отдела
                медиации Центра в идею наставничества. Это проведение интерактивных занятий по обучению подростков
                техникам медиации для решения сложных ситуаций, возникающих в СУВУ и после выпуска, это оказание помощи
                в урегулировании конфликтов между ребенком, находящимся в СУВУ и его родителями.</p>
            <p>В завершении форума прошла церемония вручения Премии общественного признания наставников «Первый среди
                равных». Поздравляем Надежду Петровну Кисиль, директора ФГБПОУ Раифское СУВУ и Аслана Туркубиевича Хута,
                директора ФГБПОУ Майкопское СУВУ, удостоенных этой премии.</p>
            <p></p>
            <div>
                <img src={img2}/>
            </div>
            <div>
                <img src={img3}/>
            </div>
            <div>
                <img src={img4}/>
            </div>
            <div>
                <img src={img5}/>
            </div>
            <div>
                <img src={img6}/>
            </div>
        </div>
        <ToNews/>
    </div>
}


export default IAmMentor2023;