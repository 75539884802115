import React from "react";
import ico from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {hrefsArrKDN} from "./FilesKDNGos";

const hrefsArr = [
    {text: "Мероприятия", input: [], to: "/kdnivents"},
    {text: "О КДНиЗП", input: [], to: "/kdnHistory"},
    {text: "Правительственная КДНиЗП", to: "/kdnGos"},
    {text: "Методологические материалы", to: "/kdnDev"},
    {text: "Нормативная правовая база", to: "/kdnRights"},
    {text: "Всероссийские совещания КДНиЗП", input: ["2021 год", "Контакты"], to: "/kdnInternational"},
    {text: "Контакты", to: "/kdnContacts"},
    {text: "Актуальные интернет-ресурсы по вопросам профилактики", to: "/kdnActiveQ"},
    {text: "Реестр НКО", to: "/kdnReestr"},
    {text: "Сведения по форме ФСН №1-КДН", to: "/kdnInfoForm"},

]
const FilesKDNContacts = () => {
    const [respData, setData] = useState([])

    
    return <>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt",
            fontSize: "30px",
            marginRight: "23vw"
        }}>
            Контакты
        </h2>


        <div className={s.filesLeftPage}>
            <div>
                <h3 style={{textAlign: "left", fontFamily:"Proxima Nova Rg", fontSize: "16px", color: "#212519"}}>
                    Зенина Елена Сергеевна
                </h3>
                <div style={{textAlign: "left", fontFamily:"Segoe UI", fontSize: "16px", color: "#212519"}}>
                    руководитель федерального ресурсного центра комиссий по делам несовершеннолетних и защите их прав
                </div>
                <h3 style={{textAlign: "left", fontFamily:"Proxima Nova Rg", fontSize: "16px", color: "#212519"}}>
                    Микрюкова Татьяна Сергеевна
                </h3>
                <div style={{textAlign: "left", fontFamily:"Segoe UI", fontSize: "16px", color: "#212519"}}>
                    аналитик 2 категории федерального ресурсного центра комиссии по делам несовершеннолетних и<br/> защите их
                    прав
                </div>
                <p></p>
                <br/>
                <div className={s.kdnContacts}>
                    тел. <a href="tel:84955870110,%203049">8 495 587-01-10, доб. 3049</a>
                    <br/>
                    e-mail: <a href="mailto:kdn@edu.gov.ru">kdn@edu.gov.ru</a>
                </div>

            </div>
            <div>
                <Hrefs hrefsArr={hrefsArrKDN}/>
            </div>
        </div>
    </>
}
export default FilesKDNContacts