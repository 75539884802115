import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News060224.jpeg"
import img1 from "../../assets/News/News060224_1.jpeg"
import img2 from "../../assets/News/News060224_2.jpeg"
import img3 from "../../assets/News/News060224_3.jpeg"

const News060224 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Презентация проекта «Коммуникация: точка опоры! Медиативные технологии в работе с замещающими семьями» 6
            февраля 2024 года
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            6 февраля 2024 года в Краснодарском крае Ассоциация профессиональных медиаторов «Единство» провела
            презентацию проекта «Коммуникация: точка опоры! Медиативные технологии в работе с замещающими семьями»,
            победившего в конкурсе Фонда президентских грантов.
            <p></p>
            В мероприятии приняли участие представители аппарата Уполномоченного по правам ребёнка Краснодарского края,
            Министерства труда и социального развития Краснодарского края, органов опеки и попечительства, Красноярское
            региональное отделение Ассоциации юристов России и другие организации, чья деятельность связана с работой с
            замещающими семьями.
            <p></p>
            Проект реализуется под руководством члена Экспертного совета по развитию служб медиации и примирения – Елены
            Владимировны Морозовой.
            <p></p>
            В ходе презентации Проекта в онлайн формате выступил начальник отдела медиации ФГБУ «Центр защиты прав и
            интересов детей» Анатолий Андреевич Козлов.
            <p></p>
            Анатолий Андреевич отметил системный подход Проекта, так как его мероприятия направлены не только на семьи
            опекунов и попечителей, приемных родителей, но и на сотрудников органов опеки и попечительства. Это
            актуально ввиду приказа Министерства труда и социальной защиты Российской Федерации от 6 сентября 2023 г. №
            691н «Об утверждении профессионального стандарта «Специалист органа опеки и попечительства в отношении
            несовершеннолетних», в котором фигурирует знание основ медиации, умение применять технологии разрешения
            конфликтов (медиации), а также взаимодействие со службами медиации.
            <p></p>
            Приказ вступает в силу с 1 сентября 2024 г.
            <p></p>
            В рамках проекта предусмотрены:
            <ul>
                <li> Индивидуальная консультативная помощь по вопросам адаптации ребёнка в приёмной (опекаемой)
                    семье;
                </li>

                <li>Клуб для родителей «Родительская гостиная», где будут проводиться встречи с педагогом-психологом и
                    медиатором;
                </li>

                <li>Цикл встреч для подростков «Развитие навыков общения и конфликторазрешения»;</li>

                <li>Коммуникативные игры для подростков «Мастер коммуникации».</li>
            </ul>
            Проект «Коммуникация: точка опоры! Медиативные технологии в работе с замещающими семьями» направлен на
            профилактику и предотвращение вторичного сиротства и возникновения кризисных ситуаций в замещающих семьях
            посредством оказания комплексной профессиональной консультативной помощи замещающей семье.
            <p></p>
        </div>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <ToNews/>
    </div>
}


export default News060224;