import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/SupportOfMediationServicesMay2022.jpg"

const SupportOfMediationServicesMay2022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сопровождение служб медиации в специальных учебно-воспитательных учреждениях в мае 2022 года
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>12 – 13 мая отдел медиации ФГБУ «Центр защиты прав и интересов детей» провел завершающие в первом
                полугодии мероприятия по сопровождению служб медиации СУВУ, они включали групповые консультации
                специалистов служб и интерактивные занятия с обучающимися.
            </p>
            <p>
                Мероприятия прошли в дистанционном формате с Абаканским СУВУ, Ишимбайским СУВУ и Мончегорским СУВУ.
            </p>
            <p>
                С сотрудниками служб в рамках консультаций обсуждались планы на 2022 год и текущее состояние службы. В
                практической части встречи проходил обмен профессиональным опытом и коллективный разбор нескольких
                сложных ситуаций из практики СУВУ.
            </p>
            <p>
                С обучающимися на интерактивных занятиях обсуждались причины конфликта и его составные части. Участники
                встречи разобрали ряд тематических кейсов, предлагали свои варианты их решения и оценивали варианты,
                озвученные сверстниками. Занятия прошли в доверительной и комфортной атмосфере.
            </p>
        </div>
        <ToNews/>
    </div>
}


export default SupportOfMediationServicesMay2022;