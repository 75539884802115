import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News131023.jpg"
import img2 from "../../assets/News/News131023_2.jpg"
import img3 from "../../assets/News/News131023_3.jpg"

const News131023 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сопровождение служб медиации в специальных учебно-воспитательных учреждениях в сентябре 2023 года
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Во II полугодии 2023 года сотрудники отдела медиации Центра защиты прав и интересов детей продолжили
                работу по сопровождению работы служб медиации специальных учебно-воспитательных учреждений,
                подведомственных Минпросвещения России.
            </p>
            <p>Мероприятия прошли 28 – 29 сентября с Куртамышским СУВУ, Щекинским СУВУ, Раифским СУВУ и Астраханским
                СУВУ в дистанционном формате.
            </p>
            <p>В рамках сопровождения для сотрудников служб были проведены консультации, на которых обсуждались
                проведенные мероприятия и достижения служб, а также их текущее состояние.
            </p>

            <p>С обучающимися на интерактивных занятиях были проведены деловые игры, направленные на формирование
                навыков ведения переговоров в ситуации неопределенности. Структура занятий была выстроена таким образом,
                чтобы создать условия для общения обучающихся разных полов.
            </p>
            <p>
                Встречи проходили в дружеской и уважительной атмосфере, обучающиеся и сотрудники положительно оценили
                интерактивный формат занятий.
            </p>
            <p>
                Мероприятия проводили: начальник отдела медиации Центра защиты прав и интересов детей – Быкова Марина
                Викторовна и аналитик II категории отдела медиации – Козлов Анатолий Андреевич.
            </p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <ToNews/>
    </div>
}


export default News131023;