import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News15042022.jpg"

const News15042022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Пресс-релиз вебинара «Популяризация мероприятий Десятилетия детства: проблемы, механизмы, практики»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>21 апреля 2022 г. в 10:00 Мск. Минпросвещения России совместно с ФГБУ «Центр защиты прав и интересов
                детей» проводит методический вебинар «Популяризация мероприятий Десятилетия детства: проблемы,
                механизмы, практики».</p>
            <p>Вебинар продолжает цикл мероприятий по реализации Десятилетия детства.</p>
            <p>В данном вебинаре примут участие представители федеральных органов исполнительной власти, рабочих групп
                при Координационном совете при Правительстве Российской Федерации по проведению в Российской Федерации
                Десятилетия детства, органов исполнительной власти субъектов Российской Федерации, общественных
                организаций и СМИ.</p>
            <p>Спикеры вебинара:</p>
            <p>Фальковская Лариса Павловна – директор Департамента государственной политики в сфере защиты прав детей
                Минпросвещения России.</p>
            <p>Жильцов Вадим Александрович – заместитель директора Департамента международного сотрудничества и связей с
                общественностью Минпросвещения России.</p>
            <p>Абрамов Сергей Алексеевич – член рабочей группы «Координация реализации Десятилетия детства» при
                Координационном совете при Правительстве Российской Федерации по проведению в Российской Федерации
                Десятилетия детства.</p>
            <p>Преображенская Елена Евгеньевна – директор Практики социальных коммуникаций Коммуникационного агентства
                гуманитарных технологий.</p>
            <p>Бондаренко Елена Александровна – главный редактор радиостанции «Детское радио».</p>
            <p>Представители органов исполнительной власти Костромской и Иркутской областей, которые представят лучшие
                региональные практики и наработки по вопросам, связанным с информационным сопровождением мероприятий
                Десятилетия детства.</p>
            <p>Мероприятие пройдет в онлайн-формате.</p>
            <p><a href="https://events.webinar.ru/41646463/11036719">Ссылка на регистрацию</a></p>
        </div>
        <ToNews/>
    </div>
}


export default News15042022;