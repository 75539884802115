import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News16062022.jpg"

const News16062022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Стартовал Всероссийский конкурс социальной рекламы «Стиль жизни — здоровье! 2022»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>31 мая 2022 года стартовал Всероссийский конкурс социальной рекламы «Стиль жизни – здоровье! 2022»</p>
            <p>Конкурс направлен на повышение эффективности формирования и распространения культуры здорового и
                безопасного образа жизни среди детей и подростков, профилактику аддиктивного и аутодеструктивного
                поведения обучающихся, повышение воспитательного потенциала образовательных организаций</p>
            <p><strong>Основные задачи Конкурса:</strong></p>
            <p>— привлечение внимания обучающихся к социально значимым проблемам общества;</p>
            <p>— просвещение детей и подростков в вопросах ведения здорового и безопасного образа жизни;</p>
            <p>— создание условий для открытого, доверительного общения, возможностей самопроявления обучающихся в
                процессе творческой работы над проектом;</p>
            <p>— развитие социальной инициативы на основе сотрудничества обучающихся и их педагогов, родителей (законных
                представителей) в процессе подготовки конкурсной работы;</p>
            <p>— обеспечение социальной поддержки творческой активности обучающихся, предоставление возможности выразить
                свое отношение к наиболее значимым социальным проблемам современного общества и предложить оптимальный
                способ их решения;</p>
            <p>— пополнение банка социально ориентированной рекламной продукции</p>
            <br/>
            <p><strong>Конкурс проводится по двум номинациям:</strong></p>
            <p>«Социальный видеоролик по пропаганде здорового и безопасного образа жизни, направленный на профилактику
                зависимого поведения обучающихся»</p>
            <p>«Наглядный раздаточный материал по пропаганде здорового и безопасного образа жизни, направленный на
                профилактику зависимого поведения обучающихся»</p>
            <p>Целевой аудиторией Конкурса являются обучающиеся образовательных организаций двух возрастных групп: 8-12
                лет и 13-18 лет</p>
            <p>Конкурс проводится в два заочных этапа:</p>
            <p>Региональный этап – по 28 октября 2022 года</p>
            <p>Федеральный этап – с 31 октября по 30 ноября 2022 года</p>
            <p><strong>К участию в федеральном этапе Конкурса допускаются победители регионального этапа!</strong></p>
            <p><a href="https://fcprc.ru/vserossijskie-konkursy/">Стартовал Всероссийский конкурс социальной рекламы «Стиль жизни - здоровье! 2022»</a></p>
            <p><a href="http://2022.social.edu-contests.ru/">Стартовал Всероссийский конкурс социальной рекламы «Стиль жизни - здоровье! 2022»</a></p>
        </div>
        <ToNews/>
    </div>
}


export default News16062022;