import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News250524.jpg"
import img1 from "../../assets/News/News250524_1.jpg"
import img2 from "../../assets/News/News250524_2.jpg"
import img3 from "../../assets/News/News250524_3.jpg"

const News250524 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            XXIV Московская городская научно-практическая конференция «Золотой стандарт в терапии и профилактике никотиновой зависимости: прошлое, настоящее, будущее»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Аналитик I категории отдела профилактики девиантного поведения несовершеннолетних ФГБУ «Центр защиты прав и
            интересов детей» Михаил Викторович Алагуев в качестве докладчика принял участие в XXIV Московской городской
            научно-практической конференции «Золотой стандарт в терапии и профилактике никотиновой зависимости: прошлое,
            настоящее, будущее», организованной ГБУЗ города Москвы «Московский научно-практический центр наркологии
            Департамента здравоохранения города Москвы».
            <p></p>
            Конференция была посвящена актуальным вопросам профилактики и лечения никотиновой зависимости, последствий
            потребления табака или иной никотинсодержащей продукции, улучшения организации медицинской помощи лицам с
            никотиновой зависимостью и совершенствования междисциплинарного взаимодействия.
            <p></p>
            Михаил Алагуев представил доклад, посвященный актуальным инструментам профилактики социально-негативных
            явлений в образовательной среде. Он поделился опытом в этой области, выделив основные методы и подходы,
            используемые для предотвращения развития зависимостей у подрастающего поколения.
            <p></p>
            В конференции также принимали участие специалисты ФГБУ «ЦНИИОИЗ» Минздрава России, ФГАОУ ВО Первый МГМУ им.
            И. М. Сеченова Минздрава России, ГБУЗ «ГКБ № 67 им. Л. А. Ворохобова ДЗМ»; ФГБУ «СПб НИИФ» Минздрава России,
            ФГБОУ ДПО РМАНПО Минздрава России, ГБУ «Городской психолого-педагогический центр ДОНМ» и ГБУЗ ЯО
            «Ярославская областная клиническая наркологическая больница».
            <p></p>
            На конференции обсуждались медицинские аспекты профилактики, тогда как выступление Михаила Викторовича с
            освещением работы системы образования подтверждает важность междисциплинарного подхода к данной проблеме.
            Это подчеркивает стремление различных организаций и ведомств к совместной работе в интересах здоровья детей
            и общества в целом.
        </div>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <ToNews/>
    </div>
}


export default News250524;