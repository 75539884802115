import React from "react";
import s from "./Default.module.css"
import {useState} from "react";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News091024.jpeg"
import img1 from "../../assets/News/News091024_1.jpeg"
import img2 from "../../assets/News/News091024_2.jpeg"
import img3 from "../../assets/News/News091024_3.jpeg"
import img4 from "../../assets/News/News091024_4.jpeg"
import img5 from "../../assets/News/News091024_5.jpeg"
import img6 from "../../assets/News/News091024_6.jpeg"

const News091024 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Новый поток лектория Общества «Знание» о медиации запускается в Москве
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            12 октября 2024 г. в Москве продолжит работу лекторий «Медиация: благополучие ребенка в семье и школе»,
            организованный Российским обществом «Знание» совместно с Национальным исследовательским университетом
            «Высшая школа экономики».
            <p></p>
            В программе лектория базовый модуль из четырех встреч на площадке Высшей школы экономики на Покровском
            бульваре.
            <p></p>
            Лекторий представляет специально подготовленный комплекс практических инструментов и информации о медиации и
            ненасильственном общении для тех, кто хочет разобраться в вопросах разрешения и предотвращения конфликтов в
            школьной среде и семейных отношениях.
            <p></p>
            Приглашенные эксперты в сфере медиации познакомят слушателей с эффективными методами разрешения конфликтов,
            обсудят с точки зрения различных наук природу конфликтов и подходы к созданию гармоничной и безопасной
            образовательной среды, помогут семьям научиться находить компромиссы, понимать и учитывать интересы друг
            друга.
            <p></p>
            По итогам участия в лекциях и мастер-классах слушатели смогут получить сертификаты и будут не только знать о
            том, такое медиация и где можно получить бесплатные услуги профессионального медиатора, но также смогут
            использовать некоторые медиативные и восстановительные технологии для решения и предупреждения своих
            конфликтных ситуаций.
            <p></p>
            Участие бесплатное, но требуется предварительная регистрация на сайте
            проекта: <a style={{color: "red", fontWeight: "bold"}}
                        href="https://popularscience.hse.ru/polls/947509109.html">https://popularscience.hse.ru/polls/947509109.html</a>
            <p></p>
            Подробнее о лектории «Медиация: благополучие ребенка в семье и школе» можно узнать по ссылке: <a
            style={{color: "red", fontWeight: "bold"}}
            href="https://popularscience.hse.ru/mediation">https://popularscience.hse.ru/mediation</a>
        </div>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <div>
            <img src={img4}/>
        </div>
        <div>
            <img src={img5}/>
        </div>
        <div>
            <img src={img6}/>
        </div>
        <ToNews/>
    </div>
}


export default News091024;