import React from "react";
import ico from "../../../assets/img/link.png"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1} from "../PageInfo/PageInfo"


const Psycho = () => {
    const [respData, setData] = useState([])


    return <>

        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Психодиагностические методики
                </h2>
                <FileLeftLine text={"Переход на страницу Методика 'Оценка родителями готовности детей к школе'"}
                              refers="https://survey.fcprc.ru/readytoschool" ico={ico}/>
                <FileLeftLine text={'Перейти на страницу Методика "Взаимодействие родитель - ребенок"'}
                              refers="https://telefon-doveria.ru/" ico={ico}/>
                <FileLeftLine text={'Перейти на страницу Методика "Анализ семейной тревоги"'}
                              refers="https://survey.fcprc.ru/anxiety" ico={ico}/>
                <FileLeftLine text={'Перейти на страницу Методика "Шкала семейной адаптации и сплоченности"'}
                              refers="https://survey.fcprc.ru/cohesion" ico={ico}/>
                <FileLeftLine text={'Перейти на страницу Методика "Опросник профессиональных склонностей"'}
                              refers="https://survey.fcprc.ru/profession" ico={ico}/>

            </div>

        </div>
    </>
}
export default Psycho