import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/bestIncSchool.png"

const ProtectionFromSexualViolence2022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            30 ноября 2022 г. в Москве обсудили вопросы защиты детей от сексуальной эксплуатации и сексуального насилия
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>30 ноября 2022 г. Минпросвещения России совместно с ФГБУ «Центр защиты прав
                и интересов детей» провели межведомственный семинар-совещание, посвященный
                вопросам противодействия и защиты детей от сексуальной эксплуатации и
                сексуального насилия.</p>
            <p>В мероприятии приняли участие представители Минпросвещения России,
                Уполномоченного при Президенте Российской Федерации по правам ребенка, СК
                России, Минюста России, МВД России, Генеральной прокуратуры Российской
                Федерации и Верховного суда Российской Федерации. Весомый вклад в обсуждение
                заявленной темы внесли доклады представителей научной школы РУДН,
                Московского университета МВД России им. В.Я. Кикотя, Тамбовского
                государственного университета им. Г.Р. Державина, Университета им. О.Е.
                Кутафина (МГЮА), а также представителей экспертного сообщества – Городского
                психолого-педагогического центра Департамента образования и науки г. Москвы и
                Лаборатории судебной сексологии Центра им. В.П. Сербского.</p>
            <p>В ходе семинара обсуждались:<br/>
                – меры противодействия и наказания за преступления в сфере сексуального насилия
                и эксплуатации детей в практике российских правоохранительных ведомств;<br/>
                – законодательство и правоприменительная практика Российской Федерации в части
                преступлений против половой неприкосновенности несовершеннолетних;<br/>
                – вопросы профилактики и превентивных мер в целях соблюдения гарантий защиты
                детей от сексуального насилия;<br/>
                – вопросы интернет-рисков для детей и подростков, использование интернета как
                средства сексуальной эксплуатации и насилия над детьми;<br/>
                – методы психотерапии и психологического консультирования в работе с детьми и
                подростками по преодолению последствий опыта насилия;<br/>
                – особенности международно-правовой защиты детей от сексуальной эксплуатации
                и сексуального насилия.</p>
            <p>При подведении итогов участники семинара-совещания поддержали практику
                проведения данного мероприятия на ежегодной основе и акцентировали внимание
                на необходимости поддержания тесного сотрудничества и межведомственных
                контактов для защиты российских детей от сексуальной эксплуатации и
                сексуального насилия.</p>
        </div>
        <ToNews/>
    </div>
}


export default ProtectionFromSexualViolence2022;