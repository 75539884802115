import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News290124.jpg"
import img1 from "../../assets/News/News290124_1.png"

const News290124 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Заявки на повышение квалификации принимаются до 1 марта 2024
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Федеральное государственное бюджетное учреждение «Центр защиты прав
            и интересов детей» (далее – Центр) Министерства просвещения Российской Федерации (далее – Министерство) в
            соответствии с имеющейся лицензией
            на образовательную деятельность с применением электронного обучения, дистанционных образовательных
            технологий планирует проведение курсов повышения квалификации в 2024 году по следующим программам обучения:<br/>
            1. «Рассмотрение споров родителей, связанных с воспитанием детей, включая споры с участием иностранного
            элемента», 36 ак. часов.<br/>
            2. «Особенности подготовки и сопровождения опекунов или попечителей несовершеннолетних», 36 ак. часов.<br/>
            3. «Основы медиации для специалистов органов опеки и попечительства»,
            36 ак. часов.<br/>
            4. «Медиативно-восстановительные технологии в образовательной организации», 36 ак. часов.<br/>
            5. «Практикум начинающего медиатора: учимся на кейсах», 20 ак. часов.<br/>
            6. «Практикум профессионального медиатора: тренируемся на кейсах»,
            20 ак. часов.<br/>
            7. «Формирование ценностей здоровьесбережения и профилактика зависимого поведения обучающихся
            образовательных организаций», 20 ак. часов.<br/>
            8. «Психологические проблемы детско-подросткового возраста: профилактика и коррекция в общеобразовательных
            организациях», 20 ак. часов.
            <p></p>
            Разработчиками и преподавателями программ дополнительного профессионального образования являются эксперты
            всероссийского и международного уровня, члены экспертных групп при Министерстве и Центре.<br/>
            Заявки на обучение по перечисленным дополнительным профессиональным программам повышения квалификации
            принимаются до 1 марта 2024 г.<br/>
            Предлагаем ознакомиться с программами повышения квалификации, условиями обучения, записаться на курсы.
            Получить подробную информацию о курсах, записаться на обучение можно на портале дополнительного
            профессионального образования Центра: <a href="https://dpo.fcprc.ru">https://dpo.fcprc.ru</a>.
            <p></p>
            <div style={{display: "flex", justifyContent: "center"}}>
                <img src={img1}/>
            </div>
            <p></p>
            Направить свой запрос на обучение специалистов по другим интересующим вас темам можно на электронную почту <a href="mailto:dpo@fcprcgov.ru">dpo@fcprcgov.ru</a>.
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News290124;