import React from "react";
import ico from "../../../assets/fileicored.svg"
import icoBlue from "../../../assets/arrow_blue.svg"
import s from "./PageFiles.module.css"
import {FileLeftLineSUVU} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1} from "../PageInfo/PageInfo"


const hrefsArr = [
    {text: "Новости", to: "/spec-suvu/news-suvu/"},
    {text: "Нормативное регулирование деятельности СУВУ", to: "/spec-suvu/normativno-pravovye-dokumenty/"},
    {
        text: "Информационно-методические материалы",
        to: "/spec-suvu/informatsionno-analiticheskie-materialy/"
    },
    {text: "Всероссийские мероприятия", to: "/spec-suvu/meropriyatiya/"},
    {text: "Люди. События. Факты.", to: "/spec-suvu/ludi-fakti-sobitiya/"},
]
const FilesSuvuMet = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/suvu").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Информационно-методические материалы
                </h2>

                <FileLeftLineSUVU text={respData[15]?.description} refers={respData[15]?.file} ico={ico}/>
                <FileLeftLineSUVU text={"Спас-Экстрим – портал детской безопасности МЧС России"} refers={""}
                                  ico={icoBlue}/>
                <FileLeftLineSUVU text={"Интернет-проект для несовершеннолетних «Подросток и закон»"}
                                  refers={"https://podrostok.edu.yar.ru/"} ico={icoBlue}/>
                <FileLeftLineSUVU text={"Федерация психологов образования России"} refers={"https://rospsy.ru/"}
                                  ico={icoBlue}/>
                <FileLeftLineSUVU
                    text={"Федеральный ресурсный центр по вопросам опеки и попечительства в отношении несовершеннолетних"}
                    refers={"https://frcopeca.ru/"} ico={icoBlue}/>
                <FileLeftLineSUVU
                    text={"Портал организационно-методической поддержки центров психолого-педагогической, медицинской и социальной помощи"}
                    refers={"https://ovzrf.ru/"} ico={icoBlue}/>
                <FileLeftLineSUVU text={"Федеральный ресурсный центр ПМПК"} refers={"https://pmpkrf.ru/"}
                                  ico={icoBlue}/>
                <FileLeftLineSUVU text={"Президент России – гражданам школьного возраста"}
                                  refers={"http://детям.президент.рф/"} ico={icoBlue}/>
                <FileLeftLineSUVU text={"Уполномоченный при Президенте Российской Федерации по правам ребенка"}
                                  refers={"http://deti.gov.ru/"} ico={icoBlue}/>
                <FileLeftLineSUVU text={respData[16]?.description} refers={respData[16]?.file} ico={ico}/>
            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArr}/>
            </div>
        </div>
    </>
}
export default FilesSuvuMet