import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News281123.jpg"
import img2 from "../../assets/News/News281123_2.jpg"
import img3 from "../../assets/News/News281123_3.jpg"
import img4 from "../../assets/News/News281123_4.jpg"
import img5 from "../../assets/News/News281123_5.jpg"
import img6 from "../../assets/News/News281123_6.jpg"

const News281123 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            ПРОДЛЕВАЕТСЯ НАБОР НА ПЛАТНОЕ ОБУЧЕНИЕ ДО 1 ДЕКАБРЯ 2023Г
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <strong>По разрешению споров родителей, связанных с воспитанием детей, с участием иностранного
                элемента</strong>
            <p></p>
            Если вы заинтересованы в содействии разрешению конфликта между сторонами и их примирению на взаимоприемлемых
            условиях в интересах ребенка, хотите работать в межведомственной команде с организациями, оказывающими
            психологическую, юридическую и иную помощь семьям и детям, то этот курс для вас!
            <p></p>
            Мирное досудебное урегулирование спора в наибольшей степени соответствует интересам ребенка, поскольку оно
            способствует установлению дружественных отношений между сторонами, позволяет исключить или минимизировать
            возможный ущерб здоровью и развитию ребенка, вызванный конфликтом между его родителями, родственниками или
            иными лицами.
            <p></p>
            Наш Центр приглашает на программу повышения квалификации «Споры родителей, связанные с воспитанием детей.
            Особенности рассмотрения споров, связанных с воспитанием детей, с участием иностранного элемента», <strong>16
            академических часов.</strong>
            <p></p>
            Записаться на курс повышения квалификации: <strong><a
            href="/education/registrationOpeca">https://fcprc.ru/education/registrationOpeca</a></strong>
            <p></p>
            <strong>Период обучения:</strong> 05 декабря - 18 декабря 2023 года
            <p></p>
            Формат обучения:
            <p></p>
            Очные занятия в дистанционном формате.
            <p></p>
            Все занятия будут проводиться с личным участием преподавателя.
            <p></p>
            После прохождения обучения, вы получите <strong>удостоверение о повышении
            квалификации</strong> установленного образца.
            <p></p>
            Преподаватели:
            <p></p>
            <strong>· Галина Владимировна Семья</strong>, доктор психологических наук, профессор;
            <p></p>
            <strong>· Марина Валерьевна Лашкул</strong>, заместитель директора ФГБУ «Центр защиты прав и интересов детей»;
            <p></p>
            <strong>· Тарасова Анна Евгеньевна</strong>, кандидат юридических наук, юрист;
            <p></p>
            <strong>· Тюльканов Сергей Леонидович</strong>, аналитик ФГБУ «Центр защиты прав и интересов детей».
            <p></p>
            <strong>Эта программа для вас, если вы:</strong>
            <p></p>
            - специалист органов опеки и попечительства;
            <p></p>
            - осуществляете содействие обеспечению и защите прав и законных интересов детей их социальных и иных
            государственных гарантий;
            <p></p>
            - рассматриваете споры:
            <p></p>
            · о месте жительства ребенка и порядке общения с ребенком отдельно проживающего родителя;
            <p></p>
            · о незаконном похищении/удержании ребенка одним из родителей.
            <p></p>
            После прохождения обучения вы сможете:
            <p></p>
            - Применять правовые основания и направления деятельности органов опеки и попечительства при рассмотрении
            споров о воспитании детей, в том числе с участием иностранного элемента:
            <p></p>
            · на досудебной стадии;
            <p></p>
            · в судебном процессе;
            <p></p>
            · в исполнительном производстве.
            <p></p>
            - Учитывать мнение ребенка при решении вопросов, затрагивающих его права и интересы.
            <p></p>
            - Применять методы и способы выяснения мнения ребенка в случаях, предусмотренных законодательством.
            <p></p>
            - Понимать процесс медиации в трансграничных семейных спорах.
            <p></p>
            <strong>‼Стоимость обучения: 7 000 рублей‼</strong>
            <p></p>
            Программа разработана в соответствии с
            <p></p>
            - примерной дополнительной профессиональной программой повышения квалификации для работников органов опеки и
            попечительства, утвержденной приказом Минобрнауки России от 24.02.2015 № 121 (зарегистрирован Минюстом
            России 21.05.2015, регистрационный № 37361);
            <p></p>
            - профессиональным стандартом «Специалист органа опеки и попечительства в отношении несовершеннолетних»,
            утвержденным приказом Министерства труда и социальной защиты Российской Федерации от 18.11.2013 № 680н
            (зарегистрирован Минюстом России 26.12.2013, регистрационный № 30850).
            <p></p>
            <strong>Подробнее о курсе:<br/>
                <a
                href="https://dpo.fcprc.ru/course/споры-родителей-связанные-с-воспита-6/">https://dpo.fcprc.ru/course/споры-родителей-связанные..</a></strong>
            <p></p>
            ФГБУ «Центр защиты прав и интересов детей» Министерства просвещения Российской Федерации разрабатывает и
            проводит курсы повышения квалификации для специалистов в сфере защиты прав и интересов детей по вопросам:
            <p></p>
            опеки и попечительства
            <p></p>
            медиации и восстановительных технологий
            <p></p>
            помощи обучающимся с ограниченными возможностями здоровья, с инвалидностью
            <p></p>
            профилактики девиантного поведения несовершеннолетних
            <p></p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <div>
            <img src={img4}/>
        </div>
        <div>
            <img src={img5}/>
        </div>
        <div>
            <img src={img6}/>
        </div>
        <ToNews/>
    </div>
}


export default News281123;