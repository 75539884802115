import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/FTS-konkurs-1.jpg"

const CompetitionOfSocializationOver = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Всероссийский конкурс программ социализации подростков завершен
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>31 октября 2022 года завершился Всероссийский конкурс программ социализации подростков. Основная задача
                конкурса – выявление и поддержка региональных инициатив по реализации программ в подростковых
                центрах.</p>
            <p>В состав экспертного совета вошли специалисты психолого-педагогического профиля. По итогам экспертной
                оценки, определено 15 победителей конкурса.</p>
            <p><strong>ЛАУРЕАТЫ КОНКУРСА</strong></p>
            <p>1 место Муниципальное учреждение культуры «Дворец культуры и спорта», экспериментальное подростковое
                пространство «ЗАВОД» города Валуйки Белгородской области.
                <br/>Руководитель – Злобина Юлия Николаевна, начальник управления культуры администрации Валуйского
                городского округа.</p>
            <p>2 место Бюджетное общеобразовательное учреждение Чувашской Республики «Центр образования и комплексного
                сопровождения детей», Республика Чувашия, г. Новочебоксарск, ул. Советская, д. 41.
                <br/>Руководитель – Ильина Светлана Валерьевна.</p>
            <p>3 место ТОГБОУ ДО «Центр развития творчества детей и юношества», Тамбовская область, г. Тамбов, ул.
                Сергея Рахманинова, 3 Б.<br/>Руководитель – Долгий Иван Анатольевич.</p>
            <p><strong>ПОБЕДИТЕЛИ КОНКУРСА</strong></p>
            <p>4 место МБУ «Молодежный Центр», Пермский край, г. Чусовой, ул. Чайковского, д. 27.
                <br/>Руководитель – Хрони Ирина Андреевна.</p>
            <p>5 место МАКДУ «Эжвинский ДКБ», Республика Коми, г. Сыктывкар, ул. Мира, д. 10/1.
                <br/>Руководитель – Андреев Василий Михайлович.</p>
            <p>6 место МБОУ «КОМПАС», Челябинская область, г. Челябинск, ул. Молдавская, д.16.
                <br/>Руководитель – Артемьева Мария Сергеевна.</p>
            <p>7 место АНО ДПО «Профи Центр», Ульяновская область, г. Ульяновск, пер. Молочный, д. 12а.
                <br/>Руководитель – Сорокина Елена Юрьевна.</p>
            <p>8 место МБУ «Молодежный многопрофильный центр «Родина», Тульская область, г. Тула, ул. Галкина, д. 31а.
                <br/>Руководитель – Титова Марина Вадимовна.</p>
            <p>9 место ГБУ РЦ «Молодёжь плюс», Саратовская область, г. Саратов, ул. Соборная, д. 42 в.
                <br/>Руководитель – Пантелеева Дарина Романовна.</p>
            <p>10 место Бюджетное учреждение Ханты-Мансийского автономного округа – Югры «Сургутский районный центр
                социальной помощи семье и детям», Ханты-Мансийский автономный округ – Югра, г. Сургут, ул. Лермонтова,
                д. 7/2.<br/>Руководитель – Черкашина Елена Леонидовна</p>
            <p>11 место ГБУ «Социально-реабилитационный центр для несовершеннолетних городского округа Семеновский»,
                Нижегородская область, г. Семенов, ул. Володарского, д. 36.<br/>Руководитель – Смирнова Галина
                Владимировна.</p>
            <p>12 место МБУ МЦ «Дом молодежи», Новосибирская область, г. Новосибирск, Первомайский район, ул. Эйхе, д.
                1. <br/>Руководитель – Блинова Александра Валерьевна.</p>
            <p>13 место АНО «Ресурсный центр профилактики социального сиротства», Рязанская область, г. Рязань, ул.
                4-линия, д.60, помещение Н1.
                <br/>Руководитель – Цыпич Наталья Валерьевна.</p>
            <p>14 место Центр «Ритм», Республика Дагестан, г. Махачкала, ул. М. Гаджиева, 170.
                <br/>Руководитель – Максудов Рашид Шамильевич.</p>
            <p>15 место ГБУ Республиканский центр психолого-медико-социального сопровождения «Сайзырал», Республика
                Тыва, ул. Рабочая, д. 56.
                <br/>Руководитель – Монгуш Альбина Зайцевна.</p>
        </div>
        <ToNews/>
    </div>
}


export default CompetitionOfSocializationOver;