import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/SupportMediationSEI2022.jpg"
import img2 from "../../assets/News/SupportMediationSEI2022_2.png"
import img3 from "../../assets/News/SupportMediationSEI2022_3.png"

const SupportMediationSEI2022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сопровождение служб медиации в специальных учебно-воспитательных учреждениях в декабре 2022 года
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>8 и 9 декабря прошли заключительные в этом году мероприятия по сопровождению служб медиации в
                специальных учебно-воспитательных учреждениях, подведомственных Минпросвещения России (СУВУ).</p>
            <p>Мероприятия провели сотрудники отдела медиации ФГБУ «Центр защиты прав и интересов детей». Мероприятия
                включали в себя групповые консультации специалистов служб и интерактивные занятия с обучающимися.</p>
            <p>Консультации и занятия прошли в дистанционном формате
                8 – 9 декабря (Каргатское СУВУ, Рефтинское СУВУ, Абаканское СУВУ, Куртамышское СУВУ, Ишимбайское
                СУВУ).</p>
            <p>С сотрудниками служб медиации СУВУ на консультации был рассмотрен и проанализирован конфликт между
                обучающимися. Разбирались варианты урегулирования и профилактики подобных случаев с использованием
                медиативных и восстановительных технологий.</p>
            <p>Также с сотрудниками служб медиации СУВУ рассматривалось актуальное состояние службы и планы на 2023
                год.</p>
            <p>Интерактивные занятия с обучающимися из разных СУВУ включали в себя знакомство с техниками медиации в
                условиях деловой игры, события которой происходят в космическом пространстве.</p>
            <p>Участники занятий отметили новые формы работы и отметили, что в таком виде материал усваивается проще и
                интересней.</p>
            <p>Работа со службами медиации СУВУ будет продолжена в 2023 году на основании Межведомственного плана
                комплексных мероприятий по реализации Концепции развития сети служб медиации, в целях реализации
                восстановительного правосудия в отношении детей, в том числе совершивших общественно опасные деяния, но
                не достигших возраста, с которого наступает уголовная ответственность в Российской Федерации, до 2025
                года.</p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <ToNews/>
    </div>
}


export default SupportMediationSEI2022;