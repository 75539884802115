import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News300524.png"

const News300524 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            ФГБУ «Центр защиты прав и интересов детей» принял участие в IV Всероссийском форуме руководителей
            организаций, осуществляющих стационарное социальное обслуживание детей с психическими расстройствами «Мы в
            ответе за наших детей»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            23–24 мая 2024 года в Казани прошел IV Всероссийский форум руководителей организаций, осуществляющих
            стационарное социальное обслуживание детей с психическими расстройствами «Мы в ответе за наших детей».
            Организаторами форума выступили Министерство труда и социальной защиты Российской Федерации, Фонд поддержки
            детей, находящихся в трудной жизненной ситуации, и Министерство труда, занятости и социальной защиты
            Республики Татарстан.
            <p></p>
            Цель форума – организация методической площадки с интерактивным образовательным процессом; создание условий
            для выявления, обсуждения и поиска возможных вариантов решений проблем детских социальных учреждений;
            презентация региональных практик, меняющих к лучшему качество жизни детей.
            <p></p>
            Заместитель директора по вопросам опеки и попечительства ФГБУ «Центра защиты прав и интересов детей» Лашкул
            Марина Валерьевна приняла участие в качестве эксперта от Минпросвещения России на площадке «Все ли проблемы
            решает трёхстороннее соглашение и какие есть варианты?», обсудив вопросы, связанные с правами и
            обязанностями родителей, временно поместившими детей в детский дом-интернат, включая участие в воспитании и
            содержании детей, сроками пребывания ребенка в учреждении.
        </div>
        <ToNews/>
    </div>
}


export default News300524;