import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/news290524.jpg"

const News290524 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Центр защиты прав и интересов детей Министерства просвещения РФ <div></div>
            и Институт дополнительного профессионального образования г. Москвы   
            <div></div>
            28.05.2024 г. заключили СОГЛАШЕНИЕ О СОТРУДНИЧЕСТВЕ!
        </h2>
        <div style={{display: "flex", justifyContent: "center"}}>
            <img src={img} />
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
        Директор Центра <strong>Борис Гусев</strong>
<div></div>
и и. о. директора ИДПО <strong>Игорь Тимофеев</strong>
<div></div>
выразили обоюдное стремление развивать двусторонние связи,
<div></div>
договорились о эффективном и взаимовыгодном сотрудничестве,
<div></div>
направленном на развитие совместных научных, образовательных программ и проектов
<div></div>
в интересах детей и семей.
            <p></p>
            Сотрудничество учреждений, направленное на поддержку детей и семей, строится на принципах
            <ul>
                <li>взаимных интересов;</li>
                <li>паритетности;</li>
                <li>взаимопомощи;</li>
                <li>законности.</li>
            </ul>
           
            
          <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News290524;