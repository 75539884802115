import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import {CaretRightOutlined} from "@ant-design/icons"
import icon from "../../assets/dasdas.png"
import {YMaps, Map, Placemark} from '@pbe/react-yandex-maps';
import {ReestrUpdMediacia} from "../../assets/Reestrs/reestrUpdmediacia"





const ReestrNecomOrgNew = ({children, header}) => {
    const [newsData, setNews] = useState([])


    const mapState = {center: [58.677300, 87.306715], zoom: 3};

    //const reestrSliced = new Array(ReestrNecomOrgMediacia.filter(el => el.col1 = "Центральный федеральный округ"))
    //const reestrSliced1 = ReestrNecomOrgMediacia.filter(el => el.col1 = " Северо-Западный федеральный округ")
    //const reestrSliced2 = ReestrNecomOrgMediacia.filter(el => el.col1 = "Южный федеральный округ")
    //const reestrSliced3 = ReestrNecomOrgMediacia.filter(el => el.col1 = "Северо-Кавказский федеральный округ")
    //const reestrSliced4 = ReestrNecomOrgMediacia.filter(el => el.col1 = "Приволжский федеральный округ")
    //const reestrSliced5 = ReestrNecomOrgMediacia.filter(el => el.col1 = "Уральский федеральный округ")
    //const reestrSliced6 = ReestrNecomOrgMediacia.filter(el => el.col1 = "Сибирский федеральный округ")
    //const reestrSliced7 = ReestrNecomOrgMediacia.filter(el => el.col1 = "Дальневосточный федеральный округ")
    return <div>
        <div className={s.newHeader}>
            <h2>
            Список организаций, реализующих программы подготовки медиаторов
            </h2>
        </div>

        <div className={s.greyBlock}>
            {ReestrUpdMediacia.map(el => <div>
                    <Region reestrSlice={el}/>
                </div>
            )}
        </div>


        <p></p>
    </div>
}


const Region = ({reestrSlice}) => {
    const [active, setActive] = useState(false)

    return <div style={{paddingTop: "20px"}}>
        <div className={s.item} onClick={() => {
            active ? setActive(false) : setActive(true)
        }}>
            <img src={icon} style={{paddingRight: "4px"}}/><strong>{reestrSlice[0][0].col1} </strong><CaretRightOutlined
            style={{rotate: `${active ? "0deg" : "90deg"}`, transition: "all 1s", marginLeft: "8px", fontSize: "20px"}}/>
        </div>
        {active ? <>{reestrSlice.map(((oblasti, id) => <ShowedLocation oblasti={oblasti} id={id}
                                                                       reestrSlice={reestrSlice}/>))}   </> :
            <div></div>}
    </div>
}

const ShowedLocation = ({oblasti, id, reestrSlice}) => {
    const [activeRegion, setActiveRegion] = useState(false)
    return <div>

        <div onClick={() => {
            activeRegion ? setActiveRegion(false) : setActiveRegion(true)
        }} style={{textAlign: "center"}}>
            <div style={{paddingBottom: "30px"}} className={s.region}><img src={icon} style={{
                paddingRight: "4px",
                height: "26px"
            }}/>{oblasti[0].col2}<CaretRightOutlined
                style={{rotate: `${activeRegion ? "0deg" : "90deg"}`, transition: "all 1s", marginLeft: "8px", fontSize: "20px"}}/></div>
            {activeRegion ? <div style={{paddingBottom: "30px", marginTop: "-15px"}}>
                {oblasti.map(((centers) => <Location col3={centers.col3} col4={centers.col4} col5={centers.col5}
                                                     col6={centers.col6}
                                                     col7={centers.col7}
                                                     col8={centers.col8.split(',').map(line =>
                                                         <span>{line}<br/></span>)}
                                                     col9={centers.col9.split(',').map(line => <a
                                                         href={`mailto:${line}`}>{line}</a>)}/>))
                }
            </div> : <></>
            }

        </div>
    </div>
}


const Location = ({col3, col4, col5, col6, col7, col8, col9}) => {
    const col7Split = col7.split(", ")
    console.log(col9)
    return <div className={s.lockations}>
        <div className={s.lockationsCol}>
            {col3}
        </div>
        <div className={s.lockationsCol}>
            {col4}
        </div>
        <div className={s.lockationsCol}>
            {col5}
        </div>
        <div className={s.lockationsCol}>
            {col6}
        </div>
        <div className={s.lockationsCol} style={{wordBreak: "break-all"}}>
            <a href={col7Split[0]}>{col7Split[0]} </a>
            <div>
                <a href={col7Split[1]}>{col7Split[1]} </a>
            </div>
            <div>
                <a href={col7Split[2]}>{col7Split[2]} </a>
            </div>
        </div>
        <div className={s.lockationsCol}>
            {col8}
        </div>
        <div className={s.lockationsCol} style={{wordBreak: "break-word"}}>
            {col9}
        </div>
    </div>
}
export default ReestrNecomOrgNew;