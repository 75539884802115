import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import IcoPMPK from "../../../assets/logo-wide.png"
import {ArrowRightOutlined, CheckSquareFilled} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {NavLink} from "react-router-dom";
import {Formik, Field, Form} from "formik";
import ImgPopup from "../Popup/PopupImg"
import axios from "axios";
import * as Yup from 'yup';


const Hotline7 = ({url, header, children, style = {}}) => {
    const [active, setActive] = useState(false)
    const [isSubmited, setSubmittingForm] = useState(true)
    const [politics, setPolitics] = useState(false)


    return <div className={s.page}>
        <div className={s.content}>
            <h2 style={{fontWeight: "normal", fontSize: "30px", color: "#3a4463"}}>
                Форма для заполнения адреса отправки удостоверения
            </h2>
            <div>

                <p></p>
                Уважаемые коллеги, здравствуйте! Просим заполнить форму в целях отправки почтой оригинала удостоверения
                о повышении квалификации.
                <p></p>
                <img src={IcoPMPK} style={{boxShadow: " 0 4px 11px rgb(0 0 0 / 30%)", borderRadius: "10px"}}/>
                <p></p>
                <div>
                    {/*                    <div className={s.hotlineHref}>*/}
                    {/*                        <input type="checkbox" onClick={() => {*/}
                    {/*                            politics? setPolitics(false): setPolitics(true)*/}
                    {/*                        }}/>*/}
                    {/*                        Я даю согласие согласие на обработку <a href="https://fcprc.ru/media/media/behavior/Приказ__39_от_29.05.2023.pdf">персональных данных</a>*/}
                    {/*                    </div>*/}
                    {/*                    <br/>*/}
                    {/*                    {politics ? <div onClick={() => {*/}
                    {/*                        setActive(true)*/}
                    {/*                    }} style={{*/}
                    {/*                        color: "white",*/}
                    {/*                        padding: "15px",*/}
                    {/*                        backgroundColor: "pink",*/}
                    {/*                        width: "230px",*/}
                    {/*                        marginBottom: "70px",*/}
                    {/*                        cursor: "pointer",*/}
                    {/*                        borderRadius: "10px"*/}
                    {/*                    }}>*/}
                    {/*                        Перейти к заполнению*/}
                    {/*                    </div> : <div style={{*/}
                    {/*                        color: "white",*/}
                    {/*                        padding: "15px",*/}
                    {/*                        backgroundColor: "#c97171",*/}
                    {/*                        width: "230px",*/}
                    {/*                        marginBottom: "70px",*/}

                    {/*                        borderRadius: "10px"*/}
                    {/*                    }}>*/}
                    {/*                        Перейти к заполнению*/}
                    {/*                    </div>}*/}
                    {/*                </div>*/}
                    {/*                {active?<div style={{fontSize:"22px"}}>*/}
                    {/*                <script src="https://yastatic.net/s3/frontend/forms/_/embed.js"></script>*/}
                    {/*<iframe src="https://forms.yandex.ru/u/65823a9284227c15f8056f5a/?iframe=1" frameborder="0" name="ya-form-65823a9284227c15f8056f5a" width="650" height="700"></iframe></div>:<></>*/}
                    {/*}*/}
                    {/*                <p></p>*/}

                    <p>
                        <h3>

                        </h3>
                    </p>
                </div>
            </div>

        </div>
    </div>


    }
    /*

    */

    export const HrefsBeh1 = ({hrefsArr, stylef = {}, to = "/"}) => {
        return <div className={s.hrefs} style={stylef}>
    <ul>
        {hrefsArr.map(el => <>
            <OneLiHrefs text={el.text} input={el.input} to={el.to}/>

        </>)}
    </ul>

    </div>
}
const OneLiHrefs = ({
    text, ul, input = null, to
}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <a href={to}>
                    {text}
                </a>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({
    text, to
}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}

export default Hotline7