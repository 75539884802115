import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News010224.jpg"

const BestInclusiveSchool2022 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Курс введения в медиацию для сотрудников СУВУ осенью 2023 года
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <strong><a href="/mediacia">Отдел медиации</a></strong> и отдел дополнительного профессионального образования ФГБУ «Центр защиты прав и интересов
            детей» разработал и успешно апробировал дистанционные курсы повышения квалификации по программе «Введение в
            медиацию. Медиативный и восстановительный подходы» в объёме 72 ак.часа.
            <p></p>
            Дистанционный курс проводился с 25 сентября по 13 ноября 2023 г. В результате обучения успешно завершили и
            прошли итоговую аттестацию 46 специалистов специальных учебно-воспитательных учреждений открытого и
            закрытого типа.
            <p></p>
            По результатам мониторинга деятельности служб медиации в субъектах Российской Федерации за 2022 и 2023
            учебный год во всех 15-ти СУВУ, подведомственных Минпросвещения России, функционируют службы медиации. Также
            в 24-х региональных СУВУ закрытого типа действует 21 служба примирения и медиации, а в 16-ти региональных
            СУВУ открытого типа действуют 12 служб.
            <p></p>
            Программа курса помогла участникам в совершенствовании профессиональных компетенций, связанных с применением
            в работе в условиях СУВУ медиативных и восстановительных технологий. В ходе интерактивных лекций и
            практических занятий для отработки навыков специалисты освоили правовые основы медиации и технологии
            разрешения конфликтов, основанные на медиативном и восстановительном подходе.
            <p></p>
            Особое внимание было уделено практической части обучения, в которой отрабатывались медиативные техники, а
            также практиковалось проведение медиативных бесед. С помощью преподавателей участники курса самостоятельно
            разработали программу «Круга сообщества», помогающую в решении конфликтов и восстановлении отношений в
            коллективе.
            <p></p>
            Преподаватели курса:
            <ul>
                <li>Марина Викторовна Быкова, начальник отдела медиации;</li>
                <li>Анатолий Андреевич Козлов, сотрудник отдела медиации;</li>
                <li>Антон Юрьевич Коновалов, сотрудник отдела медиации.</li>
            </ul>
            Ознакомиться с информацией о курсах по медиативным и восстановительным технологиям и зарегистрироваться на
            них можно на сайте Центра: <a href="https://dpo.fcprc.ru">https://dpo.fcprc.ru</a>
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default BestInclusiveSchool2022;