import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News191223.jpg"

const News191223 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Всероссийская конференция «Современные аспекты оказания
            психолого-педагогической помощи в системе образования обучающихся с ограниченными возможностями здоровья, с
            инвалидностью»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Уважаемые коллеги!
            <p></p>
            Приглашаем Вас принять участие во <strong>Всероссийской конференции</strong> «Современные аспекты оказания
            психолого-педагогической помощи в системе образования обучающихся с ограниченными возможностями здоровья, с
            инвалидностью», которая состоится <strong>21 декабря 2023г.</strong> в формате онлайн-трансляции.
            <p></p>
            В рамках Конференции будут рассмотрены вопросы нормативно-правового регулирования образования обучающихся с
            ограниченными возможностями здоровья, с инвалидностью, оказания психолого-педагогической помощи обучающимся.
            Для участия во Всероссийской конференции приглашаем руководителей и представителей органов государственной
            власти субъектов Российской Федерации в сфере образования, руководителей и специалистов центров
            психолого-педагогической, медицинской и социальной помощи, психолого-медико-педагогических комиссий,
            психолого-педагогических консилиумов, образовательных организаций, административных, педагогических и иных
            работников образовательных организаций.
            <p></p>
            <strong>Онлайн-трансляция: цифровая платформа: <a
                href="https://www.webinar.ru">www.webinar.ru</a></strong><br/>
            Участие в мероприятии бесплатное.<br/>
            Начало Всероссийской конференции — 9:30 (по московскому времени).<br/>
            Для участия в мероприятии необходимо предварительно заполнить регистрационную форму по ссылке:<br/>
            <strong><a href="https://my.mts-link.ru/41646479/1552591077">https://my.mts-link.ru/41646479/1552591077</a></strong>
            <p></p>
            <strong><a href="https://fcprc.ru/media/media/behavior/Программа_Конференции_2023.docx">Программа мероприятия</a></strong>
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News191223;