import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import logo from "../../../assets/Behavior/infosec.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {NavLink} from "react-router-dom";
import {DownOutlined} from "@ant-design/icons"


const hrefsArr = [
    {text: "Консультативная помощь подросткам", to: "/kdnRights", input: []},
    {text: "Консультативная помощь родителям", to: "/kdnDev", input: []},

]
const PagePriceLifeSecWay = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2>
                    Твой безопасный кибермаршрут
                </h2>
            </div>


            <p>
                <p>
                    <h2 style={{fontSize: "24px", textAlign: "center", color: "#3a4663"}}>
                        Эта страница для детей, подростков и родителей. Здесь вы узнаете: </h2>
                </p>
                <ul style={{fontFamily: "Proxima Nova Lt", lineHeight: "1.5", color: "#3a4663"}}>
                    <li><span style={{color: "#212529"}}>
                        О правилах ответственного и безопасного пользования услугами Интернет и мобильной (сотовой)
                        связи;</span>
                    </li>
                    <li><span style={{color: "#212529"}}>
                        О способах защиты от противоправных и иных общественно опасных действий в интернет-среде;</span>
                    </li>
                    <li><span style={{color: "#212529"}}>
                        О возможностях противостоять ситуациям психологического давления, травли и издевательств в сети
                        Интернет — кибербуллинга.</span>
                    </li>
                </ul>
            </p>

        </div>
        <div>
            <Hrefs/>
            <WhiteButton text="Ресурсы профилактики суицида" to="/сuic"/>
            <WhiteButton text="Ценность жизни — обеспечение вопросов детской безопасности"/>
            <WhiteButton text="Информационная безопасность" to="/infSec"/>
        </div>


    </div>


}

export const Hrefs = ({hrefs}) => {
    return <div className={s.hrefs}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} href={el.href} to={el.to}/>

            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, href, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({text, stylesheet}) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({text, stylesheet, to}) => {
    return <NavLink to={to}>
        <div className={s.whitebutton}>

            {text}
            <div>
                <ArrowRightOutlined/>
            </div>
        </div>
    </NavLink>
}

export default PagePriceLifeSecWay