import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/SirotyTwentyThreeHouses.png"

const SirotyTwentyThreeHouses = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Лица из числа детей-сирот старше 23 лет смогут получать сертификаты по покупку жилья
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>
                Законопроект № 261079-8 «О внесении изменений в Федеральный закон «О дополнительных гарантиях по
                социальной поддержке детей-сирот и детей, оставшихся без попечения родителей» 21 марта 2023 г. принят
                Государственной Думой РФ в первом чтении.</p>
            <p>Учитывая численность детей-сирот и лиц из их числа, нуждающихся в обеспечении жилыми помещениями,
                законопроектом предлагается расширить формы обеспечения жильем лиц из числа детей-сирот, достигших
                возраста 23 лет и успешно социализировавшихся в обществе.</p>
            <p>Законопроектом предлагается предоставлять лицам, указанной категории, выплату (сертификат) на
                приобретение жилого помещения в собственность либо на полное погашение ипотечного кредита,
                предоставленного на приобретение жилого помещения.</p>
            <p>Для защиты детей-сирот от мошенников направление средств будет осуществляться органами исполнительной
                власти субъектов РФ непосредственно на счета продавцов квартир либо (в случае их использования для
                полного погашения ипотечного кредита) на счет кредитной организации.</p>
        </div>
        <ToNews/>
    </div>
}


export default SirotyTwentyThreeHouses;