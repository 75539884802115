import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News170723.png"

const News170723 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Начинается прием заявок для участия в третьей номинации Всероссийского конкурса программ социализации
            подростков
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div  className={s.refNews} style={{fontFamily: "Proxima Nova Lt"}}>
            <p>17 июля стартует прием заявок для участия в третьей номинации Всероссийского конкурса программ
                социализации подростков «Социализация через общение, творчество и поддержку».</p>
            <p>Конкурс, который проходит с 15 марта по 8 сентября 2023 года, направлен на выявление лучших практик по
                социализации подростков в Российской Федерации и формирование сообщества специалистов по подростковой
                работе. Организатор конкурса — федеральное государственное бюджетное учреждение «Центр защиты прав и
                интересов детей».</p>
            <p>В направление «Социализация через общение, творчество и поддержку» входит:
                <br/>
                – развитие коммуникативных навыков и эмоционального интеллекта,
                <br/>
                – укрепление навыков к самообучению,
                <br/>
                – развитие креативного мышления,
                <br/>
                – повышение цифровой грамотности.</p>
            <p>В конкурсе могут участвовать организации, осуществляющие свою деятельность не менее года, планирующие
                открыть подростковый центр до конца 2023 года.</p>
            <p>Прием заявок осуществляется с 17 июля по 14 августа 2023 года.</p>
            <p>Участие в конкурсе является добровольным и бесплатным. Одна заявка может участвовать в нескольких
                номинациях конкурса. Заявка на участие в конкурсе и конкурсные материалы направляются по электронному
                адресу: <a href="mailto:fpc_info@fcprc.ru">fpc_info@fcprc.ru</a>.</p>
            <p>Ознакомиться с Положением о конкурсе можно <a href="http://подростковыецентры.рф/bureaucracy">здесь</a>.</p>
        </div>
        <ToNews/>
    </div>
}


export default News170723;