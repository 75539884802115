import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News110624_1.JPG"
import img2 from "../../assets/News/News110624_2.jpeg"
import img3 from "../../assets/News/News110624_3.JPG"
import img4 from "../../assets/News/News110624_4.JPG"
import img5 from "../../assets/News/News110624_5.JPG"
import img6 from "../../assets/News/News110624_6.JPG"
import img7 from "../../assets/News/News110624_7.JPG"
import img8 from "../../assets/News/News110624_8.JPG"
import img9 from "../../assets/News/News110624_9.JPG"
import img10 from "../../assets/News/News110624_10.JPG"
import img11 from "../../assets/News/News110624_11.JPG"
import img12 from "../../assets/News/News110624_12.JPG"
import img13 from "../../assets/News/News110624_13.png"
import img1 from "../../assets/News/News0406243_1.jpg";
import SimpleImageSlider from "react-simple-image-slider";

const News110624 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            III Международный фестиваль «Медиация. Молодость. Будущее!» прошёл 7 июня 2024 года
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            07 июня 2024 года состоялся III Международный фестиваль «Медиация. Молодость. Будущее!», проводимый с целью
            формирования профессионального сообщества, объединяющего медиаторов и специалистов служб медиации и
            примирения, популяризации гуманистических идей и технологий медиации и примирения в современном обществе.
            <p></p>
            Мероприятие организовано институтом непрерывного образования ГАОУ ВО города Москвы «Московский городской
            педагогический университет» при поддержке ФГБУ «Центр защиты прав и интересов детей», членов Экспертного
            совета по развитию служб медиации и примирения в образовательных организациях, аппаратов Уполномоченных по
            правам ребёнка в городе Москве и Московской области.
            <p></p>
            В фестивале приняли участие профессионалы в области медиации и примирения: медиаторы, координаторы,
            руководители служб медиации и примирения общеобразовательных организаций, специальных учебно-воспитательных
            учреждений (СУВУ), подведомственных Минпросвещения России, профессиональных образовательных организаций, а
            также команды юных волонтёров-медиаторов и представители некоммерческих и государственных организаций
            социальной сферы.
            <p></p>
            Мероприятие объединило участников из разных уголков России, Казахстана, Кыргызстана, Республики Беларусь и
            Республики Молдова.
            <p></p>
            Директор Центра защиты прав и интересов детей Борис Борисович Гусев обратился к участникам с приветственным
            словом на праздничном открытии фестиваля, отметив его значение в оценке развития медиации в образовательных
            организациях. Борис Борисович заметил, что школьные службы медиации должны создаваться и развиваться
            естественным путём, для чего Центр создаёт методические материалы как для взрослых, так и для детей, ведь
            обученные медиации дети могут помогать в решении конфликтов и в школе, и в семье и т.д.
            <p></p>
            Начальник отдела медиации Козлов Анатолий Андреевич выступил ведущим фестиваля и мастер-класса «Расскажи
            мне: как правильно формулировать вопросы и отвечать на них!». В мастер-классе приняли участие дети,
            подростки, студенты (том числе обучающиеся СУВУ) и учились задавать вопрос таким образом, чтобы получить
            необходимую информацию, а также правильно отвечать на неудобные и возможно даже каверзные вопросы.
            <p></p>
            Аналитик отдела медиации Марина Викторовна Быкова приняла участие в дискуссионной площадке фестиваля
            «Успешные практики обучения взрослых медиативно-восстановительным технологиям».
            В своём выступлении Марина Викторовна отметила важность практических занятий в обучении взрослых и создания
            безопасной психологической среды в процессе приобретения новых компетенций, особенно если речь идёт об
            обучении медиации, переговорам и практикам коммуникации.
            <p></p>
            На авторских мастер-классах и дискуссионной площадке фестиваля коллеги делились опытом и наработанными
            эффективными практиками по медиативным и восстановительным технологиям.
            <p></p>
            Центр защиты прав и интересов детей участвует в организации фестиваля каждый год с его основания, благодарит
            организаторов и участников фестиваля «Медиация. Молодость. Будущее!» и нацелен на дальнейшее сотрудничество
            в вопросах развития служб медиации в образовательных организациях и популяризации гуманистических идей и
            медиативно-восстановительных технологий в образовании и социальной сфере.
            <p></p>
            Материалы фестиваля и презентации выступающих и ведущих мастер-классов:<br/>
            <a href="http://festival-of-mediators.2024.tilda.ws"
               style={{color: "red"}}>http://festival-of-mediators.2024.tilda.ws</a>
            <p></p>
            Ссылка на запись дискуссионной площадки:<br/>
            <a href="https://rutube.ru/video/private/4738c2e994d8d74196ea9fec07e1093d/?p=QV6Hnu4D4LU37hR5p-jtMg"
               style={{color: "red"}}>https://rutube.ru/video/private/4738c2e994d8d74196ea9fec07e1093d/?p=QV6Hnu4D4LU37hR5p-jtMg</a>
            <p></p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <div>
            <img src={img4}/>
        </div>
        <div>
            <img src={img5}/>
        </div>
        <div>
            <img src={img6}/>
        </div>
        <div>
            <img src={img7}/>
        </div>
        <div>
            <img src={img8}/>
        </div>
        <div>
            <img src={img9}/>
        </div>
        <div>
            <img src={img10}/>
        </div>
        <div>
            <img src={img11}/>
        </div>
        <div>
            <img src={img12}/>
        </div>
        <div>
            <img src={img13}/>
        </div>
        <ToNews/>
    </div>
}


export default News110624;