import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";

const News210823 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            О выдаче жилищных сертификатов лицам из числа детей-сирот
        </h2>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Внесены изменения в Федеральный закон от 21 декабря 1996 г. № 159-ФЗ 
                «О дополнительных гарантиях по социальной поддержке детей-сирот и детей, оставшихся без попечения родителей» в части
                расширения форм обеспечения жилыми помещениями лиц, достигших 23-летнего возраста,
                относившихся к категории детей-сирот и детей, оставшихся без попечения родителей, лиц из их числа и
                своевременно не обеспеченных жилыми помещениями по договору найма специализированного жилого помещения.</p>
            <p>Указанные лица могут воспользоваться право на однократное предоставление за счет средств регионального
                бюджета выплаты на приобретение благоустроенного жилого помещения в собственность или
                для полного погашения предоставленного на приобретение жилого помещения кредита (займа) по договору,
                обязательства заемщика по которому обеспечены ипотекой.</p>
            <p>Предусмотрен ряд обстоятельств, необходимых для предоставления выплаты
                (наличие подтвержденного дохода не ниже минимального размера оплаты труда,
                отсутствие задолженности по налогам и сборам, отсутствие психических расстройств,
                отсутствие судимости за умышленные преступления, отсутствие обстоятельств,
                свидетельствующих о необходимости оказания заявителю содействия в преодолении трудной жизненной ситуации).</p>
            <p>Право на получение выплаты будет подтверждаться именным сертификатом,
                который будет доступен для реализации на всей территории Российской Федерации.</p>
        </div>
        <ToNews/>
    </div>
}


export default News210823;