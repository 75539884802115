import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News180422.jpg"

const News180422 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            В Государственной Думе прошли парламентские слушания по вопросу о деятельности органов опеки и
            попечительства
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>14 апреля 2022 г. в Комитете Государственной Думы по вопросам семьи, женщин и детей состоялись
                парламентские слушания на тему «О совершенствовании законодательного регулирования деятельности органов
                опеки и попечительства. Актуальные проблемы».</p>
            <p>В парламентских слушаниях приняли участие Министр просвещения С.С. Кравцов, заместитель Председателя
                Государственной Думы А.Ю. Кузнецова, председатель Комитета Государственной Думы по вопросам семьи,
                женщин и детей Н.А. Останина, председатель Комитета Государственной Думы по просвещению О.М. Казакова,
                представители МВД России, Следственного Комитета РФ, Генеральной прокуратуры РФ, органов исполнительной
                власти субъектов РФ, осуществляющих полномочия в сфере опеки и попечительства над несовершеннолетними
                гражданами, экспертного сообщества.</p>
            <p><a href="https://edu.gov.ru/press/5010/minprosvescheniya-vpervye-provedet-vserossiyskiy-konkurs-dlya-organov-opeki-i-popechitelstva/">● Новость Минпросвещения России от 18 апреля 2022 г.</a></p>
            <p><a href="https://fcprc.ru/media/media/behavior/Polozhenie-o-Vserossijskom-konkurse-v-sfere-professionalnoj-deyatelnosti-_altKgyU.pdf">● Положение о Всероссийском конкурсе в сфере профессиональной деятельности специалистов
                органов опеки и попечительства в отношении несовершеннолетних граждан на стороне ребенка</a></p>
        </div>
        <ToNews/>
    </div>
}


export default News180422;