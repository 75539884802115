import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/TSUMVDC.jpg"

const TSUMVDC = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Тематическая смена «Юный медиатор» в ВДЦ «Орлёнок»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>С 12 по 17 сентября 2021 года специалисты Федерального государственного бюджетного учреждения «Центр
                защиты прав и интересов детей» при содействии Международного союза детских общественных объединений
                «Союз пионерских организаций – Федерация детских организаций» (СПО–ФДО) в рамках выполнения работ по
                организации проведения общественно-значимых мероприятий в сфере образования, науки и молодежной политики
                в целях организационно-методического сопровождения развития системы профилактики девиантного поведения
                несовершеннолетних на 2021 год провели тематическую смену «Юный медиатор» в лагере «Комсомольский»
                Всероссийского детского центра «Орленок». На протяжении семи дней сто детей и подростков – активисты и
                лидеры детских общественных объединений, занимающиеся общественно полезной деятельностью, – приняли
                участие в занятиях по медиации, на которых познакомились с понятием «конфликта», причинами его
                возникновения, последствиями, стратегиями поведения в конфликтной ситуации, научились корректировать
                негативное эмоциональное состояние и прояснять интересы собеседника.</p>
            <p>В формате групповой дискуссии подростки сами смогли вывести правила и способы «здорового» бесконфликтного
                общения, сформулировать основные принципы медиации и осознать их важность. Формат интерактивных занятий
                позволила им побыть как в роли стороны конфликта, так и в роли медиатора, помогающего сторонам прийти к
                оптимальному взаимовыгодному решению.</p>
            <p>Все участники получили весомые конфликтологические знания и практический опыт, которые смогут
                использовать в переговорах для предотвращения или разрешения спорных ситуаций, а также значки, блокноты,
                ручки с символикой Центра и именные сертификаты, удостоверяющие прохождение программы.</p>
            <p>По окончании смены активные подростки смогут принять участие в работе школьных служб медиации и
                примирения на местах, участвовать в просветительских мероприятиях медиативной направленности,
                способствовать продвижению культуры бесконфликтного общения и гуманистических ценностей в
                образовательных организациях, в семье, в нашем обществе, в целом.</p>
            <p>Тематическая смена была проведена по заказу Департамента государственной политики в сфере защиты прав
                детей Минпросвещения России. Ведущие смены – сотрудники ФГБУ «Центр защиты прав и интересов детей»
                Марина Викторовна Быкова и Александр Валерьевич Зацаринный.</p>
        </div>
        <ToNews/>
    </div>
}


export default TSUMVDC;