import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import {CaretRightOutlined} from "@ant-design/icons"
import icon from "../../assets/dasdas.png"
import {YMaps, Map, Placemark} from '@pbe/react-yandex-maps';
import {ReestrNecomOrgMediacia} from "../../assets/Reestrs/reestr2023mediacia"

const mapState = {center: [55.751574, 37.573856], zoom: 10};

const placeMarks = [
    {
        coordinates: [55.772112, 37.643138],
        description: "Государственное бюджетное учреждение города Москвы Центр поддержки семьи и детства «Красносельский» Центрального административного округа города Москвы - 107078, г. Москва, Скорняжный пер., д. 4",
    },
    {
        coordinates: [55.780972, 37.698636],
        description: "Региональная общественная организация содействия инновациям в педагогике, психологии и медиации «Нравственное поколение» - 105082, Москва, ул. Б. Почтовая, д. 36, стр. 10, оф. 103D",
    },
    {
        coordinates: [55.791992, 37.770825],
        description: "Государственное бюджетное учреждение города Москвы Центр социальной помощи семье и детям «Измайлово» Восточного административного округа города Москвы - 105037, г. Москва, Измайловский проезд, д. 4а",
    },
    {
        coordinates: [55.729711, 37.414715],
        description: "Государственное бюджетное учреждение города Москвы Центр социальной помощи семье и детям «Палитра» Западного административного округа города Москвы - 121351, г. Москва, ул. Кунцевская, д. 17",
    },
    {
        coordinates: [55.861393, 37.605813],
        description: "Государственное бюджетное учреждение города Москвы Городской ресурсный Центр поддержки семьи и детства «Отрадное» ДТСЗН города Москвы - 127273, г. Москва, ул. Декабристов, д. 22 а",
    },
    {
        coordinates: [55.874567, 37.487963],
        description: "Государственное бюджетное учреждение города Москвы Центр поддержки семьи и детства Северного административного округа города Москвы - 125475, Москва,ул. Зеленоградская, д. 35 б",
    },
    {
        coordinates: [55.839304, 37.400755],
        description: "Государственное бюджетное учреждение города Москвы Центр поддержки семьи и детства Северо-Западного административного округа города Москвы - 125476, г. Москва,ул. Василия Петушкова, д. 19, к. 1",
    },
    {
        coordinates: [55.701770, 37.775406],
        description: "Государственное бюджетное учреждение города Москвы «Городской психолого-педагогический центр Департамента образования и науки города Москвы» - 109443, г. Москва,Есенинский б-р, д. 12, к. 2",
    },
    {
        coordinates: [55.680422, 37.717070],
        description: "Государственное бюджетное учреждение города Москвы Центр поддержки семьи и детства «Печатники» Юго-Восточного административного округа города Москвы - 109388, г. Москва, ул. Гурьянова, д. 55",
    },
    {
        coordinates: [55.627087, 37.725721],
        description: "Государственное бюджетное учреждение города Москвы Центр поддержки семьи и детства «Планета семьи» Южного административного округа города Москвы - 115563, г. Москва, Борисовский проезд, д. 15, к. 3",
    },
    {
        coordinates: [55.648214, 37.540856],
        description: "Национальная ассоциация профессиональных медиаторов «Паритет» - 117342, г. Москва, ул. Бутлерова, д. 17б, помещение 1, оф. 120",
    },
    {
        coordinates: [55.648214, 37.540856],
        description: "Национальная Ассоциация профессиональных медиаторов «ПАРИТЕТ» - 117342, г. Москва, ул. Бутлерова, д. 17Б, эт/пом/оф 1/I/120",
    },
    {
        coordinates: [55.541504, 37.575450],
        description: "Государственное бюджетное учреждение города Москвы Центр социальной помощи семье и детям «Гелиос» - 117623, г. Москва, ул. Старонародная, д. 2"
    },
    {
        coordinates: [55.503043, 37.594593],
        description: "Государственное бюджетное учреждение города Москвы Центр социального обслуживания «Щербинский» Троицкого и Новомосковского административных округов города Москвы - 117148, г. Москва, ул. Маршала Савицкого, д. 26, корп. 2"
    },
    {
        coordinates: [55.988434, 37.150035],
        description: "Государственное бюджетное учреждение города Москвы Центр поддержки семьи и детства «Зеленоград» Зеленоградского административного округа - 124617, г. Москва, Зеленоград, к. 1426"
    },
    {
        coordinates: [54.618551, 39.733994],
        description: "Автономная некоммерческая организация альтернативного разрешения споров «Рязанский центр медиации и права» - 390005 г. Рязань, ул. 1-я Железнодорожная, д. 60-А, пом. H1"
    },
    {
        coordinates: [52.119862, 83.307810],
        description: "КГБУСО «Комплексный центр социального обслуживания населения Усть-Калманского района» - 658150, Алтайский край, Усть-Калманский район, с. Усть-Калманка, ул. Ленина, 37"
    },
    {
        coordinates: [52.976813, 84.672917],
        description: "КГБУСО «Комплексный центр социального обслуживания населения Троицкого района» - 659841, Алтайский край, Троицкий район, с. Троицкое, пер. Речной, 2"
    },
    {
        coordinates: [52.994893, 78.649956],
        description: "КГБУСО «Комплексный центр социального обслуживания населения города Славгорода» - 658820, Алтайский край, г. Славгород, ул. Луначарского, 126"
    },
    {
        coordinates: [51.532811, 81.221149],
        description: "КГБУСО «Комплексный центр социального обслуживания населения города Рубцовска» - 658201, Алтайский край, г. Рубцовск, ул. Октябрьская, 131"
    },
    {
        coordinates: [52.495309, 80.208784],
        description: "КГБУСО «Комплексный центр социального обслуживания населения Родинского района» - 659780, Алтайский край, Родинский район, с. Родино, ул. Ленина, 228"
    },
    {
        coordinates: [53.227107, 78.985898],
        description: "КГБУСО «Комплексный центр социального обслуживания населения Немецкого национального района» - 658870 Алтайский край, Немецкий национальный район, с. Гальбштадт, ул. .Школьная 17 А"
    },
    {
        coordinates: [52.704225, 81.623954],
        description: "КГБУСО «Комплексный центр социального обслуживания населения Мамонтовского района» - 658560, Алтайский край, Мамонтовский район, с. Мамонтово, ул. Партизанская, 169"
    },
    {
        coordinates: [50.998835, 81.465563],
        description: "КГБУСО «Комплексный центр социального обслуживания населения Локтевского района» - 658420, Алтайский край, Локтевский район г. Горняк, ул. Ленина, 10а"
    },
    {
        coordinates: [53.757132, 84.926817],
        description: "КГБУСО «Комплексный центр социального обслуживания населения города Заринска» - 659100, Алтайский край, г. Заринск, у. Горького, д. 15"
    },
    {
        coordinates: [52.493499, 82.775945],
        description: "КГБУСО «Комплексный центр социального обслуживания населения города Алейска» - 658130, Алтайский край, г. Алейск, ул. Сердюка, 99"
    },
    {
        coordinates: [52.515815, 85.152869],
        description: "КГБУСО «Краевой социально-реабилитационный центр для несовершеннолетних «Надежда» - 659305, Алтайский край, г. Бийск, пер. Мартьянова д. 53/1"
    },
    {
        coordinates: [53.378150, 83.726910],
        description: "КГБУСО «Краевой социально-реабилитационный центр для несовершеннолетних «Солнышко» - 656010, Алтайский край, г. Барнаул, ул. Смирнова, 79 «Г»"
    },
    {
        coordinates: [53.355250, 83.729111],
        description: "КГБУСО «Краевой кризисный центр для мужчин» - 656052, г. Барнаул, ул. Исакова, 113-е"
    },
    {
        coordinates: [53.337963, 83.801650],
        description: "КГБУСО «Краевой кризисный центр для женщин» - 656056, г. Барнаул, ул. Гоголя, 6"
    },
    {
        coordinates: [52.220925, 82.261623],
        description: "Комплексный центр социального обслуживания населения Шипуновского района - 658390 Алтайский край, Шипуновский район, с. Шипуново, пр-т Комсомольский, д. 46"
    },
    {
        coordinates: [52.285434, 85.417297],
        description: "Комплексный центр социального обслуживания населения Советского района - 659540, Алтайский край, Советский р-н, с. Советское, ул. Советская, д. 74б"
    },
    {
        coordinates: [53.815261, 83.558907],
        description: "Комплексный центр социального обслуживания населения Тальменского района - 658030, Алтайский край, Тальменский р-н, р.п. Тальменка, ул. Алтайская, д. 8б"
    },
    {
        coordinates: [52.994893, 78.649956],
        description: "Комплексный центр социального обслуживания населения города Славгорода - 658820, Алтайский край, г. Славгород, ул. Луначарского, д. 126"
    },
    {
        coordinates: [52.300984, 85.068427],
        description: "Комплексный центр социального обслуживания населения Смоленского района - 659600, Алтайский край, Смоленский р-н, с. Смоленское, ул. Советская, д. 109/1; Факт. адрес: с. Смоленское, ул. Титова, д. 58а"
    },
    {
        coordinates: [51.532811, 81.221149],
        description: "Комплексный центр социального обслуживания населения города Рубцовска - 658201, Алтайский край, г. Рубцовск, ул. Октябрьская, д. 131"
    },
    {
        coordinates: [53.313357, 82.990938],
        description: "Комплексный центр социального обслуживания населения Павловского района - 659000, Алтайский край, Павловский р-н, с. Павловск, ул. Ленина, д. 9"
    },
    {
        coordinates: [53.412123, 83.926677],
        description: "Комплексный центр социального обслуживания населения города Новоалтайска - 658080, Алтайский край, г. Новоалтайск, ул. Строительная, д. 12а"
    },
    {
        coordinates: [51.821300, 79.723397],
        description: "Комплексный центр социального обслуживания населения Михайловского района - 658960, Алтайский край, Михайловский р-н, с. Михайловское, ул. Советская, д. 3"
    },
    {
        coordinates: [53.774696, 81.326163],
        description: "Комплексный центр социального обслуживания населения Каменского района - 658709, Алтайский край, г. Камень-на-Оби, ул. Терешковой, д. 21а"
    },
    {
        coordinates: [52.531146, 85.176225],
        description: "Комплексный центр социального обслуживания населения города Бийска - 659303, Алтайский край, г. Бийск, ул. Вагонная, д. 65"
    },
    {
        coordinates: [53.353912, 83.729075],
        description: "Комплексный центр социального обслуживания населения города Барнаула - 656052, Алтайский край, г. Барнаул, ул. Телефонная, д. 50а"
    },
    {
        coordinates: [52.278514, 104.330445],
        description: "Некоммерческая организация «Иркутский молодёжный фонд правозащитников «ЮВЕНТА» - 664009, г. Иркутск, ул. Красноярская, д. 35, кв. 12"
    },
    {
        coordinates: [52.283192, 104.278055],
        description: "Ассоциация (некоммерческое партнерство) «Байкальская лига медиаторов» - 664025, г. Иркутск, ул. Степана Разина, д. 27, оф. 801/7"
    },
    {
        coordinates: [52.268187, 104.342482],
        description: "Автономная некоммерческая организация дополнительного профессионального образования «Иркутский межрегиональный центр образовательных и медиационных технологий» - 664081, г. Иркутск, ул. Пискунова, д. 160, оф. 528"
    },
    {
        coordinates: [55.348600, 86.152721],
        description: "Государственная организация образования «Кузбасский региональный центр психолого-педагогической, медицинской и социальной помощи «Здоровье и развитие личности» - 650023, Кемеровская область - Кузбасс, г. Кемерово, пр-кт Ленина, д. 126а"
    },
    {
        coordinates: [56.041436, 92.760737],
        description: "Муниципальное молодежное автономное учреждение «Молодежный центр «Свое дело» - 660115, Красноярский край, г. Красноярск, ул. Попова,12"
    },
    {
        coordinates: [55.986259, 92.877563],
        description: "Красноярская региональная общественная организация «Кризисный центр для женщин и их семей, подвергшихся насилию «Верба» - 660016, Красноярский край, г. Красноярск, ул. Анатолия Гладкова, д.25, кв. 33"
    },
    {
        coordinates: [56.016899, 92.837965],
        description: "Межрегиональная общественная организация «Центр развивающих технологий «Сотрудничество» - 660075, Красноярский кр., г. Красноярск, ул. Маерчака, д. 3, офис. 210"
    },
    {
        coordinates: [56.007821, 92.865867],
        description: "КРОО Центр медиации «Территория согласия» - 660049, г. Красноярск,ул. Перенсона, д. 5а, кв. 127"
    },
    {
        coordinates: [54.866373, 83.108483],
        description: "Некоммерческое партнерство «Профессиональная лига медиаторов» - 630128, Новосибирская обл, г. Новосибирск, ул. Полевая, д. 11, кв. 95"
    }, {
        coordinates: [54.866373, 83.108483],
        description: "Автономная некоммерческая организация образования и права «Новосибирский центр медиации» - 630128, Новосибирская обл, г. Новосибирск, ул. Полевая, д. 11, кв. 95"
    },
    {
        coordinates: [55.066777, 82.908922],
        description: "Союз «Сибирский центр медиации» - 630049, Новосибирская обл, г. Новосибирск, Красный пр-кт, д. 171/4, оф. 54"
    },
    {
        coordinates: [54.989688, 73.358223],
        description: "ФГБПОУ ВО «Омский государственный педагогической университет» - 644099, г. Омск, Набережная Тухаческого, д. 14"
    },
    {
        coordinates: [54.974194, 73.382801],
        description: "Омская местная детская общественная организация по развитию творческой, социально-активной личности «ОАЗИС» - 644010, Омская обл., г. Омск, просп. Карла Маркса, д. 29А, кв. 87"
    },
    {
        coordinates: [54.970194, 73.408519],
        description: "Омское региональное отделение Общероссийского общественного движения «Молодежная Ассамблея народов России «МЫ-РОССИЯНЕ» - 644021, Омская обл., г. Омск, ул. 7-я Линия, д. 161; 644099 г. Омск, ул. Спартаковская д. 3, офис 12"
    },
    {
        coordinates: [51.833529, 107.599154],
        description: "Муниципальное бюджетное учреждение дополнительного образования «Центр диагностики и консультирования» г. Улан-Удэ - 670034, г. Улан-Удэ, ул. Хахалова, 8"
    },
    {
        coordinates: [51.722239, 94.444656],
        description: "Частное учреждение дополнительного образования «Центр дополнительного образования «Логос» - 667000, г. Кызыл, ул. Комсомольская 13"
    },
    {
        coordinates: [51.954785, 85.922572],
        description: "Региональный общественный благотворительный фонд защиты семьи, материнства, отцовства и детства «МАМА» Республики Алтай - 649002, Республика Алтай г. Горно-Алтайск, пр-т Коммунистический, 89."
    }, {
        coordinates: [56.498038, 84.947837],
        description: "Благотворительный Фонд социальной поддержки и защиты прав детей, находящихся в трудной жизненной ситуации, охраны окружающей среды и защиты животных «Меркурия» - 634009, Томская обл., г. Томск, пр. Ленина, д. 126, кв.19"
    }, {
        coordinates: [57.078751, 86.256449],
        description: "Автономная некоммерческая организация помощи семьям и детям, попавшим в трудную жизненную ситуацию «Социальный навигатор» - 636930, Томская обл., Первомайский р-н, с. Первомайское, ул. Колхозная, д. 5"
    }, {
        coordinates: [56.436537, 85.036232],
        description: "Автономная некоммерческая организация Ресурсный центр «Согласие» - 634507, Томская обл., Зональная Станция, ул. Изумрудная, д. 2"
    },
    {
        coordinates: [50.262657, 127.522349],
        description: "Автономная некоммерческая организация дополнительного профессионального образования образовательный центр «ЛИДЕР» - 675000, Амурская обл. город Благовещенск, ул Б. Хмельницкого 20, офис 8"
    },
    {
        coordinates: [48.795054, 132.917909],
        description: "Областное государственное бюджетное учреждение дополнительного образования «Центр МОСТ» - 679000, ЕАО, г. Биробиджан, ул. Пионерская, д. 35"
    },
    {
        coordinates: [48.782969, 132.905665],
        description: "Областное государственное бюджетное учреждение социального обслуживания «Социально-реабилитационный центр для несовершеннолетних» - 679000, ЕАО, г. Биробиджан, ул. Тихонькая, д. 5"
    },
    {
        coordinates: [52.026427, 113.517084],
        description: "Государственное учреждение «Забайкальский краевой центр психолого-педагогической, медицинской и социальной помощи «Семья»» - 672010, г. Чита, ул. Ленина, д. 27а"
    },
    {
        coordinates: [56.897773, 118.264896],
        description: "Государственное учреждение социального обслуживания «Черновский комплексный центр социального обслуживания населения «Берегиня»» Забайкальского края - 673030, г. Чита-30, КСК, ул. Труда, д. 7а"
    },
    {
        coordinates: [52.074046, 113.377630],
        description: "Государственное учреждение «Центр психолого-педагогической помощи населению «Доверие»» Забайкальского края - 672042, г. Чита, ул. Текстильщиков, д. 1а"
    },
    {
        coordinates: [53.186323, 158.387807],
        description: "Краевое государственное автономное учреждение социальной зашиты «Комплексный центр социального обслуживания населения Елизовского района» - 684000, Камчатский край, г. Елизово, ул. Беринга, д. 6"
    },
    {
        coordinates: [59.086302, 159.945935],
        description: "Краевое государственное автономное учреждение социальной защиты «Паланский комплексный центр социального обслуживания населения» - 688000, Камчатский край, пгт Палана, ул. Обухова, д. 2Б, кв.2"
    },
    {
        coordinates: [53.033688, 158.642856],
        description: "Автономное учреждение социальной защиты «Камчатский центр социальной помощи семье и детям» - 683003, Камчатский край, г. Петропавловск-Камчатский, ул. Ключевская, д. 28"
    },
    {
        coordinates: [54.698874, 158.628942],
        description: "Краевое государственное автономное учреждение социальной защиты «Камчатский социально-реабилитационный центр для несовершеннолетних» - 684300, Камчатский край, Мильковский район, с. Мильково, ул. Советская, д. 17А"
    },
    {
        coordinates: [53.020635, 158.648758],
        description: "Камчатское региональное отделение Общероссийской общественной организации «Ассоциация юристов России» - 683000, Камчатский край г. Петропавловск-Камчатский, ул. Советская, д. 40, оф. 1"
    },
    {
        coordinates: [55.648214, 37.540856],
        description: "Национальная Ассоциация профессиональных медиаторов «ПАРИТЕТ» - 117342, г. Москва, ул. Бутлерова, д. 17Б, эт/пом/оф 1/I/120"
    },
    {
        coordinates: [43.119629, 131.884344],
        description: "Ассоциация медиаторов и посредников АТР - 690091, г. Владивосток, ул. Мордовцева, д. 3, офис 1202"
    },
    {
        coordinates: [62.038604, 129.724875],
        description: "Автономная некоммерческая организация внесудебного урегулирования споров и конфликтных ситуаций «Якутский республиканский центр медиации» - 677005, Республика Саха (Якутия), г. Якутск, ул. Курашова, д. 44А, оф. 20"
    },
    {
        coordinates: [46.968828, 142.710112],
        description: "Автономная некоммерческая организация дополнительного профессионального образования «Центр профессионального урегулирования споров и конфликтов — «Медиаторы Сахалина» - 693005, Сахалинская обл., г. Южно-Сахалинск, ул. Достоевского, д. 7"
    },
    {
        coordinates: [64.530993, 40.527018],
        description: "Ассоциация «Архангельская региональная ассоциация медиаторов» - 163000, Архангельская обл., г. Архангельск, наб. Северной Двины, д. 55, блок А, офис 712"
    },
    {
        coordinates: [64.545815, 40.532525],
        description: "Государственное бюджетное учреждение Архангельской области для детей, нуждающихся в психолого-педагогической и медико-социальной помощи, «Центр психолого-медико-социального сопровождения «Надежда» - 163069, Архангельская область, г. Архангельск, ул. Попова, д. 43"
    },
    {
        coordinates: [64.553622, 40.557022],
        description: "Ассоциация медиаторов Архангельской области - 163051, г. Архангельск, ул. Тимме, д. 26"
    },
    {
        coordinates: [59.118345, 37.995125],
        description: "Благотвориительный фонд «Дорога к дому» - 162600, Вологодская обл.,г. Череповец, ул. Юбилейная, д. 26"
    },
    {
        coordinates: [54.728794, 20.483556],
        description: "Калининградская региональная общественная организация «Молодёжных и студенческих инициатив» - 236022, г Калининград, ул. К. Маркса, д. 10, пом. 22"
    },
    {
        coordinates: [54.659235, 19.906739],
        description: "Калининградская региональная молодежная общественная организация «Сатори» - 238520, Калининградская обл, г. Балтийск, ул. Ушакова, д. 26, кв. 27"
    },
    {
        coordinates: [54.699144, 20.511808],
        description: "Калининградская региональная детско-молодежная общественная организация «Юная лидерская армия» - 236039, г. Калининград, ул. Багратиона, д. 39, оф 10"
    },
    {
        coordinates: [68.963580, 33.087314],
        description: "Частное учреждение социального обслуживания «Социальный центр – SOS Мурманск» - 183025 г. Мурманск,ул. Полярные Зори, д.31, корп.1"
    },
    {
        coordinates: [58.504850, 31.207186],
        description: "Автономная некоммерческая организация «Центр экспертно-правовой помощи и разрешения конфликтов» - 173526, Новгородская обл., Новгородский р-н, р.п. Панковка, ул. Промышленная, д. 13а"
    },
    {
        coordinates: [57.816239, 28.339673],
        description: "Автономная некоммерческая организация «Центр правового просвещения и медиации» - 180000,г. Псков, ул. Ленина,д. 15, кв. 2"
    },
    {
        coordinates: [61.701442, 30.689963],
        description: "Муниципальное бюджетное образовательное учреждение для детей, нуждающихся в психолого-педагогической и медико-социальной помощи Сортавальского муниципального района Республики Карелия Центр психолого-медико-социального сопровождения - 186790, Республика Карелия,г. Сортавала, ул. Суворова д.2"
    },
    {
        coordinates: [61.680259, 50.815468],
        description: "Муниципальное учреждение дополнительного образования «Центр психолого-педагогической, медицинской и социальной помощи» г. Сыктывкара - 167031, Республика Коми, г. Сыктывкар, ул. Чкалова, д.24/ 167018, Республика Коми, г. Сыктывкар, ул. Космонавтов, д.14 каб.110"
    },
    {
        coordinates: [61.801197, 50.741429],
        description: "Муниципальное учреждение дополнительного образования «Центр психолого-педагогической, медицинской и социальной помощи» г. Сыктывкара - 167031, Республика Коми, г. Сыктывкар, ул. Чкалова, д.24/ 167018, Республика Коми, г. Сыктывкар, ул. Космонавтов, д.14 каб.110"
    },
    {
        coordinates: [61.672315, 50.829293],
        description: "Государственное образовательное учреждения дополнительного профессионального образования «Коми республиканский институт развития образования» - 167982, Республика Коми г. Сыктывкар, ул. Орджоникидзе, д. 23"
    },
    {
        coordinates: [61.654777, 50.827209],
        description: "Государственное учреждение Республики Коми «Республиканский центр психолого-педагогической, медицинской и социальной помощи» - 167000, Республика Коми, г. Сыктывкар, ул. Пушкина, д. 89"
    },
    {
        coordinates: [59.928338, 30.307810],
        description: "НКО ЧУ «Медиация и консультирование в конфликте» - 190000, г. Санкт-Петербург, Казанская ул., д. 45, кв. 57"
    },
    {
        coordinates: [59.861932, 30.316578],
        description: "Служба медиации СПБ ГБУ «ГЦСП «КОНТАКТ» - 196070, г. Санкт-Петербург,ул. Фрунзе, д. 4"
    },
    {
        coordinates: [59.930935, 30.359481],
        description: "Ассоциация «Лига медиаторов» - 191040, г. Санкт-Петербург, Невский пр., д. 81А, оф. 22Н"
    },
    {
        coordinates: [46.330996, 48.008718],
        description: "ГАУ АО «Многопрофильный социальной центр «Содействие» - 414024, Астрахань, ул. Богдана Хмельницкого, д. 2, корп. 3"
    },
    {
        coordinates: [46.099542, 47.730321],
        description: "Автономная некоммерческая организация «Центр психологической помощи и медиации, судебно-психологических экспертиз «Доверие» - 416370, Астраханская обл., Икрянинский р-н, с. Икряное, ул. М. Горького, д. 9"
    },
    {
        coordinates: [48.757216, 44.454946],
        description: "Региональный фонд «Здоровое поколение» - 400048, Волгоградская обл., г. Волгоград, Шоссе Авиаторов, д. 12Д, пом. 2"
    },
    {
        coordinates: [48.793879, 44.577099],
        description: "Фонд социальной помощи «Планета Детства» - 400065,Волгоградская область,г. Волгоград,ул. Луговского, д.11, кв. 56"
    },
    {
        coordinates: [44.718039, 37.774436],
        description: "Новороссийский институт (филиал) АНО ВО «Московский гуманитарно-экономический институт» - 353900, Южный федеральный округ, Краснодарский край, г. Новороссийск, ул. Коммунистическая/ Советов, 36/37."
    },
    {
        coordinates: [45.063432, 38.995714],
        description: "НАНЧОУ ВО «Академия маркетинга и социально-информационных технологий – ИМСИТ» - 350010, Краснодарский край, г. Краснодар, Зиповская ул., д. 5"
    },
    {
        coordinates: [45.059536, 38.962692],
        description: "Международная Ассоциация Медиаторов и Адвокатов «МедиаТ» - 350051, Краснодарский кр., г. Краснодар, ул. Монтажников, д. 1/4, этаж 8, пом.11, 15/2"
    }, {
        coordinates: [45.026164, 38.968585],
        description: "Ассоциация профессиональных медиаторов «Единство» - 350000, Краснодарский кр., г. Краснодар, ул. Рашпилевская, д. 55, пом. 11"
    },
    {
        coordinates: [45.026164, 38.968585],
        description: "Автономная некоммерческая организация дополнительного профессионального образования «Центр образования и урегулирования споров (медиации)» - 350000, Краснодарский кр., г. Краснодар, ул. Рашпилевская, д. 55, пом. 11"
    },
    {
        coordinates: [43.632006, 39.704161],
        description: "Ассоциация «Центр переговоров, медиации и примирения» - 354008, Краснодарский край,г. Сочи, ул. Анапская, д. 3, корп. 12, кв. 22"
    },
    {
        coordinates: [44.616353, 40.114116],
        description: "Центр развития примирительных процедур (медиационный центр) при Адвокатской палате Республики Адыгея - 385006, Республика Адыгея,г. Майкоп,ул. Хакурате/Гагарина,д. 199"
    },
    {
        coordinates: [46.308521, 44.272678],
        description: "БУ ДО РК «Центр психолого-педагогической, медицинской и социальной помощи» - 358000, Республика Калмыкия, г. Элиста, улица Губаревича, д.7"
    },
    {
        coordinates: [45.206138, 34.094371],
        description: "Автономная некоммерческая организация Центр социальных программ и профилактики асоциальных явлений среди несовершеннолетних и молодежи «КОНТАКТЫ» - 297511, Республика Крым, Симферопольский р-н, с. Новоандреевка, ул. Киевская, д..16А"
    },
    {
        coordinates: [44.952581, 34.101198],
        description: "Автономная некоммерческая организация «Дом молодежи» - 295000, Республика Крым, г. Симферополь, пер. Совнаркомовский, д. 3"
    },
    {
        coordinates: [44.924553, 34.086017],
        description: "Ассоциация «Первый Крымский центр переговоров и разрешения конфликтов (медиации)» - 295013, Республика Крым,г. Симферополь,ул. Д.И. Ульянова, д. 3/2, кв. 30"
    },
    {
        coordinates: [47.136452, 39.745484],
        description: "Муниципальное бюджетное учреждение дополнительного образования «Дом детского творчества» - 346880, Ростовская обл., г. Батайск, ул. Ленина, д.3"
    },
    {
        coordinates: [47.780896, 39.866307],
        description: "Муниципальное бюджетное учреждение «Центр психолого-педагогической, медицинской и социальной помощи «Успех» города Новошахтинска - 346905, Ростовская обл., г. Новошахтинск ул. 4-я Пятилетка, 16"
    },
    {
        coordinates: [44.595895, 33.514257],
        description: "Автономная некоммерческая организация «Севастопольский центр медиации и переговоров» - 299003, г. Севастополь, ул. Льва Толстого, д. 8а, кв. 12"
    },
    {
        coordinates: [50.594082, 36.577126],
        description: "Центр медиации и правовых технологий юридического института ФГАОУ ВО НИУ «БелГУ» - 308015, Белгородская область, г. Белгород, ул. Победы, д. 85, корп. 14, каб. 51"
    },
    {
        coordinates: [53.303662, 34.285514],
        description: "Брянская региональная общественная организация оказания помощи семье и профилактики социально значимых заболеваний «БЛАГО» - 241012, Брянская обл., г. Брянск, ул. Орловская, д. 30, кв. 91"
    },
    {
        coordinates: [53.242694, 34.359598],
        description: "Автономная некоммерческая организация «Специальный центр развития детей «Гармония» - 241050, Брянская обл., г. Брянск, ул. Октябрьская, д. 39, пом. 2"
    },
    {
        coordinates: [53.315331, 34.285083],
        description: "Автономная некоммерческая организация «Центр психологии и развития человека «Сфера» - 241013, г. Брянск, ул. Молодой Гвардии, д. 41, кв. 3"
    },
    {
        coordinates: [56.165638, 40.474728],
        description: "МБУДО г. Владимира «Детский оздоровительно-образовательный (социально-педагогический центр)» -  600031, г.Владимир, ул.Юбилейная, д.44"
    },
    {
        coordinates: [56.129027, 40.400059],
        description: "Некоммерческое партнерство «Владимирская региональная ассоциация медиаторов» - 600000,Владимирская область, г. Владимир, ул. Девическая, д. 9, кв. 212"
    },
    {
        coordinates: [51.656495, 39.206117],
        description: "АНО «Центр практической медиации» - 394018, г. Воронеж, Университетская пл, д. 1"
    },
    {
        coordinates: [51.656495, 39.206117],
        description: "Центр правовых инноваций и примирительных процедур Воронежского государственного университета - 394018, г. Воронеж, Университетская пл., д. 1"
    },
    {
        coordinates: [51.366027, 42.081023],
        description: "Государственное бюджетное профессиональное учреждение Воронежской области «Борисоглебский техникум промышленных и информационных технологий» - 397160, Воронежская обл.,г. Борисоглебск,ул. Третьяковская, д. 14"
    },
    {
        coordinates: [57.011793, 40.972340],
        description: "Автономная некоммерческая организация оказания услуг гражданам «Ивановский региональный центр медиации и переговоров» - 153002, Ивановская область,г. Иваново, пр-т Ленина, д.92"
    },
    {
        coordinates: [55.122606, 36.595227],
        description: "Автономная некоммерческая организация по альтернативному уреryлированию споров и конфликгных ситуаций «Калужский областной центр медиации» - 249034, Калужская обл., г. Обнинск, ул. Белкинская, д. 21, пом. 80"
    },
    {
        coordinates: [51.728431, 36.188128],
        description: "Областное казенное учреждение социального обслуживания населения «Курский социальный профессионально-реабилитационный центр» - 305000, Курская область, г. Курск, ул. Дзержинского, д. 17"
    },
    {
        coordinates: [51.886947, 37.263232],
        description: "Областное казенное учреждение социального обслуживания «Черемисиновский социально-реабилитационный центр для несовершеннолетних» - 306440, Курская область, Черемисиновский район, п. Черемисиново, ул. Комсомольская, д. 9"
    },
    {
        coordinates: [51.901798, 36.805190],
        description: "Областное казенное учреждение социального обслуживания «Охочевский социальный приют для детей и подростков» - 306504, Курская область, Щигровский район, п. Золотая роща"
    },
    {
        coordinates: [52.314936, 36.304065],
        description: "Областное казенное учреждение социального обслуживания населения «Курский областной социальный приют для детей и подростков» - 306000, Курская область, Поныровский район, п. Поныри, ул. Октябрьская, д. 119"
    },
    {
        coordinates: [51.743490, 36.240868],
        description: "Областное казенное учреждение социального обслуживания населения системы социального обеспечения «Курский областной социально-реабилитационный центр для несовершеннолетних» - 305044, Курская область, г. Курск, ул. Краснознаменная, д. 20"
    },
    {
        coordinates: [51.861792, 36.916662],
        description: "Областное казенное учреждение социального обслуживания населения «Щигровский межрайонный центр социальной помощи семье и детям» - 306515, Курская область, Щигровский район, Пригородненский с/с, ул. Комарова, д. 67"
    },
    {
        coordinates: [51.414637, 36.748075],
        description: "Областное казенное учреждение социального обслуживания населения «Солнцевский межрайонный центр социальной помощи семье и детям» - 306120, Курская область, Солнцевский район, п. Солнцево, ул. Ленина, д. 16"
    },
    {
        coordinates: [51.688572, 35.278701],
        description: "Областное казенное учреждение социального обслуживания населения системы социального обеспечения «Льговский межрайонный центр социальной помощи семье и детям» - 307750, Курская область, г. Льгов, ул. Кирова, д. 60"
    },
    {
        coordinates: [52.333893, 35.359280],
        description: "Областное казенное учреждение социального обслуживания населения системы социального обеспечения «Железногорский межрайонный центр социальной помощи семье и детям» - 307170, Курская область, г. Железногорск, ул. Комарова, д. 24"
    },
    {
        coordinates: [51.745844, 36.243069],
        description: "ОКУ «Центр сопровождения замещающих семей и граждан из числа детей-сирот и детей, оставшихся без попечения родителей» - 305044, г. Курск, ул. Союзная, д. 29а"
    },
    {
        coordinates: [52.587876, 39.550037],
        description: "Автономная некоммерческая организация по оказанию услуг в сфере социально-психологической помощи в адаптации граждан и содействию социальной интеракции общественных отношений «СОЦИАЛЬНАЯ НОРМА» - 398046, Липецкая обл., г. Липецк, просп. Победы, д. 106"
    },
    {
        coordinates: [52.149331, 38.280242],
        description: "Автономная некоммерческая организация дополнительного образования «Центр социально-психологической поддержки обучающихся «Утро» - 399540, Липецкая обл., Тербунский р-н, с. Тербуны, ул. Красноармейская, д. 4"
    },
    {
        coordinates: [52.603023, 39.592123],
        description: "Автономная некоммерческая организация по альтернативному урегулированию споров и конфликттных ситуаций «Служба медиации» - 398007, г. Липецк, ул. Первомайская, 57"
    },
    {
        coordinates: [52.596771, 39.565452],
        description: "Липецкая региональная общественная организация по оказанию психологической помощи, социальной поддержки и оздоровлению населения «Коллегия психологов» - 398024, Липецкая обл.,г. Липецк, пр-кт Победы, д.20, пом. 9"
    },
    {
        coordinates: [55.240831, 31.486957],
        description: "Смоленское областное государственное бюджетное учреждение «Демидовский социально-реабилитационный центр для несовершеннолетних «Исток» - 216240, Смоленская область, Демидовский район, дер. Титовщина, ул. Центральная, д. 21"
    },
    {
        coordinates: [52.724197, 41.450360],
        description: "Автономная некоммерческая организация дополнительного профессионального образования «Центр правовых примирительных процедур» - 392000, Тамбовская обл., г. Тамбов, ул. Державинская, д. 16А, пом. 52"
    },
    {
        coordinates: [53.142259, 38.115607],
        description: "Муниципальное казенное учреждение дополнительного образования «Дворец детского (юношеского) творчества» - 301840, Тульская область, г. Ефремов, ул. Свердлова, д. 45"
    },
    {
        coordinates: [54.348333, 38.268824],
        description: "Муниципальное учреждение «Центр обеспечения деятельности системы образования» муниципальное образование Веневский район - 301320, Тульская область, г. Венев, ул. Володарского, д. 19"
    },
    {
        coordinates: [54.210729, 37.625334],
        description: "Муниципальное бюджетное учреждение дополнительного образования «Центр психолого-педагогического и социального сопровождения» - 300002, Тульская область, г. Тула, ул. Галкина, д. 17"
    },
    {
        coordinates: [53.705804, 37.292993],
        description: "Муниципальное бюджетное учреждение дополнительного образования муниципального образования Плавский район «Центр психолого-педагогической, медицинской и социальной помощи «Доверие» - 301470, Тульская область, г. Плавск, ул. Ленина, д. 4"
    },
    {
        coordinates: [53.142259, 38.115607],
        description: "Муниципальное учреждение «Центр обеспечения деятельности системы образования» - 301840, Тульская область, г. Ефремов, ул. Свердлова, д. 45"
    },
    {
        coordinates: [53.997065, 37.505786],
        description: "Муниципальное казенное учреждение «Центр обеспечения деятельности системы образования Щекинского района» - 301243, Тульская область, Щекинский район, г. Щекино, ул. Заводская, д. 34"
    },
    {
        coordinates: [53.776478, 38.132675],
        description: "Муниципальное учреждение дополнительного образования «Центр сопровождения детей» - 301835, Тульская область,г. Богородицк, ул. Пролетарская, д. 59"
    },
    {
        coordinates: [54.006506, 38.280331],
        description: "Муниципальное бюджетное учреждение дополнительного образования «Центр психолого-педагогического и социального сопровождения» - 301664, Тульская область, г. Новомосковск, ул. Первомайская, д. 21"
    },
    {
        coordinates: [55.648214, 37.540856],
        description: "Национальная ассоциация профессиональных медиаторов «Паритет» - 117342, г. Москва, ул. Бутлерова, д. 17б, помещение 1, оф. 120"
    },
    {
        coordinates: [54.247039, 37.610458],
        description: "Ассоциация дополнительного профессионального образования «Научно-исследовательский центр медиации» - 300901, г. Тула,Гарнизонный пр., д. 2г, кв. 88"
    },
    {
        coordinates: [54.193886, 37.584990],
        description: "Ассоциация дополнительного профессионального образования «Научно-исследовательский образовательный центр» - 300041, г. Тула, ул. Дмитрия Ульянова, д. 15"
    },
    {
        coordinates: [54.190667, 37.593336],
        description: "Государственное учреждение Тульской области «Областной центр социальной помощи семье и детям» - 300034, г. Тула, ул. Демонстрации, д. 11"
    },
    {
        coordinates: [58.285046, 37.855958],
        description: "Государственное казенное учреждение социального обслуживания Ярославской области социально-реабилитационный центр «Дом детства» - 152760, Ярославская область, Брейтовский р-н, с. Брейтово, ул. Солнечная, д.18"
    },
    {
        coordinates: [57.358385, 39.140441],
        description: "Государственное казенное учреждение социального обслуживания Ярославской области социально-реабилитационный центр «Искорка» - 152174, Ярославская область, Борисоглебский район, с. Вощажниково, ул. Советская, д. 41"
    },
    {
        coordinates: [57.193947, 39.336687],
        description: "Государственное казенное учреждение социального обслуживания Ярославской области социально-реабилитационный центр «Росинка» - 152120, Ярославская область, Ростовский район, р.п. Ишня, ул. Комсомольская, д.11"
    },
    {
        coordinates: [57.870001, 39.510323],
        description: "Государственное казенное учреждение социального обслуживания Ярославской области Тутаевский социально-реабилитационный центр - 152300, Ярославская область, г. Тутаев, ул. Комсомольская, д. 56"
    },
    {
        coordinates: [56.918291, 38.260506],
        description: "Государственное казенное учреждение социального обслуживания Ярославской области социально-реабилитационный центр «Бригантина» - 152030, Ярославская область, Переславский район, с. Нагорье, ул. Адмирала Спиридова, д. 7"
    },
    {
        coordinates: [57.537956, 38.339117],
        description: "Государственное казенное учреждение социального обслуживания Ярославской области социально-реабилитационный центр «Радуга» - 152610, г. Углич, ул. Победы, д. 14-«А»"
    },
    {
        coordinates: [57.722387, 38.944851],
        description: "Государственное казенное учреждение социального обслуживания Ярославской области Большесельский социально-реабилитационный центр «Колосок» - 152360, Ярославская область, Большесельский р-н, с. Большое Село, ул. 1-ая Строителей, д. 1а"
    },
    {
        coordinates: [57.640527, 39.816837],
        description: "Государственное казенное учреждение социального обслуживания Ярославской области социально-реабилитационный центр «Медвежонок» - 150031, г. Ярославль, ул. Юности, д. 18"
    },
    {
        coordinates: [58.043353, 38.837116],
        description: "Государственное казенное учреждение социального обслуживания Ярославской области Рыбинский социально-реабилитационный центр «Наставник» - 152934, Ярославская область, г. Рыбинск, ул. Пушкина, д. 61"
    },
    {
        coordinates: [57.553335, 39.336373],
        description: "Государственное казенное учреждение социального обслуживания Ярославской области социально-реабилитационный центр «Вертикаль» - 150536, Ярославская область, Ярославский район, с. Ширинье, ул. Молодежная, д. 5"
    },
    {
        coordinates: [43.474164, 43.578739],
        description: "ГБУ «Центр психолого-медико-социального сопровождения» - 360030, КБР, г. Нальчик ул. Тарчокова, 26"
    },
    {
        coordinates: [44.212437, 42.062832],
        description: "Республиканское государственное бюджетное учреждение «Центр психолого-педагогической, медицинской и социальной помощи» - 369012, Карачаево-Черкесская Республика, г. Черкесск,ул. Ставропольская, д. 117"
    },
    {
        coordinates: [45.037591, 41.978309],
        description: "Ставропольская краевая молодежная общественная организация «Творческий союз «Звездный ветер» - 355017, Ставропольский кр., г. Ставрополь, ул. Мира, д. 264, корп. А, оф. 32А"
    },
    {
        coordinates: [45.043228, 41.967826],
        description: "Ставропольская краевая общественная детская организация «Союз детей Ставрополья» - 355025, Ставропольский кр., г. Ставрополь, пл.Ленина, д. 1, Дом Правительства, каб. 449"
    },
    {
        coordinates: [45.037591, 41.978309],
        description: "Автономная некоммерческая организация ресурсно-тренинговый центр НКО «Кампус» - 355002, Ставропольский кр., г. Ставрополь, ул. Мира, д. 264 А, оф. 32"
    },
    {
        coordinates: [43.958829, 43.018495],
        description: "Предгорненское районное местное отделение Общероссийского общественного благотворительного фонда «Российский благотворительный фонд «Нет алкоголизму и наркомании»» - 357380, Ставропольский край, Предгорный р-н, с. Юца,ул. Школьная, д. 9"
    },
    {
        coordinates: [45.037591, 41.978309],
        description: "Ставропольская краевая молодежная общественная организация «Творческий союз «Звездный ветер» - 355017, Ставропольский край, г. Ставрополь, ул. Мира,д. 264а, оф. 32а"
    },
    {
        coordinates: [58.606375, 49.679144],
        description: "Автономная некоммерческая организация «Кировский региональный центр медиации и права» - 610020, Кировская область, г. Киров, ул. Пятницкая, д. 12 корп. 1"
    },
    {
        coordinates: [58.580586, 49.649598],
        description: "Автономная некоммерческая организация «Центр методической и психологической поддержки и наставничества «Территория понимания и саморазвития» - 610001, Кировская область,г. Киров, ул. Горького, д. 63 корп. 3, кв. 53"
    },
    {
        coordinates: [56.338115, 43.872649],
        description: "Нижегородская общественная организация «Семейный центр «ЛАДА» - 603157, Нижний Новгород, Березовская, 120-52"
    },
    {
        coordinates: [51.768595, 55.106684],
        description: "АНО ДПО «ИКТ «Статус» - 460000, г. Оренбург, ул. Володарского, 39, офис 603"
    },
    {
        coordinates: [59.405357, 56.801895],
        description: "Некоммерческая организация «Верхнекамская Ассоциация медиаторов» - 618400, Пермский край, г. Березники, ул. К. Маркса, д. 53"
    },
    {
        coordinates: [58.013125, 56.210094],
        description: "Автономная некоммерческая организация «Медиация. Переговоры. Право» - 614068, Пермский край, г. Пермь, ул. Окулова, д. 75, корпус 2, офис 311"
    },
    {
        coordinates: [58.007174, 56.220002],
        description: "Пермская краевая общественная организация «Ассоциация медиаторов Пермского края» - 614066, г. Пермь, ул. Борчанинова, д. 1-75"
    },
    {
        coordinates: [58.003584, 56.211953],
        description: "Автономная некоммерческая организация по развитию медиации и иных примирительных процедур (АНО «Медиация») - 614068, г. Пермь,ул. Екатерининская, д. 165"
    },
    {
        coordinates: [54.752593, 55.995504],
        description: "Центр медиации при автономной некоммерческой организации дополнительного образования «ФБС» - 450059, г Уфа,проспект Октября, д 33, к. 2"
    },
    {
        coordinates: [54.724609, 55.947741],
        description: "Центр примирительных технологий и общественного взаимодействия. Федеральное государственное бюджетное образовательное учреждение высшего образования «Башкирский государственный педагогический университет им. М.Акмуллы» - 450008, Республика Башкортостан, г. Уфа, ул. Октябрьской революции, д. 3-а"
    },
    {
        coordinates: [54.778019, 56.042504],
        description: "Центр медиации принекоммерческом партнерстве «Ассоциация благотворительных организаций Республики Башкортостан» - 450104, г Уфа, ул. Российская, д. 43/13"
    },
    {
        coordinates: [56.636907, 47.875174],
        description: "Региональная молодежная общественная организация поддержки гражданских инициатив Республики Марий Эл «ОПОРА» - Республика Марий Эл, г. Йошкар-Ола, Ленинский пр., д. 56, кв. 65"
    },
    {
        coordinates: [56.628124, 47.905627],
        description: "Государственное бюджетное учреждение Республики Марий Эл «Республиканский центр психолого-педагогической и социальной помощи населению «Доверие» - 424033, Республика Мирий Эл, г. Йошкар-Ола, ул. Эшкинина, д. 2"
    },
    {
        coordinates: [54.184013, 45.180390],
        description: "Ассоциация медиаторов Республики Мордовия - 430005, Республика Мордовия, г. Саранск,ул. Коммунистическая, д. 50"
    },
    {
        coordinates: [55.781666, 49.112002],
        description: "Автономная некоммерческая организация «Поволжская семейная академия «УМАЙ» - 420021, Республика Татарстан, г. Казань, ул. Парижской Коммуны 20/37, оф. 22."
    },
    {
        coordinates: [55.828420, 49.057438],
        description: "МБУ МП г. Казани «Центр психолого-педагогической помощи детям и молодежи «Доверие» - 420095, Республика Татарстан, г. Казань ул. Кулахметова, д. 21"
    },
    {
        coordinates: [55.753656, 49.133570],
        description: "Ассоциация «Некоммерческое партнерство «ВАШ МЕДИАТОР» - 420054, Республика Татарстан, г. Казань, ул. Техническая, д.120 А, оф. 310 А"
    },
    {
        coordinates: [55.825699, 49.087190],
        description: "Некоммерческое партнерство «Лига медиаторов Поволжья» - 420080, Республика Татарстан, г. Казань, просп. Ямашева, д.4, кор. 1Н"
    },
    {
        coordinates: [51.529113, 46.061215],
        description: "Государственное автономное учреждение дополнительного профессионального образования «Саратовский областной институт развития образования» - 410031, г. Саратов,ул. Большая Горная, д.1."
    },
    {
        coordinates: [56.885304, 53.247594],
        description: "«Бюджетное учреждение социального обслуживания Удмуртской Республики «Республиканский центр психолого-педагогической помощи населению «СоДействие»» - 426039, Удмуртская Республика г. Ижевск Воткинское шоссе, д. 110а"
    },
    {
        coordinates: [56.835471, 53.154699],
        description: "Казенное учреждение социального обслуживания Удмуртской Республики «Республиканский социально-реабилитационный центр для несовершеннолетних» - 426006, Удмуртская Республика, г. Ижевск, ул. Телегина, д. 42а"
    },
    {
        coordinates: [56.848030, 53.298699],
        description: "Автономная некоммерческая организация досуга, просвещения, оздоровления и социальной помощи «Детская православная академия» - 426075, Удмуртская Республика, г. Ижевск, ул. Союзная, д. 39, кв. 21"
    },
    {
        coordinates: [56.807235, 53.159181],
        description: "Автономная некоммерческая организация «Центр социальной реабилитации «Судьбы» - 426075, Удмуртская Республика, г. Ижевск, ул. Ивана Сусанина, д. 28"
    },
    {
        coordinates: [56.444419, 52.205521],
        description: "Автономная некоммерческая организация по предоставлению услуг в сфере социального развития личности ребенка «Радужное Детство» - 427790, Удмуртская Республика, г. Можга, заезд Чкалова, д. 1"
    },
    {
        coordinates: [56.503488, 52.440349],
        description: "Автономная некоммерческая организация по предоставлению услуг в сфере социального развития личности ребенка «Добрые дела» - 427780, Удмуртская Республика, Можгинский район, с. Пычас, ул. Кирпичная, д. 2, кв. 1"
    },
    {
        coordinates: [56.881581, 53.185897],
        description: "Региональная общественная организация содействия в решении социальных проблем семьи и человека Удмуртской Республики «Давайте Вместе» - 426054, Удмуртская Республика, г. Ижевск, ул. Школьная, д. 59, кв. 31"
    },
    {
        coordinates: [56.860472, 53.256631],
        description: "Удмуртская региональная общественная организация «Центр социальных и образовательных инициатив» - 426060, Удмуртская Республика, г. Ижевск, ул. Халтурина, д. 113"
    },
    {
        coordinates: [56.860472, 53.256631],
        description: "Автономная некоммерческая организация проведения процедуры медиации «Медиаторы 18» - 426060, Удмуртская Республика, г. Ижевск,ул. Халтурина, д. 113"
    },

    {
        coordinates: [56.051858, 63.644066],
        description: "Автономная некоммерческая организация Агентство консалтинга и медиации «СФЕРА» - 641877, Курганская обл., г. Шадринск, ул. Веселая, д. 7"
    },

    {
        coordinates: [56.803150, 60.579050],
        description: "Некоммерческая организация «Фонд социально-правовой помощи и медиации «Равновесие» - 620146, Свердловская обл., г. Екатеринбург, ул. Волгоградская, д. 41, кв. 199"
    },

    {
        coordinates: [56.793553, 60.621891],
        description: "Фонд социальной направленности медиапрограмм и проектов для детей и молодежи и иных граждан «Возрождение» - 620089, Свердловская обл., г. Екатеринбург, ул. Родонитовая, д.15, кв.306"
    },

    {
        coordinates: [56.808157, 61.322604],
        description: "Муниципальное бюджетное общеобразовательное учреждение городского округа Заречный «Центр психолого-педагогической, медицинской и социальной помощи» - 624250, Свердловская обл.,г. Заречный, ул. Островского, д. 4"
    },

    {
        coordinates: [56.861402, 60.617246],
        description: "Муниципальное бюджетное учреждение Центр социально-психологической помощи детям и молодежи «Форпост» - 620041, Свердловская область, г. Екатеринбург, пер. Трамвайный, д. 5"
    },

    {
        coordinates: [56.846735, 60.624855],
        description: "Свердловская  Региональная общественная организация «Кризисный центр для детей и женщин «Екатерина» - 620075 г. Екатеринбург,ул. Бажова, д. 53, кв. 6"
    },

    {
        coordinates: [56.873037, 60.521800],
        description: "Межрегиональная общественная организация по содействию семьям с детьми в трудной жизненной ситуации «Аистенок» - 620050, г. Екатеринбург, ул. Ватутина, д. 11, кв. 52"
    },

    {
        coordinates: [56.855812, 60.551472],
        description: "Свердловская областная общественная организация «Детский правозащитный фонд «Шанс» - 620034, Свердловская обл.,г. Екатеринбург, ул. Бебеля,д. 71"
    },

    {
        coordinates: [56.820538, 60.541662],
        description: "Автономная некоммерческая организация дополнительного профессионального образования «Уральская академия медиаций и комплексной безопасности» - 620043, Свердловская обл., г. Екатеринбург, ул. Репина, д. 101, кв. 16"
    },

    {
        coordinates: [56.836761, 60.601697],
        description: "Автономная некоммерческая организация по оказанию социальных услуг гражданам «Уральский центр медиации» - 620014, Свердловская обл., г. Екатеринбург,ул. Воеводина, д. 4, кв. 43"
    },
    {
        coordinates: [56.822016, 60.606260],
        description: "Муниципальное бюджетное учреждение Екатеринбургский центр психолого-педагогической поддержки несовершеннолетних «Диалог» - 620142 г. Екатеринбург, ул. 8 Марта, д. 55А"
    },
    {
        coordinates: [56.496622, 60.249799],
        description: "Государственное бюджетное учреждение «Центр психолого-педагогической, медицинской и социальной помощи «Ладо» - 623384, Свердловская обл.,г. Полевской, м-н Черемушки, д. 24"
    },
    {
        coordinates: [57.116463, 65.532226],
        description: "Автономная некоммерческая организация «Западно-Сибирский региональный Центр медиации и права» - 625062, Тюменская область,г. Тюмень, ул. Федюнинского, д. 19, кв. 72."
    },
    {
        coordinates: [61.261403, 73.387157],
        description: "Союз «Сибирский Центр медиации» - 628418, Ханты-Мансийский автономный округ - Югра, г. Сургут, просп. Мира, д. 17, кв. 102"
    },
    {
        coordinates: [61.242908, 73.447039],
        description: "Автономная некоммерческая организация «Центр медиации города Сургута и Сургутского района» - 628405, Ханты-Мансийский автономный округ - Югра, г. Сургут, ул. Геологическая, д. 21, кв. 118"
    },
    {
        coordinates: [55.179097, 61.393274],
        description: "Муниципальное бюджетное учреждение «Центр психолого-педагогической, медицинской и социальной помощи Калининского района г. Челябинска» (Ресурсный центр медиации) - 454084, Челябинская обл., г. Челябинск, ул. Калинина, 17; Фактический адрес Ресурсного центра медиации 454080, Челябинская обл., г. Челябинск, ул. Сони Кривой, 45)"
    },
    {
        coordinates: [55.159393, 61.441028],
        description: "Ассоциация «Лига медиаторов Южного Урала» - 454007, Челябинская обл.,г. Челябинск, пр. Ленина,д. 13а, оф. 6"
    }
];

const ReestrNecomOrg = ({children, header}) => {
    const [newsData, setNews] = useState([])


    const mapState = {center: [58.677300, 87.306715], zoom: 3};

    //const reestrSliced = new Array(ReestrNecomOrgMediacia.filter(el => el.col1 = "Центральный федеральный округ"))
    //const reestrSliced1 = ReestrNecomOrgMediacia.filter(el => el.col1 = " Северо-Западный федеральный округ")
    //const reestrSliced2 = ReestrNecomOrgMediacia.filter(el => el.col1 = "Южный федеральный округ")
    //const reestrSliced3 = ReestrNecomOrgMediacia.filter(el => el.col1 = "Северо-Кавказский федеральный округ")
    //const reestrSliced4 = ReestrNecomOrgMediacia.filter(el => el.col1 = "Приволжский федеральный округ")
    //const reestrSliced5 = ReestrNecomOrgMediacia.filter(el => el.col1 = "Уральский федеральный округ")
    //const reestrSliced6 = ReestrNecomOrgMediacia.filter(el => el.col1 = "Сибирский федеральный округ")
    //const reestrSliced7 = ReestrNecomOrgMediacia.filter(el => el.col1 = "Дальневосточный федеральный округ")
    return <div>
        <div className={s.newHeader}>
            <h2>
                Реестр некоммерческих организаций, применяющих восстановительные, а также медиативные технологии
            </h2>
        </div>

        <div className={s.greyBlock}>
            {ReestrNecomOrgMediacia.map(el => <div>
                    <Region reestrSlice={el}/>

                </div>
            )}
        </div>

        <p></p>
        <div style={{maxWidth: "1200px", margin: "0 auto"}}>
            <YMaps>
                <Map modules={['geoObject.addon.balloon']} state={mapState} width="100%" height="400px">
                    {placeMarks.map((point, index) => (
                        <Placemark
                            key={index}
                            geometry={point.coordinates}
                            properties={{
                                balloonContent: point.description
                            }}
                        />
                    ))}
                </Map>
            </YMaps>
        </div>

        <p></p>
    </div>
}


const Region = ({reestrSlice}) => {
    const [active, setActive] = useState(false)

    return <div style={{paddingTop: "20px"}}>
        <div className={s.item} onClick={() => {
            active ? setActive(false) : setActive(true)
        }}>
            <img src={icon} style={{paddingRight: "4px"}}/><strong>{reestrSlice[0][0].col1} </strong><CaretRightOutlined
            style={{rotate: `${active ? "0deg" : "90deg"}`, transition: "all 1s", marginLeft: "8px", fontSize: "20px"}}/>
        </div>
        {active ? <>{reestrSlice.map(((oblasti, id) => <ShowedLocation oblasti={oblasti} id={id}
                                                                       reestrSlice={reestrSlice}/>))}   </> :
            <div></div>}
    </div>
}

const ShowedLocation = ({oblasti, id, reestrSlice}) => {
    const [activeRegion, setActiveRegion] = useState(false)
    return <div>

        <div onClick={() => {
            activeRegion ? setActiveRegion(false) : setActiveRegion(true)
        }} style={{textAlign: "center"}}>
            <div style={{paddingBottom: "30px"}} className={s.region}><img src={icon} style={{
                paddingRight: "4px",
                height: "26px"
            }}/>{oblasti[0].col2}<CaretRightOutlined
                style={{rotate: `${activeRegion ? "0deg" : "90deg"}`, transition: "all 1s", marginLeft: "8px", fontSize: "20px"}}/></div>
            {activeRegion ? <div style={{paddingBottom: "30px", marginTop: "-15px"}}>
                {oblasti.map(((centers) => <Location col3={centers.col3} col4={centers.col4} col5={centers.col5}
                                                     col6={centers.col6}
                                                     col7={centers.col7}
                                                     col8={centers.col8.split(',').map(line =>
                                                         <span>{line}<br/></span>)}
                                                     col9={centers.col9.split(',').map(line => <a
                                                         href={`mailto:${line}`}>{line}</a>)}/>))
                }
            </div> : <></>
            }

        </div>
    </div>
}


const Location = ({col3, col4, col5, col6, col7, col8, col9}) => {
    const col7Split = col7.split(", ")
    return <div className={s.lockations}>
        <div className={s.lockationsCol}>
            {col3}
        </div>
        <div className={s.lockationsCol}>
            {col4}
        </div>
        <div className={s.lockationsCol}>
            {col5}
        </div>
        <div className={s.lockationsCol}>
            {col6}
        </div>
        <div className={s.lockationsCol} style={{wordBreak: "break-all"}}>
            <a href={col7Split[0]}>{col7Split[0]} </a>
            <div>
                <a href={col7Split[1]}>{col7Split[1]} </a>
            </div>
            <div>
                <a href={col7Split[2]}>{col7Split[2]} </a>
            </div>
        </div>
        <div className={s.lockationsCol}>
            {col8}
        </div>
        <div className={s.lockationsCol} style={{wordBreak: "break-word"}}>
            {col9}
        </div>
    </div>
}
export default ReestrNecomOrg;