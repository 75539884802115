import React from "react";
import s from "./Falldown.module.css"
import "./Falldown.css"
import {DownOutlined} from "@ant-design/icons"
import {useState, useEffect} from "react";
import axios from "axios";
import Falldown from "../common/FalldownList/Falldown";

const Gos = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/aboutus").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <div>
        <div className={s.newHeader}>
            <h2>
                Государственное задание
            </h2>
            <Falldown header={"Государственное задание на 2024"}>
                <li>
                    <a target="_blank" href={`https://fcprc.ru/media/media/behavior/ГЗ-2024.pdf`}>
                        Государственное задание на 27.12.23.pdf
                    </a>
                </li>
            </Falldown>
            <Falldown header={"Государственное задание на 2023"}>
                <li>
                    <a target="_blank" href={`https://fcprc.ru/media/media/behavior/ГЗ-2023.pdf`}>
                        Государственное задание на 18.01.23.pdf
                    </a>
                </li>
            </Falldown>
            <Falldown header={"Государственное задание на 2022"}>
                <li>
                    <a target="_blank" href={`${respData[29]?.file}`}>
                        {respData[29]?.description}
                    </a>
                </li>
                <li>
                    <a target="_blank" href={`${respData[30]?.file}`}>
                        {respData[30]?.description}
                    </a>
                </li>
                <li>
                    <a target="_blank" href={`${respData[31]?.file}`}>
                        {respData[31]?.description}
                    </a>
                </li>
            </Falldown>

            <Falldown header={"Государственное задание на 2021"}>
                <li>
                    <a target="_blank" href={`${respData[32]?.file}`}>
                        {respData[32]?.description}
                    </a>
                </li>
                <li>
                    <a target="_blank" href={`${respData[33]?.file}`}>
                        {respData[33]?.description}
                    </a>
                </li>
            </Falldown>

            <Falldown header={"Государственное задание на 2020"}>
                <li>
                    <a target="_blank" href={`${respData[34]?.file}`}>
                        {respData[34]?.description}
                    </a>
                </li>
                <li>
                    <a target="_blank" href={`${respData[35]?.file}`}>
                        {respData[35]?.description}
                    </a>
                </li>
                <li>
                    <a target="_blank" href={`${respData[36]?.file}`}>
                        {respData[36]?.description}
                    </a>
                </li>
            </Falldown>

            <Falldown header={"Государственное задание на 2019"}>
                <li>
                    <a target="_blank" href={`${respData[37]?.file}`}>
                        {respData[37]?.description}
                    </a>
                </li>
                <li>
                    <a target="_blank" href={`${respData[38]?.file}`}>
                        {respData[38]?.description}
                    </a>
                </li>
                <li>
                    <a target="_blank" href={`${respData[39]?.file}`}>
                        {respData[39]?.description}
                    </a>
                </li>
                <li>
                    <a href={`${respData[40]?.file}`}>
                        {respData[40]?.description}
                    </a>
                </li>
                <li>
                    <a href={`${respData[41]?.file}`}>
                        {respData[41]?.description}
                    </a>
                </li>
                <li>
                    <a href={`${respData[42]?.file}`}>
                        {respData[42]?.description}
                    </a>
                </li>
            </Falldown>

            <Falldown header={"Государственное задание на 2018"}>

                <li>
                    <a href={`${respData[43]?.file}`}>
                        {respData[43]?.description}
                    </a>
                </li>
                <li>
                    <a href={`${respData[44]?.file}`}>
                        {respData[44]?.description}
                    </a>
                </li>
                <li>
                    <a href={`${respData[45]?.file}`}>
                        {respData[45]?.description}
                    </a>
                </li>
                <li>
                    <a href={`${respData[46]?.file}`}>
                        {respData[46]?.description}
                    </a>
                </li>
                <li>
                    <a href={`${respData[47]?.file}`}>
                        {respData[47]?.description}
                    </a>
                </li>
                <li>
                    <a href={`${respData[48]?.file}`}>
                        {respData[48]?.description}
                    </a>
                </li>
                <li>
                    <a href={`${respData[49]?.file}`}>
                        {respData[49]?.description}
                    </a>
                </li>
            </Falldown>

            <Falldown header={"Государственное задание на 2017"}>

                <li>
                    <a href={`${respData[50]?.file}`}>
                        {respData[50]?.description}
                    </a>
                </li>
            </Falldown>

            <Falldown header={"Государственное задание на 2016"}>
                <li>
                    <a href={`${respData[51]?.file}`}>
                        {respData[51]?.description}
                    </a>
                </li>
                <li>
                    <a href={`${respData[52]?.file}`}>
                        {respData[52]?.description}
                    </a>
                </li>
            </Falldown>
        </div>


    </div>


}

export default Gos