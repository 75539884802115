import React from "react";
import FilesValueMe from "../../../assets/AD1/FilesValueMet.jpg"
import s from "./PageFiles.module.css"
import {useState, useEffect} from "react";
import axios from "axios";
import {Hrefs} from "../PageInfo/PageInfoPriceLife"

const hrefsArr = [
    {text: "Нормативно-правовые материалы", to: "/spec-value-of-life/normativno-pravovye-materialy", input: []},
    {
        text: "Информационно-методические материалы для специалистов",
        to: "/spec-value-of-life/metodicheskie-materialy-dlya-spetsialistov"
    },
    {
        text: "Информационные материалы для родителей и детей",
        to: "/spec-value-of-life/informatsionnye-materialy-dlya-roditelej"
    },
    {text: "Видеоматериалы", to: "/spec-value-of-life/video-price"},
    {text: "Полезные ссылки", to: "/spec-value-of-life/poleznye-ssylki"},
]
const FilesValueMet = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <div className={s.filesLeftPage}>
        <div className={s.newHeader}>
            <h2>
                Информационно-методические материалы для специалистов
            </h2>
            <img style={{maxWidth: "100%"}} alt="" src={FilesValueMe}/>
            <div style={{
                fontSize: "22px",
                fontFamily: "Proxima Nova Lt",
                width: "100%",
                textAlign: "left",
                paddingBottom: "10px",
                color: "#3a4663"
            }}>
                В данном разделе размещены информационно-методические материалы для специалистов в сфере сопровождения
                вопросов детской безопасности, структурированные по следующим направлениям:
            </div>
            <div className={s.part}>
                <a href="/spec-value-of-life/metodicheskie-materialy-dlya-spetsialistov-1">Профилактика суицидов среди
                    несовершеннолетних
                </a>
            </div>
            <div className={s.part}>
                <a href="/spec-value-of-life/metodicheskie-materialy-dlya-spetsialistov-2">Профилактика преступных
                    деяний против половой неприкосновенности несовершеннолетних
                </a>
            </div>
            <div className={s.part}>
                <a href="/spec-value-of-life/metodicheskie-materialy-dlya-spetsialistov-3">Превенция интернет-рисков:
                    кибербезопасность
                </a>
            </div>
            <div className={s.part}>
                <a href="/spec-value-of-life/metodicheskie-materialy-dlya-spetsialistov-4">Профилактика рискованного,
                    девиантного поведения в детско-подростковой среде
                </a>
            </div>
            <div className={s.part}>
                <a href="/spec-value-of-life/metodicheskie-materialy-dlya-spetsialistov-5">Формирование культуры
                    здорового и безопасного образа жизни
                </a>
            </div>
            <div className={s.part}>
                <a href="/spec-value-of-life/metodicheskie-materialy-dlya-spetsialistov-6">Психолого-педагогическое
                    сопровождение в системе образования
                </a>
            </div>
            <div className={s.part}>
                <a href="https://fcprc.ru/media/media/behavior/Электронный_каталог_материалов_Психологическая_безопасность_и_благополучие.pdf">Электронный
                    каталог информационно-методических материалов для специалистов и родителей обучающихся по
                    обеспечению детской безопасности
                </a>
            </div>
            <div className={s.part}>
                <a href="https://fcprc.ru/media/media/behavior/Каталог_вебинаров_Психологическая_безопасность_и_благополучие-2019-2023.pdf">Электронный
                    каталог вебинаров для педагогов, психологов и родителей обучающихся «Психологическая безопасность и
                    благополучие в семье, школе, социуме»
                </a>
            </div>
        </div>
        <div>
            <div style={{marginTop: "72px"}}>
                <Hrefs hrefsArr={hrefsArr}/>
            </div>
        </div>
    </div>
}
export default FilesValueMet