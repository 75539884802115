import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import img1 from "../../assets/News/WorkOrganizationVICH.jpeg";
import YouTube from "react-youtube";

const ConsDANesov = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар на тему: «Психология зависимого поведения»
        </h2>
        <div>
            <p>
                26 апреля 2020 года в рамках работы Московского международного салона образования – 2020 состоялся
                вебинар, посвященный проблемам зависимого поведения. Ведущий вебинара, Дубровский Роман Геннадьевич,
                аналитик ФГБУ «Центр защиты прав и интересов детей», психолог, кандидат социологических наук, раскрыл
                особенности формирования фундаментальной особенность поведения каждого человека – аддикции. На вебинаре
                шла речь
                о том, что такое удовольствие, каково его эволюционное значение, и как его правильно использовать.
            </p>
            <p>
                Ссылка на вебинар: <a
                href="https://www.youtube.com/watch?v=eW08mNB4h10">https://www.youtube.com/watch?v=eW08mNB4h10</a>
            </p>
            <p></p>
        </div>
    </div>
}


export default ConsDANesov