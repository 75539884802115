import React from "react";
import ico from "../../../assets/link.ico"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, WhiteButton} from "../PageInfo/PageInfo"
import Falldown, {Falldown2, FileLi, HrefLi} from "../FalldownList/Falldown";
import {hrefsArrFPF} from "./FilesPublicationsForms";
import {FileLeftLineKDN} from "./FilesKDNNews";


const ResponsPerformers = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Ответственные исполнители
                </h2>
                <FileLeftLineKDN
                    text={"Министерство Просвещения Российской Федерации"}
                    refers={"https://edu.gov.ru/"}
                    ico={ico}/>
                <FileLeftLineKDN
                    text={"Министерство науки и высшего образования"}
                    refers={"https://minobrnauki.gov.ru/"}
                    ico={ico}/>
                <FileLeftLineKDN
                    text={"МВД России"}
                    refers={"https://мвд.рф/"}
                    ico={ico}/>
                <FileLeftLineKDN
                    text={"ФСИН России"}
                    refers={"http://fsin.su/"}
                    ico={ico}/>
                <FileLeftLineKDN
                    text={"Минздрав России"}
                    refers={"https://minzdrav.gov.ru/"}
                    ico={ico}/>
                <FileLeftLineKDN
                    text={"Минтруд России"}
                    refers={"https://mintrud.gov.ru/?ysclid=l6yphh0nmr723088388"}
                    ico={ico}/>
                <FileLeftLineKDN
                    text={"Росмолодежь"}
                    refers={"https://fadm.gov.ru/"}
                    ico={ico}/>
                <FileLeftLineKDN
                    text={"Роскомнадзор"}
                    refers={"https://rkn.gov.ru/?ysclid=l6ypkxbh51366226123&utm_source=yandex.ru&utm_medium=organic&utm_campaign=yandex.ru&utm_referrer=yandex.ru"}
                    ico={ico}/>
                <FileLeftLineKDN
                    text={"Фонд поддержки детей, находящихся в трудной жизненной ситуации"}
                    refers={"https://www.fond-detyam.ru/"}
                    ico={ico}/>
            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
                <WhiteButton text="Твое право" to="/tvoyo-pravo"/>
            </div>
        </div>
    </>
}
export default ResponsPerformers