import React from "react";
import ico from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1} from "../PageInfo/PageInfo"
import {hrefsArr} from "./FilesNPD";
const FilesSchoolTV = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })
    }, [])
    return <>
        <h1 style={{fontWeight: "normal", marginTop: "50px", color: "#3a4663"}}>
            Школьное телевидение как форма отражения и трансляции социальных инициатив
        </h1>
        <div className={s.filesLeftPage}>
            <div>
                <FileLeftLine text={"Школьное телевидение гимназии 1409"} refers={"https://www.wifmedia.com/"}
                              ico={ico}/>
                <FileLeftLine text={"Школьное телевидение школы № 92 г. Волгограда"} refers={"http://92tv.ru/"}
                              ico={ico}/>
            </div>
            <div>
                <HrefsBeh1 hrefsArr={hrefsArr}/>
            </div>
        </div>
    </>
}
export default FilesSchoolTV