import React from "react";
import ico from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1} from "../PageInfo/PageInfo"
import {FileLeftLineKDN} from "./FilesKDNNews";

const hrefsArr = [
    {text: "Регламентирующие документы", to: "/regDocks"},
    {text: "Методическое сопровождение", to: "/met"},
    {text: "Часто задаваемые вопросы", to: "/sptfaq"},
]
const RegDocks = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>

        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Регламентирующие документы
                </h2>
                <FileLeftLineKDN text={respData[455]?.description} refers={respData[455]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[456]?.description} refers={respData[456]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[457]?.description} refers={respData[457]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[458]?.description} refers={respData[458]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[459]?.description} refers={respData[459]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[460]?.description} refers={respData[160]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[461]?.description} refers={respData[461]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[462]?.description} refers={respData[462]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[463]?.description} refers={respData[463]?.file} ico={ico}/>
                <FileLeftLineKDN
                    text="Концепция профилактики употребления психоактивных веществ в образовательной среде на период до 2025 года (утв. 15 июня 2021 года статс-секретарем – заместителем Министра просвещения Российской Федерации А. А. Корнеевым)"
                    refers="http://fcprc.ru/media/media/behavior/Kontseptsiya-profilaktiki-PAV_1.pdf "
                    ico={ico}/>
                <FileLeftLineKDN
                    text="План мероприятий по реализации Концепции профилактики употребления ПАВ в образовательной среде на период до 2025 года"
                    refers="http://fcprc.ru/media/media/behavior/Plan-meropriyatij-Kontseptsii-profilaktiki-PAV.pdf "
                    ico={ico}/>
            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArr}/>
            </div>
        </div>
    </>
}
export default RegDocks
