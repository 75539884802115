import React from "react";
import Falldown, {Falldown2, HrefLi, FileLi} from "../common/FalldownList/Falldown";
import img21 from "../../assets/img/1.png"
import s from "./Mediacia.module.css"
import {FileLeftLine} from "../common/FilePage/FilesVeiw"
import icoFile from "../../assets/fileicored.svg"
import logo from "../../assets/Directions/2_11.svg"
import file from "../../assets/Files/PolOConc.pdf"


import {useEffect, useState} from "react";
import axios from "axios";


const Vseros = () => {
    const [respData, setData] = useState()

    useEffect(() => {
        axios.get("https://fcprc.ru/api/vserosconcurs").then(
            res => {
                setData(res.data)
            })

    }, [])
    console.log(respData)

    return <div className={s.newHeader}>
        <h2>
            Люди. События. Факты.Всероссийские конкурсы
        </h2>
        {/*Всероссийский конкурс методических материалов по профилактике употребления наркотических средств, психотропных и иных психоактивных веществ, формированию культуры здорового и безопасного образа жизни среди обучающихся «Здоровье и безопасность: методические ресурсы»*/}

        {(respData ? respData[10] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[10].img} header={respData[10].theme}>
                <div className={s.blockText}>
                    <p>Конкурс направлен на повышение уровня методического обеспечения деятельности
                        образовательных организаций, создание новых и эффективных практик в сфере профилактики
                        употребления наркотических средств, психотропных и иных психоактивных веществ,
                        формирование культуры здорового и безопасного образа жизни среди обучающихся, развитие
                        инновационных подходов к организации воспитательной и профилактической деятельности в
                        образовательной организации, обновление методического инструментария профилактической
                        деятельности, повышение воспитательного потенциала образовательных организаций.</p>
                </div>
                {respData[10].files ? respData[10].files.map(file => <FileLi href={file.file}
                                                                             text={file.name}/>) : <></>}
                {respData[10].refs ? respData[10].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[10].children ? respData[10].children.map(child =>
                    <Falldown2 href={child.ref} header={child.theme}>
                        <div className={s.blockText}>
                            <p>
                                Конкурс впервые проводится в 2023 году среди педагогических работников
                                общеобразовательных и профессиональных образовательных организаций.</p>
                        </div>
                        {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                        {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}

                        <p></p>
                    </Falldown2>) : <></>}
            </Falldown> : <div></div>}


        {/*Всероссийский конкурс в сфере профессиональной деятельности специалистов органов опеки и попечительства в*/}
        {/*отношении несовершеннолетних граждан «На стороне ребенка»*/}
        {(respData ? respData[7] : false) ?
            <Falldown style={{margin: "0 auto",}} url={logo}
                      header={"Всероссийский конкурс региональных моделей системы профилактики безнадзорности и правонарушений несовершеннолетних"}>
                <div className={s.blockText}>
                    Конкурс проводится в соответствии с пунктом 30 плана мероприятий
                    на 2021 – 2025 годы по реализации Концепции развития системы профилактики
                    безнадзорности и правонарушений несовершеннолетних на период до 2025 года,
                    утвержденного распоряжением Правительства Российской Федерации
                    от 22 марта 2017 г. № 520-р (в редакции распоряжения Правительства Российской
                    Федерации от 18 марта 2021 г. № 656-р).
                    Конкурс направлен на выявление лучших региональных практик решения
                    задач профилактики безнадзорности и правонарушений несовершеннолетних,
                    их тиражирование в субъектах Российской Федерации.
                </div>

                <Falldown2 href={logo} header={"2024"}>
                    <p></p>

                    <FileLeftLine refers="https://fcprc.ru/media/media/behavior/MShEP_Prilozhieniie_viersiia_3.pdf"
                                  text={"Отчет о реализации Всероссийского конкурса региональных моделей системы профилактики безнадзорности и правонарушений несовершеннолетних"} styles={{
                        width: "90%",
                        borderRadius: "20px",
                        padding: "7px"
                    }}/>
                    <FileLeftLine refers="https://fcprc.ru/media/media/behavior/АБ-44_07вн_от_03.04.2024.pdf"
                                  text={"Положение о конкурсе"} styles={{
                        width: "90%",
                        borderRadius: "20px",
                        padding: "7px"
                    }}/>
                    <FileLeftLine refers="https://fcprc.ru/media/media/behavior/О_внесении_изменений_в_Положение_о_Всероссийском_конкурсе_региональных.pdf"
                                  text={"Подписанное изменение в положение о конкурсе"} styles={{
                        width: "90%",
                        marginTop: "10px",
                        borderRadius: "20px",
                        padding: "7px"
                    }}/>
                    <p></p>
                </Falldown2>
                <Falldown2 href={logo} header={"2023"}>
                    <p></p>

                    <FileLeftLine refers={file} text={"Положение о конкурсе"} styles={{
                        width: "90%",
                        borderRadius: "20px",
                        padding: "7px"
                    }}/>
                    <p></p>
                    <FileLeftLine refers="https://fcprc.ru/media/media/behavior/Экспертный_совет.pdf"
                                  text={"Экспертный совет"} styles={{
                        width: "90%",
                        borderRadius: "20px",
                        padding: "7px"
                    }}/>
                    <p></p>
                    <FileLeftLine refers="/media/media/behavior/Отчет_реализация_конкурса_Модель_профилактики_2023.doc"
                                  text={"Отчет о реализации конкурса"} styles={{
                        width: "90%",
                        borderRadius: "20px",
                        padding: "7px"
                    }}/>
                    <p></p>
                    <FileLeftLine refers="/media/media/behavior/Конкурс_таблица_участники_итог_20.11.2023.docx"
                                  text={"Конкурсная таблица участников"} styles={{
                        width: "90%",
                        borderRadius: "20px",
                        padding: "7px"
                    }}/>
                    <HrefLi href={"https://модельпрофилактики.рф/"} text={"Официальный сайт Конкурса"}/>
                </Falldown2>
            </Falldown> : <div></div>}

        {(respData ? respData[7] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[7].img} header={respData[7].theme}>
                {respData[7].files ? respData[7].files.map(file => <FileLeftLine style={{margin: "0 auto"}}
                                                                                 refers={file.file}
                                                                                 text={file.name}/>) : <></>}
                {respData[7].refs ? respData[7].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[7].children ? respData[7].children.map(child =>
                    <Falldown2 href={child.ref} header={child.theme}>
                        <p></p>
                        <div className={s.blockText}>
                            Конкурс проводится начиная с 2022 г. один раз в два года в целях выявления органов опеки и
                            попечительства, а также специалистов органов опеки и попечительства, добившихся позитивных
                            результатов в своей профессиональной деятельности.
                        </div>
                        {child.refs ? child.refs.map(ref => <FileLeftLine refers={ref.file} text={ref.name} styles={{
                            width: "90%",
                            borderRadius: "20px",
                            padding: "7px"
                        }}/>) : <></>}
                        <p>
                            {child.files ? child.files.map(file => <FileLeftLine refers={file.file} text={file.name}
                                                                                 ico={icoFile} styles={{
                                width: "560px",
                                borderRadius: "20px",
                                padding: "7px"
                            }}/>) : <></>}
                        </p>
                    </Falldown2>) : <></>}
            </Falldown> : <div></div>}

        {/*Всероссийский конкурс региональных моделей психологической службы в системе образования*/}

        {(respData ? respData[9] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[9].img} header={respData[9].theme}>
                {respData[9].files ? respData[9].files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                {respData[9].refs ? respData[9].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[9].children ? respData[9].children.map(child =>
                    <Falldown2 href={child.ref} header={child.theme}>
                        <p></p>
                        <div className={s.blockText}>
                            <p>Проводится среди субъектов Российской Федерации с целью формирования единого пространства
                                деятельности психологической службы в системе образования, популяризации положительного
                                опыта и
                                эффективных региональных моделей психологической службы в субъектах Российской
                                Федерации.</p>
                            <p><strong>Внимание! Результаты конкурса будут размещены 24 декабря 2020 года.</strong></p>
                        </div>
                        {child.files ? child.files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                        {child.refs ? child.refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                    </Falldown2>) : <></>}
            </Falldown> : <div></div>}

        {/*Всероссийский конкурс "Школа - Территория здоровья"*/}
        {(respData ? respData[0] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[0].img} header={respData[0].theme}>
                {respData[0].files ? respData[0].files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                {respData[0].refs ? respData[0].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[0].children[1] ?
                    <Falldown2 href={respData[0].children[1].ref} header={respData[0].children[1].theme}>
                        <p></p>
                        <div className={s.blockText}>
                            <p><strong>Цель: </strong>выявление и транслирование лучших практик организации
                                здоровьесберегающего образовательного процесса, использования здоровьесберегающих
                                технологий
                                в отдельных образовательных организациях, привлечение внимания участников
                                образовательного
                                процесса к вопросам сохранения и укрепления здоровья обучающихся с ограниченными
                                возможностями здоровья и с инвалидностью.</p>
                            <p><strong>Номинации</strong></p>
                            <ol>
                                <li>1. Лучшая здоровьесберегающая школа.</li>
                                <li>2. Лучший конспект урока с применением здоровьесберегающих технологий.</li>
                                <li>3. Лучший конспект тематического занятия.</li>
                            </ol>
                            <p>I этап Конкурса - региональный. Срок проведения: до 31 марта 2020 года.</p>
                            <p>II этап Конкурса (федеральный) проводится заочно до 30 апреля 2020 года и состоит из двух
                                частей:</p>
                            <ul>
                                <li>экспертиза конкурсных работ, поступивших по итогам проведения регионального этапа;
                                </li>
                                <li>подведение итогов и определение победителей Конкурса.</li>
                            </ul>
                            <p>К участию во II этапе допускаются победители регионального этапа Конкурса.</p>
                        </div>
                        {respData[0].children[1].files ? respData[0].children[1].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[0].children[1].refs ? respData[0].children[1].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}
                {respData[0].children[0] ?
                    <Falldown2 href={respData[0].children[0].ref} header={respData[0].children[0].theme}>
                        <p></p>
                        <div className={s.blockText}>
                            <p><strong>Задачи конкурса</strong></p>
                            <p>Выявление лучших практик организации здоровьесберегающего образовательного процесса, а
                                также
                                здоровьесберегающих технологий в отдельных организациях, осуществляющих образовательную
                                деятельность по адаптированным основным общеобразовательным программам.
                                Трансляция лучшего опыта обеспечения охраны здоровья и формирования здорового образа
                                жизни
                                обучающихся с ОВЗ на основе современных здоровьесберегающих технологий.
                                Повышение квалификации работников коррекционных школ по программам сохранения и
                                укрепления
                                здоровья обучающихся с ОВЗ посредством использования здоровьесберегающих технологий в
                                учебном процессе.
                                Привлечение внимания участников образовательного процесса к проблематике сохранения и
                                укрепления здоровья обучающихся с ОВЗ.</p>
                            <p><strong>Номинации</strong></p>
                            <ol>
                                <li>1. Лучшая здоровьесберегающая школа.</li>
                                <li>2. Лучший конспект урока с применением здоровьесберегающих технологий.</li>
                                <li>3. Лучший конспект тематического занятия.</li>
                            </ol>
                        </div>
                        {respData[0].children[0].files ? respData[0].children[0].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[0].children[0].refs ? respData[0].children[0].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}
            </Falldown> : <div></div>}

        {/*Всероссийский конкурс "Лучшая инклюзивная школа России"*/}

        {(respData ? respData[1] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[1].img} header={respData[1].theme}>
                {respData[1].files ? respData[1].files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                {respData[1].refs ? respData[1].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[1].children[1] ?
                    <Falldown2 href={respData[1].children[1].ref} header={respData[1].children[1].theme}>
                        <p></p>
                        <div className={s.blockText}>
                            <p>Конкурс проводится с целью повышения активности образовательных организаций в развитии и
                                внедрении инклюзивного образования, а также распространения позитивного педагогического
                                опыта в сфере инклюзивного образования.</p>
                            <p>Конкурс проводится по двум номинациям:</p>
                            <ul>
                                <li>«Лучшая инклюзивная школа»;</li>
                                <li>«Лучший инклюзивный детский сад».</li>
                            </ul>
                            <p>К участию в Конкурсе приглашаются образовательные организации, реализующие наравне с
                                основными образовательными программами адаптированные основные образовательные программы
                                дошкольного, начального, основного и среднего общего образования, использующие в своей
                                практике инклюзивные подходы в обучении и развитии детей с особыми образовательными
                                потребностями.</p>
                        </div>
                        {respData[1].children[1].files ? respData[0].children[1].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[1].children[1].refs ? respData[0].children[1].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}
                {respData[1].children[0] ?
                    <Falldown2 href={respData[1].children[0].ref} header={respData[1].children[0].theme}>
                        <p></p>
                        <div className={s.blockText}>
                            <p><strong>Задачи конкурса</strong></p>
                            <p>Выявление лучших практик организации здоровьесберегающего образовательного процесса, а
                                также
                                здоровьесберегающих технологий в отдельных организациях, осуществляющих образовательную
                                деятельность по адаптированным основным общеобразовательным программам.
                                Трансляция лучшего опыта обеспечения охраны здоровья и формирования здорового образа
                                жизни
                                обучающихся с ОВЗ на основе современных здоровьесберегающих технологий.
                                Повышение квалификации работников коррекционных школ по программам сохранения и
                                укрепления
                                здоровья обучающихся с ОВЗ посредством использования здоровьесберегающих технологий в
                                учебном процессе.
                                Привлечение внимания участников образовательного процесса к проблематике сохранения и
                                укрепления здоровья обучающихся с ОВЗ.</p>
                            <p><strong>Номинации</strong></p>
                            <ol>
                                <li>1. Лучшая здоровьесберегающая школа.</li>
                                <li>2. Лучший конспект урока с применением здоровьесберегающих технологий.</li>
                                <li>3. Лучший конспект тематического занятия.</li>
                            </ol>
                        </div>
                        {respData[1].children[0].files ? respData[1].children[0].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[1].children[0].refs ? respData[1].children[0].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}
            </Falldown> : <div></div>}

        {/*Всероссийский конкурс профессионального мастерства "Педагог-психолог России"*/}

        {(respData ? respData[2] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[2].img} header={respData[2].theme}>
                {respData[2].files ? respData[2].files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                {respData[2].refs ? respData[2].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[2].children[0] ?
                    <Falldown2 href={respData[2].children[0].ref} header={respData[2].children[0].theme}>
                        <p></p>
                        <div className={s.blockText}>
                            <p>Министерство просвещения Российской Федерации объявляет о проведении Всероссийского
                                конкурса
                                профессионального мастерства <strong>«ПЕДАГОГ-ПСИХОЛОГ РОССИИ - 2021»</strong> (далее -
                                Конкурс).</p>
                            <p>Учредитель Конкурса - Министерство просвещения Российской Федерации совместно с
                                Общероссийской общественной организацией «Федерация психологов образования России».</p>
                            <p>Организатор Конкурса - федеральное государственное бюджетное учреждение «Центр защиты
                                прав и
                                интересов детей».</p>
                            <p>Конкурс проводится во исполнение плана мероприятий по реализации Концепции развития
                                психологической службы в системе образования до 2025 года.</p>
                            <p>Цель Конкурса - повышение профессионального уровня и наиболее полной реализации
                                творческого
                                потенциала психологов образования России, повышения престижа психологической службы в
                                системе образования Российской Федерации.</p>
                            <p>Основные задачи Конкурса:</p>
                            <ul>
                                <li>создание условий для самореализации педагогов-психологов, раскрытия их творческого
                                    потенциала;
                                </li>
                                <li>выявление талантливых педагогов-психологов системы образования Российской Федерации,
                                    их
                                    поддержка и поощрение;
                                </li>
                                <li>распространение передового профессионального опыта педагогов-психологов организаций,
                                    осуществляющих образовательную деятельность, центров психолого-педагогической,
                                    медицинской и социальной помощи;
                                </li>
                                <li>тиражирование лучших психолого-педагогических практик и инновационных технологий
                                    оказания психолого-педагогической помощи участникам образовательных отношений.
                                </li>
                            </ul>
                            <p>Конкурс проводится в два этапа: региональный и федеральный.</p>
                            <p>Региональный этап проводится организационными комитетами региональных конкурсов
                                профессионального мастерства «Педагог-психолог - 2021» в очной, заочной или смешанных
                                формах, в том числе с применением дистанционных технологий.</p>
                            <p>Федеральный этап включает два тура: экспертный и суперфинал, который пройдет в г. Москве
                                в
                                срок до 5 октября 2021 года.</p>
                            <p>Конкурс освещается на информационных площадках Минпросвещения России - <a
                                href="http://edu.gov.ru">http://edu.gov.ru</a>,
                                Центра защиты прав и интересов детей - <a href="http://fcprc.ru">http://fcprc.ru</a>,
                                Федерации психологов образования
                                России - <a href="www.rospsy.ru">www.rospsy.ru</a> и странице Конкурса - <a
                                    href="педагогпсихолог.рф">педагогпсихолог.рф</a>.
                            </p>
                            <p><strong>ВНИМАНИЕ!</strong></p>
                            <p>Консультации по организационным вопросам с 9:00 до 18:00 (по московскому времени) -</p>
                            <p>Баторова Елена Будаевна, специалист Центра</p>
                            <p><a href="tel:89148538031">телефон: +7 (914) 853-80-31</a></p>
                            <p><a href="mailto:pedagog-psiholog@fcprc.ru">адрес электронной почты:
                                pedagog-psiholog@fcprc.ru</a></p>
                        </div>
                        {respData[2].children[0].files ? respData[2].children[0].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[2].children[0].refs ? respData[2].children[0].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}
                {respData[2].children[1] ?
                    <Falldown2 href={respData[2].children[1].ref} header={respData[2].children[1].theme}>
                        <p></p>
                        <div className={s.blockText}>
                            <p>Министерство просвещения Российской Федерации объявляет о проведении Всероссийского
                                конкурса
                                профессионального мастерства <strong>«ПЕДАГОГ-ПСИХОЛОГ РОССИИ - 2020»</strong> (далее -
                                Конкурс).</p>
                            <p>Учредитель Конкурса - Министерство просвещения Российской Федерации совместно с
                                Общероссийской общественной организацией «Федерация психологов образования России».</p>
                            <p>Организатор Конкурса - федеральное государственное бюджетное учреждение «Центр защиты
                                прав и
                                интересов детей».</p>
                            <p>Конкурс проводится во исполнение плана мероприятий по реализации Концепции развития
                                психологической службы в системе образования до 2025 года.</p>
                            <p>Цель Конкурса - повышение профессионального уровня и наиболее полной реализации
                                творческого
                                потенциала психологов образования России, повышения престижа психологической службы в
                                системе образования Российской Федерации.</p>
                            <p>Основные задачи Конкурса:</p>
                            <ul>
                                <li>создание условий для самореализации педагогов-психологов, раскрытия их творческого
                                    потенциала;
                                </li>
                                <li>выявление талантливых педагогов-психологов системы образования Российской Федерации,
                                    их
                                    поддержка и поощрение;
                                </li>
                                <li>распространение передового профессионального опыта педагогов-психологов организаций,
                                    осуществляющих образовательную деятельность, центров психолого-педагогической,
                                    медицинской и социальной помощи;
                                </li>
                                <li>тиражирование лучших психолого-педагогических практик и инновационных технологий
                                    оказания психолого-педагогической помощи участникам образовательных отношений.
                                </li>
                            </ul>
                            <p>Конкурс проводится в два этапа: региональный и федеральный.</p>
                            <p>Региональный этап проводится организационными комитетами региональных конкурсов
                                профессионального мастерства «Педагог-психолог - 2020» в очной, заочной или смешанных
                                формах, в том числе с применением дистанционных технологий.</p>
                            <p>Федеральный этап включает два тура: экспертный и суперфинал, который пройдет в г. Москве
                                в
                                срок до 5 октября 2020 года.</p>
                            <p>Конкурс освещается на информационных площадках Минпросвещения России - <a
                                href="http://edu.gov.ru">http://edu.gov.ru</a>,
                                Центра защиты прав и интересов детей - <a href="http://fcprc.ru">http://fcprc.ru</a>,
                                Федерации психологов образования
                                России - <a href="www.rospsy.ru">www.rospsy.ru</a> и странице Конкурса - <a
                                    href="педагогпсихолог.рф">педагогпсихолог.рф</a>.
                            </p>
                        </div>
                        {respData[2].children[1].files ? respData[2].children[1].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[2].children[1].refs ? respData[2].children[1].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}
                {respData[2].children[2] ?
                    <Falldown2 href={respData[2].children[2].ref} header={respData[2].children[2].theme}>
                        <p></p>
                        <div className={s.blockText}>
                            <p>Министерство просвещения Российской Федерации объявляет о проведении <strong>Всероссийского
                                конкурса
                                профессионального мастерства «ПЕДАГОГ-ПСИХОЛОГ РОССИИ - 2019»</strong></p>
                            <p>Конкурс является практической программой, ориентированной на повышение профессионального
                                уровня и наиболее полной реализации творческого потенциала психологов образования
                                России,
                                развитие Службы практической психологии образования, пропаганду психологических знаний
                                как
                                обязательной составляющей образовательной деятельности, способствующей повышению
                                гуманистической направленности, эффективности, конкурентоспособности российского
                                образования.</p>
                            <p><strong>Конкурс проводится в целях</strong> повышения профессионального уровня и наиболее
                                полной реализации
                                творческого потенциала психологов образования России, повышения престижа психологической
                                службы в системе образования в Российской Федерации.</p>
                            <p><strong>Основными задачами Конкурса</strong> являются:</p>
                            <ul>
                                <li>создание условий для самореализации педагогов-психологов, раскрытия их творческого
                                    потенциала;
                                </li>
                                <li>выявление талантливых педагогов-психологов системы образования Российской Федерации,
                                    их
                                    поддержка и поощрение;
                                </li>
                                <li>распространение передового опыта работы профессионального опыта педагогов-психологов
                                    организаций, осуществляющих образовательную деятельность, на всех уровнях
                                    образования.
                                </li>
                            </ul>
                            <p>Тиражирование лучших психолого-педагогических практик и инновационных технологий оказания
                                психолого-педагогической помощи участникам образовательных отношений.</p>
                        </div>
                        {respData[2].children[2].files ? respData[2].children[2].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[2].children[2].refs ? respData[2].children[2].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}
            </Falldown> : <div></div>}

        {/*Всероссийский конкурс социальной рекламы "Стиль жизни - здоровье"*/}

        {(respData ? respData[3] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[3].img} header={respData[3].theme}>
                {respData[3].files ? respData[3].files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                {respData[3].refs ? respData[3].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[3].children[5] ?
                    <Falldown2 href={respData[3].children[5].ref} header={respData[3].children[5].theme}>
                        <p></p>
                        <div className={s.blockText}>
                            <p>
                                Всероссийский конкурс социальной рекламы «Стиль жизни - здоровье! 2024» направлен на
                                повышение эффективности формирования и распространения культуры здорового и безопасного
                                образа жизни среди детей и подростков, профилактику аддиктивного и аутодеструктивного
                                поведения обучающихся, повышение воспитательного потенциала образовательных организаций.
                            </p>
                            <p><strong>Конкурс проводится по двум номинациям:</strong></p>
                            <p>«Социальный видеоролик по пропаганде здорового и безопасного образа жизни, направленный на профилактику зависимого поведения обучающихся»</p>
                            <p>«Наглядный раздаточный материал по пропаганде здорового и безопасного образа жизни, направленный на профилактику зависимого поведения обучающихся»</p>
                            <p>Целевой аудиторией Конкурса являются обучающиеся образовательных организаций двух
                                возрастных
                                групп: 8-12 лет и 13-18 лет</p>
                            <p><strong>Конкурс проводится в два заочных этапа:</strong></p>
                            <p>Региональный этап – по 31 октября 2024 года</p>
                            <p>Федеральный этап – с 1 по 29 ноября 2024 года</p>
                            <p><strong>К участию в федеральном этапе Конкурса допускаются победители регионального
                                этапа!</strong></p>
                        </div>
                        {respData[3].children[5].files ? respData[3].children[5].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[3].children[5].refs ? respData[3].children[5].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}
                {respData[3].children[4] ?
                    <Falldown2 href={respData[3].children[4].ref} header={respData[3].children[4].theme}>
                        <p></p>
                        <div className={s.blockText}>
                            <p>
                                Всероссийский конкурс социальной рекламы «Стиль жизни - здоровье! 2023» направлен на
                                повышение эффективности формирования и распространения культуры здорового и безопасного
                                образа жизни среди детей и подростков, профилактику аддиктивного и аутодеструктивного
                                поведения обучающихся, повышение воспитательного потенциала образовательных
                                организаций.</p>
                            <p><strong>Конкурс проводится по двум номинациям:</strong></p>
                            <p>«Социальный видеоролик по пропаганде здорового и безопасного образа жизни, направленный
                                на профилактику зависимого поведения обучающихся»</p>
                            <p>«Наглядный раздаточный материал по пропаганде здорового и безопасного образа жизни,
                                направленный на профилактику зависимого поведения обучающихся»</p>
                            <p>Целевой аудиторией Конкурса являются обучающиеся образовательных организаций двух
                                возрастных
                                групп: 8-12 лет и 13-18 лет</p>
                            <p><strong>Конкурс проводится в два заочных этапа:</strong></p>
                            <p>Региональный этап – по 27 октября 2023 года</p>
                            <p>Федеральный этап – с 30 октября по 30 ноября 2023 года</p>
                            <p><strong>К участию в федеральном этапе Конкурса допускаются победители регионального
                                этапа!</strong></p>
                        </div>
                        {respData[3].children[4].files ? respData[3].children[4].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[3].children[4].refs ? respData[3].children[4].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}
                {respData[3].children[0] ?
                    <Falldown2 href={respData[3].children[0].ref} header={respData[3].children[0].theme}>
                        <p></p>
                        <div className={s.blockText}>
                            <p>Всероссийский конкурс социальной рекламы «Стиль жизни - здоровье! 2022» направлен на
                                повышение
                                эффективности формирования и распространения культуры здорового и безопасного образа
                                жизни
                                среди детей и подростков, профилактику аддиктивного и аутодеструктивного поведения
                                обучающихся, повышение воспитательного потенциала образовательных организаций</p>
                            <p><strong>Конкурс проводится по двум номинациям:</strong></p>
                            <p>«Социальный видеоролик по пропаганде здорового и безопасного образа жизни, направленный
                                на
                                профилактику зависимого поведения обучающихся»</p>
                            <p>«Наглядный раздаточный материал по пропаганде здорового и безопасного образа жизни,
                                направленный на профилактику зависимого поведения обучающихся»</p>
                            <p>Целевой аудиторией Конкурса являются обучающиеся образовательных организаций двух
                                возрастных
                                групп: 8-12 лет и 13-18 лет</p>
                            <p><strong>Конкурс проводится в два заочных этапа:</strong></p>
                            <p>Региональный этап – по 28 октября 2022 года</p>
                            <p>Федеральный этап – с 31 октября по 30 ноября 2022 года</p>
                            <p><strong>К участию в федеральном этапе Конкурса допускаются победители регионального
                                этапа!</strong></p>
                        </div>
                        {respData[3].children[0].files ? respData[3].children[0].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[3].children[0].refs ? respData[3].children[0].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}
                {respData[3].children[1] ?
                    <Falldown2 href={respData[3].children[1].ref} header={respData[3].children[1].theme}>
                        <p></p>
                        <div className={s.blockText}>
                            <p>Всероссийский конкурс социальной рекламы «Стиль жизни - здоровье! 2021» направлен на
                                повышение эффективности формирования и распространения культуры здорового и безопасного
                                образа жизни в среде детей и подростков; профилактику аддиктивного и аутодеструктивного
                                поведения обучающихся в образовательных организациях; внедрение современных форм и
                                методов
                                просвещения с целью популяризации здорового и безопасного образа жизни; обновление
                                наглядно-методического инструментария профилактической деятельности; повышение
                                воспитательного потенциала образовательных организаций.</p>
                            <p>Конкурс проводится по двум номинациям:</p>
                            <ul>
                                <li>1. «Социальный видеоролик по пропаганде здорового и безопасного образа жизни,
                                    направленный на профилактику зависимого поведения обучающихся»;
                                </li>
                                <li>2. «Наглядный раздаточный материал по пропаганде здорового и безопасного образа
                                    жизни,
                                    направленный на профилактику зависимого поведения обучающихся».
                                </li>
                            </ul>
                            <p>Целевой аудиторией Конкурса являются обучающиеся образовательных организаций двух
                                возрастных
                                групп: 1 группа: 8-12 лет; 2 группа: 13-18 лет.</p>
                            <p>Конкурс проводится в два заочных этапа:</p>
                            <ul>
                                <li>региональный этап - с 30 апреля по 31 октября 2021 года;</li>
                                <li>федеральный этап - с 1 по 30 ноября 2021 года.</li>
                            </ul>
                            <p>К участию в федеральном этапе Конкурса допускаются победители регионального этапа.</p>
                            <p>2 декабря 2021 года члены жюри Всероссийского конкурса социальной рекламы в области
                                формирования культуры здорового и безопасного образа жизни «Стиль жизни - здоровье!
                                2021»
                                подвели итоги федерального этапа Конкурса.</p>
                        </div>
                        {respData[3].children[1].files ? respData[3].children[1].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[3].children[1].refs ? respData[3].children[1].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}
                {respData[3].children[2] ?
                    <Falldown2 href={respData[3].children[2].ref} header={respData[3].children[2].theme}>
                        <p></p>
                        <div className={s.blockText}>
                            <p>Всероссийский конкурс социальной рекламы «Стиль жизни - здоровье! 2020» направлен на
                                повышение эффективности формирования культуры здорового и безопасного образа жизни,
                                профилактику аддиктивного поведения среди обучающихся образовательных организаций;
                                внедрение
                                современных форм и методов просвещения, обновление наглядно-методического инструментария
                                профилактической деятельности, повышение воспитательного потенциала образовательных
                                организаций.</p>
                            <p>Конкурс проводится по двум номинациям:</p>
                            <p>1. «Социальный видеоролик по пропаганде здорового и безопасного образа жизни,
                                профилактике
                                зависимого поведения обучающихся»;</p>
                            <p>2. «Наглядный раздаточный материал по пропаганде здорового и безопасного образа жизни,
                                профилактике зависимого поведения обучающихся» (буклет, плакат).</p>
                            <p>Целевой аудиторией Конкурса являются обучающиеся образовательных организаций двух
                                возрастных
                                групп: 1 группа: 8-12 лет; 2 группа: 13-18 лет.</p>
                            <p>Конкурс проводится в два заочных этапа:</p>
                            <p>I этап - региональный: с 13 апреля по 1 ноября 2020 года.</p>
                            <p>II этап - федеральный (экспертиза конкурсных работ, поступивших по итогам проведения I
                                этапа): со 2 по 30 ноября 2020 года.</p>
                            <p>К участию в федеральном этапе Конкурса <strong>допускаются победители
                                регионального</strong> этапа.</p>
                        </div>
                        {respData[3].children[2].files ? respData[3].children[2].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[3].children[2].refs ? respData[3].children[2].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}
                {respData[3].children[3] ?
                    <Falldown2 href={respData[3].children[3].ref} header={respData[3].children[3].theme}>
                        <p></p>
                        <div className={s.blockText}>
                            <p>Всероссийский конкурс социальной рекламы «Стиль жизни - здоровье!» направлен на повышение
                                эффективности формирования культуры здорового и безопасного образа жизни, профилактику
                                аддиктивного поведения среди обучающихся образовательных организаций; внедрение
                                современных
                                форм и методов просвещения, обновление наглядно-методического инструментария
                                профилактической деятельности, повышение воспитательного потенциала образовательных
                                организаций.</p>
                            <p>Конкурс проводится по двум номинациям:</p>
                            <p>1. «Социальный видеоролик по пропаганде здорового и безопасного образа жизни,
                                профилактике
                                зависимого поведения обучающихся»;</p>
                            <p>2. «Наглядный раздаточный материал по пропаганде здорового и безопасного образа жизни,
                                профилактике зависимого поведения обучающихся» (буклет, плакат).</p>
                            <p>Конкурс проводится в двух возрастных группах: 1 группа: 8-12 лет; 2 группа: 13-18
                                лет.</p>
                            <p>Конкурс проводится в два заочных этапа:</p>
                            <p>I этап — региональный: <strong>с 25 сентября по 10 ноября 2019 г.</strong> II этап —
                                федеральный: <strong>с 10 ноября
                                    2019 г. по 30 ноября 2019 года.</strong></p>
                            <p>К участию в федеральном этапе Конкурса допускаются победители регионального этапа.</p>
                        </div>
                        {respData[3].children[3].files ? respData[3].children[3].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[3].children[3].refs ? respData[3].children[3].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}
            </Falldown> : <div></div>}

        {/*Международный фестиваль «Другое искусство»*/}

        {(respData ? respData[4] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[4].img} header={respData[4].theme}>
                {respData[4].files ? respData[4].files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                {respData[4].refs ? respData[4].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[4].children[0] ?
                    <Falldown2 href={respData[4].children[0].ref} header={respData[4].children[0].theme}>
                        <p></p>
                        <div className={s.blockText}>
                            <p>С 22 по 25 июня 2022 г. на площадках Пскова при поддержке Министерства просвещения
                                Российской
                                Федерации и администрации Псковской области пройдет международный фестиваль особенных
                                людей
                                «Другое искусство».</p>
                            <p>Фестиваль имеет большое значение для привлечения внимания общества к искусству,
                                представляемому людьми с ограниченными возможностями здоровья, для понимания обществом
                                значения социокультурной инклюзии.</p>
                            <p>Цель фестиваля – включение людей с инвалидностью в жизнь общества с помощью театрального,
                                музыкального, изобразительного искусства, а также привлечение внимания государственных
                                структур, общественных и коммерческих организаций к решению проблем жизнеустройства
                                людей с
                                инвалидностью в обществе.</p>
                            <p>Фестиваль «Другое искусство» проводится в Пскове с 2016 года и станет пятым по счету в
                                2022
                                году. Программа форума включает пять направлений: театральное, выставочное, музыкальное,
                                образовательное и кинематографическое. Участники «Другого искусства» – творческие
                                коллективы, включающие детей и взрослых с инвалидностью, деятели искусства, культуры,
                                специального образования из Пскова и Псковской области, Санкт-Петербурга, Москвы,
                                Великого
                                Новгорода, Краснодара, Брянска, Иркутска, Орска, а также из Республики Беларусь.
                                Ожидается,
                                что в Псковском фестивале примут участие более 180 актеров, художников, музыкантов.</p>
                            <p>В рамках деловой части фестиваля 23.06.2022 в очно-дистанционном формате состоится
                                круглый
                                стол «Образование и социокультурная инклюзия людей с выраженными интеллектуальными
                                нарушениями, с тяжелыми множественными нарушениями развития». В обсуждении актуальных
                                вопросов современного образования и включения людей с особенностями развития в жизнь
                                общества примут участие родители, представители педагогической науки и практики, органов
                                власти, общественных организаций.</p>
                            <p>Актуальную информацию и программу фестиваля «Другое искусство» можно найти на официальном
                                сайте: <a href="https://differart.com/">https://differart.com/</a>.</p>
                        </div>
                        {respData[4].children[0].files ? respData[4].children[0].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[4].children[0].refs ? respData[4].children[0].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}
            </Falldown> : <div></div>}

        {/*Всероссийский конкурс "Учитель года России"*/}

        {(respData ? respData[5] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[5].img} header={respData[5].theme}>
                {respData[5].files ? respData[5].files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                {respData[5].refs ? respData[5].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[5].children[0] ?
                    <Falldown2 href={respData[5].children[0].ref} header={respData[5].children[0].theme}>
                        {respData[5].children[0].files ? respData[5].children[0].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[5].children[0].refs ? respData[5].children[0].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}
            </Falldown> : <div></div>}

        {/*Всероссийский конкурс профессионального мастерства "Учитель-дефектолог России"*/}

        {(respData ? respData[6] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[6].img} header={respData[6].theme}>
                {respData[6].files ? respData[6].files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                {respData[6].refs ? respData[6].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[6].children[0] ?
                    <Falldown2 href={respData[6].children[0].ref} header={respData[6].children[0].theme}>
                        {respData[6].children[0].files ? respData[6].children[0].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[6].children[0].refs ? respData[6].children[0].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}
            </Falldown> : <div></div>}

        {/*Всероссийский конкурс "Доброшкола"*/}

        {(respData ? respData[8] : false) ?
            <Falldown style={{margin: "0 auto"}} url={respData[8].img} header={respData[8].theme}>
                {respData[8].files ? respData[8].files.map(file => <FileLi href={file.file} text={file.name}/>) : <></>}
                {respData[8].refs ? respData[8].refs.map(ref => <HrefLi href={ref.ref} text={ref.name}/>) : <></>}
                {respData[8].children[0] ?
                    <Falldown2 href={respData[8].children[0].ref} header={respData[8].children[0].theme}>
                        {respData[8].children[0].files ? respData[8].children[0].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[8].children[0].refs ? respData[8].children[0].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}
                {respData[8].children[1] ?
                    <Falldown2 href={respData[8].children[1].ref} header={respData[8].children[1].theme}>
                        {respData[8].children[1].files ? respData[8].children[1].files.map(file => <FileLi
                            href={file.file}
                            text={file.name}/>) : <></>}
                        {respData[8].children[1].refs ? respData[8].children[1].refs.map(ref => <HrefLi href={ref.ref}
                                                                                                        text={ref.name}/>) : <></>}
                    </Falldown2> : <></>}
            </Falldown> : <div></div>}
        <p></p>
    </div>
}


export default Vseros
