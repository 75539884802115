import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News0106242.jpg"
import {NavLink} from "react-router-dom";

const News0106242 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Викторина по финансовой грамотности
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            При поддержке Главного управления Банка России по ЦФО
            <p></p>
            ФГБУ «Центр защиты прав и интересов детей» при поддержке
            Банка России с1 по 10 июня 2024 г. проводит викторину-онлайн
            по финансовой грамотности. Приглашаем принять участие
            в викторине детей от 12 лет. С помощью викторины можно
            проверить свои знания в финансовой сфере.
            <p></p>
            Первые 10 участников, правильно ответивших на все вопросы
            викторины, получатсертификатпобедителя викторины необычный
            сувенир от Банка России. Для уточнения организаторами викторины
            информации о способе доставки приза необходимо будет указать
            имя и контактный номер телефона. Участники, не ответившие на все
            вопросы викторины, получат сертификат об участии в викторине.
            <p></p>
            Прежде чем переходить к викторине необходимо прочесть комикс
            по финансовой грамотности,
            <p></p>
            <NavLink style={{fontWeight: "bold"}} to="/concurs">Комикс по финансовой грамотности</NavLink>
            <p></p>
            Желаем удачи!
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News0106242;