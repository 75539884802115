import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News110822.jpg"

const News110822 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Размещен сборник материалов Всероссийской научно-практической конференции по актуальным проблемам
            профилактики девиантного поведения несовершеннолетних них «НА ЗАЩИТЕ ДЕТСТВА, СТРАТЕГИИ, ПРАКТИКИ, РЕСУРСЫ»!
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Специалистами ФГБУ «Центр защиты прав и интересов детей» подготовлен <strong>сборник
                материалов</strong> по итогам работы <strong>Всероссийской научно-практической конференции по актуальным
                проблемам профилактики девиантного поведения
                несовершеннолетних</strong> «НА ЗАЩИТЕ ДЕТСТВА: СТРАТЕГИИ, ПРАКТИКИ, РЕСУРСЫ», проведенной
                Минпросвещения России
                31 мая – 1 июня 2022 года.</p>
            <p>В сборнике представлены материалы по ключевым вопросам предупреждения и устранения причин и условий,
                способствующих формированию девиантного поведения несовершеннолетних, с учетом современных исследований
                и успешной практики профилактической работы. В центре внимания – реализация приоритетных направлений
                государственной политики в сфере профилактики девиантного поведения, задачи и проблемы развития системы
                профилактики безнадзорности и правонарушений несовершеннолетних, современные тренды проектирования
                деятельности в области профилактики асоциального поведения обучающихся, включая передовой инновационный
                опыт работы субъектов Российской Федерации.</p>
            <p>Материалы сборника адресованы специалистам органов и учреждений системы профилактики безнадзорности и
                правонарушений несовершеннолетних и всем специалистам, чья деятельность связана с профилактикой
                негативных явлений в детско-подростковой среде.</p>
            <p>Сборник размещен на странице заочного этапа Конференции.</p>
            <p>Для получения электронного доступа к сборнику необходимо заполнить<br/>
                <a href="/news/otkryt-dostup-dlya-zaochnogo-uchastiya-vo-vserossijskoj-nauchno-prakticheskoj-konferentsii-na-zashhite-detstva-strategii-praktiki-resursy/">регистрационную форму участника</a>.
            </p>
        </div>
        <ToNews/>
    </div>
}


export default News110822;