import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News270624.jpg"

const News270624 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Установочная встреча на тему месяца «Медиативные технологии в решении конфликтов» для участников проекта #РодительскаяГостиная 20 июня 2024 год
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Отдел медиации Центра защиты прав и интересов детей с коллегами из Федерального подросткового центра
            развивает тему медиации для родительских отношений с подростками в рамках мероприятия #РодительскаяГостиная.
            <p></p>
            Начальник отдела медиации ФГБУ «Центр защиты прав и интересов детей», педагог-психолог и медиатор Анатолий
            Козлов 20 июня 2024 года провёл установочную встречу для представителей организаций-участников на тему
            месяца — «Медиативные технологии в решении конфликтов»
            <p></p>
            Федеральный подростковый центр с июля 2023 года проводит онлайн-встречи #РодительскаяГостиная.
            <p></p>
            На сегодняшний день мероприятия охватили уже 85 регионов и ежемесячно объединяют более 500 организаций с
            разных уголков страны.
            <p></p>
            Участвующие в нём организации собирают членов семей подростков и проводят для них лекцию на тему месяца, а
            также организуют досуговую часть, которая помогает сплотить родственников подростков в семейное сообщество,
            создает благоприятную обстановку для общения и развития родителей в контексте понимания подросткового
            возраста и поиска новых способов укрепления отношений с детьми.
            <p></p>
            Материалы мероприятия, в том числе запись установочной встречи: <strong><a href="https://vk.com/wall-223735036_295">https://vk.com/wall-223735036_295</a></strong>
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News270624;