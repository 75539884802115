import React, {useEffect} from "react";
import s from "./Default.module.css"
import {Redirect, Route} from 'react-router-dom';

const News271223 = ({children, header}) => {


    window.location.replace("https://disk.yandex.ru/i/ssdN3-Azx6j3QA");

    return <div className={s.page}>

    </div>
}


export default News271223;