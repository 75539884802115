import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News131223.jpg"

const News1412232 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Обучающиеся СУВУ провели мастер-класс по медиации
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            6 декабря 2023 года впервые состоялось мероприятие в новом формате для обучающихся специальных
            учебно-воспитательных учреждений, подведомственных Минпросвещения России.

            <p></p>
            Уникальность мероприятия состояла в том, что ведущими мастер-класса стали юные медиаторы Ишимбайского СУВУ
            Демченко Анастасия и Ульянова Полина.
            <p></p>

            Девушки в онлайн-формате провели мастер-класс по медиации на тему «Путешествие в пустыне». В мероприятии
            приняли участие обучающиеся Майкопского, Каргатского, Куртамышского, Неманского, Абаканского, Щекинского,
            Астраханского, Санкт-Петербургского, Ишимбайского и Орловского СУВУ.

            <p></p>
            На мероприятии ребята вместе вспомнили понятия «медиация», «медиатор», искали способы решения разных
            ситуаций с помощью использования техник медиации: я-сообщение», рефрейминг, петля-понимания.

            <p></p>
            Педагоги, присутствовавшие на мероприятии, были удивлены тем, как участники быстро и непринужденно выполняли
            задания и общались друг с другом.

            <p></p>
            Анастасия и Полина в первый раз были ведущими интерактивного мероприятия в онлайн-формате. Это не просто.
            Девушкам помогла поддержка участников, ребят из других СУВУ.
            <p></p>

            Отдельно Центр защиты прав и интересов детей хочет выразить глубокую благодарность всему составу службы
            медиации Ишимбайского СУВУ за высокий профессионализм и преданность делу. Служба медиации Ишимбайского СУВУ
            работает уже несколько лет, за это время коллегам удалось внедрить медиативные технологии в повседневную
            жизнь и детей и взрослых в СУВУ. Общение происходит с использованием я-сообщения, петли понимания,
            рефрейминга. Обучающиеся и сотрудники могут спокойно говорить о своих потребностях и чувствах, благодаря
            доверительной атмосфере в СУВУ.
            <p></p>

            Мероприятие проходило при организационно-методическом сопровождении сотрудников отдела медиации Центр защиты
            прав и интересов детей.
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News1412232;