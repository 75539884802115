import s from "./suvu.module.css"
import {useState} from "react";
import menu from "../../assets/SVG/burger-menu-svgrepo-com.svg"
import menu2 from "../../assets/SVG/burger2.svg"
import {NavLink} from "react-router-dom";
import statImg from "../../assets/NewSuvu/suvuStat.PNG"
import icon1 from "../../assets/NewSuvu/Group 186.svg"
import icon2 from "../../assets/NewSuvu/Group 187.svg"
import icon3 from "../../assets/NewSuvu/Group 189.svg"
import ex from "../../assets/NewSuvu/Rectangle 64.png"
import pr1 from "../../assets/NewSuvu/pr1.svg"
import pr2 from "../../assets/NewSuvu/pr2.svg"
import pr3 from "../../assets/NewSuvu/pr3.svg"
import pr4 from "../../assets/NewSuvu/pr4.svg"
import pr5 from "../../assets/NewSuvu/pr5.svg"
import pr6 from "../../assets/NewSuvu/pr6.svg"
import RussianMap from "./RussianMap";

const NewSUVU = () => {
    return (
        <div style={{backgroundColor: "#eaf2f4"}}>
            <NavBar/>
            <div className={s.main}>
                <Stats/>
                <NewsBlock/>
                <PeopleBlock/>
                <RussianMap/>
                <Partners/>
            </div>
        </div>
    )
}
export default NewSUVU

const NavBar = () => {
    const [open, setOpen] = useState(false)
    return (
        <div className={s.nav}>
            <div className={s.info}>
                <div className={s.upper}>
                    <span>
                        <NavLink to="/spec-suvu/news-suvu/">
                            Новости
                        </NavLink>
                    </span>
                    <span>
                        <NavLink to="/spec-suvu/meropriyatiya/">
                            Всероссийские мероприятия
                        </NavLink>
                    </span>
                    <span>
                        <NavLink to="/spec-suvu/ludi-fakti-sobitiya/">
                            Люди. События. Факты.
                        </NavLink>
                    </span>
                    <span>
                        <NavLink to="/spec-suvu/normativno-pravovye-dokumenty/">
                            Регулирование СУВУ
                        </NavLink>
                    </span>
                    <span>
                        <NavLink to="/spec-suvu/informatsionno-analiticheskie-materialy/">
                            Информационно-методические материалы
                        </NavLink>
                    </span>
                    <span className={s.menu} onClick={() => setOpen(!open)}>
                        <img src={open ? menu2 : menu} alt=""/>
                    </span>

                </div>
                {open ?
                    <div className={s.bottom}>
                        <span>
                            <NavLink to="/navigator">
                                Навигатор СУВУ
                            </NavLink>
                        </span>
                        <span>
                            <NavLink to="#">
                                Регионы
                            </NavLink>
                        </span>
                        <span>
                            <NavLink to="#">
                                Мультимедиа
                            </NavLink>
                        </span>
                        <span>
                            <NavLink to="#">
                                Контакты
                            </NavLink>
                        </span>
                        <span>
                            <a href="https://lk-suvu.fcprc.ru/">
                                Личные кабинеты
                            </a>
                        </span>
                    </div> : <></>
                }
            </div>
        </div>
    )
}

const Stats = () => {
    return (
        <div className={s.statsBlock}>
            <div className={s.left}>
                <div className={s.statText}>
                    <strong>Специальные учебно-воспитательные учреждения открытогои закрытого типа для обучающихся с
                        девиантным
                        поведением,</strong> нуждающихся в особых условиях воспитания, обучения и требующих специального
                    педагогического подхода создаются Российской Федерацией или субъектом Российской Федерации
                </div>
            </div>
            <div className={s.center}>
                <img src={statImg} alt=""/>
            </div>
            <div className={s.right}>
                <div className={s.statMini}>
                    В учреждения закрытого типа обучаются несовершеннолетние в возрасте от 11 до 18 лет
                    <div className={s.Img}>
                        <img src={icon1} alt=""/>
                    </div>
                </div>
                <div className={s.statMini}>
                    В учреждения открытого типа обучаются несовершеннолетние в возрасте от 8 до 18 лет
                    <div className={s.Img}>
                        <img src={icon2} alt=""/>
                    </div>
                </div>
                <div className={s.statMini}>
                    <div>
                        <span style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            textAlign: "right",
                            paddingBottom: "10px"
                        }}>4030</span><br/>
                        общая численность обучающихся (ведомственная статистика 1 января 2023 г.
                    </div>
                    <div className={s.Img}>
                        <img src={icon3} alt=""/>
                    </div>

                </div>
            </div>
        </div>
    )
}

const NewsBlock = () => {
    return (
        <div>
            <div className={s.name}>
                <h3>
                    Новости
                </h3>
                <NavLink to="/spec-suvu/news-suvu/">
                    Все новости
                </NavLink>
            </div>
            <div className={s.newsBlock}>
                <NewsCard/>
                <NewsCard/>
                <NewsCard/>
            </div>
        </div>

    )
}

const PeopleBlock = () => {
    return (
        <div>
            <div className={s.name}>
                <h3>
                    Люди.События.Факты
                </h3>
                <NavLink to="/spec-suvu/ludi-fakti-sobitiya/">
                    Все анонсы
                </NavLink>
            </div>
            <div className={s.peopleBlock}>
                {newsArray.map((el) =>
                    <PeopleCard {...el}/>
                )}
            </div>
        </div>

    )
}

const NewsCard = () => {
    return (
        <div className={s.newsCard}>
            <div className={s.image}>
                <img src={ex} alt=""/>
            </div>
            <div className={s.text}>
                <div className={s.date}>
                    26.03.2024
                </div>
                <div className={s.title}>
                    <a href="#">Вебинар #8 Языковой арт-резиденции «Как продвигать современную литературу через
                        дизайн»</a>
                </div>
            </div>
        </div>
    )
}

const PeopleCard = ({title, img, date, url}) => {
    return (
        <div className={s.peopleCard}>
            <img src={img} alt=""/>
            <div className={s.text}>
                <div style={{fontSize: "14px", paddingBottom: "10px"}}>
                    {date}
                </div>
                <div style={{fontSize: "18px", height: "80px"}}>
                    <a href={url}>{title}</a>
                </div>
            </div>
        </div>
    )
}

const RussiaMap = () => {
    return (
        <div className={s.map}>
            <NavLink to="/mediacia/reestr-2020-tso-2/" className={s.name}>
                <h3 style={{marginTop: "0", textDecorationLine: "none"}}>
                    Реестр некоммерческих организаций, применяющих восстановительные, а также медиативные технологии
                </h3>
            </NavLink>
        </div>
    )
}

const Partners = () => {
    return (
        <div>
            <div className={s.name}>
                <h3>
                    Партнеры
                </h3>
            </div>
            <div className={s.partners}>
                <a href="http://deti.gov.ru/" className={s.partner}>
                    <img src={pr1} alt=""/>
                </a>
                <a href="https://oprf.ru/" className={s.partner}>
                    <img src={pr2} alt=""/>
                </a>
                <a href="https://fond-detyam.ru/" className={s.partner}>
                    <img src={pr3} alt=""/>
                </a>
                <a href="http://ol-journal.ru/" className={s.partner}>
                    <img src={pr4} alt=""/>
                </a>
                <a href="http://solnechnymir.ru/" className={s.partner}>
                    <img src={pr5} alt=""/>
                </a>
                <a href="http://fincult.info/" className={s.partner}>
                    <img src={pr6} alt=""/>
                </a>
            </div>
        </div>
    )
}



const newsArray = [
    {
        id: 1,
        title: "Семинар о медиативно-восстановительных технологиях в системе профилактики",
        img: "https://fcprc.ru/media/media/behavior/NewsEx.png",
        date: "26.03.2024",
        url: "/"
    },
    {
        id: 2,
        title: "21 февраля День открытых дверей SAS",
        img: require("../../assets/NewSuvu/NewsEx.png"),
        date: "26.03.2024",
        url: "/"
    },
    {
        id: 3,
        title: "24 февраля Академические уикенды: Полярная лихорадка. Сценарии развития Арктики",
        img: require("../../assets/NewSuvu/NewsEx.png"),
        date: "26.03.2024",
        url: "/"
    },
    {
        id: 4,
        title: "24 февраля Академические уикенды: Полярная лихорадка. Сценарии развития Арктики",
        img: require("../../assets/NewSuvu/NewsEx.png"),
        date: "26.03.2024",
        url: "/"
    },
]