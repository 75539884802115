import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import logo from "../../../assets/Behavior/conceptions.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {NavLink} from "react-router-dom";
import {hrefsArrFPF} from "../FilePage/FilesPublicationsForms";



const PageKonceps = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2>
                    Концепция развития системы профилактики безнадзорности и правонарушений несовершеннолетних
                </h2>
            </div>
            <div>
                <img src={logo}/>
            </div>

            <p style={{fontSize: "17px", color: "#212529"}} className={s.concept}>
                <p><a href="https://fcprc.ru/wp-content/uploads/2019/08/Rasporyazhenie-Prav-RF-520-_.pdf"
                      target="_blank" rel="noopener">Концепция развития системы профилактики безнадзорности и
                    правонарушений несовершеннолетних</a> на период до 2025 года (далее — Концепция)
                    представляет собой систему взглядов, принципов и приоритетов в профилактической работе с
                    несовершеннолетними, предусматривает основные направления, формы и методы совершенствования и
                    развития системы профилактики безнадзорности и правонарушений несовершеннолетних, направленные на
                    достижение основных задач в этой сфере.</p>
                <p></p>
                <p>Концепция разработана на основании <a href="http://www.consultant.ru/document/cons_doc_LAW_28399/"
                                                         target="_blank" rel="noopener nofollow">Конституции Российской
                    Федерации</a><a href="http://www.un.org/ru/documents/decl_conv/conventions/childcon.shtml"
                                    target="_blank" rel="noopener nofollow">Конвенции ООН о правах ребенка</a>,
                    Федерального закона «<a href="http://pravo.gov.ru/proxy/ips/?docbody=nd=102054607" target="_blank"
                                            rel="noopener nofollow">Об основных гарантиях прав ребенка в Российской
                        Федерации</a>», Федерального закона «<a
                        href="http://www.consultant.ru/document/cons_doc_LAW_140174/" target="_blank"
                        rel="noopener nofollow">Об образовании в Российской Федерации</a>», Федерального закона «<a
                        href="http://www.consultant.ru/document/cons_doc_LAW_199976/" target="_blank"
                        rel="noopener nofollow">Об основах системы профилактики правонарушений в Российской
                        Федерации</a>» и с учетом общепризнанных принципов и норм международного права и международных
                    договоров Российской Федерации по вопросам профилактики правонарушений несовершеннолетних.</p>
                <p>


                    В Концепции также учитываются положения Стратегии национальной безопасности Российской Федерации,
                    относящей создание единой государственной системы профилактики преступности, в первую очередь среди
                    несовершеннолетних (включая мониторинг и оценку эффективности правоприменительной практики), к
                    мерам, способствующим обеспечению государственной и общественной безопасности на долгосрочную
                    перспективу, а также Стратегии развития воспитания в Российской Федерации на период до 2025 года,
                    ориентированной на развитие социальных институтов воспитания, обновление воспитательного процесса в
                    системе общего и дополнительного образования, в сферах физической культуры и спорта, культуры на
                    основе оптимального сочетания отечественных традиций, современного опыта, достижений научных школ,
                    культурно-исторического, системно-деятельностного подхода к социальной ситуации развития ребенка.
                </p>

                <p>
                    Концепция призвана служить основой для принятия органами государственной власти Российской
                    Федерации, органами государственной власти субъектов Российской Федерации и органами местного
                    самоуправления решений в области правоприменительной деятельности, касающихся повышения
                    эффективности системы профилактики безнадзорности и правонарушений несовершеннолетних и создания
                    условий для их успешной социализации (ресоциализации).
                </p>

                <p>

                    <h1 style={{fontFamily: 'Proxima Nova Lt', fontSize: "26px", color: "#3a4663"}}>
                        Ожидаемые результаты реализации Концепции
                    </h1>

                </p>
                <p>
                    Итогом реализации Концепции станет сформированная система дополнительных мер, направленных на
                    выявление и устранение причин и условий, способствующих правонарушениям и антиобщественным действиям
                    несовершеннолетних, развитие системы профилактики безнадзорности и правонарушений
                    несовершеннолетних.
                </p>

                <p>


                    Оценка эффективности реализации Концепции проводится на основе данных постоянного мониторинга. По
                    результатам реализации Концепции к 2025 году будут достигнуты следующие целевые показатели
                    (индикаторы):
                    <ul>


                        <li>
                            снижение доли несовершеннолетних, совершивших преступления, в общей численности
                            несовершеннолетних в возрасте от 14 до 17 лет;
                        </li>
                        <li>
                            снижение доли осужденных несовершеннолетних, совершивших повторные преступления, в общей
                            численности несовершеннолетних, состоящих на учете в уголовно-исполнительных инспекциях;

                        </li>
                        <li>
                            повышение до 100 процентов доли несовершеннолетних, приступивших к обучению в
                            общеобразовательных организациях, в общей численности несовершеннолетних, подлежащих
                            обучению;

                        </li>
                        <li>
                            увеличение количества несовершеннолетних осужденных, состоящих на учете в
                            уголовно-исполнительных инспекциях, получивших социально-психологическую и иную помощь, по
                            отношению к общей численности несовершеннолетних осужденных, нуждающихся в ней, до 90
                            процентов
                            в 2025 году;
                        </li>
                        <li>
                            снижение доли несовершеннолетних, совершивших повторные преступления, в общей численности
                            несовершеннолетних участников преступлений;

                        </li>
                        <li>
                            снижение доли несовершеннолетних, совершивших преступления, административные правонарушения
                            и иные антиобщественные действия, в период проведения с ними различными органами и
                            учреждениями системы профилактики безнадзорности и правонарушений несовершеннолетних
                            индивидуальной профилактической работы, в общей численности лиц этой возрастной категории, с
                            которыми проводилась индивидуальная профилактическая работа.

                        </li>
                    </ul></p>
            </p>
            <p><a className={s.concepcA} href="https://fcprc.ru/media/media/behavior/Kontseptsiya-razvitiya-sistemy-profilaktiki_2020_szxbwJ0.pdf">Концепция развития системы профилактики_2020.pdf</a></p>
            <p><a className={s.concepcA} href="https://fcprc.ru/media/media/behavior/Postanovlenie-Pravitelstva-Rossijskoj-Federatsii-ot-18-marta-2021-goda-65_BcYJY0G.pdf">Постановление Правительства Российской Федерации от 18 марта 2021 года № 656-р.pdf</a></p>
            <p><a className={s.concepcA} href="https://fcprc.ru/media/media/behavior/Plan-meropriyatij-po-realizatsii-Kontseptsii-na-2021-2025-gody_X9uViZ7.pdf">План мероприятий на 2021-2025 годы по реализации Концепции.pdf</a></p>
        </div>
        <div style={{marginTop: "57px"}}>
            <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            <WhiteButton text="Твое право" to="/tvoyo-pravo"/>

        </div>


    </div>


}

export const HrefsBeh1 = ({hrefsArr, stylef = {}, to = "/"}) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>
            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({text, stylesheet}) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({text, stylesheet, to}) => {
    return <div className={s.whitebutton}>
        <NavLink to={to}>
        {text}
        </NavLink>
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default PageKonceps