import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/ResultsAndProblemsOfEBA.jpg"

const ResultsAndProblemsOfEBA = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Результаты и проблемы внедрения доказательного подхода в сферу детства в России
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Онлайн конференции пользуются большой популярностью, так как позволяют объединить большое количество
                спикеров и слушателей.</p>
            <p>22-23 ноября Подростковый федеральный центр принял участие в научно-практической онлайн конференции
                «Результаты и проблемы внедрения доказательного подхода в сферу детства в России».</p>
            <p>Цель конференции — описание опыта внедрения доказательного подхода в социальной сфере и образовании.</p>
            <p>Конференция особенно была интересна тем, кто интересуется темой доказательности в целом и особенностями
                ее применения.</p>
            <p>Первый день конференции, в котором спикером выступил руководитель Федерального подросткового центра
                Валерий Майоров, был посвящен анализу прорывов и барьеров внедрения доказательного подхода на
                федеральном уровне в социальной сфере. Валерий рассказал об открывшихся региональных подростковых
                центрах и поделился планами на будущее.</p>
            <p>Второй день конференции посвящен презентации и разбору кейсов практик, описанных на основе Стандарта
                доказательности и размещенных в Реестре доказательных практик «Десятилетия детства» на федеральной
                платформе обмена практиками «Смартека» Агентства стратегических инициатив.</p>
            <p>Благодарим коллег за приглашение и возможность участия.</p>
        </div>
        <ToNews/>
    </div>
}


export default ResultsAndProblemsOfEBA;