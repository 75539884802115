import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/News210723_1.jpg"
import img2 from "../../assets/News/News210723_2.jpg"

const News210723 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Всероссийская конференция по восстановительному правосудию «Восстановительное правосудие: жизнь, практика,
            наука» 28 – 30 июня 2023 года
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Сотрудники отдела медиации Центра с 28 по 30 июня 2023 года приняли участие в ежегодной Всероссийской
                конференции по восстановительному правосудию с международным участием «Восстановительное правосудие:
                жизнь, практика, наука».
            </p>
            <p>
                Организатором конференции выступила Всероссийская ассоциация восстановительной медиации совместно с
                Московским государственным психолого-педагогическим университетом (МГППУ), на базе которого
                осуществляется подготовка специалистов по магистерской программе «Восстановительный подход и медиативные
                технологии в образовании и системе профилактики социальных рисков».
            </p>
            <p>
                В этом году конференция проходила в онлайн формате. Участвовали более ста человек из разных регионов
                России.</p>
            <p>Восстановительное правосудие является современной мировой социальной практикой реагирования на жёсткие
                конфликты, правонарушения, насилие и травлю. При этом, данная практика актуальна не только в уголовном
                праве, но и в образовательной и социальной сфере. Поэтому конференция вызвала интерес и отклик у
                специалистов, работающих в разных сферах.</p>
            <p>В этому году на конференции было несколько сессий.</p>
            <p>На сессии «Исследования в области примирительных процедур и восстановительного правосудия» были
                представлены российские количественные и качественные социологические и психологические исследования в
                области восстановительных практик и служб примирения. Пока отечественных исследований в этой сфере не
                так много, поэтому важно развивать это направление деятельности. Научные исследования являются серьезным
                фактором в укреплении доказательности практики восстановительного правосудия, выявлении новых важных
                феноменов, и развития российской модели восстановительного правосудия.</p>
            <p>Укреплению российской модели восстановительного правосудия способствовала сессия с докладами по
                ежегодному мониторингу территориальных служб примирения и служб примирения в образовательных
                организациях.</p>
            <p>Сессия «Вопросы институционализации восстановительных практик: международный опыт» была посвящена
                понимание мировых тенденций развития восстановительных практик, чтобы интегрировать актуальные научные
                разработки и обсуждения в отечественную практику.</p>
            <p>Российский опыт восстановительного правосудия и служб примирения в регионах был представлен на сессии
                для начинающих «Восстановительные практики: в начале пути…».</p>
            <p>Для опытных специалистов проходила сессия «Инновационные проекты продвижения восстановительного подхода
                в регионах», а были представлены секционные доклады по направлениям: «территориальные службы
                примирения», «школьные службы примирения», «восстановительные практики в социальной сфере».</p>
            <p>Был представлен опыт 19 субъектов Российской Федерации, а также опыт Республики Беларусь.</p>
            <p>Обмен новыми разработками и профессиональное общение на конференции дают импульс к дальнейшему развитию
                восстановительного правосудия и служб примирения в России.</p>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <ToNews/>
    </div>
}


export default News210723;