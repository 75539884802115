import React from "react";
import ico from "../../../assets/fileicored.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, WhiteButton} from "../PageInfo/PageInfo"
import {hrefsArrFPF} from "./FilesPublicationsForms";
import {FileLeftLineKDN} from "./FilesKDNNews";

const FilesConceptMon = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>
        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Программа мониторинга реализации Концепции
                </h2>
                <FileLeftLineKDN text={respData[259]?.description} refers={respData[259]?.file} ico={ico}/>
            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
                <WhiteButton text="Твое право" to="/tvoyo-pravo"/>
            </div>
        </div>
    </>
}
export default FilesConceptMon