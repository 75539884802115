import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News1707232.jpg"

const News1707232 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинары для родителей обучающихся специальных учебно-воспитательных учреждений в апреле 2023 года
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div className={s.refNews} style={{fontFamily: "Proxima Nova Lt"}}>
            <p>25 и 26 апреля 2023 года отдел медиации Центра защиты прав и интересов детей провел два вебинара для
                родителей (законных представителей) обучающихся специальных учебно-воспитательных учреждений,
                подведомственных Минпросвещения России по теме «Медиативные технологии как способ договориться с
                ребенком».</p>
            <p>На вебинарах родители узнали основные понятия и сущность медиативного подхода в вопросах общения с
                ребенком и выстраивания конструктивных внутрисемейных отношений. Ведущие вебинара рассматривали причины
                конфликтов и способы их урегулирования на основе медиативных технологий. Ключевой частью вебинаров был
                практической разбор конфликтной ситуации между родителем и ребенком с позиции медиативного подхода.</p>
            <p>Данные вебинары для родителей уже стали традиционными и в этом году к ним проявили интерес более 107 родителей и законных представителей обучающихся СУВУ.</p>
            <p>Продолжение вебинаров запланировано на 2 полугодие 2023 года.</p>
            <p>Ведущие вебинаров: начальник отдела медиации Центра защиты прав и интересов детей – Быкова Марина Викторовна и аналитик II категории отдела медиации – Козлов Анатолий Андреевич.</p>
            <p>Также все заинтересованные могут ознакомиться с записью подобного вебинара проведенного в 2022 году: <a href="https://www.youtube.com/watch?v=GVajpa3UeqA">https://www.youtube.com/watch?v=GVajpa3UeqA</a></p>
        </div>
        <ToNews/>
    </div>
}


export default News1707232;