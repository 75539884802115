import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/bestIncSchool.png"

const MoscowFourthDecade = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            В Москве 18 ноября 2022 года состоялась IV Всероссийская конференция по вопросам реализации Десятилетия
            детства.
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>В пленарном заседании и трёх малых пленумах приняли участие более 3000 человек: представителей
                федеральных и региональных органов исполнительной власти, заинтересованных организаций и экспертного
                сообщества.</p>
            <p>В рамках пленарного заседания обсуждены важнейшие аспекты качественного развития ключевых механизмов
                Десятилетие детства и значимые итоги реализации Десятилетие детства.</p>
            <p>С приветствием к участникам обратились Заместитель Председателя Правительства Российской Федерации,
                Председатель Координационного Совета при Правительстве Российской Федерации по проведению в Российской
                Федерации Десятилетия детства Татьяна Алексеевна Голикова, председатель Комитета Совета Федерации по
                науке, образованию и культуре Лилия Салаватовна Гумерова и председатель Комитета по вопросам семьи,
                женщин и детей Государственной Думы Федерального Собрания Российской Федерации Нина Александровна
                Останина, первый заместитель Министра просвещения Российской Федерации Александр Вячеславович
                Бугаев.</p>
            <p>Директор Департамента государственной политики в сфере защиты прав детей Минпросвещения России Лариса
                Павловна Фальковская в своем докладе представила ключевые направления, объединяющие реализацию плана
                основных мероприятий, проводимых в рамках Десятилетия детства.</p>
            <p>На тематических малых пленумах обсуждались вопросы участия детей и подростков в формирования и принятия
                управленческих решений, значимые практикоориентированные результаты научных исследований в сфере
                детства, а также вопросы поддержки семей с детьми, сохранения и укрепления традиционных семейных
                ценностей.</p>
            <p>Модераторами и спикерами секций стали члены Координационного совета при Правительстве Российской
                Федерации по проведению в Российской Федерации Десятилетия детства и руководители действующих при нем
                рабочих групп, представители Общественной палаты Российской Федерации, представители федеральных и
                региональных органов законодательной и исполнительной властей, некоммерческих организаций,
                Уполномоченные по правам ребенка в субъектах Российской Федерации, ведущие профильные российские
                эксперты.</p>
            <p><strong>Справочно:</strong> организаторы конференции – Минпросвещения России, ФГБУ «Центр защиты прав и
                интересов детей».</p>
            <p>2018–2027 годы в Российской Федерации объявлены Десятилетием детства в соответствии с Указом Президента
                Российской Федерации от 29 мая 2017 года № 240.</p>
            <p>План основных мероприятий, проводимых в рамках Десятилетия детства, на период до 2027 года утвержден
                распоряжением Правительства Российской Федерации от 23 января 2021
                года № 122-р.</p>
        </div>
        <ToNews/>
    </div>
}


export default MoscowFourthDecade;