import React from "react";
import ico from "../../../assets/arrow.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1} from "../PageInfo/PageInfo"
import Falldown, {Falldown2, FileLi, HrefLi} from "../FalldownList/Falldown";
import {FileLeftLineKDN, FileLeftLineKDNPlus} from "./FilesKDNNews";

const hrefsArr = [
    {text: "Регламентирующие документы", to: "/regDocks"},
    {text: "Методическое сопровождение", to: "/met"},
    {text: "Часто задаваемые вопросы", to: "/sptfaq"},
]
const PopQuest = () => {
    const [respData, setData] = useState([])


    return <>

        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Часто задаваемые вопросы
                </h2>
                <h2 style={{fontSize: "27px"}}>
                    Используемые сокращения
                </h2>
                <div className={s.terminsText}>
                    <p>
                        <strong>СПТ</strong> – социально-психологическое тестирование обучающихся в общеобразовательных организациях и
                        профессиональных образовательных организациях, а также образовательных организациях высшего
                        образования
                    </p>
                    <p>
                        <strong>ПМО</strong> – профилактические медицинские осмотры обучающихся в общеобразовательных организациях и
                        профессиональных образовательных организациях, а также образовательных организациях высшего
                        образования
                    </p>
                    <p>
                        <strong>Порядок проведения СПТ</strong> – Порядок проведения социально-психологического тестирования обучающихся
                        в
                        общеобразовательных организациях и профессиональных образовательных организациях, утвержденный
                        приказом Минпросвещения России от 20 февраля 2020 г. № 59

                    </p>
                    <p>
                        <strong>Порядок проведения ПМО</strong> – Порядок проведения профилактических медицинских осмотров обучающихся в
                        общеобразовательных организациях и профессиональных образовательных организациях, а также
                        образовательных организациях высшего образования в целях раннего выявления незаконного
                        потребления
                        наркотических средств и психотропных веществ, утвержденный приказом Минздрава России от 6
                        октября
                        2014 г. № 581н
                    </p>
                </div>
                <h2 style={{fontSize: "27px", color: "#3a4663"}}>
                    Термины, применяемые на настоящей странице:
                </h2>
                <div className={s.terminsText}>
                    <p>
                        <strong>Аддиктивное поведение</strong> (от англ. <i>addiction</i> склонность, пагубная привычка) – форма деструктивного
                        поведения, которое выражается в стремлении к уходу от реальности посредством специального
                        изменения
                        своего психического состояния через злоупотребление наркотическими средствами и психотропными
                        веществами
                    </p>
                    <p>
                        <strong>Профилактическая работа</strong> – профилактика употребления психоактивных веществ как комплекс
                        социальных,
                        образовательных, психологических и медицинских воздействий, направленных на выявление и
                        устранение
                        причин и условий, способствующих распространению и употреблению ПАВ, на предупреждение развития
                        и
                        ликвидацию негативных личностных, социальных и медицинских последствий употребления ПАВ.
                    </p>
                </div>
                <h2 style={{fontSize: "27px"}}>
                    Общие вопросы
                </h2>
                <FileLeftLineKDNPlus
                    text={"Что такое социально-психологическое тестирование?"}
                    ico={ico}
                    answer={<div>Социально-психологическое тестирование (СПТ) – это мероприятие, направленное на раннее
                        выявление незаконного потребления наркотических средств и психотропных веществ среди обучающихся
                        в образовательных организациях субъектов Российской Федерации. СПТ проводится в образовательных
                        организациях в соответствии со статьей 53.4 Федерального закона от 8 января 1998 г. № 3-ФЗ «О
                        наркотических средствах и психотропных веществах» и является первым этапом раннего выявления
                        незаконного потребления наркотических средств и психотропных веществ среди обучающихся. Второй
                        этап – проведение профилактических медицинских осмотров (ПМО).<p></p>
                        СПТ – это психодиагностическое исследование, в котором обучающиеся в образовательных организациях
                        участвуют ежегодно. Процедура проведения СПТ регулируется Порядком проведения СПТ. <br/>
                        С 2019 года СПТ проводится по Единой методике, которая представляет собой опросник, направленный
                        на исследование социально-психологических условий, формирующих психологическую готовность к
                        рисковому (аддиктивному) поведению у лиц подросткового и юношеского возраста.</div>}/>
                <FileLeftLineKDNPlus
                    text={"С какого возраста или с какого класса проводится СПТ?"}
                    ico={ico}
                    answer={<div>СПТ проводится в отношении обучающихся, достигших возраста 13 лет, начиная с 7 класса
                        обучения в общеобразовательной организации, что означает необходимость соблюдения обоих условий
                        для участия в тестировании.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Зачем нужно СПТ?"}
                    ico={ico}
                    answer={<div>По результатам СПТ среди обучающихся определяются группы риска вовлечения в аддиктивное
                        поведение.
                        <br/>
                        Анализ полученных результатов помогает определить направленность и содержание дальнейшей
                        профилактической и воспитательной работы в образовательной организации, позволяет оказывать
                        обучающимся группы риска своевременную психолого-педагогическую помощь и поддержку.

                        <p></p>
                        Таким образом, СПТ является диагностическим компонентом для построения адресной профилактической
                        и воспитательной работы с обучающимися.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Как часто проводится СПТ?"}
                    ico={ico}
                    answer={<div>Согласно п. 4 Порядка проведения СПТ, в общеобразовательных организациях и
                        профессиональных образовательных организациях СПТ проводится ежегодно. Мероприятия СПТ
                        проводятся в начале учебного года, что позволяет использовать результаты СПТ в профилактической
                        и воспитательной работе образовательной организации в течение всего учебного года.
                        <p></p>
                        Одновременно, проведение СПТ в начале учебного года определено необходимостью дальнейшего
                        проведения профилактических медицинских осмотров (ПМО). Порядок проведения ПМО обязывает органы
                        исполнительной власти субъектов Российской Федерации в сфере охраны здоровья составлять список
                        образовательных организаций субъектов Российской Федерации, участвующих в ПМО, не позднее чем за
                        1 месяц до начала календарного года.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Является ли тестирование обязательным или добровольным для обучающегося?"}
                    ico={ico}
                    answer={<div>Статьей 53.4 Федерального закона от 8 января 1998 г. № 3-ФЗ «О наркотических средствах
                        и психотропных веществах» определено, что СПТ проводится только при наличии информированного
                        согласия (самого обучающегося, если он достиг возраста 15 лет, или его родителей). </div>}/>
                <FileLeftLineKDNPlus
                    text={"В какой форме проводится СПТ?"}
                    ico={ico}
                    answer={<div>Согласно п. 7 Порядка проведения СПТ, тестирование может проводиться как в бланковой,
                        так и в компьютерной форме.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Можно ли выявить факт потребления наркотиков или психотропных веществ с помощью СПТ?"}
                    ico={ico}
                    answer={<div>Нет.
                        <p></p>
                        СПТ не выявляет наркопотребителей. С помощью СПТ исследуются социально-психологические условия,
                        формирующие психологическую готовность к рисковому (аддиктивному) поведению у лиц подросткового и
                        юношеского возраста.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Какие нормативные правовые акты определяют необходимость проведения СПТ в образовательной организации?"}
                    ico={ico}
                    answer={<div>Согласно статье 53.4 Федерального закона от 8 января 1998 г. № 3-ФЗ «О наркотических
                        средствах и психотропных веществах» СПТ является первым этапом мероприятий раннего выявления
                        незаконного потребления наркотических средств и психотропных веществ среди обучающихся.
                        <p></p>
                        Подпункт 7 пункта 1 статьи 14 Федерального закона от 24 июня 1999 г. № 120-ФЗ «Об основах
                        системы профилактики безнадзорности и правонарушений несовершеннолетних» устанавливает, что
                        органы, осуществляющие управление в сфере образования, в пределах своей компетенции обеспечивают
                        проведение мероприятий по раннему выявлению незаконного потребления наркотических средств и
                        психотропных веществ в общеобразовательных организациях и профессиональных образовательных
                        организациях, а также образовательных организациях высшего образования.
                        <p></p>
                        Пункт 15.1 части 3 статьи 28 Федерального закона от 29 декабря 2012 г. № 273-ФЗ «Об образовании
                        в Российской Федерации» относит проведение СПТ к компетенции образовательной организации.
                    </div>}/>
                <FileLeftLineKDNPlus
                    text={"Какие нормативные правовые акты определяют условия проведения СПТ в образовательной организации?"}
                    ico={ico}
                    answer={<div>Порядок проведения социально-психологического тестирования обучающихся в
                        общеобразовательных и профессиональных образовательных организациях утвержден Приказом
                        Минпросвещения России от 20 февраля 2020 г. № 59.
                        <p></p>
                        В образовательных организациях высшего образования СПТ организуется в соответствии с Порядком
                        проведения СПТ, утвержденным приказом Министерства науки и высшего образования РФ от 20 февраля
                        2020 г. № 239.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Каков опыт применения тестирования? Каковы его результаты?"}
                    ico={ico}
                    answer={<div>В России СПТ проводится с 2014 года.
                        <p></p>
                        С 2019 года тестирование проводится по Единой методике, правообладателем которой является
                        Министерство просвещения Российской Федерации.
                        <p></p>
                        В 2022/23 учебном году мероприятиями СПТ было охвачено 98,99% образовательных организаций и
                        93,57% обучающихся, подлежащих тестированию.
                        <p></p>
                        Благодаря реализации организационных мер, обеспечивающих проведение СПТ в субъектах Российской
                        Федерации, в образовательных организациях сформировалась система адресной профилактической и
                        воспитательной работы, обеспечивающей психолого-педагогической сопровождение обучающихся.
                    </div>}/>
                <FileLeftLineKDNPlus
                    text={"Какие вопросы задаются обучающимся в ходе проведения СПТ?"}
                    ico={ico}
                    answer={<div>В Единой методике СПТ представлены вопросы на понятном для обучающихся языке, которые
                        касаются различных сторон личности подростка/юноши, особенностей его реакции на определенные
                        жизненные обстоятельства и выбора стратегии поведения в них. Психологические опросники не
                        предполагают правильного (хорошего) ответа или неправильного (нехорошего). Важен любой ответ,
                        главное, чтобы он был искренним и отражал точку зрения респондента.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Кому будут известны результаты тестирования?"}
                    ico={ico}
                    answer={<div>Согласно Порядку проведения СПТ, как при проведении СПТ, так и при хранении его
                        результатов должна обеспечиваться конфиденциальность.
                        <p></p>
                        «Конфиденциальность информации – обязательное для выполнения лицом, получившим доступ к
                        определенной информации, требование не передавать такую информацию третьим лицам без согласия ее
                        обладателя» (пункт 7 статьи 2 Федерального закона от 27 июля 2006 г. № 149-ФЗ «Об информации,
                        информационных технологиях и о защите информации»).
                        <p></p>
                        Для обеспечения конфиденциальности анкеты обезличены, а обучающимся присваиваются персональные
                        коды. Список соответствия персональных кодов и ФИО обучающихся не подлежит распространению и
                        размещению в открытом доступе.
                        <p></p>
                        За обеспечение конфиденциальности при проведении СПТ и хранении его результатов ответственность
                        несет руководитель образовательной организации. </div>}/>
                <FileLeftLineKDNPlus
                    text={"Могут ли результаты СПТ быть использованы для диагностики заболеваний?"}
                    ico={ico}
                    answer={<div>Нет, результаты СПТ не являются основанием для постановки какого-либо диагноза. СПТ
                        представляет собой психодиагностический опросник, который состоит из набора утверждений,
                        касающихся различных сторон личности подростка/юноши, особенностей его реакции на определенные
                        жизненные обстоятельства и выбора стратегии поведения в них. </div>}/>
                <FileLeftLineKDNPlus
                    text={"На каком сайте нужно проходить СПТ?"}
                    ico={ico}
                    answer={<div>Согласно п. 15 Порядка проведения СПТ, органы исполнительной власти субъектов
                        Российской Федерации, осуществляющие государственное управление в сфере образования, выполняют
                        обработку и проведение анализа результатов тестирования.
                        <p></p>
                        Таким образом, в каждом субъекте Российской Федерации могут быть разработаны собственные
                        электронные платформы для тестирования. За подробными разъяснениями вы можете обратиться в орган
                        исполнительной власти субъектов Российской Федерации, осуществляющий государственное управление
                        в сфере образования в вашем субъекте Российской Федерации.</div>}/>

                <h2 style={{fontSize: "27px"}}>
                    Специалистам органов государственной власти и местного самоуправления, педагогическому коллективу
                    образовательной организации
                </h2>
                <FileLeftLineKDNPlus
                    text={"Что такое «региональный оператор СПТ»?"}
                    ico={ico}
                    answer={<div>Региональный оператор СПТ – организация, обеспечивающая координационное,
                        организационное, техническое сопровождение проведения СПТ в субъекте Российской Федерации.
                        <p></p>
                        Согласно п. 15 Порядка проведения СПТ органы исполнительной власти субъектов Российской
                        Федерации, осуществляющие государственное управление в сфере образования, выполняют обработку и
                        проведение анализа результатов тестирования. Региональный оператор, как правило, содействует
                        органам исполнительной власти в выполнении этих задач. Региональный оператор также обеспечивает
                        экспертно-методическое сопровождение работы специалистов образовательных организаций по
                        психолого-педагогическому сопровождению обучающихся по результатам тестирования.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Является ли проведение СПТ обучающихся обязательным для всех образовательных организаций?"}
                    ico={ico}
                    answer={<div>Нормативными правовыми актами не выделяются категории образовательных организаций, где
                        СПТ не является обязательным к проведению. Таким образом, тестированию подлежат обучающиеся всех
                        без исключения общеобразовательных организаций и профессиональных образовательных организаций,
                        вне зависимости от формы собственности и ведомственной подчиненности.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Обязательно ли проведение СПТ среди обучающихся с ограниченными возможностями здоровья?"}
                    ico={ico}
                    answer={<div>В нормативных правовых актах не описаны категории обучающихся, не подлежащих СПТ.
                        <p></p>
                        Статьей 53.4 Федерального закона от 8 января 1998 г. № 3-ФЗ «О наркотических средствах и
                        психотропных веществах» определено, что СПТ проводится только при наличии информированного
                        согласия (самого обучающегося, если он достиг возраста 15 лет, или его родителей).
                        <p></p>
                        Участие в СПТ обучающихся, осваивающих адаптированные основные общеобразовательные программы,
                        носит рекомендательный характер. На текущий момент в регионах сложилась следующая практика:
                        образовательная организация проводит педагогический совет, в ходе которого решается вопрос о
                        прохождении обучающимися с ОВЗ СПТ в зависимости от особенностей ребенка, конкретной
                        нозологии.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Нижняя возрастная граница тестирования – 13 лет, существует ли верхняя возрастная граница?"}
                    ico={ico}
                    answer={<div>Нормативные правовые акты, на основании которых проводится СПТ, не содержат сведений об
                        ограничениях по верхней границе возраста. Таким образом, тестированию подлежат все обучающиеся,
                        достигшие возраста 13 лет, начиная с 7 класса обучения в общеобразовательной
                        организации.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Должны ли дети, которым исполняется 13 лет на момент проведения мероприятий СПТ, участвовать в тестировании?"}
                    ico={ico}
                    answer={<div>Тестированию подлежат обучающиеся, достигшие возраста 13 лет, начиная с 7 класса
                        обучения в общеобразовательной организации (п. 2 Порядка проведения СПТ).
                        <p></p>
                        Мероприятия СПТ проводятся, как правило, не в один день, что позволяет распределить участие
                        обучающихся в определенный период времени.
                        <p></p>
                        При организации и проведении мероприятия СПТ стоит учитывать, что согласно ст. 191 Гражданского
                        кодекса Российской Федерации и Постановлению Пленума Верховного Суда РФ от 1 февраля 2011 г. № 1
                        лицо считается достигшим определенного возраста не в день рождения, а по его истечении, т. е. с
                        ноля часов следующих суток. </div>}/>
                <FileLeftLineKDNPlus
                    text={"Существует ли единая электронная платформа для проведения СПТ?"}
                    ico={ico}
                    answer={<div>Согласно п. 15 Порядка проведения СПТ, органы исполнительной власти субъектов
                        Российской Федерации, осуществляющие государственное управление в сфере образования, выполняют
                        обработку и проведение анализа результатов тестирования.
                        <p></p>
                        Таким образом, вопросы по работе электронных платформ для проведения СПТ относятся к компетенции
                        субъектов Российской Федерации.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Как использовать результаты СПТ в дальнейшей работе с обучающимися?"}
                    ico={ico}
                    answer={<div>Анализ результатов СПТ помогает определить направленность и адресное содержание
                        дальнейшей профилактической и воспитательной работы в образовательной организации, позволяет
                        оказывать обучающимся группы риска своевременную психолого-педагогическую помощь и поддержку.
                        <p></p>
                        Анализ результатов СПТ по каждой шкале методики позволяет определить и сильные (ресурсные)
                        стороны личности обучающегося, использовать эти данные при определении траектории развития.
                        <p></p>
                        Подробно ознакомиться с рекомендациями по использованию результатов СПТ в профилактической
                        работе можно на <a href="/met">этой странице</a>.</div>}/>
                <FileLeftLineKDNPlus
                    text={"В СПТ слишком много вопросов. Можно ли сделать опросник короче?"}
                    ico={ico}
                    answer={<div>Единая методика СПТ апробирована и пригодна к использованию только в изначальном,
                        неизменном виде. Недопустимо исключать какие-либо вопросы из методики.
                        <p></p>
                        Единственное исключение делается при проведении СПТ детей-сирот и детей, оставшихся без
                        попечения родителей. В целях снижения психологического напряжения и предотвращения
                        психотравмирующих ситуаций необходимо исключить из опросника утверждения шкалы «Принятие
                        родителями».</div>}/>
                <FileLeftLineKDNPlus
                    text={"Обучение в образовательной организации ведется не на русском языке. Существуют ли переводы Единой методики социально-психологического тестирования на другие языки?"}
                    ico={ico}
                    answer={<div>Согласно ст. 68 Конституции Российской Федерации, государственным языком Российской
                        Федерации на всей ее территории является русский язык.
                        <p></p>
                        Для Единой методики СПТ не существует рекомендованных к применению версий на каких-либо языках,
                        кроме русского. Во многом это связано с тем, что создание и валидизация психодиагностического
                        инструментария, особенно такого сложного, как Единая методика СПТ, представляет собой
                        комплексную ресурсоемкую научную задачу.
                        <p></p>
                        При проведении СПТ рекомендуется прохождение ее на русском языке.</div>}/>
                <FileLeftLineKDNPlus
                    text={"В течение какого срока нужно хранить информированные согласия на СПТ? Кто должен хранить согласия?"}
                    ico={ico}
                    answer={<div>Согласно п. 14 Порядка проведения СПТ, хранение согласий должно быть обеспечено
                        руководителем образовательной организации вплоть до момента отчисления обучающегося из
                        образовательной организации. </div>}/>
                <FileLeftLineKDNPlus
                    text={"Должны ли образовательные организации высшего образования проводить СПТ?"}
                    ico={ico}
                    answer={<div>Статьей 53.4 Федерального закона от 8 января 1998 г. № 3-ФЗ «О наркотических средствах
                        и психотропных веществах» определено, что мероприятия по раннему выявлению незаконного
                        потребления наркотических средств и психотропных веществ проводятся в образовательных
                        организациях высшего образования. Пункт 3 вышеуказанной статьи определяет, что Порядок
                        проведения СПТ в образовательных организациях высшего образования устанавливается федеральным
                        органом исполнительной власти, осуществляющим функции по выработке и реализации государственной
                        политики и нормативно-правовому регулированию в сфере высшего образования.
                        <p></p>
                        В образовательных организациях высшего образования СПТ организуется в соответствии с Порядком
                        проведения СПТ, утвержденным приказом Министерства науки и высшего образования РФ от 20 февраля
                        2020 г. № 239.</div>}/>


                <h2 style={{fontSize: "27px"}}>
                    Родителям (законным представителям)
                </h2>
                <FileLeftLineKDNPlus
                    text={"Зачем моему ребенку проходить СПТ?"}
                    ico={ico}
                    answer={<div>Результаты диагностики позволяют обучающемуся получить информацию о самом себе, своих
                        сильных и слабых сторонах, содействуя развитию навыков рефлексии, позволяющей адекватно
                        оценивать потенциальные риски и обезопасить себя от них.
                        <p></p>
                        СПТ является диагностическим компонентом для построения адресной профилактической и
                        воспитательной работы в образовательной организации. Полученные результаты определяют ее
                        направленность и содержание, позволяют оказывать обучающимся своевременную
                        психолого-педагогическую помощь.
                        <p></p>
                        Согласие на участие ребенка в тестировании – это ваша возможность удерживать в поле своего
                        внимания вопросы рисков и безопасного образа жизни детей и подростков.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Является ли тестирование обязательным или добровольным для моего ребенка?"}
                    ico={ico}
                    answer={<div>Статьей 53.4 Федерального закона от 8 января 1998 г. № 3-ФЗ «О наркотических средствах
                        и психотропных веществах» определено, что СПТ проводится только при наличии информированного
                        согласия (самого обучающегося, если он достиг возраста 15 лет, или его родителей). Более того,
                        согласно п. 12 Порядка проведения СПТ каждый обучающийся имеет право в любое время отказаться от
                        тестирования.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Кому будут переданы данные о результатах тестирования ребенка?"}
                    ico={ico}
                    answer={<div>Все результаты тестирования строго конфиденциальны! Каждому обучающемуся, принимающему
                        участие в тестировании, присваивается индивидуальный код участника, который делает невозможным
                        персонификацию данных. Таким образом, персональные результаты могут быть доступны только
                        нескольким лицам: самому обучающемуся (в адаптированном виде), родителю или законному
                        представителю ребенка, а также специалисту, который организует процесс тестирования в
                        образовательной организации.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Могу ли я присутствовать при прохождении моим ребенком СПТ?"}
                    ico={ico}
                    answer={<div>Согласно п. 10 Порядка проведения СПТ, при проведении тестирования допускается
                        присутствие в кабинете (аудитории) в качестве наблюдателей родителей (законных представителей)
                        обучающихся, участвующих в тестировании. Обратите внимание, что роль родителя является ролью
                        наблюдателя; родители не должны вмешиваться в процесс тестирования, оказывать давление,
                        подсказывать или иным образом влиять на ответы, даваемые обучающимися.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Кто именно должен подписывать информированное согласие на прохождение ребенком СПТ?"}
                    ico={ico}
                    answer={<div>Родители несовершеннолетних обучающихся, согласно статье 64 Семейного кодекса
                        Российской Федерации, являются законными представителями своих детей. Нет разницы, кто именно из
                        родителей подпишет согласие, если согласие будет дано.
                        <p></p>
                        Обучающиеся старше 15 лет подписывают информированное согласие самостоятельно.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Как я могу узнать результаты СПТ своего ребенка?"}
                    ico={ico}
                    answer={<div>Вам нужно обратиться в образовательную организацию, в которой обучается ваш ребенок.
                        Специалист, который организует процесс тестирования, или руководство образовательной организации
                        не могут отказать вам в том, чтобы вы ознакомились с результатами СПТ своего несовершеннолетнего
                        ребенка.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Могут ли меня или моего ребенка наказать за «нехорошие» результаты тестирования?"}
                    ico={ico}
                    answer={<div>Результаты СПТ не могут быть основанием для каких-либо негативных последствий для
                        ребенка или его родителей (законных представителей).
                        <p></p>
                        Стоит также отметить, что результаты психологической диагностики не могут быть «хорошими» или
                        «плохими». Результаты СПТ лишь позволяют индивидуализировать работу педагогического коллектива с
                        обучающимися.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Что делать, если мой ребенок не хочет проходить СПТ?"}
                    ico={ico}
                    answer={<div>Прежде всего, вам самим стоит понять, что такое СПТ. Далее вы можете провести
                        доверительную беседу с ребенком, чтобы выяснить, почему он отказывается от прохождения
                        тестирования. Не стоит давить на него или принуждать к тому или иному выбору. Решение о
                        прохождении СПТ должно быть принято взрослыми совместно с ребенком. Если ребенок не хочет
                        участвовать в СПТ, можно обсудить с ним причины его отказа и попробовать вместе найти
                        решение.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Что будет, если мой ребенок не пройдет СПТ?"}
                    ico={ico}
                    answer={<div>Непрохождение обучающимся СПТ не влечет никаких юридических или иных последствий.
                        <p></p>
                        Заметим, однако, что СПТ является диагностическим компонентом для построения адресной
                        профилактической и воспитательной работы в образовательной организации. Полученные результаты
                        определяют ее направленность и содержание, позволяют оказывать обучающимся своевременную
                        психолого-педагогическую помощь. Участие ребенка в тестировании – это ваша возможность
                        удерживать в поле своего внимания вопросы рисков и безопасного образа жизни детей и
                        подростков.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Какие последствия для семьи будут, если ребенок попадет в группу риска?"}
                    ico={ico}
                    answer={<div>Результаты тестирования не являются фактическим или юридическим основанием для
                        постановки тестируемого на какой-либо вид учета или для постановки какого-либо диагноза.
                        <p></p>
                        Тестирование служит точному определению направленности и содержания профилактической и
                        воспитательной работы с обучающимися, а также позволяет оказывать им своевременную адресную
                        психолого-педагогическую помощь.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Не будет ли тестирование провоцировать интерес ребёнка к наркотикам?"}
                    ico={ico}
                    answer={<div>Вопросы Единой методики СПТ не содержат информацию о каких-либо наркотических средствах
                        и психотропных веществах, не содержат вопросов об опыте потребления психоактивных веществ и не
                        акцентируют внимание респондента на проблеме наркопотребления.
                        <p></p>
                        Вопросы теста касаются различных сторон личности подростка/юноши, особенностей его реакции на
                        определенные жизненные обстоятельства и выбора стратегии поведения в них.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Могут ли результаты тестирования повлиять на будущую карьеру моего ребенка?"}
                    ico={ico}
                    answer={<div>Результаты СПТ не имеют никаких правовых последствий для дальнейшей трудовой карьеры.
                        <p></p>
                        Персональные результаты СПТ могут быть доступны только нескольким лицам: самому обучающемуся (в
                        адаптированном виде), родителю или законному представителю ребенка, а также специалисту, который
                        организует процесс тестирования в образовательной организации. Результаты СПТ не
                        распространяются далее образовательной организации, и, соответственно, не могут оказывать
                        какого-либо влияния на будущую карьеру человека.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Почему я не могу ознакомиться с вопросами СПТ?"}
                    ico={ico}
                    answer={<div>Единая методика СПТ не подлежит свободному распространению и размещению в открытом
                        доступе, что обусловлено необходимостью сохранения надежности методики. В случае, если
                        обучающиеся знакомы с вопросами заранее, они могут подготовить ответы, что может существенно
                        исказить результаты исследования.</div>}/>
                <FileLeftLineKDNPlus
                    text={"В классе с моим ребенком обучается ребенок, чье поведение меня беспокоит. Могу ли я узнать результаты тестирвоания этого ребенка?"}
                    ico={ico}
                    answer={<div>Результаты СПТ не передаются третьим лицам, поэтому у вас нет законного способа узнать о результатах СПТ кого-либо, кроме вашего ребенка. </div>}/>

                <h2 style={{fontSize: "27px"}}>
                    Обучающимся
                </h2>
                <FileLeftLineKDNPlus
                    text={"Зачем мне участвовать в СПТ?"}
                    ico={ico}
                    answer={<div>СПТ позволяет выявить твои сильные стороны как личности, то есть твои ресурсы, на
                        которые ты можешь опираться в жизни. Кроме того, ты, возможно, узнаешь о проблемных зонах –
                        чертах, которые могут осложнять твое общение с окружающими или затруднять преодоление стрессовых
                        ситуаций, и которые ты можешь в дальнейшем компенсировать или корректировать.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Могут ли меня наказать за «нехорошие» результаты тестирования?"}
                    ico={ico}
                    answer={<div>Нет, результаты тестирования не влекут за собой каких-либо санкций.
                        <p></p>
                        Стоит также отметить, что результаты психологической диагностики не могут быть «хорошими» или
                        «плохими». Результаты СПТ лишь позволяют психологам лучше понимать тебя.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Влияет ли СПТ на школьные оценки?"}
                    ico={ico}
                    answer={<div>Нет, это тестирование совершенно никак не связано ни с каким предметом, и за него не
                        выставляют оценок.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Является ли тестирование обязательным или добровольным?"}
                    ico={ico}
                    answer={<div>Добровольным.
                        <p></p>
                        Педагоги и родители, как правило, заинтересованы в твоем участии в СПТ, потому что это позволит
                        лучше и эффективнее организовывать образовательную среду, в которой ты проводишь большую часть
                        жизни. Но принуждать тебя к участию в тестировании у них нет права.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Если я отказался от участия в СПТ, могу ли я позже передумать и сдать тест?"}
                    ico={ico}
                    answer={<div>Обычно мероприятия СПТ проводятся в ограниченный период времени, в начале учебного
                        года. В течение этого периода у тебя сохраняется возможность пройти СПТ. Тем не менее, даже
                        после окончания этого периода ты можешь обсудить своё желание пройти СПТ со специалистом,
                        который организует процесс тестирования в образовательной организации (как правило, это
                        педагог-психолог школы). Не исключено, что пройти СПТ будет возможно и позднее.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Кто будет знать мои результаты СПТ?"}
                    ico={ico}
                    answer={<div>Результаты СПТ конфиденциальны, то есть никто посторонний не должен иметь возможность с
                        ними знакомиться. С результатами СПТ ознакомиться в дальнейшем сможешь ты сам(-а), твои родители
                        (законные представители), а также специалист, который организует процесс тестирования в
                        образовательной организации (как правило, это педагог-психолог школы).</div>}/>
                <FileLeftLineKDNPlus
                    text={"Могу ли я отказаться от участия в СПТ?"}
                    ico={ico}
                    answer={<div>Ты можешь в любой момент отказаться от прохождения тестирования, даже когда письменное
                        согласие ты или родители уже написали.
                        <p></p>
                        Но обрати внимание, пожалуйста, что в СПТ есть свои преимущества. Это интересный способ лучше
                        узнать себя.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Почему в СПТ так много вопросов?"}
                    ico={ico}
                    answer={<div>При малом количестве вопросов была бы выше вероятность ошибки. Чем больше вопросов, тем
                        точнее можно определить сильные стороны твоей личности или зоны, требующие внимательного
                        отношения в будущем.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Мне не нравятся, какие у меня результаты СПТ. Могу ли я пересдать его?"}
                    ico={ico}
                    answer={<div>СПТ проводится ежегодно, так что в следующий раз ты сможешь пройти тестирование через
                        год после последнего прохождения.
                        <p></p>
                        Если тебе не нравятся твои результаты, попробуй оценить, какие именно результаты тебя задевают
                        сильнее всего. Возможно, это – та сфера твоей жизни, в которой стоит прикладывать больше усилий,
                        стараться расти как личность и скорректировать своё поведение. Ты можешь обратиться с этим
                        вопросом к педагогу-психологу или другому взрослому, с которым у тебя установлены доверительные
                        отношения.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Как я могу подготовиться к СПТ?"}
                    ico={ico}
                    answer={<div>СПТ – не контрольная работа, к нему не нужно готовиться. В ходе СПТ тебе задаются
                        вопросы о твоем характере, поведении и окружении. Просто будь полон сил и настройся на
                        максимальную искренность. Самый верный ответ тот, который первым пришел в голову.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Зачем проходить СПТ каждый год? "}
                    ico={ico}
                    answer={<div>Если ранее ты уже проходил(-а) подобную диагностику, это делает тестирование даже
                        ценнее.
                        <p></p>
                        Год – это немалый срок, за который можно получить много новых знаний и опыта. Вновь ответив на
                        предложенные вопросы, ты получаешь возможность оценить эти изменения, осознать их и
                        скорректировать собственную траекторию личностного развития.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Могут ли мои родители присутствовать при прохождении СПТ?"}
                    ico={ico}
                    answer={<div>Да, но в только качестве наблюдателя. Родители не должны вмешиваться в процесс
                        тестирования, оказывать давление на тебя или подсказывать тебе ответы.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Я студент. С родителями не живу давно. Зачем мне отвечать на вопросы о родителях?"}
                    ico={ico}
                    answer={<div>Вопросы в СПТ, которые затрагивают отношения с родителями, касаются эмоционального
                        контакта и теплоты человеческих отношений. Они могут вполне сохраняться при раздельном
                        проживании.</div>}/>
                <FileLeftLineKDNPlus
                    text={"Я думаю, что результаты тестирования ошибочны. Я совсем не такой, как написано в результатах. Какие действия я могу предпринять?"}
                    ico={ico}
                    answer={<div>Результаты СПТ не являются окончательными и не определяют всю твою жизнь, а лишь
                        помогают понять некоторые черты твоей личности. Поэтому, даже если ты не согласен с результатами
                        СПТ, ничего серьёзного на самом деле не случилось. Это лишь повод проанализировать свои ответы и
                        понять себя.
                        <p></p>
                        Если у тебя есть вопросы по поводу тестирования или результатов, то обратись за помощью к
                        педагогу-психологу своей школы или к другому взрослому, с которым у тебя установлены
                        доверительные отношения. Они могут помочь тебе проанализировать результаты и дать рекомендации
                        по тому, как использовать их для своего личностного развития.</div>}/>
            </div>
            <div style={{marginTop: "57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArr}/>
            </div>
        </div>


    </>
}

export default PopQuest
