import React from "react";
import s from "./Default.module.css"
import ToNews from "../News/ToNews";
import img from "../../assets/News/News160724.jpg"

const News160724 = ({children, header}) => {

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Определены первые 20 победителей Всероссийского конкурса программ социализации подростков
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Экспертный совет рассмотрел 265 заявок на участие в четырех номинациях конкурса от специалистов со всей
            России! Среди них – команды из Москвы, Санкт-Петербурга и других крупных городов, а также маленьких
            населенных пунктов.
            <p></p>
            Каждая программа по-своему уникальна и направлена на социализацию подростков через искусство, литературу,
            кино, игровую деятельность, спорт или театр. В материалах участников представлены креативные идеи и
            предложения, в том числе особое внимание уделено развитию школьников через здоровый образ жизни.
            <p></p>
            Проведя очные собеседования с самыми яркими командами, экспертный совет определил 20 победителей, среди
            которых – действующие центры и команды, которым только предстоит реализовать свои идеи.
            <p></p>
            Совсем скоро подростки смогут и сами оценить представленные программы – специалисты получат ресурсную
            поддержку, экспертное сопровождение и необходимые методические рекомендации.
            <p></p>
            Среди материальных призов – мебель, телевизоры, ноутбуки, портативные проекторы и экраны, принтеры,
            переносные колонки. Кроме того, победители, в зависимости от номинации, получат планшеты/настольные
            игры/спортивные комплексы/наборы звукового оборудования, видеокамеры и ширмы.
            <p></p>
            Список победителей:
            <p></p>
            <strong>Номинация<br/>
                «Социализация через искусство, литературу и кино»</strong>
            <ul>
                <li>Подростковый центр «Свобода»<br/>
                    Кабардино-Балкарская, Республика, ст. Екатериноградская.
                </li>
                <li>Центр детского творчества «Радуга»,<br/>
                    Кировская область, г. Кирово-Чепец.
                </li>
                <li>Семейный центр «Зеленая школа»,<br/>
                    Омская область, г. Омск.
                </li>
                <li>Дом молодежи Василеостровского района Санкт-Петербурга,<br/>
                    г. Санкт-Петербург.
                </li>
                <li>Подростково-молодежный центр «Калининский», Проект «Образы времени»<br/>
                    г. Санкт-Петербург.
                </li>
            </ul>
            <strong>Номинация<br/>
                «Социализация через организацию свободного досуга с элементами игровой деятельности»</strong>
            <ul>
                <li>Подростковый центр «#Я_ДОМА»,<br/>
                Белгородская область, г. Белгород.
                </li>
                <li>Подростковый центр «К.И.В.И.»,<br/>
                Волгоградская область, г. Волгоград.
                </li>
                <li>Муниципальное учреждение культуры «Централизованная библиотечная система г. Саянска»,<br/>
                Иркутская область, г. Саянск.
                </li>
                <li>Центр развития творчества детей и юношества,<br/>
                Ростовская область, г. Новошахтинск.
                </li>
                <li>Детско-юношеская организация «Многодетных, приемных, молодых и неполных семей "Лоза"»,<br/>
                    Томская область, г. Северск.</li>
            </ul>
            <strong>Номинация<br/>
                «Социализация через спортивную деятельность»</strong>
            <ul>
                <li>Подростковый центр «Соколенок»,<br/>
                Республика Бурятия, г. Улан-Удэ.
                </li>
                <li>Подростковый центр «Гренада»,<br/>
                Республика Татарстан, г. Нижнекамск.
                </li>
                <li>Подростковый центр «Выручай комната»,<br/>
                Воронежская область, г. Воронеж.
                </li>
                <li>Объединение подростковых клубов «Перекресток»,<br/>
                Кировская область, г. Киров.
                </li>
                <li>Молодежный центр «Содружество»,<br/>
                Новосибирская область, г. Новосибирск.</li>
            </ul>
            <strong>Номинация<br/>
                «Социализация через театральную деятельность»</strong>
            <ul>
                <li>Подростковый центр «Диалог»,<br/>
                Ульяновская область, г. Ульяновск.
                </li>
                <li>Подростковый центр «РОСТ»,<br/>
                Челябинская область, г. Миасс.
                </li>
                <li>ГБУ «Мой семейный центр "Зеленоград"»,<br/>
                г. Москва.
                </li>
                <li>ГБУ «Мой семейный центр "Преображение"»,<br/>
                г. Москва.
                </li>
                <li>Подростково-молодежный центр «Калининский», Молодежное пространство «Сцена».<br/>
                г. Санкт-Петербург.</li>
            </ul>
            <i>*Ознакомиться с Протоколом заседания Организационного комитета можно по <a style={{color: "red"}} href="https://u.to/o6ylHw">ссылке</a>.</i>
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News160724;